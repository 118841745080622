import * as types from './actionTypes';

const initialState = {
  isOpen: false,
  /* Cleans refers that the user hasn't touched the menu
  ** Used to avoid extra animations on mount.
  */
  isClean: true
};

const menuReducer = (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case types.TOGGLE_MENU:
      return {
        ...state,
        isOpen: !state.isOpen,
        isClean: false
      };

    case types.OPEN_MENU:
      return {
        ...state,
        isOpen: true,
        isClean: true
      };

    case types.CLOSE_MENU:
      return {
        ...state,
        isOpen: false,
        isClean: true
      };

    default:
      return { ...state };
  }
};

export default menuReducer;
