import React from 'react';
import { TranslateContext } from '../../../../components';
import './index.css';

export const Title = () => (
  <TranslateContext.Consumer>
    {({ translate }) => (
      <div className="TitleImport-header">
        <h2>{translate('list.headerTitle')}</h2>
        <p>{translate('list.headerSubTitle')}</p>
      </div>
    )}
  </TranslateContext.Consumer>
);

export default Title;
