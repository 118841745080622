/**
 * 'Conversions' refers to formatting data.
 * There are 4 types of conversions:
 *
 * 1) From 'raw' to 'pretty'
 * 2) From 'editing' to 'raw;
 * 3) From 'pretty' to 'editing';
 * 4) From 'editing' to 'pretty';
 *
 * 'Raw' format refers to the format that
 * is sent from the backend to the frontend and vice versa.
 * It is an unlocalized format (where there is no
 * thousands separator and the decimals separator is always a dot);
 *
 * 'Editing' format refers to the format when the user is editing a cell.
 * It has restrictions on the amount of decimals and others.
 *
 * 'Pretty' is the format displayed when the cell is not being edited.
 * It is fully localized.
 *
 */
import numeral from 'numeral';
import memoizeFormatConstructor from 'intl-format-cache';
import {
  userCantUseEqual,
  userCantWriteNonAllowedCharacters,
  isAllowedCharacter
} from './userInputValidations';
import {
  errorHandlerForNumberValues,
  errorHandlerForStringValues,
  hasValidTypesForNumberValues,
  hasValidTypesForStringValues
} from './validations';
import {
  addMinusToDisplayValue,
  isDecimalMinusZero,
  isEmptyCellException,
  isPercentageMinusZero,
  rawToPrettyExceptionHandler
} from './exceptions';

const getNumberFormat = memoizeFormatConstructor(Intl.NumberFormat);

/**
 * Simple number localization.
 *
 * @param {*} value
 * @param {*} numberFormat
 * @param {*} options
 */
export function localizeNumber(value, numberFormat, options) {
  return {
    displayValue: getNumberFormat(numberFormat, options).format(value),
    rawValue: value
  };
}

/**
 * @param {*} value
 * @param {*} numberFormat
 * @param {*} style
 * @param {*} options
 * @param {*} errorIdentifier
 * @return {Object}
 */
export function throwErrorForRawNumbers(
  value,
  numberFormat,
  style,
  options,
  errorIdentifier
) {
  return errorHandlerForNumberValues(
    value,
    numberFormat,
    style,
    errorIdentifier
  );
}

/**
 * Usually triggered when cell has new data.
 * Converts from Number to String.
 *
 * It is hooked to the initial rendering state.
 */
export function rawToPretty({ ...args }) {
  const { value, style, viewName, numberFormat, precision, exception } = args;
  const errorIdentifier = 'raw2pretty';
  const options = {
    style,
    // Decimal cells should have no decimals.
    maximumFractionDigits: precision || 0
  };
  const exceptionValue = rawToPrettyExceptionHandler(
    value,
    style,
    viewName,
    exception
  );

  // Exceptions
  if (exceptionValue) return exceptionValue;
  // Percentages should have 1 decimal by default.
  // Note: 'style' in the frontend, is the 'display' sent from backend.
  if (style === 'percent') options.maximumFractionDigits = precision || 1;

  // End of exceptions
  return hasValidTypesForNumberValues(value, numberFormat, style)
    ? localizeNumber(value, numberFormat, options)
    : throwErrorForRawNumbers(
        value,
        numberFormat,
        style,
        options,
        errorIdentifier
      );
}

/**
 * Converts Number to String (localized but with restrictions).
 * Import: this takes the rawValue (because it contains the decimals)
 * whereas the prettyfied value (string) does not.
 *
 * It is hooked to the onFocus event.
 * @return {String}
 */
export function rawToEditing({ ...cellState }) {
  const { rawValue, style, numberFormat, displayValue } = cellState;
  const errorIdentifier = 'raw2edit';
  const options = {
    style,
    // Don't use thousands separator
    useGrouping: false,
    // Set digits back to 3
    minimumFractionDigits: 3
  };

  // Exceptions
  if (isEmptyCellException(rawValue)) {
    return {
      ...cellState,
      displayValue
    };
  }

  if (isDecimalMinusZero(rawValue, style)) {
    return addMinusToDisplayValue(
      localizeNumber,
      rawValue,
      numberFormat,
      options
    );
  }

  if (isPercentageMinusZero(rawValue, style)) {
    return addMinusToDisplayValue(
      localizeNumber,
      rawValue,
      numberFormat,
      options
    );
  }
  // End of exceptions

  return hasValidTypesForNumberValues(rawValue, numberFormat, style)
    ? localizeNumber(rawValue, numberFormat, options)
    : throwErrorForRawNumbers(
        rawValue,
        numberFormat,
        style,
        options,
        errorIdentifier
      );
}

/**
 * So data can be sent to the backend.
 * Usually is not visible to the user.
 * Converts String to Number.
 *
 * @return {Number}
 */
export function editingToRaw(args) {
  const { numberFormat, style } = args;
  let { displayValue } = args;

  const errorIdentifier = 'edit2raw';
  // Typechecking
  if (!hasValidTypesForStringValues(displayValue, numberFormat, style)) {
    return errorHandlerForStringValues(
      displayValue,
      numberFormat,
      style,
      errorIdentifier
    );
  }

  // Setting the right locale
  numeral.locale(numberFormat);

  // When converting to RAW a percentage number,
  // we either divide by 100
  // or let the numeral do the conversion.
  // In this case, the latter was preferred.
  if (style === 'percent' && !displayValue.includes('%')) {
    displayValue += '%';
  }

  if (style === 'decimal' && displayValue.includes('%')) {
    displayValue = displayValue.split('%', 1).join('');
  }

  return numeral(displayValue).value();
}

/**
 * Editing to Pretty has two steps:
 *
 * 1) Editing -> Raw
 * 2) Raw -> Pretty
 * @param {String} value
 * @param {String} numberFormat
 * @return {String}
 */
export function editingToPretty({ ...cellState }) {
  const { displayValue, style, numberFormat } = cellState;
  const errorIdentifier = 'edit2pretty';

  // Typechecking;
  if (!hasValidTypesForStringValues(displayValue, numberFormat, style)) {
    return errorHandlerForStringValues(
      displayValue,
      numberFormat,
      style,
      errorIdentifier
    );
  }

  // 1) convert to raw before prettyfying (typechecking purposes)
  const convertedNumberFromEditToRaw = editingToRaw({
    displayValue,
    numberFormat,
    style
  });

  // 2) User input validation
  // 2.1) Can't type formulas
  if (displayValue.includes('=')) return userCantUseEqual(displayValue);
  // 2.2) Cant type non-allowed characters
  if (!isAllowedCharacter(displayValue)) {
    return userCantWriteNonAllowedCharacters(displayValue);
  }
  // 2) then prettify
  return rawToPretty({
    value: convertedNumberFromEditToRaw,
    numberFormat,
    style
  });
}

/**
 * Some values are rendered as exceptions,
 * because of a custom precision on the number of decimals.
 * @param  {[type]} value        [description]
 * @param  {[type]} numberFormat [description]
 * @param  {[type]} precision    [description]
 * @return {String}              [description]
 */
export function localizeNumberWithCustomPrecision(
  value,
  numberFormat,
  precision,
  display
) {
  const exception = rawToPrettyExceptionHandler(value, display, null);
  return !exception
    ? localizeNumber(value, numberFormat, {
        minimumFractionDigits: precision,
        style: display
      }).displayValue
    : exception.displayValue;
}
