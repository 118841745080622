export const CLEAR_CLIENT_DATA = 'CLEAR_CLIENT_DATA';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';
export const CLICK_SUBMIT_BUTTON = 'CLICK_SUBMIT_BUTTON';
export const ERROR_FETCH_DATAFROMCVR = 'ERROR_FETCH_DATAFROMCVR';
export const ERROR_INPUT_VALIDATION = 'ERROR_INPUT_VALIDATION';
export const ERROR_POST_CREATE_CLIENT = 'ERROR_POST_CREATE_CLIENT';
export const ERROR_POST_FORM = 'ERROR_POST_FORM';
export const INIT_FETCH_DATAFROMCVR = 'INIT_FETCH_DATAFROMCVR';
export const INIT_POST_FORM = 'INIT_POST_FORM';
export const INPUT_UPDATE_CLIENTDATA = 'INPUT_UPDATE_CLIENTDATA';
export const SUCCESS_FETCH_DATAFROMCVR = 'SUCCESS_FETCH_DATAFROMCVR';
export const SUCCESS_POST_CREATE_CLIENT = 'SUCCESS_POST_CREATE_CLIENT';
export const UPDATE_CVR_NUMBER = 'UPDATE_CVR_NUMBER';
