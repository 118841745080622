import * as types from './actionTypes';

import {
  NAME,
  CVR_NUMBER,
  CUSTOMER_ID,
  ADDRESS,
  CITY,
  ZIP_CODE,
  COUNTRY,
  FISCAL_YEAR_START,
  NUMBER_FORMAT,
  CURRENCY
} from './constants';

const defaultForm = {
  [NAME]: {
    isInvalid: false,
    isTouched: false
  },
  [CVR_NUMBER]: {
    isInvalid: false,
    isTouched: false
  },
  [CUSTOMER_ID]: {
    isInvalid: false,
    isTouched: false
  },
  [ADDRESS]: {
    isInvalid: false,
    isTouched: false
  },
  [CITY]: {
    isInvalid: false,
    isTouched: false
  },
  [ZIP_CODE]: {
    isInvalid: false,
    isTouched: false
  },
  [COUNTRY]: {
    isInvalid: false,
    isTouched: false
  },
  [FISCAL_YEAR_START]: {
    isInvalid: false,
    isTouched: false
  },
  [NUMBER_FORMAT]: {
    isInvalid: false,
    isTouched: false
  },
  [CURRENCY]: {
    isInvalid: false,
    isTouched: false
  }
};

const defaultClient = () => ({
  [NAME]: '',
  [CVR_NUMBER]: '',
  [CUSTOMER_ID]: '',
  [ADDRESS]: '',
  [CITY]: '',
  [ZIP_CODE]: '',
  [COUNTRY]: '',
  [FISCAL_YEAR_START]: '01/01',
  [NUMBER_FORMAT]: 'da-DK',
  [CURRENCY]: 'DKK'
});

export const initialState = {
  isLoading: false,
  client: defaultClient(),
  form: defaultForm
};

export default function createClientState(state = initialState, action = {}) {
  const { payload } = action;
  switch (action.type) {
    case types.INIT_FETCH_DATAFROMCVR:
      return { ...state, isLoading: true };

    case types.CLICK_SUBMIT_BUTTON:
      return {
        ...state,
        isLoading: true
      };

    case types.UPDATE_CVR_NUMBER:
      return { ...state };

    case types.INPUT_UPDATE_CLIENTDATA:
      return {
        ...state,
        client: Object.assign(state.client, payload.client),
        form: Object.assign(state.form, payload.form)
      };

    case types.SUCCESS_POST_CREATE_CLIENT:
      return {
        ...state,
        isLoading: false,
        client: defaultClient()
      };

    case types.SUCCESS_FETCH_DATAFROMCVR:
      return {
        ...state,
        client: Object.assign(state.client, payload),
        isLoading: false
      };

    case types.ERROR_FETCH_DATAFROMCVR:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case types.ERROR_INPUT_VALIDATION:
      return {
        ...state,
        form: {
          ...state.form,
          ...payload
        },
        isLoading: false
      };

    case types.ERROR_POST_FORM:
      return {
        ...state,
        error: payload,
        isLoading: false
      };

    case types.CLEAR_CLIENT_DATA:
      return {
        ...state,
        client: { ...payload }
      };

    case types.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          [NAME]: {
            isInvalid: false,
            isTouched: false
          },
          [CVR_NUMBER]: {
            isInvalid: false,
            isTouched: false
          },
          [CUSTOMER_ID]: {
            isInvalid: false,
            isTouched: false
          },
          [ADDRESS]: {
            isInvalid: false,
            isTouched: false
          },
          [CITY]: {
            isInvalid: false,
            isTouched: false
          },
          [ZIP_CODE]: {
            isInvalid: false,
            isTouched: false
          },
          [COUNTRY]: {
            isInvalid: false,
            isTouched: false
          },
          [FISCAL_YEAR_START]: {
            isInvalid: false,
            isTouched: false
          },
          [NUMBER_FORMAT]: {
            isInvalid: false,
            isTouched: false
          },
          [CURRENCY]: {
            isInvalid: false,
            isTouched: false
          }
        }
      };

    default:
      return { ...state };
  }
}
