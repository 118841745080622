/**
 * Return a missing CVR label if vatin is not available
 *
 * @param  {String} vatin
 * @param  {Function} translate
 * @returns  {String} right label for CVR/Vatin
 */
export default function vatinAsLabel(vatin, translate) {
  if (vatin) {
    return `CVR ${vatin}`;
  }

  return translate('noVatin');
}
