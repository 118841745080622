import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';
import loadingImage from '../../shared/images/loader_tiny.svg';
import { TranslateContext } from '..';

import './index.css';

export const LoadingSpinner = ({ text, title, centered }) => (
  <div
    className={`Spinner-container${
      centered ? ' Spinner-container--centered' : ''
    }`}
  >
    <img alt="Loading spinner" className="Spinner" src={loadingImage} />
    <TranslateContext.Consumer>
      {({ translate }) => (
        <Fragment>
          {title ? <p className="title">{translate(title)}</p> : null}
          {text ? <p className="text">{translate(text)}</p> : null}
        </Fragment>
      )}
    </TranslateContext.Consumer>
  </div>
);

LoadingSpinner.propTypes = {
  text: string,
  title: string,
  centered: bool
};

LoadingSpinner.defaultProps = {
  text: null,
  title: null,
  centered: false
};

export default LoadingSpinner;
