import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';

import { IntegrationsContainer } from '../container';

import {
  fetchCasewareExistingTokens,
  generateNewToken,
  deleteToken
} from '../backend/requests';
import {
  sanitizeCasewareResponse,
  sanitizeCasewareNewTokenResponse
} from '../backend/responses';

import Main from './Main';
import './index.css';
import logoNoTokenImg from './token_empty.svg';
import logoTokenImg from './token_full.svg';
import copiedTokenImg from './token_copied.svg';

export class GenerateToken extends Component {
  state = {
    status: 'isLoading',
    token: undefined,
    disabledInput: true,
    tokenID: undefined
  };

  async componentDidMount() {
    const response = await fetchCasewareExistingTokens();
    this.updateStateOnDataFetched(response);
  }

  onClickSubmitButton = async e => {
    e.preventDefault();

    if (this.state.status === 'noToken') {
      const response = await generateNewToken();
      return this.setState(sanitizeCasewareNewTokenResponse(response));
    }

    if (this.state.status === 'hasToken') return this.copyToken();

    return null;
  };

  onClickConfirmDeleteTokenButton = async () => {
    await deleteToken(this.state.tokenID);
    this.setState({ status: 'noToken', token: undefined, tokenID: undefined });
  };

  copyToken = async () => {
    await this.setState({ disabledInput: false });
    await document.getElementById('DisplayToken-input').select();
    await document.execCommand('copy');
    this.setState({
      status: 'copiedToken',
      disabledInput: true
    });
  };

  updateStateOnDataFetched = response => {
    this.setState(sanitizeCasewareResponse(response));
  };

  determineLogo = status => {
    switch (status) {
      case 'hasToken':
        return logoTokenImg;
      case 'copiedToken':
        return copiedTokenImg;
      default:
        return logoNoTokenImg;
    }
  };

  render() {
    const { status } = this.state;

    return (
      <IntegrationsContainer
        render={() => (
          <form className="GenerateToken" onSubmit={this.onClickSubmitButton}>
            <div className="Caseware-title-area">
              <h1>
                <Translate id="caseware.generateToken.main.title" />
              </h1>
            </div>
            <div className="Caseware-inner-content">
              <div className="Caseware-logo">
                <img alt="logo" src={this.determineLogo(status)} />
              </div>
              <div className="Caseware-inner-text">
                <div className="Caseware-inner-text-title">
                  <Translate id="caseware.generateToken.inner.title" />
                </div>
                <div className="Caseware-inner-text-body">
                  <Translate id="caseware.generateToken.inner.body" />
                </div>
              </div>
              <Main
                onClickConfirmDeleteTokenButton={
                  this.onClickConfirmDeleteTokenButton
                }
                {...this.state}
              />
            </div>
          </form>
        )}
        shouldGenerateDataImportSession={false}
      />
    );
  }
}
