import { getClientId } from '../../services/navigation/routeMapping';

/**
 * BurgerMenu/SideBar navigation options.
 * @param {String} currentLocation
 * @param {Object} Routes
 */
export default function navigationOptions(currentLocation, { ...ROUTES }) {
  return [
    {
      name: 'reports',
      subsections: [
        {
          name: 'managementReport',
          route: `${ROUTES.MANAGEMENT_REPORT_ROUTE_NAME}?clientId=${getClientId(
            currentLocation
          )}`
        },
        {
          name: 'budgetReport',
          route: `${ROUTES.BUDGET_REPORT_ROUTE_NAME}?clientId=${getClientId(
            currentLocation
          )}`
        },
        {
          name: 'simulation',
          route: `${ROUTES.SIMULATION_REPORT_ROUTE_NAME}?clientId=${getClientId(
            currentLocation
          )}`
        },
        {
          name: 'forecast',
          route: `${ROUTES.FORECAST_ROUTE_NAME}?clientId=${getClientId(
            currentLocation
          )}`
        },
        {
          name: 'valuationReport',
          route: `${ROUTES.VALUATION_REPORT_ROUTE_NAME}?clientId=${getClientId(
            currentLocation
          )}`
        }
      ]
    },
    {
      name: 'input',
      subsections: [
        {
          name: 'accountingData',
          route: `${ROUTES.INPUT_DATA_ROUTE_NAME}?clientId=${getClientId(
            currentLocation
          )}`
        },
        {
          name: 'budgetData',
          route: `${ROUTES.INPUT_BUDGET_ROUTE_NAME}?clientId=${getClientId(
            currentLocation
          )}`
        }
      ]
    },
    {
      name: 'stamdata',
      subsections: [
        {
          name: 'company',
          route: `${
            ROUTES.CLIENT_DATA_COMPANY_ROUTE_NAME
          }?clientId=${getClientId(currentLocation)}`
        },
        {
          name: 'settings',
          route: `${
            ROUTES.CLIENT_DATA_SETTINGS_ROUTE_NAME
          }?clientId=${getClientId(currentLocation)}`
        }
      ]
    }
  ];
}
