import React from 'react';
import { func, string } from 'prop-types';
import vatinAsLabel from '../../../../helpers/translations/vatinLabel';

const LeftSideNavigation = ({ translate, clientName, clientVatin }) => (
  <div className="NavigationArea-left-side">
    {clientName && (
      <div className="NavigationArea-company-name">{clientName}</div>
    )}
    {clientVatin && (
      <div className="NavigationArea-company-cvr">
        {`${vatinAsLabel(clientVatin, translate)}`}
      </div>
    )}
  </div>
);

LeftSideNavigation.propTypes = {
  translate: func.isRequired,
  clientName: string,
  clientVatin: string
};

LeftSideNavigation.defaultProps = {
  clientName: '',
  clientVatin: ''
};

export default LeftSideNavigation;
