import React from 'react';

import { optimizedTypeSanitizer } from '../helpers';
import { typeSanitizer } from '../../../helpers/translations';

import { TableTitlePropTypes } from '../types';
import './index.css';

export const TableTitle = ({
  sectionName,
  translate,
  definition,
  showBottomBorder,
  hasDefinition,
  sidePanelId,
  toggleSidePanel
}) => (
  <div
    className={`TableTitle ${
      showBottomBorder ? 'TableTitle--bottom-border' : ''
    }`}
  >
    <h2 className="TableTitle__main">
      {hasDefinition ? (
        <button
          className="ui basic TableTitle__header--has-definition"
          type="button"
          onClick={() => toggleSidePanel({ sidePanelId })}
        >
          {typeSanitizer(translate, 'title', sectionName)}
        </button>
      ) : (
        typeSanitizer(translate, 'title', sectionName)
      )}
    </h2>

    {definition && (
      <p className="TableTitle__definition">
        {optimizedTypeSanitizer({
          translate,
          text: definition.text,
          sectionName
        })}
        {definition.readMoreIdKey && (
          <span className="Tabletitle__read-more">
            <button
              className="Tabletitle__read-more__button"
              // Below is for when the 'read more' feature is implemented.
              // eslint-disable-next-line
              onClick={() => console.warn(definition.readMoreIdKey)}
              type="button"
            >
              Read more
            </button>
          </span>
        )}
      </p>
    )}
  </div>
);

TableTitle.propTypes = TableTitlePropTypes;
