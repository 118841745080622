import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { SECTION_REPORTS } from '../../../services/navigation';
import Screen from '../../../components/Screen';
import ReportSimulationLocale from './locale.json';

import Main from './Main';

export class ReportSimulation extends PureComponent {
  static propTypes = {
    currentLocation: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    fiscal_year: PropTypes.number
  };

  static defaultProps = {
    fiscal_year: null
  };

  render() {
    const { currentLocation } = this.props;
    return (
      <Screen
        showComments
        showDownloadButton
        showRightSideNavigation
        currentLocation={currentLocation}
        locale={ReportSimulationLocale}
        section={SECTION_REPORTS}
      >
        <Main section={SECTION_REPORTS} {...this.props} title="simulation" />
      </Screen>
    );
  }
}

const mapStateToProps = ({ router, simulationReport, locale }) => ({
  currentLocation: router.location,
  sections: simulationReport.sections,
  viewName: simulationReport.viewName,
  chartsData: simulationReport.chartsData,
  fiscal_year: simulationReport.fiscal_year,
  translate: getTranslate(locale)
});

export default connect(mapStateToProps)(ReportSimulation);
