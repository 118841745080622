import React from 'react';
import { connect } from 'react-redux';
import { rawToPretty } from '../../../../../components/Cell/conversions';
import './Value.css';

const mapStateToProps = state => ({
  numberFormat: state.client.numberFormat
});

const Value = connect(mapStateToProps)(({ value, numberFormat }) => (
  <div className="Value-container">
    <p className="Value-container__value">
      {
        rawToPretty({
          ...value,
          // backend should send this directly.
          numberFormat,
          // backend needs to update this in the cell api.
          style: value.display
        }).displayValue
      }
    </p>
  </div>
));

export { Value };
