import React, { Component } from 'react';
import deepEqual from 'deep-equal';
import { Bar, defaults } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { StickyContext } from '../../Sticky';

import {
  BarChartComponentTypes,
  ChartTypes
} from '../../../screens/Analysis/Simulation/types';
import * as actions from '../../../screens/Analysis/Simulation/actions';
import { barChartOptions } from './options';
import './index.css';

export const Chart = ({ chartsData, name, numberFormat, currency }) =>
  chartsData ? (
    <Bar
      data={chartsData[name]}
      // Loading data for the specific section name.
      options={barChartOptions({ numberFormat, currency })}
    />
  ) : null;

Chart.propTypes = ChartTypes;

export class BarChartComponent extends Component {
  static propTypes = BarChartComponentTypes;

  static defaultProps = {
    chartsData: undefined
  };

  componentDidMount() {
    // TODO: this desperately needs to be refactored
    // so that its not hooked to redux anymore.
    this.props.convertRawDataIntoGraphData(this.props.section);
    // Chart styling.
    defaults.global.defaultFontColor = '#2f2f2b';
    defaults.global.fontRoboto = 'Roboto';
  }

  /**
   * The component should only update in three cases:
   *
   * 1) Upon mounting.
   * 2) Upon new data has been received from the backend.
   * 3) Upon language update
   * @param {*} nextProps
   */
  shouldComponentUpdate(nextProps) {
    const isFirstLoad =
      deepEqual(this.props.chartsData, nextProps.chartsData) === false;
    const hasReceivedNewData =
      deepEqual(this.props.section, nextProps.section) === false;

    if (isFirstLoad) {
      return true;
    }

    if (hasReceivedNewData) {
      this.props.convertRawDataIntoGraphData(nextProps.section);
      return true;
    }

    if (nextProps.locale !== this.props.locale) {
      this.props.convertRawDataIntoGraphData(nextProps.section);
      return true;
    }

    return false;
  }

  render() {
    const {
      translate,
      chartsData,
      section,
      numberFormat,
      currency
    } = this.props;
    const { name } = section;

    return (
      <StickyContext.Consumer>
        {({ status }) => (
          <div className="BarChartComponent-container">
            <div
              className={`BarChartComponent__legend ${
                status === 'sticky'
                  ? 'BarChartComponent__legend--compressed'
                  : ''
              }`}
            >
              <div className="BarChartComponent__legend-item actual">
                {translate(`${name}.actual`)}
              </div>
              <div className="BarChartComponent__legend-item projected">
                {translate(`${name}.projected`)}
              </div>
            </div>

            <div
              className={`BarChartWrapper ${
                status === 'sticky' ? 'BarChartWrapper--compressed' : ''
              }`}
              id="chartContainer"
            >
              <Chart
                chartsData={chartsData}
                currency={currency}
                name={name}
                numberFormat={numberFormat}
              />
            </div>
          </div>
        )}
      </StickyContext.Consumer>
    );
  }
}

const mapStateToProps = state => ({
  locale: state.locale.languages.find(lang => lang.active),
  chartsData: state.simulationReport.chartsData,
  translate: getTranslate(state.locale),
  numberFormat: state.client.number_format,
  currency: state.client.currency
});

export default connect(
  mapStateToProps,
  actions
)(BarChartComponent);
