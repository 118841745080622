import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import CellDispatcher from '../../../components/Cell/Dispatcher';
import './Table.css';

const TableLoadingData = () => <div>Loading...</div>;
const TableSensitivityAnalysis = ({ ...props }) =>
  props.isLoading ? (
    <TableLoadingData />
  ) : (
    <Table fixed padded unstackable className="SensitivityAnalysis-table">
      <Table.Body>
        {props.rows.map((row, rowIndex) => (
          <Table.Row
            key={`SensitivityAnalysis-table-row-${rowIndex + row.cells.length}`}
            id={`SensitivityAnalysis-table-row-${row.name}`}
          >
            {row.cells.map((cell, cellIndex) => (
              <CellDispatcher
                key={
                  // eslint-disable-next-line
                  `SensitivityAnalysis-cell-${cellIndex}`
                }
                cell={cell}
                cellIndex={cellIndex}
              />
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

TableSensitivityAnalysis.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired
};
export default TableSensitivityAnalysis;
