import axios from 'axios';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from './reducer';
import { sort } from './helpers';
import * as types from './actionTypes';

function errorFetchClientsList(error) {
  return {
    type: types.ERROR_FETCH_CLIENTS_LIST,
    payload: error
  };
}

function successFetchClientsList(response) {
  return {
    type: types.SUCCESS_FETCH_CLIENTS_LIST,
    payload: response
  };
}

function initFetchClientsList() {
  return {
    type: types.INIT_FETCH_CLIENTS_LIST,
    payload: { isLoading: true }
  };
}

export function fetchClientsList() {
  return dispatch => {
    dispatch(initFetchClientsList());
    return axios
      .get(`${API_URL}/clients`)
      .then(response => dispatch(successFetchClientsList(response.data)))
      .catch(error =>
        // Error callback
        dispatch(errorFetchClientsList(error))
      );
  };
}

export function handleSort(sortKey) {
  return (dispatch, getState) => {
    const state = getState();
    const { sortOrder, clients } = state.listClients;

    const newSortOrder =
      sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC;

    const sortedClients = sort({
      clients,
      sortKey,
      sortOrder: newSortOrder
    });

    return dispatch({
      type: types.SORTING_DASHBOARD_LIST,
      payload: {
        clients: sortedClients,
        sortKey,
        sortOrder: newSortOrder
      }
    });
  };
}

function clientForLookup(client) {
  return `${client.name} ${client.vatin} ${client.customer_id}`;
}

export function handleLookup(query) {
  return (dispatch, getState) => {
    const state = getState();
    const regex = new RegExp(query, 'i');
    let { clients } = state.listClients;
    clients = clients.map(client => ({
      ...client,
      visible: regex.test(clientForLookup(client))
    }));

    return dispatch({
      type: types.LOOKUP_DASHBOARD_LIST,
      payload: { clients }
    });
  };
}
