import update from 'immutability-helper';
import {
  CONVERT_RAW_DATA_INTO_GRAPH_DATA,
  REPORT_SIMULATION_CLEAR_DATA,
  REPORT_SIMULATION_ERROR_FETCH_DATA,
  REPORT_SIMULATION_INIT_UPDATE_CELL_VALUE,
  REPORT_SIMULATION_SUCCESS_FETCH_DATA,
  REPORT_SIMULATION_UPDATE_CELL_VALUE
} from './actions';

const initialState = {
  isLoading: false,
  error: null,
  view_name: 'whatif_report',
  fiscal_year: 2016,
  chartsData: null,
  sections: []
};

export default function(state = initialState, action = {}) {
  const { payload } = action;

  switch (action.type) {
    case CONVERT_RAW_DATA_INTO_GRAPH_DATA:
      return {
        ...state,
        chartsData: { ...state.chartsData, ...payload}
      };

    case REPORT_SIMULATION_INIT_UPDATE_CELL_VALUE:
      const { sectionIndex, rowIndex, cellIndex, value } = payload;
      return update(state, {
        sections: {
          [sectionIndex]: {
            rows: {
              [rowIndex]: {
                cells: { [cellIndex]: { value: { $set: value } } }
              }
            }
          }
        }
      });

    case REPORT_SIMULATION_CLEAR_DATA:
      return {
        ...state,
        sections: []
      };

    case REPORT_SIMULATION_SUCCESS_FETCH_DATA:
      return {
        ...state,
        ...payload
      };

    case REPORT_SIMULATION_UPDATE_CELL_VALUE:
      return {
        ...state,
        ...payload
      };

    case REPORT_SIMULATION_ERROR_FETCH_DATA:
      return {
        ...state,
        error: {
          status: payload.status,
          statusText: payload.statusText
        },
        isFetchingInitialData: payload.isFetchingInitialData
      };

    default:
      return { ...state };
  }
}
