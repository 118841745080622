import deepEqual from 'deep-equal';
import queryString from 'query-string';
import { getClientId } from '../../services/navigation/routeMapping';
import navOptions from '../../services/navigation/options';

/**
 * ATTENTION: this function fails on the following cases:
 * example:() the param 'step' and 'ssstep' will return values if they exist.
 *
 * TODO: Improve and write tests.
 * Until this, the getURLParams() will be used for new features.
 *
 * Gets the value of a parameter in a location query
 *
 * @param  {String} param   [query parameter to be fetch]
 * @param  {Object} location [Location from router]
 * @return {String/Null}  [Returns a string/number with the value of the requested parameter]
 */
export function paramFromLocation(param, location) {
  if (!location.search) return null;
  const regex = new RegExp(`${param}=([^&]+)`);
  const matchedParam = location.search.match(regex);
  return matchedParam && matchedParam[1];
}

/**
 * Extracts the pathname from a full route
 *
 * @param  {String} route   [Full route with pathname and query]
 * @return {String}  [Returns a string with the pathname of the route]
 */
export function pathnameFromRoute(route) {
  const regex = new RegExp('((http|https)://[^/]+)?([^?]+)');
  return route && route.match(regex)[3];
}

/**
 * Appends a clientId to each route.
 * @param {*} route
 * @param {*} location
 */
export function routeWithClientId(route, clientId) {
  const id = clientId || getClientId(window.location);

  if (!id) {
    return route;
  }

  return `${route}?clientId=${id}`;
}

/**
 * The 'queryParams' should be location.search
 * @param {String} queryParams
 */
export function getURLParams(queryParams) {
  return queryString.parse(queryParams);
}

const findOption = (options = [], location) =>
  options.find(
    option => location.pathname === pathnameFromRoute(option.link)
  ) || {};

export const getNavOption = ({ section, location } = {}) => {
  if (!location.pathname) {
    return {};
  }

  const options = navOptions({ section, location });

  if (section) {
    return findOption(options, location);
  }

  const sections = Object.keys(options);
  let foundOption;

  sections.find(currentSection => {
    foundOption = findOption(options[currentSection], location);
    return !deepEqual(foundOption, {});
  });

  return foundOption;
};

export const getModuleId = ({ section, location } = {}) =>
  getNavOption({ section, location }).id;

export const routeWithoutAuth = (route, location = {}) => {
  const params = queryString.parse(location.search);

  if (deepEqual(params, {})) {
    return route;
  }

  delete params.jwt_token;
  const noAuthParams = queryString.stringify(params);

  if (!noAuthParams) {
    return route;
  }

  return `${route}?${noAuthParams}`;
};

export const extractYearFromURL = ({ location }) => {
  const params = queryString.parse(location.search);
  return params.selectedYear ? parseInt(params.selectedYear, 10) : undefined;
};
