import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { localize } from 'react-localize-redux';
import getClassNames from '../../helpers/style';

import './index.css';

/**
 * Button used for actions (and not submissions).
 * @param {func} translate
 * @param {object} props
 */
const CommonWhiteButton = ({ translate, ...props }) => (
  <div
    className={`Common-ButtonWhite-container ${getClassNames(props.className)}`}
  >
    <Button
      className="Common-ButtonWhite-content"
      disabled={!props.isEnabled}
      id={props.id}
      loading={props.isLoading}
      type="button"
      onClick={props.handleClick}
    >
      {translate(props.text)}
    </Button>
  </div>
);

CommonWhiteButton.propTypes = {
  // the actual text of the button
  text: PropTypes.string,
  // A spinner is shown when loading
  isLoading: PropTypes.bool,
  // Event handler for when button is clicked
  handleClick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  className: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string
};

CommonWhiteButton.defaultProps = {
  isEnabled: true,
  className: [],
  isLoading: false,
  text: '',
  id: null
};

export default localize(CommonWhiteButton, 'locale');
