import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import BoxWrapper from './BoxWrapper';
import './index.css';

const determinePosition = (length, index) => {
  switch (true) {
    case index === 0:
      return 'first';

    case index + 1 === length:
      return 'last';

    default:
      return 'between';
  }
};

export const Pyramid = ({ pyramidData, translate, numberFormat }) => {
  if (pyramidData) {
    return (
      <div className="Pyramid-container section-separator">
        <div className="Pyramid-top">
          <div className="Pyramid-top-left">
            {translate(`pyramid.${pyramidData.number_heading}`, {
              year: pyramidData.number_heading_year
            })}
          </div>
          <div className="Pyramid-top-right">
            {translate('pyramid.percentage')}
          </div>
        </div>
        <div className="Pyramid-branch">
          <BoxWrapper data={pyramidData} position="init" />
          <div className="Pyramid-subbranch">
            {pyramidData.children.map((value, index, array) => (
              <div
                key={
                  // eslint-disable-next-line
                  `Pyramid-branch-${index}`
                }
                className="Pyramid-branch"
              >
                <BoxWrapper
                  data={value}
                  numberFormat={numberFormat}
                  position={determinePosition(array.length, index)}
                  type={value.type}
                />

                <div
                  className={`Pyramid-subbranch ${determinePosition(
                    array.length,
                    index
                  )}-position`}
                >
                  {value.children.map((subvalue, subvalueIndex, subArray) => (
                    <BoxWrapper
                      key={
                        // eslint-disable-next-line
                        `child-${subvalueIndex}-of-parent-0`
                      }
                      data={subvalue}
                      index={subvalueIndex}
                      numberFormat={numberFormat}
                      position={determinePosition(
                        subArray.length,
                        subvalueIndex
                      )}
                      type={subvalue.type}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return <div className="Pyramid-container-empty" />;
};

Pyramid.propTypes = {
  translate: PropTypes.func.isRequired,
  pyramidData: PropTypes.object,
  numberFormat: PropTypes.string
};

Pyramid.defaultProps = {
  pyramidData: undefined,
  numberFormat: undefined
};

const mapStateToProps = state => ({
  pyramidData: state.reportManagement.pyramid,
  translate: getTranslate(state.locale),
  numberFormat: state.client.number_format
});

export default connect(
  mapStateToProps,
  null
)(Pyramid);
