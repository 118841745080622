import React, { Fragment } from 'react';

import { CardTitle, ModalConfirmation } from '../../../../components';
import { Table } from '../../Overview';
import { ButtonsArea } from '../ButtonsArea';
import { MapScreenContentTypes } from '../types';
import { Main } from '../Main';
import { DiscrepancyNotification } from '../Notification';

export const MapScreenContent = ({
  currentlySelectedAccountItem,
  getData,
  handleCancel,
  importSessionSelectedYears,
  integration,
  isConfimationModalOpen,
  isNotificationOpen,
  onCancelParentItemChange,
  onClickSubmitButton,
  onConfirmParentItemChange,
  overviewRows,
  overviewYear,
  selectedPanel,
  toggleConfirmationModal,
  translate
}) => (
  <Fragment>
    {isNotificationOpen && (
      <DiscrepancyNotification onClickSubmitButton={onClickSubmitButton} />
    )}
    {isConfimationModalOpen &&
      currentlySelectedAccountItem && (
        <ModalConfirmation
          isWarning
          showLoading
          accountName={translate(
            `map.selector.${currentlySelectedAccountItem.account}`
          )}
          buttonCancelText="map.confirmation.button.cancel"
          buttonSubmitText="map.confirmation.button.submit"
          contentDescription="map.confirmation.description"
          /**
           * The account name has to be translated before being passed to the component
           * because otherwise would be passed as a "raw" key. "Raw" refers to the format
           * that the backend sends to the frontend.
           */
          contentTitle="map.confirmation.title"
          isModalOpen={isConfimationModalOpen}
          onClickCancelButton={onCancelParentItemChange}
          onClickSubmitButton={onConfirmParentItemChange}
          onModalClose={toggleConfirmationModal}
        />
      )}

    <CardTitle
      integration={integration}
      subtitle="map.subtitle"
      title="map.title"
    />

    <Table
      integration={integration}
      rows={overviewRows}
      selectedYear={overviewYear}
      translate={translate}
      years={importSessionSelectedYears}
      onSelectYear={(e, data) => getData(data.value)}
    />
    <Main selectedPanel={selectedPanel} />
    <ButtonsArea
      handleCancel={handleCancel}
      translate={translate}
      onClickSubmitButton={onClickSubmitButton}
    />
  </Fragment>
);

MapScreenContent.propTypes = MapScreenContentTypes;
