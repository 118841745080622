/**
 * Placeholder to determine which CSS classes should be enabled,
 * depending on the Cell's state.
 */
export function shouldCellBeHighlighted(isFocused) {
  return isFocused ? 'isHighlighted' : null;
}

/**
 * [shouldCellShowWarningBackground description]
 * @param  {[type]} error [description]
 * @return {[type]}       [description]
 */
export function shouldCellShowErrorBackground(error) {
  return error ? error.type : null;
}

export function shouldCellBeStyled(cellStyle) {
  let style = '';

  if (cellStyle && cellStyle.bold) style = 'bold';
  if (cellStyle && cellStyle.relevance) {
    style = `${style} ${cellStyle.relevance}`;
  }

  return style;
}
