import axios from 'axios';

const getRequestBody = ({ text, name, email }) => ({
  orgid: '00D58000000JuWI',
  origin: 'Web (DK)',
  location: window.location.href,
  company: '1003808157',
  phone: '3000000551',
  subject: 'customer-feedback',
  reason: 'Platform',
  title: 'Feedback & Ideas',
  retURL: '%E2%9C%93',
  utf8: '✓',
  name,
  email,
  description: text
});

export async function submitFeedback({ text, name, email }) {
  const requestBody = getRequestBody({ text, name, email });

  return axios.post("/feedbacks", { feedback: requestBody });
}
