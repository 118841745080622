import React from 'react';
import { Translate } from 'react-localize-redux';

import { MapScreenContext } from '../../context';
import { MAPPED, UNMAPPED } from './constants';
import './index.css';

const determineSelectedTabClass = ({ selectedTab, panel }) => {
  if (selectedTab === panel) return `TabsList__item TabsList__item--selected`;
  return `TabsList__item`;
};

export const Tabs = () => (
  <div className="Tabs">
    <MapScreenContext.Consumer>
      {({ unmappedCount, integration, selectedTab, toggleSelectedTab }) => (
        <ul className="TabsList">
          <li
            className={determineSelectedTabClass({
              selectedTab,
              panel: MAPPED
            })}
          >
            <button type="button" onClick={() => toggleSelectedTab(MAPPED)}>
              <Translate data={{ integration }} id="map.main.tabs.tab1" />
            </button>
          </li>
          <li
            className={determineSelectedTabClass({
              selectedTab,
              panel: UNMAPPED
            })}
          >
            <button type="button" onClick={() => toggleSelectedTab(UNMAPPED)}>
              <Translate id="map.main.tabs.tab2" />
            </button>

            {unmappedCount > 0 && (
              <span className="TabsList__item--count">{unmappedCount}</span>
            )}
          </li>
          {/**
          * The following is an upcoming feature.
          <li className="TabsList__item">
            <span>
              <Translate id="map.main.tabs.tab3" />
            </span>
          </li> */}
        </ul>
      )}
    </MapScreenContext.Consumer>

    {/* <ul className="TabsHelp">
      <li className="TabsHelp__item">
        <Translate id="map.main.tabs.help" />
      </li>
    </ul> */}
  </div>
);

export { MAPPED, UNMAPPED };
