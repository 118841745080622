import React from 'react';
import { push } from 'connected-react-router';
import { Dropdown } from 'semantic-ui-react';

import { routeWithClientId } from '../../helpers/location';

import { convertOptionsToDropdownOptionsFormat } from './helpers';
import { SelectYearPropTypes } from './types';

export const SelectYear = ({
  allYears,
  selectedYear,
  isLoading,
  dispatch,
  location
}) => (
  <div className="TitleArea-button-select-year">
    <Dropdown
      search
      selection
      loading={!selectedYear || isLoading}
      options={convertOptionsToDropdownOptionsFormat(allYears)}
      selectOnBlur={false}
      value={selectedYear}
      onChange={(event, data) => {
        const { value } = data;

        const url = `${routeWithClientId(
          location.pathname
        )}&selectedYear=${value}`;

        dispatch(push(url));
      }}
    />
  </div>
);

SelectYear.propTypes = SelectYearPropTypes;

SelectYear.defaultProps = {
  selectedYear: undefined,
  allYears: [],
  isLoading: false
};
