import { array, func, string, number, shape, bool } from 'prop-types';

export const YearsComponentPropTypes = {
  years: array.isRequired,
  goTo: func.isRequired,
  translate: func.isRequired,
  integration: string.isRequired,
  submitYears: func.isRequired
};

export const CheckBoxYearComponentPropTypes = {
  clickYearCheckBox: func.isRequired,
  index: number.isRequired,
  year: shape({
    value: number,
    checked: bool,
    hasData: bool
  }).isRequired,
  translate: func.isRequired,
  integration: string.isRequired
};
