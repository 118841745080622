import React from 'react';
import { Table } from 'semantic-ui-react';

import { optimizedTypeSanitizer } from '../helpers';
import { TableHeaderPropTypes } from '../types';
import { typeSanitizer } from '../../../helpers/translations';

import './index.css';

export function TableHeader({
  translate,
  sectionName,
  subtitle,
  headings,
  subheadings
}) {
  return (
    <Table.Header>
      <Table.Row className="TableHeader-row">
        <Table.HeaderCell className="TableHeader-subtitle">
          {optimizedTypeSanitizer({ translate, text: subtitle, sectionName })}
        </Table.HeaderCell>

        {headings.map((headerValue, index) => (
          <Table.HeaderCell
            key={
              // eslint-disable-next-line
              `header-${sectionName}-${headerValue}-${index}`
            }
            className="TableHeader-header"
            id={`header-${sectionName}-${headerValue}-section-${index}`}
          >
            {typeSanitizer(translate, headerValue, sectionName)}

            {subheadings && (
              <div className="TableHeader-subtitle">
                {typeSanitizer(translate, subheadings[index], sectionName)}
              </div>
            )}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
}

TableHeader.defaultProps = {
  subheadings: null,
  subtitle: null
};

TableHeader.propTypes = TableHeaderPropTypes;
