import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate, addTranslation } from 'react-localize-redux';
import { string, func, arrayOf, shape, bool } from 'prop-types';

import { InfoSection } from './InfoSection';
import { AccessSection } from './AccessSection';

import './index.css';

import TrialLocale from './locale.json';

import lock from './circle_lock.svg';
import logo from '../Header/logo_k.svg';

class InfoCardDetailedComponent extends Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(addTranslation(TrialLocale));
  }

  render() {
    const {
      icon,
      title,
      text,
      children,
      buttons,
      translate,
      accessTitle,
      accessText,
      accessFeatureTitle,
      modules,
      accessFooter,
      footer
    } = this.props;
    return (
      <div className="info-card-detailed">
        <div className="info-card-detailed__container">
          <InfoSection {...{ icon, title, text, buttons, translate }} />
          {children && children({ translate })}
          {modules.length > 0 && (
            <AccessSection
              {...{
                accessFooter,
                accessTitle,
                accessText,
                accessFeatureTitle,
                modules,
                translate
              }}
            />
          )}

          <hr className="info-card-detailed__separator" />
          <div className="info-card-detailed__footer">
            <img
              alt="Karnov Group"
              className="info-card-detailed__logo"
              src={logo}
            />
            <p>{translate(footer)}</p>
          </div>
        </div>
      </div>
    );
  }
}

InfoCardDetailedComponent.propTypes = {
  dispatch: func.isRequired,
  icon: string,
  translate: func.isRequired,
  title: string,
  text: string,
  buttons: arrayOf(
    shape({
      text: string.isRequired,
      url: string.isRequired,
      primary: bool
    })
  ),
  accessTitle: string,
  accessText: string,
  accessFeatureTitle: string,
  accessFooter: string,
  footer: string,
  modules: arrayOf(string)
};

InfoCardDetailedComponent.defaultProps = {
  icon: lock,
  text: 'detailed-card.unauthorized.info.text',
  title: 'detailed-card.unauthorized.info.title',
  buttons: [],
  accessTitle: 'detailed-card.unauthorized.access.title',
  accessText: 'detailed-card.unauthorized.access.text',
  accessFeatureTitle: 'detailed-card.unauthorized.access.feature-title',
  accessFooter: '',
  modules: [],
  footer: 'detailed-card.unauthorized.footer'
};

const mapStateToProps = state => ({
  translate: getTranslate(state.locale)
});

const InfoCardDetailed = connect(mapStateToProps)(InfoCardDetailedComponent);

export { InfoCardDetailed };
