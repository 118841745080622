import { func, shape, oneOf, string, number, arrayOf, bool } from 'prop-types';
import { ECONOMIC, VIRK, CASEWARE } from '../../../helpers/constants';

export const SelectYearsComponentPropTypes = {
  goTo: func.isRequired,
  name: string.isRequired,
  dataImportSession: shape({
    id: number,
    source: string,
    years: arrayOf(
      shape({
        text: number,
        value: number,
        selected: bool
      })
    )
  }).isRequired,
  match: shape({
    params: shape({
      integration: oneOf([ECONOMIC, VIRK, CASEWARE])
    })
  }).isRequired,
  saveDataImportSession: func.isRequired
};
