import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, func, object, bool } from 'prop-types';
import { Input } from 'semantic-ui-react';
import { optionalTranslation } from '../../../../helpers/translations';
import { Tooltip } from '../../../../components';
import * as actions from '../actions';
import './index.css';

export class TextInput extends Component {
  static propTypes = {
    // Text above the input field.
    label: string.isRequired,
    // Text below the input field.
    subLabel: string,
    // Name of the input field. ex: postalCode.
    name: string.isRequired,
    // Type of the input field (text or number).
    type: string,
    // Data relating to the company's details.
    client: object.isRequired,
    // action dispatched to update props upon keydown.
    updateClientDataOnInput: func.isRequired,
    // The form status (ie, input fields are valid).
    form: object.isRequired,
    translate: func.isRequired,
    showTooltip: bool
  };

  static defaultProps = {
    subLabel: null,
    type: undefined,
    showTooltip: false
  };

  handleChange = event => {
    const { updateClientDataOnInput } = this.props;
    // Name of the input field. ex: postalCode.
    const inputName = event.target.name;
    // The actual value of the input field.
    const inputValue = event.target.value;
    // Dispatching an update to the store.
    updateClientDataOnInput(inputName, inputValue);
  };

  /**
   * Only certain fields have validation.
   * This method ensures that only these fields have errors displayed.
   */
  showErrorMessage() {
    const { form, name } = this.props;
    // Ensuring the errors in the form (object) exists
    // && mapping to the right input field name while
    // cheking that the size is bigger than zero.
    return form[name].isInvalid;
  }

  render() {
    const {
      translate,
      label,
      name,
      client,
      subLabel,
      type,
      showTooltip
    } = this.props;
    return (
      <div>
        <div className="TextInput-container">
          <div className={`TextInput-field TextInput-${name}`}>
            <div className="TextInput-label">
              <label htmlFor={name}>{translate(label)}</label>
              {showTooltip && (
                <Tooltip
                  message="tooltips.create.clientNumber.message"
                  type="info"
                />
              )}
            </div>
            <Input
              error={this.showErrorMessage()}
              id={name}
              name={name}
              type={type}
              value={client[name]}
              onChange={this.handleChange}
            />
            <div className="TextInput-subLabel">
              {optionalTranslation(translate, subLabel)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.createClient.client,
  form: state.createClient.form,
  createClient: state.createClient
});

export default connect(
  mapStateToProps,
  actions
)(TextInput);
