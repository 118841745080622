import { tooltipCallbacks } from '../options';

/**
 * Line colors have specific requirements.
 * For example, the most proeminent color is blue
 * and is assigned to the first row.
 * @param  {Number} rowIndex [The index will determine the color]
 * @return {String}          [A color code is returned.]
 */
export const determineColorOfLine = rowIndex => {
  switch (rowIndex) {
    case 0:
      return '#5F768B';
    case 1:
      return '#C9C2B8';
    case 2:
      return '#CD977A';
    case 3:
      return '#90A19A';
    case 4:
      return '#333A35';
    case 5:
      return '#982223';
    case 6:
      return '#D0D1E6';
    case 7:
      return '#F3E8BF';
    case 8:
      return '#B4DFE9';
    case 9:
      return '#A6BDDB';
    case 10:
      return '#E2C759';
    default:
      return '#2F2F2B';
  }
};

export const chartOptions = ({ numberFormat, units }) => ({
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 40,
      bottom: 0
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: false,
    position: 'top'
  },
  legend: {
    display: false
  },
  tooltips: {
    callbacks: tooltipCallbacks({ numberFormat, units, isBarChart: false }),
    caretSize: 5,
    caretPadding: 1,
    intersect: false,
    displayColors: false,
    backgroundColor: 'white',
    xPadding: 23,
    yPadding: 23,
    cornerRadius: 3,
    titleFontColor: '#72726f',
    titleFontSize: 14,
    titleFontStyle: 'normal',
    bodyFontColor: '#2f2f2b',
    bodyFontSize: 16,
    bodyFontStyle: 'bold',
    borderColor: '#D5D5D5',
    footerFontColor: '#72726f',
    footerFontStyle: 'normal',
    footerFontSize: 14,
    borderWidth: 0,
    shadowOffsetX: 0,
    shadowOffsetY: 2,
    shadowBlur: 8,
    shadowColor: 'rgba(0, 0, 0, 0.1)'
  },
  hover: {
    mode: 'dataset',
    intersect: false
  },

  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          offsetGridLines: true,
          zeroLineBorderDashOffset: 50,
          drawBorder: false,
          zeroLineColor: '#E2E2E2'
        },
        ticks: {
          padding: 10,
          drawTicks: false,
          fontFamily: "'Roboto Mono'"
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
          tickMarkLength: 30,
          drawBorder: false,
          zeroLineBorderDashOffset: 150
        }
      }
    ]
  },
  scaleStartValue: 0
});
