import React from 'react';
import PropTypes from 'prop-types';
import { localize } from 'react-localize-redux';
import { localizeNumberWithCustomPrecision } from '../Cell/conversions';

import './BoxContent.css';
import IconPerformance from '../IconPerformance';

/**
 * The inner part of a box. Doesn't include the 'connectors'.
 */
export const BoxContent = ({
  values,
  translate,
  name,
  icon,
  type,
  numberFormat
}) => (
  <div className="BoxContent-outer">
    <div
      className={`${
        type === 'flat' ? 'BoxContent-inner-flat' : 'BoxContent-inner'
      }`}
    >
      <div className="BoxContent-icon-wrapper">
        <IconPerformance icon={icon} />
      </div>
      <div className="BoxContent-name">{translate(name)}</div>

      <div className="BoxContent-values-wrapper">
        {values.map((value, index) => {
          if (type === 'flat') {
            return (
              <div
                key={
                  // eslint-disable-next-line
                  `BoxContent-value-${index}`
                }
                className="BoxContent-values-wrapper-flat"
              >
                <div className={`BoxContent-value-${index + 1}`}>
                  <div className="BoxContent-value-1-value">
                    {// eslint-disable-next-line
                    isNaN(value.values)
                      ? value.values
                      : localizeNumberWithCustomPrecision(
                          value.values,
                          numberFormat,
                          1,
                          value.display
                        )}
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div
              key={
                // eslint-disable-next-line
                `BoxContent-value-${index}`
              }
              className="BoxContent-values-wrapper"
            >
              <div className={`BoxContent-value-${index + 1}`}>
                <div className="BoxContent-value-year">{value.name}</div>
                <div className="BoxContent-value-1-value">
                  {// eslint-disable-next-line
                  isNaN(value.values)
                    ? value.values
                    : localizeNumberWithCustomPrecision(
                        value.values,
                        numberFormat,
                        1,
                        value.display
                      )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

BoxContent.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  values: PropTypes.array,
  translate: PropTypes.func.isRequired,
  numberFormat: PropTypes.string
};

BoxContent.defaultProps = {
  name: undefined,
  icon: undefined,
  type: 'flat',
  values: [],
  numberFormat: 'da-DK'
};

export default localize(BoxContent, 'locale');
