import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getTranslate } from 'react-localize-redux';

import { CREATE_CLIENT_ROUTE_NAME } from '../../../services/navigation';

import './Content.css';
import icon from './icon-newcompany.svg';

export const Content = ({ translate }) => (
  <div className="Content-container main-container">
    <div className="Content-start-area">
      <div className="Content-icon">
        <img alt="icon-newcompany" src={icon} />
      </div>
      <div className="Content-start-area-title">{translate('card.title')}</div>
      <div className="Content-start-area-subtitle">
        {translate('card.body')}
      </div>
      <div className="Content-start-area-button">
        <Link to={CREATE_CLIENT_ROUTE_NAME}>
          <div className="Content-start-area-button-text">
            {translate('card.mainButton')}
          </div>
        </Link>
      </div>
    </div>
  </div>
);

Content.propTypes = {
  translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ translate: getTranslate(state.locale) });
export default connect(
  mapStateToProps,
  null
)(Content);
