// vendors
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router';
import '@karnov/kui-deprecated/build/lib/kui.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'chartjs-plugin-style';

import { redirectToErrorView } from './external/rollbar';
import App from './components/App';
import store, { history } from './store';
import './index.css';

import { initializers } from './services/initializers';

initializers();

// Boot app with routing props
const Application = withRouter(App);

// App rendering with UI fallback on run time errors.
try {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Application />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app')
  );
} catch (e) {
  if (ENV === 'development') throw new Error(e);
  redirectToErrorView({ message: e.message });
}
