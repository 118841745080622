import { string, bool, func, number, arrayOf, shape, array } from 'prop-types';

export const TitleAreaPropTypes = {
  title: string.isRequired,
  showImportButton: bool,
  translate: func.isRequired,
  showCurrentFiscalYear: bool,
  selectedYear: number,
  showDropDown: bool,
  allYears: arrayOf(number),
  dispatch: func,
  location: shape({
    pathname: string.isRequired,
    search: string.isRequired
  }),
  viewName: string,
  importButtonText: string,
  showDescription: bool,
  descriptionText: string
};

export const SelectYearPropTypes = {
  selectedYear: number,
  allYears: array,
  isLoading: bool,
  location: shape({
    pathname: string.isRequired,
    search: string.isRequired
  }).isRequired,
  dispatch: func.isRequired
};

export const TitlePropTypes = {
  translate: func.isRequired,
  title: string.isRequired,
  showDescription: bool,
  descriptionText: string
};
