import { string, arrayOf, oneOf, bool, shape, func } from 'prop-types';

const ContentType = string.isRequired;
const TitleType = string.isRequired;
// will rename when the data model is more defined
const TypeType = oneOf(['text', 'formula']);

export const SidePanelTitleTypes = {
  title: TitleType,
  description: string.isRequired
};

export const SidePanelModuleItemTypes = {
  title: TitleType,
  content: ContentType,
  type: TypeType,
  imageUrl: string
};

export const SidePanelModuleItemContentTypes = {
  content: ContentType
};

export const SidePanelModulesTypes = {
  modules: arrayOf(
    shape({
      content: ContentType,
      title: TitleType,
      type: TypeType,
      imageUrl: string,
      key: string
    })
  )
};

export const SidePanelContainerPropTypes = {
  sidePanelId: string,
  toggleSidePanel: func.isRequired
};

export const SidePanelTypes = {
  isVisible: bool,
  onClickCloseButton: func.isRequired,
  sidePanelId: string
};

export const DefinitionType = shape({
  ...SidePanelTitleTypes,
  ...SidePanelModulesTypes
});

export const SidePanelContentTypes = {
  current: DefinitionType,
  old: DefinitionType
};
