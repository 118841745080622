import React from 'react';
import PropTypes from 'prop-types';

import CommonWhiteButton from '../../../../../components/ButtonAction';
import { economicURL } from '../../../helpers/integration';

import economicLogo from '../../../../../shared/images/economicLogo.svg';

const Economic = ({ translate, language }) => (
  <div className="Integration-container">
    <div className="Integration-container-logo">
      <img alt="economic" className="economic-logo" src={economicLogo} />
    </div>
    <div className="Integration-container-text-area">
      <div className="Integration-container-text-title">
        {translate('list.economic.title')}
      </div>
      <div className="Integration-container-text">
        {translate('list.economic.loginHelpText')}
      </div>
      <CommonWhiteButton
        // user is thrown to outside of KBO.
        handleClick={() => window.location.assign(economicURL(language))}
        id="e-conomic"
        text="list.economic.loginButton"
      />
    </div>
  </div>
);

Economic.propTypes = {
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
};

export default Economic;
