// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addTranslation } from 'react-localize-redux';
// shared
import Header from '../../../components/Header';
import TopRow from '../../../components/TopRow';
import Footer from '../../../components/Footer';
// local
import * as actions from './actions';
import TableListClients from './Table';
// locales
import ClientDataLocale from '../locale.json';
import TopRowLocale from '../../../components/TopRow/locale.json';
import HeaderLocale from '../../../components/Header/locale.json';
import FooterLocale from '../../../components/Footer/locale.json';

import './index.css';

export class ListClients extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.dispatch(addTranslation(HeaderLocale));
    this.props.dispatch(addTranslation(TopRowLocale));
    this.props.dispatch(addTranslation(ClientDataLocale));
    this.props.dispatch(addTranslation(FooterLocale));
    this.props.dispatch(actions.fetchClientsList());
  }

  render() {
    return (
      <div className="full-height-container">
        <Header />
        <TopRow hasCreateClientButton />
        <div className="ListClients-main main-container">
          <TableListClients />
        </div>
        <Footer />
      </div>
    );
  }
}

ListClients.propTypes = { dispatch: PropTypes.func.isRequired };
ListClients.contextTypes = { store: PropTypes.object };

export default connect()(ListClients);
