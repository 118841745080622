import memoizeFormatConstructor from 'intl-format-cache';
// We want to memoize the Int.NumberFormat instance, so that the object is
// not instantiated everytime.
const getNumberFormat = memoizeFormatConstructor(Intl.NumberFormat);

// Default values, will be hooked to a store when auth* is in place.
const language = 'da-DK';

export function formatPrecisionDecimal(value, precision) {
  const localOptions = {
    style: 'decimal',
    maximumFractionDigits: precision,
    minimumFractionDigits: 0
  };
  return getNumberFormat(language, localOptions).format(
    value.toFixed(precision)
  );
}

export function formatPercentage(value) {
  const localOptions = {
    style: 'percent',
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
  };
  return getNumberFormat(language, localOptions).format(value);
}

/**
 * Strips values from thousands separators,
 * so it can be parsed in the number format for the FormatNumber function.
 * @param  {String} value []
 * @return {String}       []
 */
export function fromLocalizedStringToRawNumber(value) {
  return value !== 0
    ? parseFloat(value.replace(/[.]/g, '').replace(/[,]/g, '.'))
    : value;
}
