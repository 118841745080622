import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { INPUT_DATA_START_YEAR } from '../../../helpers/constants';
import AnalysisModuleDispatcher from '..';
import TitleArea from '../../../components/TitleArea';
import Pyramid from '../../../components/Pyramid';

import * as actions from './actions';
import KPIs from './KPIs';
import Section from './Section';

export const Main = ({
  fetchReportsData,
  kpi,
  sections,
  title,
  year,
  viewName,
  currentLocation,
  allYears
}) => (
  <div className="ReportManagement-container">
    <div className="analysis-section-container">
      <TitleArea
        showDropDown
        allYears={allYears}
        fetchDataOnChangeYear={fetchReportsData}
        importButton={false}
        location={currentLocation}
        selectedYear={year}
        title={`${title}.title`}
        viewName={viewName}
      />
      <KPIs data={kpi} reportName={viewName} />
      <div className="Section-container section-separator">
        <Pyramid />
      </div>

      {sections.map((moduleSection, sectionIndex) => (
        <Section
          key={`section-${moduleSection.name}`}
          chart_subtitle={moduleSection.chart_subtitle}
          name={moduleSection.name}
          section={moduleSection}
          sectionIndex={sectionIndex}
        />
      ))}
    </div>
  </div>
);

Main.propTypes = {
  // 'viewName' will determine which view to be render,
  // and which API url to be hit (depending on the view).
  viewName: PropTypes.string.isRequired,
  currentLocation: PropTypes.object.isRequired,
  kpi: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  year: PropTypes.number,
  title: PropTypes.string.isRequired,
  // Years to be shown in the dropdown
  allYears: PropTypes.array,
  fetchReportsData: PropTypes.func.isRequired
};

Main.defaultProps = {
  year: INPUT_DATA_START_YEAR,
  allYears: []
};

export default AnalysisModuleDispatcher(
  connect(
    null,
    actions
  )(Main)
);
