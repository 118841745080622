import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  shape,
  string
} from 'prop-types';

export const TableCommonPropTypes = {
  name: string,
  section: object.isRequired
};

export const TableHeaderPropTypes = {
  headings: array.isRequired,
  sectionName: string,
  subheadings: array,
  subtitle: string,
  translate: func.isRequired
};

export const TableBodyPropTypes = {
  row: shape({
    name: string.isRequired,
    type: string.isRequired
  })
};

export const TableRowPropTypes = {
  row: object.isRequired,
  rowIndex: number.isRequired,
  style: string,
  translate: func.isRequired,
  numberFormat: string
};

export const TableRowNamePropTypes = {
  row: object.isRequired,
  rowIndex: number.isRequired,
  hasDefinition: bool,
  translate: func.isRequired,
  numberFormat: string,
  sidePanelId: string
};

export const TableRowCellsPropTypes = {
  row: shape({
    cells: arrayOf(
      shape({
        display: string.isRequired
      })
    )
  }).isRequired,
  rowIndex: number.isRequired
};

export const TableTitlePropTypes = {
  sectioName: string,
  translate: func.isRequired,
  section: shape({
    readMoreIdKey: string,
    text: string.isRequired
  }),
  showBottomBorder: shape({
    text: string,
    readMoreIdKey: string
  }),
  hasDefinition: bool,
  sidePanelId: string
};
