import React, { Component } from 'react';
import { func, oneOf, number, bool, object } from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { routeWithClientId } from '../../helpers';
import {
  setInSessionStorage,
  getFromSessionStorage,
  removeFromSessionStorage,
  DATA_SESSION_IMPORT_ID
} from '../../services/sessionStorage';
import {
  getDataImportSession,
  createDataImportSession
} from './backend/requests';

import { LoadingSpinner } from '../../components';
import Screen, { TranslateContext } from '../../components/Screen';
import { SECTION_INPUTS, INTEGRATION_LIST } from '../../services/navigation';
import { saveDataImportSession as saveData } from './actions';
import { getURLParams } from '../../helpers/location';

import locale from './locale.json';
import './container.css';

class IntegrationsContainerComponent extends Component {
  static propTypes = {
    render: func.isRequired,
    goTo: func.isRequired,
    /**
     * Refers to the type of 'card'.
     * A card is the content container and
     * can take a full screen or
     * be wrapped in a grey bg.
     */
    type: oneOf(['medium', 'large']),
    bgColor: oneOf(['default', 'light']),
    id: number,
    saveDataImportSession: func.isRequired,
    /**
     * Some screens don't need to generate
     * a data import session. For example,
     * 424, list of integrations and
     * the generate token for Caseware.
     */
    shouldGenerateDataImportSession: bool,
    locale: object,
    isList: bool
  };

  static defaultProps = {
    id: null,
    isList: false,
    type: 'large',
    bgColor: 'default',
    locale: {},
    shouldGenerateDataImportSession: true
  };

  async componentDidMount() {
    const {
      id: idFromProps,
      saveDataImportSession,
      shouldGenerateDataImportSession,
      isList
    } = this.props;
    const idFromStorage = getFromSessionStorage(DATA_SESSION_IMPORT_ID);

    if (isList) {
      // Removal of previous import session data
      return removeFromSessionStorage(DATA_SESSION_IMPORT_ID);
    }

    /**
     * if the data import session is already in store
     * we need to do nothing.
     */
    if (idFromProps) {
      return null;
    }

    /**
     * if there is an id in localStorage, means the user
     * started an import so we fetch it.
     */
    if (idFromStorage) {
      const { data } = await getDataImportSession(idFromStorage);
      return saveDataImportSession(data);
    }

    if (shouldGenerateDataImportSession) {
      return this.generateDataImportSession();
    }

    return null;
  }

  generateDataImportSession = async () => {
    const { integration, goTo } = this.props;
    const { clientId, token } = getURLParams(window.location.search);

    if (!integration) return goTo(INTEGRATION_LIST);

    const response = await createDataImportSession({
      integration,
      clientId,
      token
    });

    const { status, data = {} } = response || {};

    if (status !== 200 && !data.data_import_session) {
      return this.generateDataImportSession(integration);
    }

    if (status !== 200 && !data.data_import_session.years) {
      return this.getDataImportSession(
        integration,
        data.data_import_session.id
      );
    }

    return this.storeSession(data);
  };

  storeSession = data => {
    const { data_import_session: dataImportSession } = data;
    const { saveDataImportSession } = this.props;

    setInSessionStorage(DATA_SESSION_IMPORT_ID, dataImportSession.id);
    saveDataImportSession(data);
  };

  render() {
    const {
      bgColor,
      goTo,
      id,
      render,
      type,
      locale: additionalLocale,
      shouldGenerateDataImportSession
    } = this.props;

    if (!id && shouldGenerateDataImportSession) return <LoadingSpinner />;

    return (
      <Screen
        locale={{ ...locale, ...additionalLocale }}
        section={SECTION_INPUTS}
        showDownloadButton={false}
        showRightSideNavigation={false}
      >
        <div
          className={`Integrations__container ${
            bgColor === 'default'
              ? 'Integrations__container--gray'
              : 'Integrations__container--light'
          }`}
        >
          <div
            className={`Integrations__content ${
              type === 'large'
                ? 'Integrations__content--large'
                : 'Integrations__content--medium'
            }`}
          >
            <TranslateContext.Consumer>
              {({ translate }) =>
                render({
                  goTo,
                  id,
                  translate
                })
              }
            </TranslateContext.Consumer>
          </div>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = ({ dataImportSession }) => ({
  id: dataImportSession.id
});

const mapDispatchToProps = dispatch => ({
  goTo: route => dispatch(push(routeWithClientId(route))),
  saveDataImportSession: id => dispatch(saveData(id))
});

export const IntegrationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationsContainerComponent);
