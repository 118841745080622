import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { get } from 'axios';

import {
  LIST_CLIENT_ROUTE_NAME,
  DASHBOARD_INIT_ROUTE_NAME,
  ENDPOINT_DASHBOARD_CLIENT
} from '../../services/navigation';
import Loading from '../LoadingSpinner';
import {
  RETURN_URL_PATH,
  RETURN_URL_SEARCH,
  getFromSessionStorage,
  removeFromSessionStorage
} from '../../services/sessionStorage/index';

/**
 * The first view to be loaded.
 * Refers to the root route of the App -> '/'.
 */
export default class Root extends Component {
  state = {
    isLoading: false
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const returnPathname = getFromSessionStorage(RETURN_URL_PATH);
    const returnSearch = getFromSessionStorage(RETURN_URL_SEARCH);

    if (returnPathname !== null) {
      removeFromSessionStorage(RETURN_URL_PATH);
      removeFromSessionStorage(RETURN_URL_SEARCH);

      this.setState({
        pathname: returnPathname,
        search: returnSearch,
        isLoading: false
      });
    }
  }

  componentDidMount() {
    const url = `${API_URL}${ENDPOINT_DASHBOARD_CLIENT}`;
    get(url).then(response => {
      if (response.data.clients.length) {
        return this.setState({
          pathname: LIST_CLIENT_ROUTE_NAME,
          isLoading: false
        });
      }

      return this.setState({
        pathname: DASHBOARD_INIT_ROUTE_NAME,
        isLoading: false
      });
    });
  }

  render() {
    const { pathname, search, isLoading } = this.state;
    if (isLoading) {
      return <Loading />;
    }
    return (
      <Redirect
        to={{
          pathname,
          search
        }}
      />
    );
  }
}
