import { economicLogo, virkLogo } from '../../shared';
import { VIRK } from '../constants';

const determineImageProperties = integration => {
  switch (integration) {
    case VIRK:
      return {
        src: virkLogo,
        width: '100px'
      };

    // e-conomic
    default:
      return {
        src: economicLogo,
        width: '200px'
      };
  }
};

export { determineImageProperties };
