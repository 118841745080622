import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';

import { CREATE_CLIENT_ROUTE_NAME } from '../../services/navigation';
import './index.css';

export class TopRow extends PureComponent {
  renderCreateClientButton = () => {
    if (this.props.hasCreateClientButton) {
      return (
        <Link
          className="TopRow-button Buttons-white"
          to={CREATE_CLIENT_ROUTE_NAME}
        >
          <div className="Content-start-area-button-text">
            {this.props.translate('topRow.addCompany')}
          </div>
        </Link>
      );
    }
    return null;
  };

  render() {
    return (
      <div className="TopRow-container">
        <div className="TopRow-text-container">
          <div className="TopRow-title">
            {this.props.translate('topRow.title')}
          </div>
          <div className="TopRow-subtitle">
            {this.props.translate('topRow.subtitle')}
          </div>
        </div>
        {this.renderCreateClientButton()}
      </div>
    );
  }
}

TopRow.propTypes = {
  hasCreateClientButton: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

TopRow.defaultProps = {
  hasCreateClientButton: false
};

const mapStateToProps = state => ({ translate: getTranslate(state.locale) });

export default connect(mapStateToProps)(TopRow);
