import {
  FORECAST_FETCH_DATA_INIT,
  FORECAST_FETCH_DATA_SUCCESS
} from './actions';

const initialState = {
  clientID: undefined,
  fiscalYear: undefined,
  isLoading: true,
  sections: []
};

export default function forecastReducer(state = initialState, action = {}) {
  const { payload } = action;

  switch (action.type) {
    case FORECAST_FETCH_DATA_INIT:
      return {
        ...state,
        isLoading: true
      };

    case FORECAST_FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sections: payload.sections,
        clientID: payload.clientID,
        fiscalYear: payload.fiscalYear
      };

    default:
      return { ...state };
  }
}
