import React, { Fragment, Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { addTranslation } from 'react-localize-redux';

import TopRow from '../../../components/TopRow';
import { InfoCardDetailed } from '../../../components/InfoCardDetailed';
import Footer from '../../../components/Footer';
import calendar from './circle_calendar.svg';
import TopRowLocale from '../../../components/TopRow/locale.json';
import TrialExpiredLocale from './locale.json';
import FooterLocale from '../../../components/Footer/locale.json';

class TrialExpiredComponent extends Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(addTranslation(TopRowLocale));
    dispatch(addTranslation(TrialExpiredLocale));
    dispatch(addTranslation(FooterLocale));
  }

  render() {
    return (
      <Fragment>
        <TopRow />
        <InfoCardDetailed
          buttons={[
            {
              text: 'trial.expired.info.button1.text',
              url: 'trial.expired.info.button1.url',
              primary: true
            }
          ]}
          footer="trial.expired.footer"
          icon={calendar}
          text="trial.expired.info.text"
          title="trial.expired.info.title"
        />
        <Footer />
      </Fragment>
    );
  }
}

TrialExpiredComponent.propTypes = {
  dispatch: func.isRequired
};

const TrialExpired = connect()(TrialExpiredComponent);

export { TrialExpired };
