import React, { useState, useEffect } from 'react';
import './index.css';
import { shape, func, arrayOf, bool, string } from 'prop-types';
import { setInLocalStorage } from '../../../../services/localStorage';
import { getActiveLegendsIndexes } from './helpers';

export const ChartLegends = ({ chart, legends, chartID }) => {
  const [activeLegends, setActiveLegends] = useState(legends);
  const setLegendHidden = (selectedIndex, hidden) => setActiveLegends(
      activeLegends.map((legend, index) => {
        // leave the non-clicked labels intact
        if (index !== selectedIndex) {
          return legend;
        }
        // update the hidden state of the clicked label
        return { ...legend, hidden };
      })
    );

  const legendClickCallback = (selectedLegend, selectedIndex) => {
    const meta = chart.getDatasetMeta(selectedIndex);

    if (selectedLegend.hidden) {
      meta.hidden = false;
      setLegendHidden(selectedIndex, false);
    } else {
      meta.hidden = true;
      setLegendHidden(selectedIndex, true);
    }

    chart.update();
  };

  // on mount
  useEffect(() => {
    setActiveLegends(legends);
  }, [legends]);

  // on legends toggled, write to storage
  useEffect(() => {
    setInLocalStorage(chartID, getActiveLegendsIndexes(activeLegends));
  }, [activeLegends]);

  if (!chart || legends.length === 0) {
    return null;
  }

  return (
    <div className="chart-legends">
      <ul className="chart-legends__list">
        {activeLegends.map((legend, index) => (
          <li
            key={legend.label}
            className={`chart-legends__item ${
              legend.hidden ? 'chart-legends__item--hidden' : ''
            }`}
          >
            <button
              className="chart-legends__trigger"
              type="button"
              onClick={e => {
                e.preventDefault();
                legendClickCallback(legend, index);
              }}
            >
              <span
                className="chart-legends__check"
                style={{ backgroundColor: legend.color }}
              />
              {legend.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

ChartLegends.propTypes = {
  chart: shape({
    update: func.isRequired,
    getDatasetMeta: func.isRequired
  }),
  legends: arrayOf(
    shape({
      color: string.isRequired,
      label: string.isRequired,
      hidden: bool.isRequired
    })
  ),
  chartID: string.isRequired
};

ChartLegends.defaultProps = {
  chart: null,
  legends: []
};
