export const dropdownData = [
  {
    name: 'profit_loss_statement',
    id: 999,
    children: [
      {
        name: 'turnover',
        id: 1000
      },
      {
        name: 'costs_of_goods_sold',
        id: 1001
      },
      {
        name: 'other_variable_costs',
        id: 1002
      },
      {
        name: 'staff_costs',
        id: 1003
      },
      {
        name: 'sales_and_marketing_expenses',
        id: 1004
      },
      {
        name: 'costs_of_premises',
        id: 1005
      },
      {
        name: 'administrative_expenses',
        id: 1006
      },
      {
        name: 'other_operating_expenses_net',
        id: 1007
      },
      {
        name: 'depreciation_amortisation',
        id: 1008
      },
      {
        name: 'financial_income',
        id: 1009
      },
      {
        name: 'financial_expenses',
        id: 1010
      },
      {
        name: 'corporate_tax',
        id: 1011
      }
    ]
  },
  {
    name: 'assets',
    id: 1999,
    children: [
      {
        name: 'intangible_assets',
        id: 2000
      },
      {
        name: 'tangible_assets',
        id: 2001
      },
      {
        name: 'deferred_tax_assets',
        id: 2002
      },
      {
        name: 'financial_assets',
        id: 2003
      },
      {
        name: 'inventories',
        id: 2004
      },
      {
        name: 'accounts_receivable',
        id: 2005
      },
      {
        name: 'other_receivables',
        id: 2006
      },
      {
        name: 'short_term_investments',
        id: 2007
      },
      {
        name: 'cash_and_cash_equivalents',
        id: 2008
      }
    ]
  },
  {
    name: 'total_liabilities_and_equity',
    id: 2999,
    children: [
      {
        name: 'equity_including_minority_interests',
        id: 3000
      },
      {
        name: 'deferred_tax_liabilities',
        id: 3001
      },
      {
        name: 'other_provisions',
        id: 3002
      },
      {
        name: 'credit_institutions_non_current_debt',
        id: 3003
      },
      {
        name: 'other_interest_bearing_debt_non_current',
        id: 3004
      },
      {
        name: 'credit_institutions_current_debt',
        id: 3005
      },
      {
        name: 'other_interest_bearing_debt_current',
        id: 3006
      },
      {
        name: 'accounts_payable',
        id: 3007
      },
      {
        name: 'other_payables',
        id: 3008
      }
    ]
  },
  {
    name: 'supplemental_information',
    id: 3999,
    children: [
      {
        name: 'employee',
        id: 4000
      },
      {
        name: 'hours',
        id: 4001
      },
      {
        name: 'square_meters',
        id: 4002
      }
    ]
  }
];
