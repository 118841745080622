/* eslint-disable react/no-typos */
import React from 'react';
import { Button } from '@karnov/kui-deprecated';
import { func, bool } from 'prop-types';
import { TranslateContext } from '../../../../components/Screen';
import { ActionButtons } from './ActionButtons';
import './index.css';

export const ButtonsOverview = ({
  error,
  onClickSubmitButton,
  onClickMapButton,
  handleCancel
}) => (
  <TranslateContext.Consumer>
    {({ translate }) => (
      <div className="Overview__buttons-area">
        <div className="Overview__action-buttons">
          <ActionButtons
            {...{
              translate,
              error,
              onClickSubmitButton,
              onClickMapButton
            }}
          />
        </div>
        <div className="Overview__cancel">
          <Button
            text={translate('ButtonsArea.cancel')}
            onClick={handleCancel}
          />
        </div>
      </div>
    )}
  </TranslateContext.Consumer>
);

ButtonsOverview.propTypes = {
  onClickSubmitButton: func.isRequired,
  onClickMapButton: func.isRequired,
  handleCancel: func.isRequired,
  error: bool
};

ButtonsOverview.defaultProps = {
  error: false
};
