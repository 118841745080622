import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { CheckBoxYearComponentPropTypes } from './types';

export default class CheckboxYear extends Component {
  static propTypes = CheckBoxYearComponentPropTypes;

  shouldComponentUpdate = prevProps =>
    prevProps.year.checked !== this.props.year.checked ||
    prevProps.translate !== this.props.translate;

  render() {
    const {
      year,
      clickYearCheckBox,
      index,
      integration,
      translate
    } = this.props;
    const { value, checked, hasData, disabled } = year;

    return (
      <div className="SelectYear">
        <Checkbox
          key={`${value}`}
          checked={checked}
          className="SelectYear__checkbox"
          disabled={disabled}
          label={value}
          name={value.toString()}
          tabIndex={index + 1}
          onClick={() => clickYearCheckBox(index)}
        />
        {checked && hasData && !disabled ? (
          <span className="SelectYear__has-data">
            {translate(`${integration}.selectYears.hasData`)}
          </span>
        ) : null}

        {disabled ? (
          <span className="SelectYear__no-multiple-years">
            {translate(`${integration}.selectYears.noMultipleFiscalYears`)}
          </span>
        ) : null}
      </div>
    );
  }
}
