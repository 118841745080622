import React from 'react';
import PropTypes from 'prop-types';

import TitleArea from '../../../components/TitleArea';
import { InfoBox, Table } from '../../../components';
import AnalysisModuleDispatcher from '..';
import SensitivityAnalysis from './SensitivityAnalysis';

const ValuationMain = ({ fiscalYear, sections, translate }) => (
  <div className="Valuation-main-container main-container">
    <InfoBox
      text="valuation.requirements"
      title="valuation.requirementsTitle"
    />
    <TitleArea
      showCurrentFiscalYear
      selectedYear={fiscalYear}
      title="valuation.title"
      translate={translate}
    />
    {sections.map((section, sectionIndex) => (
      <div key={`section-${section.name}`} className="Section-container section-separator">
        <Table
          key={
            // eslint-disable-next-line
            `table-section-${section.name}-${sectionIndex}`
          }
          name={section.name}
          section={section}
        />
      </div>
    ))}
    <SensitivityAnalysis table="table1" />
    <SensitivityAnalysis table="table2" />
  </div>
);

ValuationMain.propTypes = {
  sections: PropTypes.array,
  fiscalYear: PropTypes.number,
  translate: PropTypes.func.isRequired
};

ValuationMain.defaultProps = {
  sections: [],
  fiscalYear: undefined
};

export default AnalysisModuleDispatcher(ValuationMain);
