import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, TextArea } from 'semantic-ui-react';

import ButtonAction from '../ButtonAction';
import ButtonSubmit from '../ButtonSubmit';
import CommentActions from './actionButtons';

class EditComment extends Component {
  static propTypes = {
    id: PropTypes.number,
    comment: PropTypes.string,
    createdAt: PropTypes.string,
    printable: PropTypes.bool,
    placeholder: PropTypes.string,
    translate: PropTypes.func.isRequired,
    toggleEdit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
  };

  static defaultProps = {
    id: 0,
    comment: undefined,
    createdAt: undefined,
    printable: false,
    placeholder: null
  };

  state = {
    id: this.props.id || null,
    comment: this.props.comment,
    printable: this.props.printable,
    isModalOpen: false
  };

  onChange = event => this.setState({ comment: event.target.value });

  onCancel = () => {
    this.setState({ comment: this.props.comment });
    this.props.toggleEdit();
    if (!this.props.id) this.props.handleCancel();
  };

  toggleModal = () => this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));

  handleSubmit = e => {
    e.preventDefault();
    const { comment, printable, id } = this.state;

    this.props
      .handleSubmit({
        id,
        printable,
        comment
      })
      .then(() => {
        if (this.props.id) this.props.toggleEdit();
      });

    return {
      comment,
      printable,
      id
    };
  };

  togglePrint = () => this.setState(prevState => ({ printable: !prevState.printable }));

  render() {
    const { handleDelete, translate, placeholder, id, createdAt } = this.props;
    const { comment, isModalOpen } = this.state;

    return (
      <form
        className="Comments-main Comments-editable"
        onSubmit={this.handleSubmit}
      >
        <TextArea
          ref={textarea => {
            this.editTextArea = textarea;
          }}
          autoFocus
          autoHeight
          className="Comments-textarea"
          placeholder={translate(placeholder)}
          rows={1}
          value={comment}
          onChange={this.onChange}
        />
        <div className="Comments-footer">
          <CommentActions
            edit
            createdAt={createdAt}
            handleDelete={handleDelete}
            hasComment={!!comment}
            id={id}
            isModalOpen={isModalOpen}
            toggleModal={this.toggleModal}
            translate={translate}
          />
          <div className="Comments-spacer" />
          <ul className="Comments-container Comments-list">
            <li>
              <Checkbox
                checked={this.state.printable}
                className="Comments-print-check"
                label={translate('comments.actions.print')}
                onClick={this.togglePrint}
              />
            </li>
            <li className="Comments-cancel-wrapper">
              <ButtonAction
                className={['Comments-white-button', 'Comments-cancel-button']}
                handleClick={this.onCancel}
                text="comments.actions.cancel"
                translate={translate}
              />
            </li>
            <li className="Comments-save-wrapper">
              <ButtonSubmit
                className={['Comments-white-button', 'Comments-save-button']}
                disabled={!comment}
                text="save"
              />
            </li>
          </ul>
        </div>
      </form>
    );
  }
}

export default EditComment;
