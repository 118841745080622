import { determineColorOfLine } from './options';

// Removing rows of data that have no name.
// Rows with no name can be 'divider', 'label'.
const removeEmptyRows = data => data.rows.filter(rowData => rowData.name);

const determineHiddenStatus = ({
  rowIndex,
  rowData,
  enabledLegendsIndexArray
}) => {
  let hiddenStatus = true;

  // if theres data from the url that then
  if (enabledLegendsIndexArray) {
    enabledLegendsIndexArray.map(legend => {
      if (rowIndex === legend) {
        hiddenStatus = false;
      }
      return hiddenStatus;
    });
  } else {
    hiddenStatus = rowData.hidden;
  }

  return hiddenStatus;
};

const rawDataToChartData = ({
  translate,
  data,
  chartID,
  enabledLegendsIndexArray
}) => removeEmptyRows(data).map((rowData, rowIndex) => ({
    data: rowData.cells.map(cell => cell.value),
    labelRaw: rowData.name,
    label: translate(rowData.name),
    chartID,
    fill: false,
    lineTension: 0,
    // Line colors change within a specific order
    borderColor: determineColorOfLine(rowIndex),
    backgroundColor: determineColorOfLine(rowIndex),
    hoverBackgroundColor: determineColorOfLine(rowIndex),
    hoverBorderColor: determineColorOfLine(rowIndex),
    hoverBorderWidth: 2,
    radius: 0,
    borderWidth: 2,
    // Point options
    pointHitRadius: 20,
    pointHoverRadius: 5,
    pointBorderWidth: 2,
    pointHoverBackgroundColor: determineColorOfLine(rowIndex),
    pointHoverBorderColor: '#ffffff',
    hidden: determineHiddenStatus({
      rowIndex,
      rowData,
      enabledLegendsIndexArray
    })
  }));

export const chartData = ({
  section,
  translate,
  data,
  chartID,
  enabledLegendsIndexArray
}) => ({
  labels: section.header,
  datasets: rawDataToChartData({
    translate,
    data,
    chartID,
    enabledLegendsIndexArray
  })
});

export const generateLegends = ({
  translate,
  data,
  chartID,
  enabledLegendsIndexArray
}) => removeEmptyRows(data).map((rowData, rowIndex) => ({
    label: translate(rowData.name),
    labelRaw: rowData.name,
    chartID,
    color: determineColorOfLine(rowIndex),
    hidden: determineHiddenStatus({
      rowIndex,
      rowData,
      enabledLegendsIndexArray
    })
  }));

export default chartData;
