import {
  BUDGET_REPORT_ROUTE_NAME,
  CLIENT_DATA_COMPANY_ROUTE_NAME,
  CLIENT_DATA_SETTINGS_ROUTE_NAME,
  INPUT_BUDGET_ROUTE_NAME,
  INPUT_DATA_ROUTE_NAME,
  MANAGEMENT_REPORT_ROUTE_NAME,
  SECTION_CLIENT_DATA,
  SECTION_INPUTS,
  SECTION_REPORTS,
  SIMULATION_REPORT_ROUTE_NAME,
  FORECAST_ROUTE_NAME,
  VALUATION_REPORT_ROUTE_NAME
} from '.';
import { routeWithClientId } from '../../helpers/location';

/**
 * Returns specific options for navigation.
 * Used in inline navigation and segment track methods.
 *
 * id: Alias for the module.
 * page: Name to be displayed in segment for that specific route.
 */
export const NAVIGATION_OPTIONS = ({ section } = {}) => {
  const OPTIONS = {
    [SECTION_REPORTS]: [
      {
        link: routeWithClientId(MANAGEMENT_REPORT_ROUTE_NAME),
        name: 'menu.managementReport',
        id: 'management_report',
        page: 'kbo_management_report_page_viewed',
        section: SECTION_REPORTS
      },
      {
        link: routeWithClientId(BUDGET_REPORT_ROUTE_NAME),
        name: 'menu.budgetReport',
        id: 'budget_report',
        page: 'kbo_budget_report_page_viewed',
        section: SECTION_REPORTS
      },
      {
        link: routeWithClientId(SIMULATION_REPORT_ROUTE_NAME),
        name: 'menu.simulation',
        id: 'simulation_analysis',
        page: 'kbo_simulation_page_viewed',
        section: SECTION_REPORTS
      },
      {
        link: routeWithClientId(FORECAST_ROUTE_NAME),
        name: 'menu.forecast',
        id: 'forecast_analysis',
        page: 'kbo_forecast_page_viewed',
        section: SECTION_REPORTS
      },
      {
        link: routeWithClientId(VALUATION_REPORT_ROUTE_NAME),
        name: 'menu.valuationReport',
        id: 'valuation_report',
        page: 'kbo_valuation_page_viewed',
        section: SECTION_REPORTS
      }
    ],
    [SECTION_INPUTS]: [
      {
        link: routeWithClientId(INPUT_DATA_ROUTE_NAME),
        name: 'menu.accountingData',
        id: 'actual',
        section: SECTION_INPUTS
      },
      {
        link: routeWithClientId(INPUT_BUDGET_ROUTE_NAME),
        name: 'menu.budgetData',
        id: 'budget',
        section: SECTION_INPUTS
      }
    ],
    [SECTION_CLIENT_DATA]: [
      {
        link: routeWithClientId(CLIENT_DATA_COMPANY_ROUTE_NAME),
        name: 'menu.company',
        section: SECTION_CLIENT_DATA
      },
      {
        link: routeWithClientId(CLIENT_DATA_SETTINGS_ROUTE_NAME),
        name: 'menu.settings',
        section: SECTION_CLIENT_DATA
      }
    ]
  };

  return section ? OPTIONS[section] : OPTIONS;
};

/**
 * Because the `id` is the universal (used for frontend & backend)
 * name for routes, there is no need for sections when
 * accessing the navigation options.
 *
 * This function returns the details for a specific route.
 * The details include name, link, page and id.
 * @param {*} id
 */
export const flatNavigationOptions = id =>
  Object.values(NAVIGATION_OPTIONS())
    .reduce((acc, value) => acc.concat(value))
    .find(value => value.id === id);

export const getNavigationOptionsByLocation = ({ pathname }) =>
  Object.values(NAVIGATION_OPTIONS())
    .reduce((acc, value) => acc.concat(value))
    .find(value => value.link === routeWithClientId(pathname));

export const getSectionByLocation = ({ pathname }) =>
  NAVIGATION_OPTIONS(getNavigationOptionsByLocation({ pathname }));

export default NAVIGATION_OPTIONS;
