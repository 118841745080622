import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { getTranslate } from 'react-localize-redux';

import * as actions from '../actions';
import './index.css';

export class Search extends Component {
  state = { CVRnumber: '' };

  handleChange = event => {
    const CVRnumber = event.target.value;
    this.setState({ CVRnumber });
    return this.requestCVRdata(CVRnumber);
  };

  /**
   * Requests CVR Data when the length of the input field reaches 8.
   */
  requestCVRdata = CVRnumber => {
    const { fetchDataFromCVR } = this.props;
    return CVRnumber.length === 8 ? fetchDataFromCVR(CVRnumber) : null;
  };

  render() {
    const { translate, isLoading } = this.props;
    const { CVRnumber } = this.state;

    return (
      <div className="Search-container">
        <div className="Search-content">
          <div className="Search-content-left">
            <h2>{translate('searchCvr.title')}</h2>
            {translate('searchCvr.message')}
          </div>
          <div className="Search-content-right">
            <div className="Search-content-searchbox">
              <Input
                icon="search"
                id="vatin-search"
                loading={isLoading}
                placeholder={translate('searchCvr.placeholder')}
                value={CVRnumber}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  fetchDataFromCVR: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

Search.defaultProps = {
  isLoading: false
};

const mapStateToProps = state => ({
  isLoading: state.createClient.isLoading,
  translate: getTranslate(state.locale)
});

export default connect(
  mapStateToProps,
  actions
)(Search);
