/*
 * We check if the element is static in the middle of the scroll.
 * we do nothing till the element reaches the top or bottom of the window
 */
const isStatic = ({
  status,
  direction,
  distanceToTopFromSticky,
  distanceToBottomFromSticky,
  windowHeight
}) =>
  status === 'static' &&
  ((direction === 'up' && distanceToTopFromSticky < 0) ||
    (direction === 'down' && distanceToBottomFromSticky >= windowHeight));

const topState = {
  status: 'at-top',
  position: 'absolute',
  bottom: '',
  top: 0
};

const stickyToTop = ({
  distanceToTop,
  distanceToTopFromBottom,
  viewableHeight,
  stickyElementHeight,
  offset,
  bottomElement
}) => {
  if (bottomElement && distanceToTopFromBottom - viewableHeight <= 0) {
    return {
      status: 'at-bottom',
      position: 'absolute',
      top: distanceToTopFromBottom - distanceToTop - stickyElementHeight
    };
  }

  if (distanceToTop <= -offset) {
    return {
      status: 'sticky',
      position: 'fixed',
      top: 0 - offset,
      bottom: ''
    };
  }

  return topState;
};

const setStatic = ({
  direction,
  distanceToTop,
  stickyElementHeight,
  windowHeight
}) => ({
  status: 'static',
  position: 'absolute',
  top:
    direction === 'up'
      ? -distanceToTop - stickyElementHeight + windowHeight
      : -distanceToTop,
  bottom: ''
});

const stickyToBottom = ({
  distanceToTopFromBottom,
  windowHeight,
  distanceToTopFromElementBottom,
  distanceToTop,
  stickyElementHeight
}) => {
  if (distanceToTopFromBottom < windowHeight) {
    return {
      status: 'at-bottom',
      position: 'absolute',
      bottom: '',
      top: distanceToTopFromBottom - distanceToTop - stickyElementHeight
    };
  }

  if (distanceToTopFromElementBottom < windowHeight) {
    return {
      status: 'sticky',
      position: 'fixed',
      bottom: 0,
      top: ''
    };
  }

  return topState;
};

const getScrollableStickyness = ({
  bottomElement,
  offset,
  status,
  hasDirectionChanged,
  direction,
  distanceToTop,
  stickyElementHeight,
  windowHeight,
  distanceToTopFromBottom,
  distanceToTopFromElementBottom,
  viewableHeight,
  distanceToTopFromSticky,
  distanceToBottomFromSticky
}) => {
  /*
   * We check if the element is static in the middle of the scroll.
   * we do nothing till the element reaches the top or bottom of the window
   */

  if (
    isStatic({
      status,
      direction,
      distanceToTopFromSticky,
      distanceToBottomFromSticky,
      windowHeight
    })
  ) {
    return null;
  }

  /*
   * When scrolling and changing scrolling direction
   * we need to make the sticky element static
   * so users can scroll through it
   */

  if (hasDirectionChanged && status !== 'at-top' && status !== 'at-bottom') {
    return setStatic({
      direction,
      distanceToTop,
      stickyElementHeight,
      windowHeight
    });
  }

  /*
   * When we are scrolling down, the sticky element needs to be sticky on the bottom of the window
   */

  if (direction === 'down') {
    return stickyToBottom({
      distanceToTopFromBottom,
      windowHeight,
      distanceToTopFromElementBottom,
      distanceToTop,
      stickyElementHeight
    });
  }

  /*
   * When scrolling up, it is the normal stick to top behavior
   */

  if (direction === 'up' && distanceToTopFromSticky >= 0) {
    return stickyToTop({
      bottomElement,
      offset,
      distanceToTop,
      distanceToTopFromBottom,
      viewableHeight,
      stickyElementHeight
    });
  }

  if (distanceToTopFromSticky <= distanceToTop) {
    return topState;
  }

  return null;
};

const setPosition = params => {
  const { stickyElementHeight, windowHeight } = params;

  if (stickyElementHeight > windowHeight) {
    return getScrollableStickyness(params);
  }

  return stickyToTop(params);
};

export {
  setPosition,
  getScrollableStickyness,
  stickyToTop,
  stickyToBottom,
  isStatic,
  setStatic
};
