/**
 *  Converting the format sent by backend
 *  ...into semantic UI dropdown format.
 */
export const convertOptionsToDropdownOptionsFormat = allYears =>
  allYears.sort((a, b) => b - a).map(value => ({
    key: value,
    value,
    text: `${value}`
  }));

export default convertOptionsToDropdownOptionsFormat;
