import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TitlePropTypes } from './types';

const Description = ({ descriptionText }) => <div className="TitleArea-descriptionText">{descriptionText}</div>;

Description.propTypes = {
  descriptionText: PropTypes.string.isRequired
};

export const Title = ({
  translate,
  title,
  showDescription,
  descriptionText
}) => (
  <Fragment>
    <div className="TitleArea-text-container">
      <h1 className="TitleArea-title">{translate(title)}</h1>
      {showDescription && <Description descriptionText={descriptionText} />}
    </div>
  </Fragment>
);

Title.propTypes = TitlePropTypes;

Title.defaultProps = {
  showDescription: false,
  descriptionText: ''
};
