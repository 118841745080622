import React from 'react';
import PropTypes from 'prop-types';
import { addTranslation } from 'react-localize-redux';
import { connect } from 'react-redux';

import Header from '../../../components/Header';
import TopRow from '../../../components/TopRow';
import Footer from '../../../components/Footer';
import Content from './Content';

import ClientDataLocale from '../locale.json';
import TopRowLocale from '../../../components/TopRow/locale.json';
import HeaderLocale from '../../../components/Header/locale.json';
import FooterLocale from '../../../components/Footer/locale.json';

export class Init extends React.Component {
  static propTypes = { dispatch: PropTypes.func.isRequired };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.dispatch(addTranslation(HeaderLocale));
    this.props.dispatch(addTranslation(TopRowLocale));
    this.props.dispatch(addTranslation(ClientDataLocale));
    this.props.dispatch(addTranslation(FooterLocale));
  }

  render() {
    return (
      <div className="DashboardInit-container">
        <Header />
        <TopRow />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default connect()(Init);
