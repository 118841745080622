import * as types from './actionTypes';

export const setGlobalState = response => ({
  type: types.SUCCESS_FETCH_DATA_INPUT,
  payload: response
});

export const wipeGlobalState = {
  type: types.WIPE_DATA_INPUT
};
