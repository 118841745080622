import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

export class Confirmation extends React.PureComponent {
  render() {
    return (
      <Modal
        basic
        open={this.props.isModalOpen}
        size="small"
        trigger={this.props.trigger}
        onClose={this.props.handleCancelButton}
      >
        <Header content={this.props.title} icon="warning circle" />
        <Modal.Content>
          <p>{this.props.message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            inverted
            color="red"
            id="cancel"
            onClick={this.props.handleCancelButton}
          >
            <Icon name="remove" />
            {this.props.cancelButtonText}
          </Button>
          <Button
            inverted
            color="green"
            id="delete"
            onClick={this.props.handleOkButton}
          >
            <Icon name="checkmark" />

            {this.props.okButtonText}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleOkButton: PropTypes.func.isRequired,
  handleCancelButton: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  trigger: PropTypes.object.isRequired,
  okButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired
};

export default Confirmation;
