import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  INTEGRATION_LIST,
  INPUT_DATA_ROUTE_NAME
} from '../../../services/navigation';

import {
  removeFromSessionStorage,
  DATA_SESSION_IMPORT_ID
} from '../../../services/sessionStorage';
import { saveDataImportSession as saveSession } from '../actions';
import {
  getDataImportSession,
  confirmDataImportSession
} from '../backend/requests';
import {
  translateTotals,
  filterSelectedYears,
  isBalanceZero
} from '../helpers/conversions';
import { getSelectedAccountPlan } from '../helpers/extractions';
import { InfoBanner, CardTitle, LoadingSpinner } from '../../../components';
import { IntegrationsContainer } from '../container';
import { OverviewTypes } from './types';
import { Table } from './Table';
import { ButtonsOverview } from './Buttons';
import {
  trackDataImportCancelled,
  trackDataImportSuccess
} from '../../../external/segment';

import './index.css';

export class OverviewComponent extends Component {
  static propTypes = OverviewTypes;

  state = {
    loading: false,
    loadingDropdown: false
  };

  integration = this.props.match.params.integration;

  onClickMapButton = ({ e, goTo, integration }) => {
    e.preventDefault();
    const url = `${INTEGRATION_LIST}/map/${integration}/main`;
    goTo(url);
    return url;
  };

  onClickSubmitButton = async ({ e, goTo, integration }) => {
    e.preventDefault();
    const { id, vatin, selectedAccountPlan } = this.props;

    this.toggleLoading();

    await trackDataImportSuccess({
      source: integration,
      cvr: vatin,
      accountPlan: selectedAccountPlan
    });

    await confirmDataImportSession(id);
    // removal of session data because the import is complete
    removeFromSessionStorage(DATA_SESSION_IMPORT_ID);

    return this.goToInput(goTo);
  };

  getDataImportSession = async year => {
    const { id, saveDataImportSession } = this.props;
    const { data } = await getDataImportSession(id, year);

    saveDataImportSession(data);
  };

  goToInput = goTo => {
    const url = INPUT_DATA_ROUTE_NAME;
    goTo(url);
    return url;
  };

  fetchDataImportSessionOnYearChange = async (e, { value: year }) => {
    this.toggleLoadingDropdown();
    this.getDataImportSession(year);
    this.toggleLoadingDropdown();
  };

  toggleLoading = () =>
    this.setState(prevState => ({
      loading: !prevState.loading
    }));

  toggleLoadingDropdown = () =>
    this.setState(prevState => ({
      loadingDropdown: !prevState.loadingDropdown
    }));

  handleCancel = goTo => {
    const { integration } = this;

    trackDataImportCancelled({ source: integration });
    return this.goToInput(goTo);
  };

  render() {
    const { loading, loadingDropdown } = this.state;
    const { overview, years, match } = this.props;
    const { integration } = match.params;
    const hasError = !isBalanceZero(overview.totals);

    return (
      <IntegrationsContainer
        render={({ goTo, id, translate }) => (
          <div className="Overview-container">
            {loading || !id ? (
              <LoadingSpinner />
            ) : (
              <Fragment>
                <CardTitle
                  integration={integration}
                  subtitle="overview.subtitle"
                  title="overview.title"
                />

                {hasError ? (
                  <InfoBanner
                    integration={integration}
                    subtitle={`${integration}.overview.banner.text`}
                    title={`${integration}.overview.banner.title`}
                  />
                ) : null}
                <Table
                  integration={integration}
                  loading={loadingDropdown}
                  rows={translateTotals({
                    totals: overview.totals,
                    translate,
                    namespace: 'overview',
                    integration
                  })}
                  selectedYear={overview.year}
                  translate={translate}
                  years={filterSelectedYears(years)}
                  onSelectYear={this.fetchDataImportSessionOnYearChange}
                />
                <ButtonsOverview
                  error={hasError}
                  handleCancel={e => {
                    e.preventDefault();
                    this.handleCancel(goTo);
                  }}
                  onClickMapButton={e =>
                    this.onClickMapButton({ e, goTo, integration })
                  }
                  onClickSubmitButton={e =>
                    this.onClickSubmitButton({
                      e,
                      goTo,
                      integration
                    })
                  }
                />
              </Fragment>
            )}
          </div>
        )}
        type="large"
      />
    );
  }
}

const mapStateToProps = ({ dataImportSession, client }) => ({
  overview: dataImportSession.overview,
  years: dataImportSession.years,
  id: dataImportSession.id,
  selectedAccountPlan: getSelectedAccountPlan(dataImportSession),
  vatin: client.vatin
});

const mapDispatchToProps = dispatch => ({
  saveDataImportSession: data => dispatch(saveSession(data))
});

export const Overview = connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewComponent);

export { rows } from './mocks';
export { Table, ButtonsOverview };
