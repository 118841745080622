import {
  CELL_EVENT_END_SUBMIT,
  CELL_EVENT_INIT_SUBMIT,
  CELL_EVENT_UPDATE_POSITION,
  CELL_EVENT_NOTHING_TO_UPDATE
} from './actions';

const initialState = {
  isLoading: false,
  cellBeingEdited: null,
  error: null
};

export default function(state = initialState, action = {}) {
  const { payload } = action;

  switch (action.type) {
    case CELL_EVENT_INIT_SUBMIT:
    case CELL_EVENT_END_SUBMIT:
    case CELL_EVENT_UPDATE_POSITION:
    case CELL_EVENT_NOTHING_TO_UPDATE:
      return {
        ...state,
        ...payload
      };

    default:
      return { ...state };
  }
}
