import React from 'react';
import { string } from 'prop-types';
import { Translate } from 'react-localize-redux';
import plainTriangleSmall from '../../shared/images/plainTriangleSmall.svg';

import './index.css';

export const InfoBanner = ({ integration, title, subtitle }) => (
  <div className="Banner-container">
    <div className="Banner-icon__area">
      <img alt="triangle_warning" src={plainTriangleSmall} />
    </div>

    <div className="Banner-text_area">
      <div className="Banner-text_area-title">
        <p>
          <Translate data={{ integration }} id={title} />
        </p>
      </div>

      <div className="Banner-text_area-text">
        <p>
          <Translate data={{ integration }} id={subtitle} />
        </p>
      </div>
    </div>
  </div>
);

InfoBanner.propTypes = {
  // eslint-disable-next-line
  integration: string.isRequired,
  // eslint-disable-next-line
  title: string.isRequired,
  // eslint-disable-next-line
  subtitle: string.isRequired
};
