import { string, func } from 'prop-types';

const showFeedbackPanel = func.isRequired;

export const FeedbackPanelContentPropTypes = {
  language: string.isRequired
};

export const FeedbackPanelMainPropTypes = {
  showFeedbackPanel,
  panelMainDescription: string.isRequired,
  panelMainTitle: string.isRequired,
  panelSubmitButton: string.isRequired
};

export const FeedbackPanelButtonPropTypes = {
  showFeedbackPanel,
  text: string.isRequired
};
