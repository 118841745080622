import {
  APP_INIT_FETCHING_INITIAL_DATA,
  APP_STOP_FETCHING_INITIAL_DATA,
  APP_HAS_ERROR,
  APP_CLIENT_DATA_STATE_UPDATE,
  APP_TOGGLE_DEFINITIONS_PANEL
} from './actionTypes';

const clientReducerInitialState = {
  vatin: undefined,
  id: undefined,
  name: undefined,
  numberFormat: 'da-DK'
};

export default function clientReducer(
  state = clientReducerInitialState,
  action = {}
) {
  const { payload } = action;

  switch (action.type) {
    case APP_CLIENT_DATA_STATE_UPDATE:
      return {
        ...state,
        ...payload
      };

    default:
      return { ...state };
  }
}

export const appReducerInitialState = {
  isLoading: false,
  isFetchingInitialData: false,
  error: null,
  sidePanelId: null
};

/**
 * Sets the overall state of the app.
 */
export function appReducer(state = appReducerInitialState, action = {}) {
  const { payload } = action;
  switch (action.type) {
    case APP_TOGGLE_DEFINITIONS_PANEL:
      return {
        ...state,
        sidePanelId: payload.sidePanelId
      };

    case APP_INIT_FETCHING_INITIAL_DATA:
      return {
        ...state,
        isFetchingInitialData: true,
        error: null
      };

    case APP_STOP_FETCHING_INITIAL_DATA:
      return {
        ...state,
        isFetchingInitialData: false
      };

    case APP_HAS_ERROR:
      const { error } = payload;

      return {
        ...state,
        isFetchingInitialData: false,
        isLoading: false,
        error: {
          details: error.details ? error.details : null,
          message: error.message,
          status: error.status
        }
      };

    default:
      return {
        ...state,
        isLoading: false
      };
  }
}
