import React, { PureComponent } from 'react';
import { Loader } from 'semantic-ui-react';
import Tooltip from '../Tooltip';

const Spinner = () => (
  <Loader active inline className="Cell-spinner" size="mini" />
);

export default class IconArea extends PureComponent {
  state = {
    ...this.props
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps });
  }

  render() {
    const { error, isLoading } = this.state;
    // Show an error if needed
    if (error && !isLoading) {
      return (
        <Tooltip
          message={error.message}
          title={error.title}
          type={error.type}
        />
      );
    }
    // Show loading icon when Cell component is doing backend API calls
    if (isLoading) return <Spinner />;
    // Show nothing
    return null;
  }
}
