import { paramFromLocation, getNavOption } from '../../helpers/location';
import { convertCategoryName, eventUserId } from './helpers';
import * as event from './eventNames';

export const triggerActionAndSubmitToSegment = ({
  eventName,
  eventPayload
}) => {
  const analyticsPayload = {
    ...eventPayload,
    client_id:
      eventPayload.client_id || paramFromLocation('clientId', window.location)
  }
  // sending data to segment
  if (ENV === 'integration' || ENV === 'production') {
    /*eslint-disable */
    analytics.track(eventName, analyticsPayload);
  } else {
    console.log('Tracking Event', eventName, 'with Payload', analyticsPayload);
  }
};

export const trackSaveButtonClick = ({ clientId, inputData }) =>
  triggerActionAndSubmitToSegment({
    eventName: event.INPUT_SAVED,
    eventPayload: {
      client_id: clientId,
      input_data: inputData
    }
  });

export const trackClientCreated = ({ clientId }) => {
  triggerActionAndSubmitToSegment({
    eventName: event.CLIENT_CREATED_NEW_TRACKING,
    eventPayload: {
      client_id: clientId
    }
  });
};

export const trackClientDeleted = ({ clientId }) =>
  triggerActionAndSubmitToSegment({
    eventName: event.CLIENT_DELETED,
    eventPayload: {
      client_id: clientId
    }
  });

export const trackCommentAdded = ({ clientId, moduleId }) =>
  triggerActionAndSubmitToSegment({
    eventName: event.COMMENT_ADDED,
    eventPayload: {
      client_id: clientId,
      module: moduleId
    }
  });

export const trackReportDownloaded = ({ clientId, year, moduleId }) => {
  triggerActionAndSubmitToSegment({
    eventName: event.REPORT_DOWNLOADED_NEW_TRACKING,
    eventPayload: {
      client_id: clientId,
      module: moduleId,
      year: year
    }
  });
};

export const trackDataImportFail = ({ clientId, cvr, taxonomy }) =>
  // Tracking the new events.
  triggerActionAndSubmitToSegment({
    eventName: event.DATA_IMPORTED_TRACKING,
    eventPayload: {
      client_id: clientId,
      data_source: taxonomy,
      status: 'fail',
      vatin: cvr
    }
  });

export const trackDataImportSuccess = ({ clientId, source, cvr, accountPlan }) => {
  // Tracking the new events.
  triggerActionAndSubmitToSegment({
    eventName: event.DATA_IMPORTED_TRACKING,
    eventPayload: {
      client_id: clientId,
      data_source: convertCategoryName({ source, accountPlan }),
      status: 'success',
      vatin: cvr
    }
  });
};

export const trackDataImportCancelled = ({ clientId, source }) => {
  triggerActionAndSubmitToSegment({
    eventName: event.DATA_IMPORT_CANCELLED,
    eventPayload: {
      client_id: clientId,
      data_source: source
    }
  });
};

export const submitIdentityToSegment = ({ state }) => {
  const clientInfo = state.auth.sub;
  const { language, per_id, psp_id, email } = clientInfo;
  // the country is a placeholder, should be replaced with BA data
  const country = 'DK';
  const userId = eventUserId({
    pspId: psp_id,
    perId: per_id
  });
  const eventName = eventUserId({
    pspId: psp_id,
    perId: per_id
  });
  const eventPayload = {
    userId,
    per_id,
    psp_id,
    email,
    country,
    language
  };
  analytics && analytics.identify(eventName, eventPayload);
  return eventPayload;
};

export const trackPage = ({ location, options }) => {
  const navOption = getNavOption({ location });
  // eslint-disable-next-line
  analytics && analytics.page(navOption.page, options);
};
