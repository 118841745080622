import React from 'react';
import { Table } from '../../components';

export const Sections = ({ sections }) =>
  sections.map((section, sectionIndex) => (
    <div key={`section-${section.name}`} className="Section-container">
      <Table
        key={`section-${section.name}`}
        name={section.name}
        section={section}
        sectionIndex={sectionIndex}
      />
    </div>
  ));
