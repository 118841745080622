import * as types from './actionTypes';

const initialState = {
  years: [],
  id: null,
  source: null,
  accountPlans: [],
  errors: [],
  overview: {}
};

const dataImportSessionReducer = (state = initialState, action = {}) => {
  const { payload, type } = action;

  switch (type) {
    case types.SAVE_DATA_IMPORT_SESSION:
      return {
        ...state,
        ...payload
      };

    default:
      return { ...state };
  }
};

export default dataImportSessionReducer;
