import React from 'react';
import PropTypes from 'prop-types';

import CommentActions from './actionButtons';

const StaticComment = ({ translate, ...props }) => (
  <div className="Comments-main Comments-static-wrapper">
    <div className="Comments-content">{props.comment}</div>
    <div className="Comments-footer static-comment">
      <CommentActions
        createdAt={props.createdAt}
        handleDelete={props.handleDelete}
        handleEdit={props.toggleEdit}
        hasComment={!!props.comment}
        id={props.id}
        translate={translate}
      />
      <div className="Comments-spacer" />
      {!props.printable ? (
        <div className="Comments-timestamp Comments-hidden-sign">
          {translate('comments.actions.hidden')}
        </div>
      ) : null}
    </div>
  </div>
);

StaticComment.propTypes = {
  comment: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  printable: PropTypes.bool,
  toggleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

StaticComment.defaultProps = { printable: true };

export default StaticComment;
