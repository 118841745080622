import React from 'react';
import { object, array, number, func } from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { SECTION_REPORTS } from '../../../services/navigation';
import Screen from '../../../components/Screen';
import Main from './Main';

import './index.css';

import AnalysisLocale from '../locale.json';
import ValuationLocale from './locale.json';

export const Valuation = ({
  currentLocation,
  sections,
  fiscalYear,
  translate
}) => (
  <Screen
    showDownloadButton
    showRightSideNavigation
    currentLocation={currentLocation}
    locale={{ ...AnalysisLocale, ...ValuationLocale }}
    section={SECTION_REPORTS}
  >
    <Main
      currentLocation={currentLocation}
      fiscalYear={fiscalYear}
      sections={sections}
      translate={translate}
    />
  </Screen>
);

Valuation.propTypes = {
  currentLocation: object.isRequired,
  sections: array,
  fiscalYear: number,
  translate: func.isRequired
};

Valuation.defaultProps = {
  sections: [],
  fiscalYear: undefined
};

const mapStateToProps = state => ({
  currentLocation: state.router.location,
  clientData: state.clientData,
  sections: state.valuationReport.valuation.sections,
  isLoading: state.valuationReport.valuation.isLoading,
  fiscalYear: state.valuationReport.valuation.fiscalYear,
  translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(Valuation);
