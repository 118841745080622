import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { Tooltip } from '../../../components';

import './TextInput.css';

export class TextInput extends Component {
  shouldComponentUpdate(nextProps) {
    const { value, currentLocale } = this.props;
    // only when the value on the input has changed
    return (
      value !== nextProps.value || currentLocale !== nextProps.currentLocale
    );
  }

  handleChange = (event, data) => {
    const { onChange } = this.props;
    // Propagating to the parent component
    onChange(event, data);
  };

  render() {
    const { translate, label, name, value, showTooltip } = this.props;
    return (
      <div className="TextInput-container">
        <div className="TextInput-field">
          <div className="TextInput-label">
            <label htmlFor={name}>{translate(label)}</label>
            {showTooltip && (
              <Tooltip
                message="tooltips.create.clientNumber.message"
                type="info"
              />
            )}
          </div>

          <Input
            error={false}
            name={name}
            value={value}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

TextInput.propTypes = {
  // Changing the input value, propagating it to the parent so state can be changed
  onChange: PropTypes.func.isRequired,
  // The text above the input field
  label: PropTypes.string.isRequired,
  // Name of the input field
  name: PropTypes.string.isRequired,
  // Value of the input field, hooked to the props
  value: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool
};

TextInput.defaultProps = {
  showTooltip: false
};

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLocale: getActiveLanguage(state.locale).code
});

export default connect(mapStateToProps)(TextInput);
