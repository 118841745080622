/**
 * Configuration for the initial request of data in the forecast view.
 * @param {?String/Number} clientID
 */
export function configForRequestFetchForecastData(clientID) {
  const url = `/clients/${clientID}/reports/forecast_analysis`;
  return {
    method: 'GET',
    url,
    baseURL: API_URL
  };
}

/**
 * Response sanitization of data.
 * @param {Object} response
 */
export function cleanUpResponse(response) {
  return {
    clientID: response.data.forecast_analysis.client_id,
    fiscalYear: response.data.forecast_analysis.fiscal_year,
    sections: response.data.forecast_analysis.sections
  };
}
