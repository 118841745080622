import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import ReadOnlyCell from './ReadOnlyCell';
import ExceptionCell from './ExceptionCell';
import Cell from '.';

const CellDispatcher = ({ cell, cellIndex, rowIndex, sectionIndex }) => {
  if (!cell) {
    return <Table.Cell key={`cell-empty-${cellIndex}`} className="TableCell" />;
  }
  switch (cell.type) {
    case 'editable':
      return (
        <Table.Cell
          key={`cell-editable-${cellIndex}`}
          className="TableCell EditableCell"
        >
          <Cell
            cellIndex={cellIndex}
            error={cell.error}
            /**
             * This (grid_position and reference) is temporary.
             * Should have uniform naming in the future.
             * From a backend perspective, 'id' is different.
             * From a frontend one, 'id' is the cell location.
             */
            id={cell.grid_position}
            reference={cell.id}
            rowIndex={rowIndex}
            rowName={cell.row_name}
            sectionIndex={sectionIndex}
            style={cell.display}
            value={cell.value}
          />
        </Table.Cell>
      );
    case 'exception':
      return (
        <ExceptionCell
          {...cell}
          key={`cell-exception-${cellIndex}`}
          cellIndex={cellIndex}
          styleProperties={cell.style_properties}
        />
      );
    // default or cell.type === 'normal'
    default:
      return (
        <ReadOnlyCell
          key={`cell-data-${cellIndex}`}
          display={cell.display}
          error={cell.error}
          exception={cell.exception}
          icon={cell.icon}
          precision={cell.precision}
          styleProperties={cell.style_properties}
          type={cell.type}
          value={cell.value}
        />
      );
  }
};

CellDispatcher.propTypes = {
  cell: PropTypes.object,
  cellIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number,
  sectionIndex: PropTypes.number
};

CellDispatcher.defaultProps = {
  cell: {
    type: 'normal'
  },
  rowIndex: 0,
  sectionIndex: 0
};

export default CellDispatcher;
