import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { localize } from 'react-localize-redux';
import getClassNames from '../../helpers/style';
import './index.css';

export const ButtonSubmit = ({
  translate,
  disabled,
  isLoading,
  className,
  text
}) => (
  <div className={`Common-Button-container ${getClassNames(className)}`}>
    <Button
      className="Common-Button-content"
      disabled={disabled}
      loading={isLoading}
      type="submit"
    >
      {translate(text)}
    </Button>
  </div>
);

ButtonSubmit.propTypes = {
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  className: PropTypes.arrayOf(PropTypes.string)
};

ButtonSubmit.defaultProps = {
  text: '',
  isLoading: false,
  disabled: false,
  className: undefined
};

export default localize(ButtonSubmit, 'locale');
