const getClassNames = classes => {
  if (!classes) {
    return '';
  }
  // in case numbers or booleans are passed as classes
  const stringifyClasses = classes.toString();

  return stringifyClasses.split(',').join(' ');
};

export default getClassNames;
