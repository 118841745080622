import React from 'react';
import { connect } from 'react-redux';

import { SECTION_CLIENT_DATA } from '../../../services/navigation';
import Screen from '../../../components/Screen';
import Main from './Main';

import ClientDataLocale from '../locale.json';

export const ClientEdit = ({ ...props }) => {
  const { currentLocation } = props;
  return (
    <Screen
      showRightSideNavigation
      currentLocation={currentLocation}
      locale={ClientDataLocale}
      section={SECTION_CLIENT_DATA}
    >
      <Main section={SECTION_CLIENT_DATA} {...props} />
    </Screen>
  );
};

const mapStateToProps = ({ client, router: { location } }) => ({
  client,
  currentLocation: location
});

export default connect(mapStateToProps)(ClientEdit);
