// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { addTranslation } from 'react-localize-redux';
import { connect } from 'react-redux';
// Shared
import Footer from '../../../components/Footer';
import InfoCard from '../../../components/InfoCard';
import TopRow from '../../../components/TopRow';
import {
  init,
  decryptHashedError,
  rollbarPayload
} from '../../../external/rollbar';
// Local
import './index.css';
// Locales
import TopRowLocale from '../../../components/TopRow/locale.json';
import ErrorCardLocale from '../../../components/Error/locale.json';
import FooterLocale from '../../../components/Footer/locale.json';

const RUN_TIME_ERROR = 'RUN_TIME_ERROR';

class ServerErrorCard extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({ id: PropTypes.string })
    }),
    id: PropTypes.number.isRequired
  };

  static defaultProps = {
    match: null
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    // rollbar init
    init();
    // dispatching translations
    this.props.dispatch(addTranslation(TopRowLocale));
    this.props.dispatch(addTranslation(ErrorCardLocale));
    this.props.dispatch(addTranslation(FooterLocale));
  }

  componentDidMount() {
    const { match, id, dispatch } = this.props;
    if (match.params && match.params.id) {
      const dehashedError = decryptHashedError(match.params.id);
      const errorMessage = JSON.stringify(dehashedError.error);

      Rollbar.configure(rollbarPayload({ id, dehashedError }));

      dispatch({ type: RUN_TIME_ERROR, payload: { ...dehashedError } });
      Rollbar.critical(errorMessage);
    }
  }

  componentWillUnmount() {
    Rollbar.configure({
      enabled: false
    });
  }

  render() {
    return (
      <div className="ServerErrorCard-container full-height-container">
        <TopRow />
        <InfoCard
          buttonText="error.mainButton"
          text="error.body"
          title="error.title"
          url="/"
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  id: state.auth.sub.per_id
});

export default connect(mapStateToProps)(ServerErrorCard);
