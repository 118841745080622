import { getClientId } from '../../../services/navigation/routeMapping';
import { ENDPOINT_DASHBOARD_CLIENT } from '../../../services/navigation';

/**
 * Generates url to be hit.
 * @param {Object} state
 */
export function url(state) {
  // Current location
  const { location } = state.router;
  // so the clientId can be extracted from the URL
  const clientId = getClientId(location);
  // and added to the request URL.
  return `${API_URL}${ENDPOINT_DASHBOARD_CLIENT}${clientId}`;
}

/**
 * Response sanitizer.
 * @param {Object} data
 */
export function sanitizeResponseData(data) {
  return {
    companyName: data.name || '',
    cvrNumber: data.vatin || '',
    customerId: data.customer_id || '',
    address: data.address || '',
    city: data.city || '',
    postNumber: data.zip_code || '',
    country: data.country || '',
    fiscalYearStart: data.fiscal_year_start,
    currency: data.currency || '',
    numberFormat: data.number_format || ''
  };
}

export function formatFiscalYear(fiscalYearStart) {
  return `${fiscalYearStart}/2016`;
}

/**
 * Converts the frontend object format into backend-ready format.
 * Takes the state of the component as a parameter.
 * @param {Object} localState
 */
export function preparePayload(localState) {
  return {
    name: localState.companyName,
    vatin: localState.cvrNumber,
    customer_id: localState.customerId,
    address: localState.address,
    city: localState.city,
    zip_code: localState.postNumber,
    country: localState.country,
    fiscal_year_start: formatFiscalYear(localState.fiscalYearStart),
    currency: localState.currency,
    number_format: localState.numberFormat
  };
}
