import {
  CLIENT_DATA_TOGGLE_MODAL_CONFIRMATION,
  DROPDOWN_VALUE_UPDATED_CLIENT_DATA,
  RESET_FORM_CLIENT_DATA,
  SUCCESS_FETCH_CLIENT_DATA,
  SUCCESS_POST_CLIENT_DATA,
  CLIENT_DATA_NUMBERFORMAT_UPDATE
} from './actions';

const initialState = {
  isModalOpen: false,
  isLoading: false,
  companyName: '',
  cvrNumber: '',
  customerId: '',
  address: '',
  city: '',
  postNumber: '',
  country: '',
  fiscalYearStart: '',
  currency: '',
  numberFormat: ''
};

export default function clientDataReducer(state = initialState, action = {}) {
  const { payload } = action;

  switch (action.type) {
    case CLIENT_DATA_TOGGLE_MODAL_CONFIRMATION:
      return {
        ...state,
        isModalOpen: payload.isModalOpen
      };

    case SUCCESS_FETCH_CLIENT_DATA:
      return {
        ...state,
        ...payload,
        isLoading: false
      };
    case SUCCESS_POST_CLIENT_DATA:
      return {
        ...state,
        isLoading: false,
        ...payload
      };

    case RESET_FORM_CLIENT_DATA:
      return {
        ...state,
        ...payload
      };

    case DROPDOWN_VALUE_UPDATED_CLIENT_DATA:
      return {
        ...state,
        currency: payload
      };

    case CLIENT_DATA_NUMBERFORMAT_UPDATE:
      return {
        ...state,
        numberFormat: payload
      };

    default:
      return { ...state };
  }
}
