const mergeActiveIndexes = (acc, legend, index) => {
  if (legend.hidden === false) {
    acc.push(index);
  }
  return acc;
};
export const getActiveLegendsIndexes = activeLegends => {
  if (Array.isArray(activeLegends) === false) {
    throw new Error('this function needs an empty array at least');
  }

  return activeLegends.reduce(mergeActiveIndexes, []).join('');
};
