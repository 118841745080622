import React, { Component, Fragment } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { get } from 'axios';

import { paramFromLocation } from '../../../helpers/location';
import { CREATE_CLIENT_ROUTE_NAME } from '../../../services/navigation';
import LoadingSpinner from '../../../components/LoadingSpinner';

import { IntegrationsContainer } from '../container';
import { getIntegrationsListURL } from '../backend/requests';
import { SkipImport } from './SkipImport';
import Cards from './Cards';
import Title from './Title';
import './index.css';

/**
 * This container is responsible for the initial /GET call
 * to fetch the list of integrations.
 *
 * It will then render the existing list of integrations.
 */
class ListComponent extends Component {
  static propTypes = {
    translate: func.isRequired,
    language: string.isRequired,
    dispatch: func.isRequired
  };

  state = {
    integrations: undefined
  };

  componentDidMount() {
    this.getIntegrationList();
  }

  componentWillUnmount() {
    this.setState({ integrations: null });
  }

  getIntegrationList = async () => {
    const response = await get(getIntegrationsListURL);

    // eslint-disable-next-line
    this.setState({ integrations: response.data.integrations });
  };

  render() {
    const { translate, language, dispatch } = this.props;
    const { integrations } = this.state;

    const shouldShowBottomButton =
      paramFromLocation('from', window.location) === CREATE_CLIENT_ROUTE_NAME;

    return (
      <IntegrationsContainer
        isList
        render={({ goTo }) => (
          <Fragment>
            {integrations ? (
              <div className="List-container">
                <Title />
                <Cards
                  dispatch={dispatch}
                  integrations={integrations}
                  language={language}
                />

                {shouldShowBottomButton ? (
                  <SkipImport goTo={goTo} translate={translate} />
                ) : null}
              </div>
            ) : (
              <LoadingSpinner translate={translate} />
            )}
          </Fragment>
        )}
        shouldGenerateDataImportSession={false}
        type="large"
      />
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  language: getActiveLanguage(state.locale).code,
  location: state.router.location
});

export const List = connect(mapStateToProps)(ListComponent);
