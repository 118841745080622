import React, { useState } from 'react';

import { FeedbackPanelMainPropTypes } from './types';
import { submitFeedback } from './api';

import './FeedbackMain.css';

export function FeedbackMain({
  showFeedbackPanel,
  panelMainDescription,
  panelMainTitle,
  panelSubmitButton,
  panelTextArea,
  userName,
  userEmail
}) {
  const [isLoading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [hasResolved, setHasResolved] = useState(false);

  return (
    <aside className="FeedbackMain">
      <header className="FeedbackMain__header">
        <button
          className="header-close"
          type="button"
          onClick={() => showFeedbackPanel(false)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </header>
      <section className="FeedbackMain__content">
        <h2 className="content-title">{panelMainTitle}</h2>
        <p className="content-description">{panelMainDescription}</p>
        <form className="feedback-form">
          <textarea
            className="feedback-form__input"
            disabled={hasResolved === true && isLoading === false}
            placeholder={panelTextArea}
            value={text}
            onChange={e => setText(e.target.value)}
          />
          <div className="feedback-submit">
            <button
              className="feedback-submit__button kui-button kui-button--primary"
              disabled={text.length === 0}
              type="submit"
              onClick={async e => {
                e.preventDefault();
                setLoading(false);
                await submitFeedback({
                  text,
                  user: userName,
                  email: userEmail
                });
                setHasResolved(true);
                setLoading(false);
                showFeedbackPanel(false);
              }}
            >
              {panelSubmitButton}
            </button>
          </div>
        </form>
      </section>
      <footer className="FeedbackMain__footer">
        <div className="footer-contacts">
          <i className="mobile icon" />
          <a className="footer-contacts__phone" href="tel:+4533740700">
            33 74 07 00
          </a>
          <i className="mail icon" />
          <a
            className="footer-contacts__mail"
            href="mailto:kundeservice@karnovgroup.com"
          >
            kundeservice@karnovgroup.com
          </a>
        </div>
      </footer>
    </aside>
  );
}

FeedbackMain.propTypes = FeedbackPanelMainPropTypes;
