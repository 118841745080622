import React from 'react';
import PropTypes from 'prop-types';

const CurrentFiscalYear = ({ selectedYear }) => (
  <div className="TitleArea-current-fiscal-year">{selectedYear}</div>
);

CurrentFiscalYear.propTypes = {
  selectedYear: PropTypes.number.isRequired
};

export default CurrentFiscalYear;
