import React from 'react';
import PropTypes from 'prop-types';

import './index.css';
import iconDown from './icon_down.svg';
import iconUp from './icon_up.svg';
import iconIrrelevant from './icon_irrelevant.svg';
import iconBlank from './icon_blank.svg';

const IconPerformance = ({ icon }) => {
  if (icon) {
    switch (icon) {
      case 'up':
        return (
          <img
            alt="Going up"
            className="Icon-performance up"
            src={`${iconUp}`}
          />
        );

      case 'down':
        return (
          <img
            alt="Going down"
            className="Icon-performance down"
            src={`${iconDown}`}
          />
        );

      case 'irrelevant':
        return (
          <img
            alt="Remaining"
            className="Icon-performance irrelevant"
            src={`${iconIrrelevant}`}
          />
        );

      case 'blank':
        return (
          <img
            alt="Nothing to display"
            className="Icon-performance blank"
            src={`${iconBlank}`}
          />
        );

      default:
        return (
          <img
            alt="Remaining"
            className="Icon-performance irrelevant"
            src={`${iconIrrelevant}`}
          />
        );
    }
  }
  return <div />;
};

IconPerformance.propTypes = { icon: PropTypes.string };
IconPerformance.defaultProps = { icon: null };

export default IconPerformance;
