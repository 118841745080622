import { string, object, func } from 'prop-types';

export const ChartTypes = {
  chartsData: object,
  name: string.isRequired,
  numberFormat: string.isRequired
};

export const BarChartComponentTypes = {
  // The actual data of the whole section
  section: object.isRequired,
  // Converter of section data into data format
  convertRawDataIntoGraphData: func.isRequired,
  // Post-conversion data format
  chartsData: object,
  // element marking the bottom of the chart's container to stop the sticky behaviour
  translate: func.isRequired,
  numberFormat: string.isRequired
};
