import { getTranslate } from 'react-localize-redux';

/**
 * Because we need to directly translate the text and what to use getTranslate,
 * from the react-localize-redux package. But that function translates a React Element,
 * and not a string.
 * This functions translates directly.
 *
 * @param  {Object} state
 * @param  {String} text
 * @returns  {String} text
 */
export default function translateRawText(state, text) {
  try {
    if (text) {
      return getTranslate(state.locale)(text);
    }
  } catch (e) {
    return `${e.toString()} Trying to translate: ${text}`;
  }

  return null;
}
