/**
 * ROUTE NAMES.
 *
 * This allows a uniform naming convention for all routes.
 * It also allows for easier localization.
 */
export const BUDGET_REPORT_ROUTE_NAME = '/budget_report';
export const CLIENT_DATA_COMPANY_ROUTE_NAME = '/stamdata_company';
export const CLIENT_DATA_SETTINGS_ROUTE_NAME = '/stamdata_settings';
export const CONTAINER_STATIC_404 = '/404';
export const STATIC_ERROR_424 = '/424';
export const CREATE_CLIENT_ROUTE_NAME = '/add_company';
export const DASHBOARD_INIT_ROUTE_NAME = '/dashboard_1st';
export const ERROR_SERVER_DOWN = '/error';
export const FORECAST_ROUTE_NAME = '/forecast';
export const INIT_ROUTE_NAME = '/';
export const TRIAL_EXPIRED_ROUTE_NAME = '/trial/expired';
export const TRIAL_ROUTE_NAME = '/trial/:trial_access_token';
export const INPUT_BUDGET_ROUTE_NAME = '/input_budget';
export const INPUT_DATA_ROUTE_NAME = '/input_data';
export const LIST_CLIENT_ROUTE_NAME = '/list_companies';
export const MANAGEMENT_REPORT_ROUTE_NAME = '/mgt_report';
export const UNAUTHENTICATED_CARD_ROUTE_NAME = '/unauthenticated';
export const UNAUTHORIZED_CARD_ROUTE_NAME = '/unauthorized';
export const SIMULATION_REPORT_ROUTE_NAME = '/simulation';
export const VALUATION_REPORT_ROUTE_NAME = '/valuation';

// Importing and mapping related routes
export const INTEGRATION_LIST = '/integrations';
export const INTEGRATION_IMPORT_SUCCESS = `${INTEGRATION_LIST}/import/:integration(e-conomic|caseware|virk)/success`;
export const INTEGRATION_SELECT_YEARS = `${INTEGRATION_LIST}/import/:integration(e-conomic|caseware|virk)/select`;

// Custom Account Mapping
export const CAP_INTEGRATION_SELECT_ACCOUNT_PLAN = `${INTEGRATION_LIST}/import/:integration(e-conomic|caseware|virk)/plan`;
export const CAP_INTEGRATION_OVERVIEW = `${INTEGRATION_LIST}/import/:integration(e-conomic|caseware|virk)/overview`;
export const CAP_INTEGRATION_MAP = `${INTEGRATION_LIST}/map/:integration(e-conomic|caseware|virk)/main`;

// Caseware
export const INTEGRATION_CASEWARE_GENERATE_TOKEN = `${INTEGRATION_LIST}/import/caseware/token`;

/**
 * SECTION NAMES.
 *
 * Allows to group URL and its names.
 * Closely tied to the NAVIGATION_OPTIONS from Options.Navigation.
 */
export const SECTION_REPORTS = 'reports';
export const SECTION_INPUTS = 'inputs';
export const SECTION_CLIENT_DATA = 'client';

// API URLS
export const ENDPOINT_DASHBOARD_CLIENT = '/clients/';

// Exports
export { Routes } from './Routes';
