import React, { Fragment } from 'react';
import { Table as SemanticUiTable } from 'semantic-ui-react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { toggleDefinitionsPanel } from '../App/actions';

import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { TableCommonPropTypes } from './types';
import { TableTitle } from './TableTitle';

import './index.css';

export function TableCommon({
  name,
  section,
  translate,
  numberFormat,
  toggleSidePanel
}) {
  const {
    header,
    subtitle,
    subheader,
    rows,
    definition,
    hasDefinition,
    sidePanelId
  } = section;

  return (
    <Fragment>
      <TableTitle
        definition={definition}
        hasDefinition={hasDefinition}
        sectionName={name}
        showBottomBorder={definition}
        sidePanelId={sidePanelId}
        toggleSidePanel={toggleSidePanel}
        translate={translate}
      />
      <SemanticUiTable className="Table-container" id={`table-section-${name}`}>
        <TableHeader
          headings={header}
          sectionName={name}
          subheadings={subheader}
          subtitle={subtitle}
          translate={translate}
        />
        <TableBody
          numberFormat={numberFormat}
          rows={rows}
          toggleSidePanel={toggleSidePanel}
          translate={translate}
        />
      </SemanticUiTable>
    </Fragment>
  );
}

TableCommon.propTypes = TableCommonPropTypes;

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  numberFormat: state.client.number_format
});

const mapDispatchToProps = dispatch => ({
  toggleSidePanel: ({ sidePanelId }) =>
    dispatch(toggleDefinitionsPanel({ sidePanelId }))
});

export const Table = connect(
  mapStateToProps,
  mapDispatchToProps
)(TableCommon);
export { SimpleTable } from './SimpleTable';
