import { INPUT_DATA_START_YEAR } from '../../helpers/constants';
import * as types from './actionTypes';

export const initialState = {
  currentFiscalYear: INPUT_DATA_START_YEAR,
  years: [2015, 2016],
  sections: null
};

export default function inputDataReducer(state = initialState, action = {}) {
  const { payload } = action;

  switch (action.type) {
    case types.SUCCESS_FETCH_DATA_INPUT:
      return {
        ...state,
        currentFiscalYear: payload.currentFiscalYear,
        years: payload.years,
        sections: payload.sections
      };

    case types.WIPE_DATA_INPUT:
      return {
        ...state,
        currentFiscalYear: null,
        years: null,
        sections: null
      };

    default:
      return { ...state };
  }
}
