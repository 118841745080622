import React from 'react';
import PropTypes from 'prop-types';
import { rawToPretty } from '../../Cell/conversions';

const TableRowNameSubLabelAttribute = ({ ...props }) => (
  <span className="TableHeader-SubLabel">
    {props.translate(props.attribute.name)}{' '}
    {
      rawToPretty({
        type: props.attribute.type,
        exception: props.attribute.exception,
        value: props.attribute.value,
        style: props.attribute.display,
        numberFormat: props.numberFormat
      }).displayValue
    }
  </span>
);

TableRowNameSubLabelAttribute.propTypes = {
  translate: PropTypes.func.isRequired,
  attribute: PropTypes.object,
  numberFormat: PropTypes.string
};

TableRowNameSubLabelAttribute.defaultProps = {
  attribute: '',
  numberFormat: undefined
};

export const TableRowNameSubLabel = ({
  row,
  rowIndex,
  numberFormat,
  translate
}) => (
  <div id={`cell-header${row.name}-${rowIndex}`}>
    {row.label.attributes.map((attribute, attributeIndex) => (
      <TableRowNameSubLabelAttribute
        key={`${row.label.key + attributeIndex}`}
        attribute={attribute}
        attributeIndex={attributeIndex}
        numberFormat={numberFormat}
        translate={translate}
      />
    ))}
  </div>
);

TableRowNameSubLabel.propTypes = {
  row: PropTypes.object.isRequired,
  numberFormat: PropTypes.string.isRequired,
  rowIndex: PropTypes.number,
  translate: PropTypes.func.isRequired
};

TableRowNameSubLabel.defaultProps = {
  rowIndex: 0
};
