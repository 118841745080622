import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import burgerIcon from '../burger.svg';
import { BurgerButtonTypes } from '../types';

const BurgerButtonComponent = ({ toggleMenu }) => (
  <button className="BurgerMenu__button" type="button" onClick={toggleMenu}>
    <img alt="Open Menu" src={burgerIcon} />
  </button>
);

BurgerButtonComponent.propTypes = BurgerButtonTypes;

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(actions.toggleMenu())
});

const BurgerButton = connect(
  null,
  mapDispatchToProps
)(BurgerButtonComponent);

export { BurgerButton };
