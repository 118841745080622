import React from 'react';
import { string, func, shape, arrayOf } from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import './index.css';

const SelectPlanDropdown = ({ onSelectOption, selected, options }) => (
  <div className="SelectPlan-Dropdown">
    <Dropdown
      fluid
      selection
      className="--select"
      options={options}
      value={selected}
      onChange={onSelectOption}
    />
  </div>
);

SelectPlanDropdown.propTypes = {
  // The 'disable' below is known bug: https://github.com/yannickcr/eslint-plugin-react/issues/1389
  // eslint-disable-next-line react/no-typos
  selected: string.isRequired,
  // eslint-disable-next-line react/no-typos
  onSelectOption: func.isRequired,
  options: arrayOf(
    shape({
      text: string.isRequired,
      value: string.isRequired
    }).isRequired
  ).isRequired
};

export { SelectPlanDropdown };
