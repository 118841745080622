import { get, put } from 'axios';
import translateRawText from '../../../helpers/conversions/formatText';
import { getClientId } from '../../../services/navigation/routeMapping';
import { preparePayload } from './integrations';
import { appHasErrorAfterFetch } from '../../../components/App/actions';
import {
  APP_INIT_FETCHING_INITIAL_DATA,
  APP_STOP_FETCHING_INITIAL_DATA
} from '../../../components/App/actionTypes';
import { getCommentsData } from '../../../components/Comments/actions';

export const CONVERT_RAW_DATA_INTO_GRAPH_DATA =
  'SIMULATION_CONVERT_RAW_DATA_INTO_GRAPH_DATA';
export const REPORT_SIMULATION_ERROR_FETCH_DATA = 'SIMULATION_ERROR_FETCH_DATA';
export const REPORT_SIMULATION_ERROR_UPDATE_CELL_VALUE =
  'SIMULATION_ERROR_UPDATE_CELL_VALUE';
export const REPORT_SIMULATION_INIT_FETCH_DATA = 'SIMULATION_INIT_FETCH_DATA';
export const REPORT_SIMULATION_INIT_UPDATE_CELL_VALUE =
  'SIMULATION_INIT_UPDATE_CELL_VALUE';
export const REPORT_SIMULATION_SUCCESS_FETCH_DATA =
  'SIMULATION_SUCCESS_FETCH_DATA';
export const REPORT_SIMULATION_UPDATE_CELL_VALUE =
  'SIMULATION_UPDATE_CELL_VALUE';
export const REPORT_SIMULATION_CLEAR_DATA = 'SIMULATION_CLEAR_DATA';

/**
 * Line colors have specific requirements.
 * For example, the most proeminent color is blue
 * and is assigned to the first row.
 * @param  {Number} rowIndex [The index will determine the color]
 * @return {String}          [A color code is returned.]
 */
export function determineColorOfBar(rowIndex) {
  switch (rowIndex) {
    case 0:
      return '#3b4856';
    case 1:
      return '#FED551';
    default:
      return '#2F2F2B';
  }
}

function initFetchData() {
  return {
    type: REPORT_SIMULATION_INIT_FETCH_DATA,
    payload: {
      sections: [],
      isLoading: true
    }
  };
}

export function successFetchData(response) {
  return {
    type: REPORT_SIMULATION_SUCCESS_FETCH_DATA,
    payload: {
      ...response,
      isLoading: false
    }
  };
}

function errorFetchData(error) {
  return {
    type: REPORT_SIMULATION_ERROR_FETCH_DATA,
    payload: { ...error }
  };
}

export const fetchSimulationTablesData = () => (dispatch, getState) => {
  dispatch({ type: APP_INIT_FETCHING_INITIAL_DATA });
  dispatch(initFetchData());

  const clientId = getClientId(getState().router.location);

  get(`${API_URL}/clients/${clientId}/reports/simulation_analysis`)
    .then(response => {
      dispatch({ type: APP_STOP_FETCHING_INITIAL_DATA });
      dispatch(successFetchData(response.data.simulation_analysis));
    })
    .catch(error => {
      // internal error action
      dispatch(errorFetchData(error));
      // global error actions
      dispatch(appHasErrorAfterFetch(error));
    });
};

export const fetchSimulationData = () => dispatch =>
  Promise.all([
    dispatch(fetchSimulationTablesData()),
    dispatch(getCommentsData())
  ]);

function errorUpdateCellValue(error) {
  return {
    type: REPORT_SIMULATION_ERROR_UPDATE_CELL_VALUE,
    error
  };
}

function initUpdateCellValue(sectionIndex, rowIndex, cellIndex, value) {
  return {
    type: REPORT_SIMULATION_INIT_UPDATE_CELL_VALUE,
    payload: {
      isLoading: true,
      sectionIndex,
      rowIndex,
      cellIndex,
      value
    }
  };
}

export function updateCellValue(
  sectionIndex,
  rowIndex,
  cellIndex,
  value,
  sectionName,
  rowName
) {
  return (dispatch, getState) => {
    dispatch(initUpdateCellValue(sectionIndex, rowIndex, cellIndex, value));
    const payload = preparePayload(sectionName, rowName, value);
    // This is temporary, until the authentication hook is ready,
    // which returns the clientID.
    const clientId = getClientId(getState().router.location);

    return put(
      `${API_URL}/clients/${clientId}/reports/simulation_analysis`,
      payload
    )
      .then(response =>
        dispatch(successFetchData(response.data.simulation_analysis))
      )
      .catch(e => dispatch(errorUpdateCellValue(e)));
  };
}

export function updateSectionsData(response) {
  return {
    type: REPORT_SIMULATION_UPDATE_CELL_VALUE,
    payload: response
  };
}

/**
 * The response from the server has a format that needs to
 * be adapted to the format of the bar chart.
 * This chart has a different format than the ones in management/budget report views.
 * @param  {Object} section [Contains all the data from the tables and charts]
 * @return {Object}         [Sending the payload to the reducer]
 */
export function convertRawDataIntoGraphData(section) {
  return (dispatch, getState) => {
    const state = getState();
    const dataLabels = [];
    // New array to store the transposed values.
    const dataArray = [[], []];
    const xxLabels = section.rows.map((rowData, rowIndex) => {
      // Updating the label names.
      dataLabels.push(section.header[rowIndex]);
      // Transposing the array.
      rowData.cells.map((cell, cellIndex) => {
        // Removing the 'afvigelse' column from the array.
        if (cellIndex < 2 && cell) {
          return dataArray[cellIndex].push(Math.round(cell.value));
        }
        return null;
      });
      // ...and return the xLabels.
      return translateRawText(state, rowData.name);
    }, []);

    const datasets = dataArray.map(
      (data, index) => ({
        data,
        label: dataLabels[index],
        backgroundColor: determineColorOfBar(index)
      }),
      []
    );

    return dispatch({
      type: CONVERT_RAW_DATA_INTO_GRAPH_DATA,
      payload: {
        [section.name]: {
          labels: xxLabels,
          datasets
        }
      }
    });
  };
}
