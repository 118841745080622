import React from 'react';
import { Table } from 'semantic-ui-react';

import { TableRowName } from './TableRowName';
import { TableRowCells } from './TableRowCells';
import { TableRowPropTypes } from '../types';

import './index.css';

export const TableRow = ({
  row,
  rowIndex,
  style,
  translate,
  numberFormat,
  hasDefinition,
  sidePanelId,
  toggleSidePanel
}) => (
  <Table.Row className={`TableRow ${style}`}>
    <TableRowName
      hasDefinition={hasDefinition}
      numberFormat={numberFormat}
      row={row}
      rowIndex={rowIndex}
      sidePanelId={sidePanelId}
      toggleSidePanel={toggleSidePanel}
      translate={translate}
    />
    <TableRowCells row={row} rowIndex={rowIndex} />
  </Table.Row>
);

TableRow.propTypes = TableRowPropTypes;
TableRow.defaultProps = {
  style: '',
  numberFormat: undefined
};
