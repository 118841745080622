import React, { Component } from 'react';

import { sanitizeAccountName } from '../backend';
import { RowType } from '../types';
import { RowContext } from '../context';
import { Group } from '../Group';

import { Right } from './Right';
import { Left } from './Left';
import { Dropdown } from './Dropdown';

import './index.css';

class Row extends Component {
  state = {
    isExpanded: false
  };

  toggleChildren = () =>
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));

  render() {
    const {
      source_mapping_key: name,
      source_mapping_label: label,
      kbo_account: kboAccount,
      childrenData,
      rowData,
      isRoot,
      value,
      changed: hasOriginalValueChanged
    } = this.props;

    const account = sanitizeAccountName(kboAccount);
    const { isExpanded } = this.state;

    return (
      <RowContext.Provider value={{ name, label, rowData, childrenData }}>
        <ul
          className={`Row-container ${isRoot ? 'Row-container--isRoot' : null}`}
        >
          <li className="Row-container__item">
            <Left
              hasOriginalValueChanged={hasOriginalValueChanged}
              isExpanded={isExpanded}
              label={label}
              length={childrenData.length}
              name={name}
              toggleChildren={this.toggleChildren}
            />

            <Right account={account} label={label} name={name} value={value} />
          </li>

          {isExpanded && <Group data={childrenData} isContainer={false} />}
        </ul>
      </RowContext.Provider>
    );
  }
}

Row.propTypes = RowType;

export { Row, Group, Right, Left, Dropdown };
