import React from 'react';
import { Button } from '@karnov/kui-deprecated';

import { DiscrepancyNotificationTypes } from '../types';
import { TranslateContext } from '../../../../components/Screen';
import closeIcon from '../../../../shared/images/closeIcon.svg';
import successIcon from '../../../../shared/images/successIcon.svg';
import { MapScreenContext } from '../context';

import './index.css';

export const DiscrepancyNotification = ({ onClickSubmitButton }) => (
  <div className="DiscrepancyNotification">
    <MapScreenContext.Consumer>
      {({ toggleNotification }) => (
        <div className="Inner">
          <TranslateContext.Consumer>
            {({ translate }) => (
              <div className="Content">
                <div className="Content__icon">
                  <img alt="success" src={successIcon} />
                </div>

                <div className="ContentRight">
                  <div className="ContentRight__title">
                    {translate('map.notification.title')}
                  </div>
                  <div className="ContentRight__subtitle">
                    {translate('map.notification.subtitle')}
                  </div>

                  <Button
                    text={translate('map.notification.button')}
                    type="primary"
                    onClick={onClickSubmitButton}
                  />
                </div>
              </div>
            )}
          </TranslateContext.Consumer>

          <button
            className="CloseIcon"
            type="button"
            onClick={toggleNotification}
          >
            <img alt="close" src={closeIcon} />
          </button>
        </div>
      )}
    </MapScreenContext.Consumer>
  </div>
);

DiscrepancyNotification.propTypes = DiscrepancyNotificationTypes;
