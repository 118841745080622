// vendors
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Divider } from 'semantic-ui-react';
import { getTranslate } from 'react-localize-redux';
// local
import * as actions from './actions';
import TableSensitivityAnalysis from './Table';
import { convertResponseOptionsToDropdownOptionsFormat } from './integration';
import './SensitivityAnalysis.css';

export class SensitivityAnalysis extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    valueDriversOptions: PropTypes.array.isRequired,
    table: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    // Fetching data
    this.props.dispatch(actions.fetchData(this.props.table));
  }

  render() {
    return (
      <div className="SensitivityAnalysis-container Section-container">
        <div className="SensitivityAnalysis-title-area">
          {this.props.translate('sensitivityAnalysis.title')}
        </div>
        <Divider />
        <div className="SensitivityAnalysis-dropdowns-area">
          {this.props.translate('sensitivityAnalysis.compare')}
          <span className="SensitivityAnalysis-dropdown-axis-text">
            {this.props.translate('sensitivityAnalysis.yAxis')}
          </span>
          <Dropdown
            selection
            className="SensitivityAnalysis-dropdown"
            loading={this.props[`${this.props.table}`].isLoading}
            options={convertResponseOptionsToDropdownOptionsFormat(
              this.props.valueDriversOptions,
              this.props.table,
              'Y'
            )}
            placeholder="Select Option"
            value={this.props[`${this.props.table}`].valueDriverY}
            onChange={(event, data) =>
              this.props.dispatch(
                actions.onDropdownChange(event, data, 'Y', this.props.table)
              )
            }
          />
          {this.props.translate('sensitivityAnalysis.with')}
          <span className="SensitivityAnalysis-dropdown-axis-text">
            {this.props.translate('sensitivityAnalysis.xAxis')}
          </span>
          <Dropdown
            selection
            className="SensitivityAnalysis-dropdown"
            loading={this.props[`${this.props.table}`].isLoading}
            options={convertResponseOptionsToDropdownOptionsFormat(
              this.props.valueDriversOptions,
              this.props.table,
              'X'
            )}
            placeholder="Select Option"
            value={this.props[`${this.props.table}`].valueDriverX}
            onChange={(event, data) =>
              this.props.dispatch(
                actions.onDropdownChange(event, data, 'X', this.props.table)
              )
            }
          />
        </div>
        <Divider />
        <TableSensitivityAnalysis
          {...this.props[`${this.props.table}`]}
          isLoading={this.props[`${this.props.table}`].isLoading}
        />
      </div>
    );
  }
}

const mapDispatchToProps = state => ({
  ...state.valuationReport.sensitivityAnalysis,
  numberFormat: state.client.number_format,
  translate: getTranslate(state.locale),
  valueDriversOptions:
    state.valuationReport.sensitivityAnalysis.valueDriversOptions
});

export default connect(mapDispatchToProps)(SensitivityAnalysis);
