import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addTranslation } from 'react-localize-redux';

import TopRow from '../../../components/TopRow';
import { InfoCardDetailed } from '../../../components/InfoCardDetailed';
import Footer from '../../../components/Footer';

import './index.css';

import TopRowLocale from '../../../components/TopRow/locale.json';
import Locale from './locale.json';
import FooterLocale from '../../../components/Footer/locale.json';

class Unauthorized extends PureComponent {
  static propTypes = { dispatch: PropTypes.func.isRequired };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;

    dispatch(addTranslation(TopRowLocale));
    dispatch(addTranslation(Locale));
    dispatch(addTranslation(FooterLocale));
  }

  render() {
    return (
      <div className="Unauthorized-container full-height-container">
        <TopRow />
        <InfoCardDetailed
          accessText="detailed-card.unauthorized-screen.access.text"
          accessTitle="detailed-card.unauthorized-screen.access.title"
          buttons={[
            {
              text: 'detailed-card.unauthorized-screen.info.button.text',
              url: 'detailed-card.unauthorized-screen.info.button.url',
              primary: true
            }
          ]}
          modules={[
            'detailed-card.unauthorized-screen.access.module1',
            'detailed-card.unauthorized-screen.access.module2',
            'detailed-card.unauthorized-screen.access.module3',
            'detailed-card.unauthorized-screen.access.module4'
          ]}
          text="detailed-card.unauthorized-screen.info.text"
          title="detailed-card.unauthorized-screen.info.title"
        />
        <Footer />
      </div>
    );
  }
}

export default connect()(Unauthorized);
