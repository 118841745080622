import { localeReducer } from 'react-localize-redux';
import { reducer as notifications } from 'react-notification-system-redux';
import { routerReducer } from 'react-router-redux';

import authReducer from '../services/auth/reducer';
import cellReducer from '../components/Cell/reducer';
import clientDataReducer from '../screens/Client/Edit/reducer';
import clientReducer, { appReducer } from '../components/App/reducer';
import commentsReducer from '../components/Comments/reducer';
import createClientReducers from '../screens/Client/Create/reducer';
import dataImportSessionReducer from '../screens/Integrations/reducer';
import forecastReducer from '../screens/Analysis/Forecast/reducer';
import inputDataReducer from '../screens/Input/reducer';
import listClientsReducers from '../screens/Client/List/reducer';
import menuReducer from '../components/BurgerMenu/reducer';
import reportManagementReducers from '../screens/Analysis/Management/reducer';
import reportSimulationReducers from '../screens/Analysis/Simulation/reducer';
import valuationReducer from '../screens/Analysis/Valuation/reducer';

export const reducers = {
  // 'app' is the global state reducer (isLoading, etc).
  app: appReducer,
  auth: authReducer,
  cell: cellReducer,
  client: clientReducer,
  clientData: clientDataReducer,
  comments: commentsReducer,
  createClient: createClientReducers,
  dataImportSession: dataImportSessionReducer,
  inputData: inputDataReducer,
  listClients: listClientsReducers,
  locale: localeReducer,
  menu: menuReducer,
  notifications,
  reportManagement: reportManagementReducers,
  router: routerReducer,
  simulationReport: reportSimulationReducers,
  valuationReport: valuationReducer,
  forecast: forecastReducer
};
