import React, { Component } from 'react';
import { func, string, bool, object, arrayOf, shape } from 'prop-types';
import { connect } from 'react-redux';

import {
  SIMULATION_REPORT_ROUTE_NAME,
  MANAGEMENT_REPORT_ROUTE_NAME,
  BUDGET_REPORT_ROUTE_NAME,
  VALUATION_REPORT_ROUTE_NAME,
  FORECAST_ROUTE_NAME
} from '../../services/navigation';
import { extractYearFromURL } from '../../helpers/location';
import Loading from '../../components/LoadingSpinner';

import * as actionsReport from './Management/actions';
import * as actionsSimulation from './Simulation/actions';
import * as actionsForecast from './Forecast/actions';
import * as actionsValuation from './Valuation/actionsValuation';

import ScreenDispatcher from './ScreenDispatcher';

export default function analysisModuleDispatcher(WrappedComponent) {
  class AnalysisModuleDispatcher extends Component {
    static propTypes = {
      dispatch: func.isRequired,
      isFetchingInitialData: bool.isRequired,
      location: object.isRequired,
      error: object,
      currentLocation: shape({
        pathname: string.isRequired,
        search: string.isRequired
      }).isRequired,
      sections: arrayOf(object).isRequired
    };

    static defaultProps = {
      error: null
    };

    componentDidMount() {
      this.fetchData(this.props);
    }

    /**
     * When the selected year has changed (via dropdown)
     * the url is updated and we need to fetch new data based
     * on the newly selected year.
     */
    shouldComponentUpdate({
      location: nextLocation,
      sections: nextSections,
      isFetchingInitialData: nextIsFetchingInitialData
    }) {
      const {
        currentLocation,
        sections: currentSections,
        isFetchingInitialData
      } = this.props;

      switch (true) {
        case currentLocation && nextLocation.search !== currentLocation.search:
          this.fetchData({ location: nextLocation });
          return true;

        case nextSections !== currentSections ||
          isFetchingInitialData !== nextIsFetchingInitialData:
          return true;

        default:
          return false;
      }
    }

    fetchData({ location }) {
      const { pathname } = location;
      const { dispatch } = this.props;

      switch (pathname) {
        case SIMULATION_REPORT_ROUTE_NAME:
          return dispatch(actionsSimulation.fetchSimulationData());

        case MANAGEMENT_REPORT_ROUTE_NAME:
        case BUDGET_REPORT_ROUTE_NAME:
        default:
          const yearExtractedFromURL = extractYearFromURL({ location });
          return dispatch(actionsReport.fetchReportsData(yearExtractedFromURL));

        case VALUATION_REPORT_ROUTE_NAME:
          return dispatch(actionsValuation.fetchValuationData());

        case FORECAST_ROUTE_NAME:
          return dispatch(actionsForecast.fetchForecastData());
      }
    }

    render() {
      return this.props.isFetchingInitialData === true ? (
        <Loading />
      ) : (
        <ScreenDispatcher Component={WrappedComponent} {...this.props} />
      );
    }
  }

  const mapStateToProps = state => ({
    isFetchingInitialData: state.app.isFetchingInitialData,
    error: state.app.error,
    location: state.router.location
  });

  return connect(mapStateToProps)(AnalysisModuleDispatcher);
}
