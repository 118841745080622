export const areAllYearsSelected = years => years.every(year => year.checked);

export const areSomeYearsSelected = years =>
  years.some(element => element.checked);

export const areSomeYearsDisabled = years =>
  years.some(element => element.disabled);

export const isIndeterminate = years =>
  !areAllYearsSelected(years) && areSomeYearsSelected(years);

export const onlyEnabledYears = years => years.filter(year => !year.disabled);

export const selectAllYears = years =>
  years.map(year => {
    if (year.disabled) {
      return {
        ...year,
        checked: false
      };
    }

    return {
      ...year,
      checked: !areAllYearsSelected(onlyEnabledYears(years))
    };
  });
