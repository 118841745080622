import { SIMULATION_REPORT_ROUTE_NAME } from '../../services/navigation';

/**
 * In simulation view, the value is null so that the text rendered in
 * the input field is the placeholder text.
 * @param {Number} rawValue
 * @param {String} viewName
 * @return {Boolean}
 */
export const isSimulationException = (rawValue, viewName) =>
  rawValue === null && viewName === SIMULATION_REPORT_ROUTE_NAME;

/**
 * Check's if the -0 is really -0, and display decimal.
 * @param {*} value
 */
export const isDecimalMinusZero = (value, style) =>
  Object.is(-0, value) && style === 'decimal';

/**
 * Check's if the -0 is really -0, and display percent.
 * @param {*} value
 * @param {*} style
 */
export const isPercentageMinusZero = (value, style) =>
  Object.is(-0, value) && style === 'percent';

/**
 * The placeholder text for the input field changes based on the view.
 * For example, the simulation view should display a placeholder of 'Status Quo' when cell is empty.
 * @return {String}
 */
export const showCorrectPlaceholder = viewName =>
  viewName && viewName === SIMULATION_REPORT_ROUTE_NAME ? 'Status Quo' : '';

/**
 * Because the localization library (Intl.Number) doesn't localize -0 properly,
 * a '-' needs to be prepended to the displayValue
 * @return {[type]} [description]
 */
export function addMinusToDisplayValue(
  localizeNumber,
  rawValue,
  numberFormat,
  options
) {
  // When editing a number, we don't want the % sign.
  // Hence, the library localizes as a 'decimal' number and doesn't return a % sign.
  // options.style = 'decimal';
  const updatedOptions = {
    ...options,
    style: 'decimal'
  };
  const localizedValue = localizeNumber(rawValue, numberFormat, updatedOptions).displayValue;

  return {
    displayValue: localizedValue.startsWith('-') ? localizedValue : `-${localizedValue}`,
    // RawValue is not affected by the disappearing on the '-' signal.
    rawValue
  };
}

export const isEmptyCellException = rawValue => rawValue === null;

/**
 *  Some views have different UI requirements for 'null' values.
 * Examples: -0, 0, null, etc. handling
 * @return {[type]} [description]
 */
export function rawToPrettyExceptionHandler(value, style, viewName, exception) {
  if (isDecimalMinusZero(value, style)) {
    return {
      displayValue: '-0',
      rawValue: -0
    };
  }

  if (isPercentageMinusZero(value, style)) {
    return {
      displayValue: '-0 %',
      rawValue: -0
    };
  }

  if (isSimulationException(value, viewName)) {
    return {
      displayValue: '',
      rawValue: null
    };
  }

  if (exception) {
    return {
      displayValue: exception,
      rawValue: null
    };
  }

  if (value === null) {
    return {
      displayValue: '',
      rawValue: null
    };
  }

  return false;
}
