import rollbar from 'rollbar';
import { ERROR_SERVER_DOWN } from '../../services/navigation';

/**
 * Information sent to rollbar,
 * when reporting an error.
 * @param {object} args
 */
export function rollbarPayload({ id, dehashedError }) {
  return {
    payload: {
      context: { ...dehashedError },
      person: {
        // the per_id from auth.sub is sent.
        id
      }
    }
  };
}
/**
 * Initializing Rollbar's settings.
 * @param {String} email
 */
export function init() {
  /*eslint-disable */
  const Rollbar = new rollbar({
    accessToken: '449dfcfae4ee48e588daaa1ecb7221a3',
    payload: {
      environment: ENV,
    },
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    autoInstrument: {
      network: true,
      log: true,
      dom: false,
      navigation: true,
      connectivity: true,
    },
  })

  window.Rollbar = Rollbar
  return Rollbar
}

/**
 * The /error view is responsible for the triggering a Rollbar error.
 * Thus, Rollbar.*any errors are reported there.
 * @param {object} args
 */
export function redirectToErrorView(args) {
  const { message, location } = args
  const isErrorPage = window.location.pathname.includes('/error')
  const isErrorRoute = location === ERROR_SERVER_DOWN
  const hashedError = window.btoa(
    JSON.stringify({
      error: `${message}.`,
      location: `${window.location.href}`,
    })
  )

  // Don't redirect when already in /error.
  if (!isErrorRoute && !isErrorPage) window.location = `/error/${hashedError}`

  return hashedError
}

export function decryptHashedError(hashedError) {
  return JSON.parse(window.atob(hashedError))
}

/**
 * The middleware is for catching runtime errors after the app
 * was loaded.
 *
 * The middleware gives access to the store, action and error.
 * There are useful because they allow to not report errors on all action types
 * or locations.
 */
export function crashReporterReduxMiddleware() {
  return store => next => action => {
    const location = store.getState().router.location.pathname
    try {
      return next(action)
    } catch (err) {
      //if (action.type === '@@router/LOCATION_CHANGE') {
      return redirectToErrorView({ message: err, location })
      //} else throw err;
    }
  }
}
