import React from 'react';
import { Link } from 'react-router-dom';
import { determineActiveLink } from '../helpers';
import { SubsectionTypes } from '../../../types';

const Subsection = ({ subsection, translate, currentLocation }) => (
  <Link to={subsection.route}>
    <div
      className={`menu-item ${determineActiveLink(
        currentLocation,
        subsection.route
      )}`}
      id={`menu-item-${subsection.name}`}
    >
      {translate(`menu.${subsection.name}`)}
    </div>
  </Link>
);

Subsection.propTypes = SubsectionTypes;

export { Subsection };
