import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import CellDispatcher from '../../Cell/Dispatcher';

import './index.css';

const SimpleTable = ({ rows }) => (
  <Table unstackable className="SimpleTable-container">
    <Table.Body>
      {rows.map((row, rowIndex) => (
        <Table.Row key={`${rowIndex + row.cells.length}`} id={`${row.name}`}>
          <Table.Cell className="TableCell-header">{row.name}</Table.Cell>

          {row.cells.map((cell, cellIndex) => (
            <CellDispatcher
              key={
                // eslint-disable-next-line
                cell.id
              }
              cell={cell}
              cellIndex={cellIndex}
            />
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

SimpleTable.propTypes = {
  rows: PropTypes.array.isRequired
};

export { SimpleTable };
