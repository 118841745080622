/* eslint no-case-declarations: [0] */
/* eslint consistent-return: [0] */
import { push } from 'connected-react-router';
import { ERROR_SERVER_DOWN, STATIC_ERROR_424 } from '../../navigation';
import { routeWithClientId } from '../../../helpers';
import { responseSanitizer } from '../../../screens/Static/424/api/response';
import {
  RETURN_URL_PATH,
  RETURN_URL_SEARCH,
  setInSessionStorage
} from '../../sessionStorage';

export default function errorCodeResponseHandler(response) {
  const statusCode = response.status;

  return dispatch => {
    switch (statusCode) {
      case 401:
        // 401 == unauthenticated, we don't know who you are
        const url = response.data.errors.details.redirect_to;

        // Save the current location before redirecting user to account service
        if (window.location.pathname !== null) {
          setInSessionStorage(RETURN_URL_PATH, window.location.pathname);
          setInSessionStorage(RETURN_URL_SEARCH, window.location.search);
        }

        if (url) {
          return window.location.assign(url);
        }
        return;
      case 403:
        // 403 == forbidden, access denied, unauthorized
        const urlForbidden = response.data.errors.details.redirect_to;
        if (urlForbidden) {
          return window.location.assign(urlForbidden);
        }
        return;
      case 404:
        const containsCVRLookupPath = new RegExp('/cvr_lookup/');
        if (response.config.url.match(containsCVRLookupPath)) {
          return;
        }

        return dispatch(push(ERROR_SERVER_DOWN));

      case 412:
        // 412 === data missing.
        // Handled in the view.
        // The absence of this case, will fallback to the general error card.
        return;
      case 422:
        // Failed form validation
        // Handled in the view.
        // TODO: Remove this from here, when client/edit screen handles errors
        const addCompanyPath = new RegExp('/add_company');
        if (window.location.href.match(addCompanyPath)) {
          return;
        }

        return dispatch(push(ERROR_SERVER_DOWN));

      case 424:
        // 424 === Import data has failed.
        return dispatch(
          push(routeWithClientId(STATIC_ERROR_424), responseSanitizer(response))
        );

      default:
        return dispatch(push(ERROR_SERVER_DOWN));
    }
  };
}
