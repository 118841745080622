import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { convertYearsFormatForDropdown } from '../../helpers/conversions';
import { Tooltip, SimpleTable } from '../../../../components';

import { TableTypes } from '../types';
import './index.css';

export const Table = ({
  rows,
  years,
  selectedYear,
  onSelectYear,
  loading,
  translate,
  integration
}) => (
  <section className="Overview-Table">
    <div className="Overview-TableHeader">
      <div className="Overview-TableHeaderTitle">
        <h3>{translate('overview.table.title')}</h3>
        <Tooltip
          message={`${integration}.tooltips.overview.table.message`}
          type="info"
        />
      </div>
      <Dropdown
        className="Overview-Table__select-year"
        loading={loading}
        options={convertYearsFormatForDropdown(years)}
        selectOnBlur={false}
        value={selectedYear}
        onChange={onSelectYear}
      />
    </div>
    <SimpleTable integration={integration} rows={rows} />
  </section>
);

Table.propTypes = TableTypes;
