import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

import { StickyContext } from './context';
import { setPosition } from './helpers';

import './index.css';

export class Sticky extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    offset: PropTypes.number,
    bottomElement: PropTypes.instanceOf(Element)
  };

  static defaultProps = {
    offset: 0,
    bottomElement: null
  };

  state = {
    position: 'absolute',
    status: 'at-top',
    placeholderHeight: this.stickyElement && this.stickyElement.offsetHeight
  };

  /*
   ** This means that as default, the scroll is at top.
   ** Gets updated on component did mount.
   */
  scroll = 0;

  direction = null;

  hasDirectionChanged = false;

  stickyElement = null;

  placeholder = null;

  componentDidMount() {
    window.addEventListener('load', this.setProperties);
    window.addEventListener('scroll', this.setPropertiesWithScroll);
    window.addEventListener('resize', this.setProperties);
    this.setProperties();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { status, placeholderHeight, top } = this.state;

    /* if the sticky element has changed we need to update */
    if (!isEqual(nextProps.children, this.props.children)) {
      return true;
    }

    /*
     ** if we resize the window height, we may need to update the sticky position
     */
    if (
      status === 'sticky' &&
      nextState.status === 'sticky' &&
      top !== nextState.top
    ) {
      return true;
    }

    if (
      status === nextState.status &&
      placeholderHeight === nextState.placeholderHeight
    ) {
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setPropertiesWithScroll);
    window.removeEventListener('resize', this.setProperties);
    window.removeEventListener('load', this.setProperties);
  }

  setProperties = () => {
    this.setPlaceholderHeight();
    this.setSticky();
  };

  setPropertiesWithScroll = () => {
    this.setDirection();
    this.setProperties();
  };

  setPlaceholderHeight = () => {
    if (!this.stickyElement) {
      return;
    }

    if (this.stickyElement.offsetHeight === this.placeholder.offsetHeight) {
      return;
    }

    this.setState({
      placeholderHeight: this.stickyElement.offsetHeight
    });
  };

  setStickyElement = element => {
    this.stickyElement = element;
  };

  setPlaceholder = element => {
    this.placeholder = element;
  };

  setDirection = () => {
    const scroll = this.getCurrentScroll();
    const currentDirection = this.scroll > scroll ? 'up' : 'down';

    if (this.direction && currentDirection !== this.direction) {
      this.hasDirectionChanged = true;
    } else {
      this.hasDirectionChanged = false;
    }

    this.direction = currentDirection;
    this.setScroll(scroll);
  };

  getCurrentScroll = () =>
    document.documentElement.scrollTop || document.body.scrollTop;

  setScroll = scroll => {
    this.scroll = scroll;
  };

  getPositionalParameters = () => {
    const { direction, hasDirectionChanged } = this;
    const { status } = this.state;
    const windowHeight = window.innerHeight;
    const { offset, bottomElement } = this.props;
    const distanceToTop = this.placeholder.getBoundingClientRect().top;
    const distanceToTopFromBottom =
      bottomElement && bottomElement.getBoundingClientRect().top;
    const stickyElementHeight = this.stickyElement.offsetHeight || 0;
    const viewableHeight = stickyElementHeight - offset;
    const distanceToTopFromElementBottom = this.placeholder.getBoundingClientRect()
      .bottom;
    const distanceToTopFromSticky = this.stickyElement.getBoundingClientRect()
      .top;
    const distanceToBottomFromSticky = this.stickyElement.getBoundingClientRect()
      .bottom;

    return {
      bottomElement,
      offset,
      distanceToTop,
      distanceToTopFromBottom,
      stickyElementHeight,
      viewableHeight,
      distanceToTopFromElementBottom,
      distanceToTopFromSticky,
      distanceToBottomFromSticky,
      hasDirectionChanged,
      direction,
      status,
      windowHeight
    };
  };

  setSticky = () => {
    if (!this.stickyElement) {
      return null;
    }

    const params = this.getPositionalParameters();

    return this.setState(setPosition(params));
  };

  render() {
    const { top, position, status, bottom, placeholderHeight } = this.state;

    return (
      <StickyContext.Provider value={{ status }}>
        <div className={`sticky-container ${status}`}>
          <div
            ref={this.setStickyElement}
            className="sticky-element"
            style={{
              position,
              top,
              bottom
            }}
          >
            {this.props.children}
          </div>
          <div
            ref={this.setPlaceholder}
            style={{
              height: placeholderHeight
            }}
          />
        </div>
      </StickyContext.Provider>
    );
  }
}

export { StickyContext } from './context';
export default Sticky;
