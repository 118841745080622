import React from 'react';
import PropTypes from 'prop-types';

import EditComment from './Edit';
import StaticComment from './Static';

class Comment extends React.PureComponent {
  static propTypes = {
    printable: PropTypes.bool,
    edit: PropTypes.bool
  };

  static defaultProps = {
    printable: true,
    edit: false
  };

  state = { edit: this.props.edit };

  toggleEdit = () => {
    this.setState(prevState => ({ edit: !prevState.edit }));
  };

  addHiddenClass = () =>
    !this.props.printable ? 'Comments-hidden-from-print' : '';

  render() {
    return (
      <div className={`Comments-comment ${this.addHiddenClass()}`}>
        {this.state.edit ? (
          <div className="Comments-edit-wrapper">
            <EditComment {...this.props} toggleEdit={this.toggleEdit} />
          </div>
        ) : (
          <div className="Comments-static-wrapper">
            <StaticComment {...this.props} toggleEdit={this.toggleEdit} />
          </div>
        )}
      </div>
    );
  }
}

export default Comment;
