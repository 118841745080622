/* eslint-disable react/no-typos */
import React from 'react';
import { RightComponentTypes } from '../types';
import { Dropdown } from '../Dropdown';

import { Value } from './Value';
import './index.css';

const Right = ({ value, name, account }) => (
  <div className="Row-rightarea">
    <Dropdown account={account} name={name} />
    <Value value={value} />
  </div>
);

Right.propTypes = RightComponentTypes;

export { Right };
