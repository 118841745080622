import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';

import { handleNewDefinition } from './helpers';
import { SidePanelContent } from './SidePanelContent';
import { toggleDefinitionsPanel } from '../App/actions';
import { SidePanelTypes, SidePanelContainerPropTypes } from './types';

import './index.css';

export const SidePanelComponent = ({
  isVisible,
  onClickCloseButton,
  sidePanelId
}) => {
  const [definitions, setDefinitions] = useState([]);
  async function fetchDefinitionData() {
    if (sidePanelId) {
      const response = await axios.get(`/definitions/${sidePanelId}`);
      setDefinitions(handleNewDefinition(response.data, definitions));
    }
  }

  const handleClosePanel = () => {
    setDefinitions([]);
    onClickCloseButton({ sidePanelId: null });
  };

  useEffect(() => {
    fetchDefinitionData();
  }, [sidePanelId]);

  return (
    <CSSTransition
      unmountOnExit
      classNames="sidebar"
      in={isVisible}
      timeout={500}
    >
      <div className="sidebar">
        <button
          className="sidebar__close"
          type="button"
          onClick={handleClosePanel}
        />
        {definitions.length > 0 && (
          <SidePanelContent definitions={definitions} />
        )}
      </div>
    </CSSTransition>
  );
};

SidePanelComponent.propTypes = SidePanelTypes;

export function SidePanelContainer({ sidePanelId, toggleSidePanel }) {
  return (
    <SidePanelComponent
      isVisible={Boolean(sidePanelId)}
      sidePanelId={sidePanelId}
      onClickCloseButton={toggleSidePanel}
    />
  );
}

const mapStateToProps = state => ({
  sidePanelId: state.app.sidePanelId
});

const mapDispatchToProps = dispatch => ({
  toggleSidePanel: ({ sidePanelId }) =>
    dispatch(toggleDefinitionsPanel({ sidePanelId }))
});

SidePanelContainer.propTypes = SidePanelContainerPropTypes;

export const SidePanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidePanelContainer);
