import axios from 'axios';
import { getClientId } from '../../../services/navigation/routeMapping';
import { appHasErrorAfterFetch } from '../../../components/App/actions';
import {
  APP_INIT_FETCHING_INITIAL_DATA,
  APP_STOP_FETCHING_INITIAL_DATA
} from '../../../components/App/actionTypes';
import {
  configForRequestFetchForecastData,
  cleanUpResponse
} from './integration';

export const FORECAST_FETCH_DATA_INIT = 'FORECAST_FETCH_DATA_INIT';
export const FORECAST_FETCH_DATA_SUCCESS = 'FORECAST_FETCH_DATA_SUCCESS';
export const FORECAST_FETCH_DATA_ERROR = 'FORECAST_FETCH_DATA_ERROR';

export function errorFetchingData(error) {
  return dispatch => {
    // global error actions
    dispatch(appHasErrorAfterFetch(error));

    return { type: FORECAST_FETCH_DATA_ERROR };
  };
}

export function successFetchForecastData(response) {
  const sanitizedResponse = cleanUpResponse(response);
  return {
    type: FORECAST_FETCH_DATA_SUCCESS,
    payload: { ...sanitizedResponse }
  };
}

export function fetchForecastData() {
  return (dispatch, getState) => {
    const clientID = getClientId(getState().router.location);
    dispatch({ type: APP_INIT_FETCHING_INITIAL_DATA });
    dispatch({ type: FORECAST_FETCH_DATA_INIT });

    axios(configForRequestFetchForecastData(clientID))
      .then(response => {
        dispatch({ type: APP_STOP_FETCHING_INITIAL_DATA });
        dispatch(successFetchForecastData(response));
      })
      .catch(error => {
        dispatch(errorFetchingData(error));
      });
  };
}
