import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
// Local
import styleJS from './index.style';
import './index.css';

const NotificationsComponent = ({ notifications }) => (
  <Notifications notifications={notifications} style={styleJS} />
);

NotificationsComponent.propTypes = { notifications: PropTypes.array };
NotificationsComponent.defaultProps = { notifications: [] };

export default connect(state => ({ notifications: state.notifications }))(
  NotificationsComponent
);
