import React from 'react';
import { string, func, arrayOf, shape, bool } from 'prop-types';

const InfoSection = ({ translate, icon, title, text, buttons }) => (
  <div className="info-card-detailed__info">
    <img alt="Unauthorized" className="info-card-detailed__lock" src={icon} />
    <h2 className="info-card-detailed__title">{translate(title)}</h2>
    <h3 className="info-card-detailed__text">{translate(text)}</h3>
    {buttons.length > 0 && (
      <ul className="info-card-detailed__links">
        {buttons.map(button => (
          <li key={button.text} className="info-card-detailed__link-item">
            <a
              className={`info-card-detailed__link ${
                button.primary
                  ? 'info-card-detailed__link--primary'
                  : 'info-card-detailed__link--default'
              }`}
              href={translate(button.url)}
              rel="noreferrer noopener"
              target="_blank"
            >
              {translate(button.text)}
            </a>
          </li>
        ))}
      </ul>
    )}
  </div>
);

InfoSection.propTypes = {
  translate: func.isRequired,
  icon: string.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  buttons: arrayOf(
    shape({
      text: string.isRequired,
      url: string.isRequired,
      primary: bool
    })
  )
};

InfoSection.defaultProps = {
  buttons: []
};

export { InfoSection };
