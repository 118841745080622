export const handleNewDefinition = (newDefinition, definitions) => {
  if (definitions.length === 0) {
    return [{ ...newDefinition, current: true }];
  }

  return [
    { ...newDefinition, current: true },
    { ...definitions[0], current: false }
  ];
};
