import { tooltipCallbacks } from '../options';

export function barChartOptions({ numberFormat, currency }) {
  return {
    tooltips: {
      callbacks: tooltipCallbacks({ numberFormat, units: currency, isBarChart: true }),
      caretSize: 5,
      caretPadding: 1,
      displayColors: false,
      backgroundColor: 'white',
      xPadding: 23,
      yPadding: 23,
      cornerRadius: 3,
      titleFontColor: '#72726f',
      titleFontSize: 14,
      titleFontStyle: 'normal',
      bodyFontColor: '#2f2f2b',
      bodyFontSize: 16,
      bodyFontStyle: 'bold',
      borderColor: '#D5D5D5',
      footerFontColor: '#72726f',
      footerFontStyle: 'normal',
      footerFontSize: 14,
      borderWidth: 0,
      shadowOffsetX: 0,
      shadowOffsetY: 2,
      shadowBlur: 8,
      shadowColor: 'rgba(0, 0, 0, 0.1)'
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    defaultColor: 'rgba(255, 255, 255, 1)',
    scales: {
      xAxes: [
        {
          barPercentage: 0.9,
          categoryPercentage: 0.5,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {}
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            offsetGridLines: true,
            zeroLineBorderDashOffset: 50,
            drawBorder: false,
            zeroLineColor: '#E2E2E2'
          },
          ticks: {
            padding: 10,
            drawTicks: false,
            fontFamily: "'Roboto Mono'"
          }
        }
      ]
    }
  };
}
