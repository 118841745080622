import { pathnameFromRoute } from '../../../../../helpers/location';

/**
 * Determines if a link should be 'active' (ie, with the yellow bar prepended to it).
 *
 * @param  {Object}  currentLocation [Location provided by the store]
 * @param  {String}  routeName       [Provided by the navigation routes]
 * @return {String}                 [If the route and the route name match, add class 'active']
 */
export const determineActiveLink = (currentLocation, routeName) =>
  currentLocation.pathname === pathnameFromRoute(routeName) ? 'active' : null;

/**
 * If the section (ie, group of links) has a selected route in its children,
 * then the whole section is highlight (through a gray background).
 *
 * It iterates the 'children' of the section,
 * to check if any of them correspond to the current route.
 *
 * Returns undefined if nothing was found.
 *
 * @param  {Object} currentLocation [Location provided by the store]
 * @param  {Object} section         [contains the children of the section]
 * @return {String}
 */
export function determineActiveSection(currentLocation, section) {
  const activeSection = section.subsections.find(
    value => pathnameFromRoute(value.route) === currentLocation.pathname
  );

  return activeSection ? activeSection.name : null;
}
