import { createContext } from 'react';

export const RowContext = createContext({
  name: ''
});

export const SelectorContext = createContext({
  onClickChildItem: () => undefined,
  onClickParentItem: () => undefined,
  onClickBackButton: () => undefined,
  visiblePanel: undefined,
  selectedOption: undefined
});

export const MapScreenContext = createContext({
  data: undefined,
  goTo: undefined,
  integration: undefined,
  isTabAreaLoading: false,
  selectedPanel: undefined,
  selectedTab: undefined,
  toggleNotification: undefined,
  toggleSelectedTab: undefined,
  unmappedCount: undefined
});
