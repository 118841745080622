import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import ModalConfirmation from '../../../components/ModalConfirmation';

import './DeleteToken.css';

export default class DeleteToken extends Component {
  static propTypes = {
    onClickConfirmDeleteTokenButton: PropTypes.func.isRequired,
    tokenID: PropTypes.number.isRequired
  };

  state = {
    isModalOpen: false
  };

  onModalClose = () => this.setState({ isModalOpen: false });

  openModal = e => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  render() {
    const { onClickConfirmDeleteTokenButton, tokenID } = this.props;

    return (
      <Fragment>
        <ModalConfirmation
          buttonCancelText="caseware.deleteToken.buttons.cancel"
          buttonSubmitText="caseware.deleteToken.buttons.confirm"
          contentDescription="caseware.deleteToken.description"
          contentTitle="caseware.deleteToken.title"
          isModalOpen={this.state.isModalOpen}
          tokenID={tokenID}
          onClickSubmitButton={onClickConfirmDeleteTokenButton}
          onModalClose={this.onModalClose}
        />
        <div className="DisplayToken-buttons">
          <button
            className="DisplayToken-delete-button"
            type="button"
            onClick={this.openModal}
          >
            <Translate id="caseware.deleteToken.buttons.openDialog" />
          </button>
        </div>
      </Fragment>
    );
  }
}
