import React from 'react';
import { string, func } from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import { localize } from 'react-localize-redux';

import './index.css';

import questionMarkImage from './questionMark.svg';

export const Tooltip = ({ message, title, type, translate }) => (
  <div className="Tooltip-container">
    <Popup
      basic
      className={`Tooltip-container ${type}`}
      content={translate(message)}
      header={title ? translate(title) : undefined}
      offset={6}
      on="click"
      style={{
        padding: '25px',
        borderRadius: '2px',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.08), 0 1px 10px 0 rgba(0,0,0,0.1)',
        border: '0',
        fontSize: '13px',
        width: '310px',
        height: 'auto',
        wordBreak: 'normal',
        zIndex: 0
      }}
      trigger={
        type === 'info' ? (
          <div className="Tooltip-Info" id="Tooltip-info">
            <img alt={translate(message)} src={questionMarkImage} />
          </div>
        ) : (
          <Icon className={type} name="exclamation triangle" />
        )
      }
    />
  </div>
);

Tooltip.propTypes = {
  message: string.isRequired,
  title: string,
  type: string.isRequired,
  translate: func.isRequired
};

Tooltip.defaultProps = {
  title: undefined
};

export default localize(Tooltip, 'locale');
