import { toggleDefinitionsPanel } from '../../../components/App/actions';
/**
 * Close any definitions panel when route changes.
 */
export const closeDefinitionsPanel = () => store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const { sidePanelId } = store.getState().app;

    if (sidePanelId) {
      store.dispatch(toggleDefinitionsPanel({ sidePanelId: null }));
    }
  }

  return next(action);
};
