import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Screen from '../../../components/Screen';
import ClientDataLocale from '../locale.json';
import { SECTION_CLIENT_DATA } from '../../../services/navigation';
import Main from './Main';

export const ClientDataSettings = ({ ...props }) => {
  const { currentLocation } = props;
  return (
    <Screen
      showRightSideNavigation
      currentLocation={currentLocation}
      locale={ClientDataLocale}
      section={SECTION_CLIENT_DATA}
    >
      <Main section={SECTION_CLIENT_DATA} {...props} />
    </Screen>
  );
};

const mapStateToProps = state => ({
  location: state.router.location,
  translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(ClientDataSettings);
