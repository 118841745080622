export function sanitizeResponseOfGetData(response) {
  const { current_fiscal_year: currentFiscalYear, sections, years } = response[
    'accounting_year/list'
  ];

  return {
    currentFiscalYear,
    years,
    sections
  };
}
