import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import * as actions from '../actions';
import { generateClassNameForOverlay } from './helpers';
import { isWideEnough } from '../helpers';
import { ContainerTypes } from '../types';
import Sticky from '../../Sticky';

export class ContainerComponent extends Component {
  static propTypes = ContainerTypes;

  componentDidMount() {
    const { openMenu, isClean } = this.props;

    if (isWideEnough() && isClean) {
      openMenu();
    }

    window.addEventListener('resize', this.handleResize, false);
  }

  componentWillUnmount() {
    const { isOpen, closeMenu } = this.props;

    if (isOpen && !isWideEnough()) {
      closeMenu();
      document.body.style.overflow = '';
    }

    window.removeEventListener('resize', this.handleResize, false);
  }

  handleResize = () => {
    const { isOpen, openMenu, closeMenu } = this.props;
    const canShowMenu = isWideEnough();

    if (isOpen && !canShowMenu) {
      return closeMenu();
    }

    if (!isOpen && canShowMenu) {
      return openMenu();
    }

    return null;
  };

  setBottomElement = element => {
    this.bottomElement = element;
  };

  containerRender = ({
    isOpen,
    canShowMenu,
    children,
    closeMenu,
    currentLocation,
    translate
  }) => (
    <Fragment>
      <div
        className={`BurgerMenu__container ${
          !isOpen ? 'BurgerMenu__container--hidden' : ''
        } ${!canShowMenu ? 'BurgerMenu__container--fixed' : ''}`}
      >
        {children({
          isOpen,
          closeMenu,
          currentLocation,
          translate
        })}
      </div>
    </Fragment>
  );

  render() {
    const {
      isOpen,
      isClean,
      closeMenu,
      toggleMenu,
      children,
      currentLocation,
      translate
    } = this.props;

    const canShowMenu = isWideEnough();

    return (
      <Fragment>
        <div
          className={generateClassNameForOverlay({
            isOpen,
            isClean,
            isWideEnough: canShowMenu
          })}
          role="presentation"
          onClick={toggleMenu}
        />
        <div className={`BurgerMenu ${!isOpen ? 'BurgerMenu--hidden' : ''}`}>
          <div className="BurgerMenu--wrapper">
            {canShowMenu ? (
              <Sticky bottomElement={this.bottomElement} offset={0}>
                {this.containerRender({
                  isOpen,
                  canShowMenu,
                  children,
                  closeMenu,
                  currentLocation,
                  translate
                })}
              </Sticky>
            ) : (
              this.containerRender({
                isOpen,
                canShowMenu,
                children,
                closeMenu,
                currentLocation,
                translate
              })
            )}
          </div>
          <span ref={this.setBottomElement} className="BurgerMenu__bottom" />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  locale,
  router: { location },
  menu: { isOpen, isClean }
}) => ({
  translate: getTranslate(locale),
  currentLocation: location,
  isOpen,
  isClean
});

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(actions.toggleMenu()),
  closeMenu: () => dispatch(actions.closeMenu()),
  openMenu: () => dispatch(actions.openMenu())
});

export const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerComponent);
