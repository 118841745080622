import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';

import { FeedbackButton } from './FeedbackButton';
import { FeedbackMain } from './FeedbackMain';
import { FeedbackPanelContentPropTypes } from './types';
import { locale } from './locale';

export function FeedbackPanelContent({ language, userName, userEmail }) {
  const [isPanelOpen, showFeedbackPanel] = useState(false);

  return isPanelOpen ? (
    <FeedbackMain
      panelCloseButton={locale.panelCloseButton[language]}
      panelMainDescription={locale.panelMainDescription[language]}
      panelMainTitle={locale.panelMainTitle[language]}
      panelSubmitButton={locale.panelSubmitButton[language]}
      panelTextArea={locale.panelTextArea[language]}
      showFeedbackPanel={showFeedbackPanel}
      userEmail={userEmail}
      userName={userName}
    />
  ) : (
    <FeedbackButton
      showFeedbackPanel={showFeedbackPanel}
      text={locale.buttonOpen[language]}
    />
  );
}

FeedbackPanelContent.propTypes = FeedbackPanelContentPropTypes;

const mapStateToProps = state => ({
  language: getActiveLanguage(state.locale).code,
  userName: state.auth.sub.name,
  userEmail: state.auth.sub.email
});

export const FeedbackPanel = connect(mapStateToProps)(FeedbackPanelContent);
