import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Comment from './Comment';
import { generateEmptyComment } from './integration';

import * as actions from './actions';
import './index.css';

export class ReportComments extends PureComponent {
  static propTypes = {
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        comment: PropTypes.string,
        id: PropTypes.number,
        edit: PropTypes.bool,
        createdAt: PropTypes.string
      })
    ),
    getCommentsData: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    updateComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
  };

  static defaultProps = {
    comments: {
      comment: undefined,
      id: 0,
      edit: false
    }
  };

  state = {
    comments: this.props.comments,
    placeholder: 'comments.placeholder'
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ comments }) {
    this.setState({ comments });
  }

  handleSubmit = comment => {
    const { updateComment, postComment } = this.props;

    if (comment.id) {
      return updateComment(comment);
    }

    return postComment(comment);
  };

  handleDelete = id => {
    this.props.deleteComment(id);
  };

  handleCancel = () => {
    this.setState(prevState => ({
      ...prevState,
      comments: prevState.comments.filter(comment => !!comment.id)
    }));
  };

  addComment = () => {
    const commentNew = generateEmptyComment();
    this.setState(prevState => ({
      comments: [...prevState.comments, commentNew]
    }));

    return commentNew;
  };

  hasEmptyComment = () => this.state.comments.some(comment => !comment.id);

  addHiddenClass = () =>
    this.state.comments.length === 0 ? 'Comments-hidden-from-print' : '';

  render() {
    const { comments, placeholder } = this.state;
    const { translate } = this.props;

    return (
      <div className={`Comments-wrapper ${this.addHiddenClass()}`}>
        <div className="analysis-section-container">
          <h3 className="Comments-title">{translate('comments.title')}</h3>
          <div className="Comments">
            {comments.length === 0 ? (
              <button
                className="Comments-main Comments-add"
                type="button"
                onClick={this.addComment}
              >
                <div className="Comments-content Comments-content-empty">
                  {translate(placeholder)}
                </div>
              </button>
            ) : (
              comments.map(comment => (
                <Comment
                  key={comment.id || 0}
                  {...comment}
                  handleCancel={this.handleCancel}
                  handleDelete={this.handleDelete}
                  handleSubmit={this.handleSubmit}
                  placeholder={this.state.placeholder}
                  translate={this.props.translate}
                />
              ))
            )}
          </div>
          {comments.length !== 0 && !this.hasEmptyComment() ? (
            <button
              className="Comments-add Comments-has-comments Comments-hidden-from-print"
              type="button"
              onClick={this.addComment}
            >
              <i aria-hidden="true" className="Comments-icon">
                +
              </i>{' '}
              {translate('comments.placeholder')}
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  comments: state.comments.comments,
  translate: getTranslate(state.locale)
});

export default connect(
  mapStateToProps,
  actions
)(ReportComments);
