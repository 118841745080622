import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';
import { addTranslation, getTranslate } from 'react-localize-redux';
import { func, element, bool, string, object, shape, oneOf } from 'prop-types';
import isEqual from 'react-fast-compare';

import ReportComments from '../Comments';
import Header from '../Header';
import Footer from '../Footer';

import NavigationBar from './NavigationBar';
import BurgerMenu from '../BurgerMenu';
import ScreenLocale from './locale.json';
import './index.css';
import { PRIMARY_BACKGROUND, SECONDARY_BACKGROUND } from './constants';

export const TranslateContext = createContext('');

/**
 * This container will be responsible to load everything related to a screen:
 * header, menu and footer.
 */
export class Screen extends Component {
  componentDidMount() {
    const { dispatch, locale } = this.props;
    const translation = { ...ScreenLocale, ...locale };
    dispatch(addTranslation(translation));
  }

  shouldComponentUpdate(nextProps) {
    const { translate, children } = this.props;
    const hasNewTranslation = !isEqual(translate, nextProps.translate);
    const hasNewChildren = !isEqual(children, nextProps.children);

    return hasNewTranslation || hasNewChildren;
  }

  shouldShowCommets = () => {
    const { showComments, isFetchingInitialData, error } = this.props;
    return showComments && !isFetchingInitialData && !error;
  };

  render() {
    const {
      hideNavigation,
      showDownloadButton,
      section,
      showRightSideNavigation,
      currentLocation,
      translate,
      client,
      children,
      background
    } = this.props;

    return (
      <div className="full-height-container">
        <TranslateContext.Provider value={{ translate }}>
          <Header />
          {!hideNavigation && (
            <NavigationBar
              client={client}
              currentLocation={currentLocation}
              section={section}
              showDownloadButton={showDownloadButton}
              showRightSideNavigation={showRightSideNavigation}
              translate={translate}
            />
          )}
          <div className={`screen-main-container ${background}`}>
            {!hideNavigation && <BurgerMenu />}
            <div className="full-height-container">{children}</div>
          </div>
          {this.shouldShowCommets() && <ReportComments />}
          <Footer />
        </TranslateContext.Provider>
      </div>
    );
  }
}

Screen.propTypes = {
  /* When consuming Screen Component,
  ** if your screen needs a sepcial background,
  ** you need to create a constant and pass it as a prop.
  ** For an example, you can check Create Company screen.
  */
  background: oneOf([PRIMARY_BACKGROUND, SECONDARY_BACKGROUND]),
  showComments: bool,
  isFetchingInitialData: bool,
  dispatch: func.isRequired,
  children: element.isRequired,
  hideNavigation: bool,
  translate: func.isRequired,
  showDownloadButton: bool,
  section: string,
  showRightSideNavigation: bool,
  currentLocation: object.isRequired,
  locale: object.isRequired,
  client: shape({
    name: string,
    vatin: string
  }),
  error: object
};

Screen.defaultProps = {
  background: PRIMARY_BACKGROUND,
  isFetchingInitialData: false,
  showComments: false,
  hideNavigation: false,
  showDownloadButton: false,
  showRightSideNavigation: false,
  client: {},
  section: '',
  error: null
};

const mapStateToProps = ({
  router: { location },
  client,
  locale,
  app: { isFetchingInitialData, error }
}) => ({
  currentLocation: location,
  client,
  translate: getTranslate(locale),
  isFetchingInitialData,
  error
});

export default connect(mapStateToProps)(Screen);
