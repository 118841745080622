import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Input, Label, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { getTranslate } from 'react-localize-redux';

// Component level dependencies
import * as actions from './actions';
import './Table.css';
import { INPUT_DATA_ROUTE_NAME } from '../../../services/navigation';
import vatinAsLabel from '../../../helpers/translations/vatinLabel';

const headerRows = [
  {
    name: 'dashboard.company',
    type: 'primary',
    sortBy: 'name'
  },
  {
    name: 'dashboard.customerId',
    type: 'secondary',
    sortBy: 'customer_id'
  },
  {
    name: 'dashboard.updatedAt',
    type: 'secondary',
    sortBy: 'updated_at'
  }
];

const TableCell = props => (
  <Table.Cell key={props.index} className="ListClients-table-cell-secondary">
    <div className={`ListClients-table-${props.name}`}>{props.value}</div>
  </Table.Cell>
);

TableCell.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string.isRequired,
  value: PropTypes.string
};

TableCell.defaultProps = {
  index: 0,
  value: ''
};

const DemoLabel = ({ translate }) => (
  <Popup
    basic
    className="ListClients-demo-tooltip"
    content={translate('demo.tooltip.content')}
    header={translate('demo.tooltip.header')}
    on="hover"
    position="right center"
    style={{
      padding: '25px',
      borderRadius: '2px',
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.08), 0 1px 10px 0 rgba(0,0,0,0.1)',
      border: '0',
      fontSize: '13px',
      width: '310px',
      height: 'auto',
      wordBreak: 'normal',
      zIndex: 0,
      maxWidth: '310px'
    }}
    trigger={
      <Label basic className="ListClients-demo-label" size="small">
        DEMO
      </Label>
    }
  />
);

DemoLabel.propTypes = {
  translate: PropTypes.func.isRequired
};

const TableLinkedCell = props => (
  <Table.Cell
    key={props.index}
    className="ListClients-table-cell-primary"
    id={`company-${props.index}`}
  >
    <Link to={`${INPUT_DATA_ROUTE_NAME}?clientId=${props.id}`}>
      <div className="ListClients-table-name" id={`company-${props.subValue}`}>
        {props.value}
        {props.demo ? <DemoLabel translate={props.translate} /> : null}
      </div>
      <div className="ListClients-table-subname">
        {vatinAsLabel(props.subValue, props.translate)}
      </div>
    </Link>
  </Table.Cell>
);

TableLinkedCell.propTypes = {
  demo: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number,
  value: PropTypes.string,
  subValue: PropTypes.string
};

TableLinkedCell.defaultProps = {
  demo: false,
  index: 0,
  value: '',
  subValue: ''
};

const TableBody = props => (
  <Table.Body>
    {props.tableData.map((value, index) => {
      const updatedAtDate = new Date(value.updated_at);

      const updatedAtValue = Number.isNaN(updatedAtDate.getDate())
        ? 'Invalid Date'
        : format(updatedAtDate, 'dd.MM.yy');

      if (value.visible !== false) {
        return (
          <Table.Row key={value.id}>
            <TableLinkedCell
              demo={value.demo}
              id={value.id}
              index={index}
              name="company"
              subValue={value.vatin}
              translate={props.translate}
              value={value.name}
            />
            <TableCell name="customerId" value={value.customer_id} />
            <TableCell name="updatedAt" value={updatedAtValue} />
          </Table.Row>
        );
      }
      return null;
    })}
  </Table.Body>
);

TableBody.propTypes = {
  tableData: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
};

export const sortOrder = (sortKey, sortBy, order) =>
  sortKey === sortBy ? order : null;

export const TableHeader = props => (
  <Table.Header>
    <Table.Row>
      {props.headerRows.map(value => (
        <Table.HeaderCell
          key={value.name}
          className={`ListClients-table-header-${value.type}`}
          sorted={sortOrder(props.sortKey, value.sortBy, props.sortOrder)}
          onClick={() => props.handleSort(value.sortBy)}
        >
          {props.translate(value.name)}
        </Table.HeaderCell>
      ))}
    </Table.Row>
  </Table.Header>
);

TableHeader.propTypes = {
  headerRows: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired
};

export const TableListClients = props => (
  <div className="ListClients-container">
    <Input
      className="ListClients-search"
      icon="search"
      iconPosition="left"
      placeholder={props.translate('dashboard.lookupPlaceholder')}
      onChange={event => props.handleLookup(event.target.value)}
    />
    <Table fixed selectable sortable className="ListClients-table">
      <TableHeader headerRows={headerRows} {...props} />
      <TableBody tableData={props.clients} translate={props.translate} />
    </Table>
  </div>
);

TableListClients.propTypes = {
  handleLookup: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
  clients: state.listClients.clients,
  sortKey: state.listClients.sortKey,
  sortOrder: state.listClients.sortOrder,
  translate: getTranslate(state.locale)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableListClients);
