import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';

import { selectorItemType } from '../../types';
import { SelectorContext } from '../../../context';

import './index.css';

export const ParentItem = ({ text, active }) => (
  <SelectorContext.Consumer>
    {({ onClickParentItem }) => (
      <button
        className="SectionItem"
        type="button"
        onClick={() => onClickParentItem({ text })}
      >
        <div className="Left">
          <div className="Icon-container">
            {active ? <Icon name="check" /> : null}
          </div>
          <p>
            <Translate id={`map.selector.${text}`} />
          </p>
        </div>
        <Icon name="chevron right" />
      </button>
    )}
  </SelectorContext.Consumer>
);

ParentItem.propTypes = selectorItemType;
