import React from 'react';
import { object, func } from 'prop-types';
import Chart from '../LineChart';
import { chartDataProps } from '../propTypes';

function hasTwoCharts(section) {
  return section && section.hasChart && section.chartData.length > 1;
}

export function getClassForSideBySideChart(section) {
  return hasTwoCharts(section) ? 'side-by-side' : 'single';
}

export function ChartWrapper({ section, translate, item }) {
  return (
    <Chart
      key={item.key}
      data={item}
      section={section}
      shouldDisplayChartsSideBySide={hasTwoCharts(section)}
      translate={translate}
      units=""
    />
  );
}

ChartWrapper.propTypes = {
  item: chartDataProps.isRequired,
  section: object.isRequired,
  translate: func.isRequired
};
