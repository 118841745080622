export const AUTH_ID = 'id';

export const KBO_STORAGE_KEY_PREFIX = 'KBO_STORAGE';

export const getLocalStorageKey = key => {
  const normalizedKey = key && key.toUpperCase();
  return `${KBO_STORAGE_KEY_PREFIX}_${normalizedKey}-${ENV}`;
};

export const setInLocalStorage = (key, value) => {
  const kboKey = getLocalStorageKey(key);

  window.localStorage.setItem(kboKey, value);

  return { [kboKey]: value };
};

export const getFromLocalStorage = key => {
  const kboKey = getLocalStorageKey(key);
  return window.localStorage.getItem(kboKey);
};

export const removeFromLocalStorage = key => {
  const kboKey = getLocalStorageKey(key);

  window.localStorage.removeItem(kboKey);
};

export const clearLocalStorage = () => window.localStorage.clear();
