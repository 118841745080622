import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

export const TableDivider = ({ row }) => (
  <Table.Row className="TableRow TableRow-divider">
    <Table.Cell>
      <div className="divider" />
    </Table.Cell>
    {row.cells.map((cell, cellIndex) => (
      <Table.Cell
        key={
          // eslint-disable-next-line
          `cell-data-${cellIndex}`
        }
        className="TableCell-divider"
      >
        <div className="divider" />
      </Table.Cell>
    ))}
  </Table.Row>
);

TableDivider.propTypes = {
  row: PropTypes.object.isRequired
};
