export const sanitizeCasewareResponse = ({ data }) => {
  if (data && data['fsr-caseware']) {
    return {
      status: 'hasToken',
      token: data['fsr-caseware'][0].token,
      tokenID: data['fsr-caseware'][0].id,
      disabledInput: true
    };
  }
  return {
    status: 'noToken',
    disabledInput: true
  };
};

export const sanitizeCasewareNewTokenResponse = ({ data }) => ({
  token: data.token,
  tokenID: data.id,
  status: 'hasToken'
});

export default sanitizeCasewareResponse;
