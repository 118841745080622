import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import IconPerformance from '../IconPerformance';
import { localizeNumberWithCustomPrecision } from './conversions';
import Tooltip from '../Tooltip';
import { shouldCellBeStyled } from './styling';
import './ExceptionCell.css';

/**
 * There are multiple types of exceptions.
 * Check the components/Cell/README.md
 * @param  {[type]} exception [description]
 * @param  {[type]} value     [description]
 * @return {[type]}           [description]
 */
export function determineValueToBeRendered(
  exception,
  value,
  numberFormat,
  precision,
  display
) {
  return exception === 'fullNumber'
    ? // if it is a full number, localize with 4 decimals
      localizeNumberWithCustomPrecision(value, numberFormat, precision, display)
    : exception;
}

const ExceptionCell = ({
  cellIndex,
  error,
  styleProperties,
  exception,
  value,
  numberFormat,
  display,
  icon
}) => (
  <Table.Cell
    key={`cell-exception-${cellIndex}`}
    className={`TableCell ReadOnlyCell ${
      error ? error.type : null
    } ${shouldCellBeStyled(styleProperties)}`}
  >
    <div className="TableCell-innerContainer">
      <div className="errorIcon">{error && <Tooltip {...error} />}</div>

      <div className="number-display">
        {determineValueToBeRendered(exception, value, numberFormat, 4, display)}
        <IconPerformance icon={icon} />
      </div>
    </div>
  </Table.Cell>
);

ExceptionCell.propTypes = {
  exception: PropTypes.string.isRequired,
  value: PropTypes.number,
  display: PropTypes.string.isRequired,
  cellIndex: PropTypes.number.isRequired,
  error: PropTypes.object,
  icon: PropTypes.string,
  numberFormat: PropTypes.string,
  styleProperties: PropTypes.object
};

ExceptionCell.defaultProps = {
  value: undefined,
  icon: null,
  error: null,
  numberFormat: 'da-DK',
  styleProperties: undefined
};

const mapDispatchToProps = state => ({
  numberFormat: state.client.number_format
});

export default connect(mapDispatchToProps)(ExceptionCell);
