import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import IconArea from './IconArea';
import * as actions from './actions';
import { rawToPretty, rawToEditing, editingToPretty } from './conversions';
import {
  shouldCellBeHighlighted,
  shouldCellShowErrorBackground
} from './styling';
import { showCorrectPlaceholder } from './exceptions';
import { moveCell } from './events';
import './index.css';

export class Cell extends React.Component {
  static propTypes = {
    error: PropTypes.object,
    id: PropTypes.string,
    triggerCellSubmit: PropTypes.func,
    viewName: PropTypes.string
  };

  static defaultProps = {
    error: null,
    id: undefined,
    triggerCellSubmit: undefined,
    viewName: 'input_data'
  };

  constructor(props) {
    super(props);
    // Prettify props right away
    const prettyfiedFormat = rawToPretty(props);

    this.state = {
      ...props,
      ...prettyfiedFormat,
      error: props.error,
      isLoading: false,
      isFocused: false
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    return this.setState({
      // updating and prettifying the cell value
      ...rawToPretty(nextProps),
      // Backend validation hooked to the view
      error: nextProps.error
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const hasDisplayValueChanged =
      this.state.displayValue !== nextState.displayValue;
    const isCellHighlighted = this.state.isFocused !== nextState.isFocused;
    const hasChangedLoadingState = this.state.isLoading !== nextState.isLoading;
    const hasNewErrors = this.props.error !== nextProps.error;

    return (
      hasDisplayValueChanged ||
      isCellHighlighted ||
      hasChangedLoadingState ||
      hasNewErrors
    );
  }

  /**
   * Formatting should not happen here.
   * @param  {Object} e [event attached to the target]
   * @return {[type]}   [description]
   */
  onChange(e) {
    this.setState({ displayValue: e.target.value });
  }

  /**
   * Trigger number formating.
   * @param  {Object} e
   * @return {Object}   [returns the new state object]
   */
  onFocus(e) {
    e.persist();

    // 'raw' to 'editing' conversion
    const editingFormat = rawToEditing(this.state);
    const updatedStateOnFocus = {
      // Use the props to update the state.
      ...this.props,
      // Use the return of the rawToEditing function to update the state too.
      ...editingFormat,
      error: null,
      isFocused: true
    };

    // Updating state and selecting the text
    this.setState(updatedStateOnFocus, () => e.target.select());
    return updatedStateOnFocus;
  }

  /**
   * Triggers number formating with a cell update.
   * @param  {Object} e
   * @return {Object}   [returns the new state object]
   */
  onBlur(e) {
    // Init request
    e.persist();
    e.preventDefault();
    const editToPrettyFormat = editingToPretty(this.state);
    const updatedStateOnBlur = {
      ...this.props,
      ...editToPrettyFormat,
      isLoading: true
    };

    // triggering cell submit using the setState function callback
    this.setState(updatedStateOnBlur, async () => {
      // Persist value in case there were changes
      await this.props.triggerCellSubmit(this.state, this.props.viewName);
      // after request is resolved, stop loading
      await this.setState({
        isLoading: false,
        isFocused: false
      });
    });
    return updatedStateOnBlur;
  }

  /**
   * Key event listeners placeholder.
   * @param  {Object} e
   */
  onKeyDown(e) {
    e.persist();
    const shiftTabCombination = e.shiftKey && e.keyCode === 9;
    // Checks for the navigational keys determined in the use cases.
    const shouldCellMovePlace =
      e.key === 'Enter' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp' ||
      e.keyCode === 9 ||
      shiftTabCombination;
    // Pressing 'Esc' support
    const shouldValueBeRevertedToOriginal = e.keyCode === 27;
    // When navigating outside the cell (aka onBlur), trigger a cell update.
    if (shouldCellMovePlace) moveCell(e, this.props.id);
    // When 'Escape' is pressed, only the displayValue has changed in the state.
    // But the value (what is received from props) and rawValue remain the same.
    // Therefore, because rawToEditing takes a rawValue rather then a display value,
    // that function is enough to rollback to the initial value.
    // Return to previous state and select all text in the cell.
    if (shouldValueBeRevertedToOriginal) {
      this.setState(
        prevState => rawToEditing(prevState),
        () => e.target.select()
      );
    }
  }

  render() {
    return (
      <div
        className={`
          Cell
          ${shouldCellShowErrorBackground(this.state.error)}
          ${shouldCellBeHighlighted(this.state.isFocused)}`}
      >
        {this.state.error || this.state.isLoading ? (
          <IconArea error={this.state.error} isLoading={this.state.isLoading} />
        ) : null}

        <input
          disabled={this.state.isLoading}
          // cell position
          id={this.props.id}
          // validates -> localizes -> submits.
          placeholder={showCorrectPlaceholder(this.props.viewName)}
          // keyDown moves to cell below
          type="string"
          value={this.state.displayValue}
          onBlur={e => this.onBlur(e)}
          // 'placeholder' varies depending on the view
          onChange={e => this.onChange(e)}
          // When user changes input value
          onFocus={e => this.onFocus(e)}
          // onClick Toggles 'editing' format.
          onKeyDown={e => this.onKeyDown(e)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  numberFormat: state.client.number_format,
  viewName: state.router.location.pathname,
  cellBeingEdited: state.cell.cellBeingEdited
});

export default connect(
  mapStateToProps,
  actions
)(Cell);
