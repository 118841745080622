import { CASEWARE, ECONOMIC, VIRK } from './integrations';

/**
 * In order to enable/disable certain features on the frontend,
 * the backend sends data via index.html.erb that defines which
 * features to be toggled.
 *
 *
 * This file lists the features and maps naming.
 *
 * If you want to toggle features, just use (for ex):
 * FEATURES = [];
 * below but never commit it.
 */

// FEATURES = [];

export const FEATURE_ECONOMIC = FEATURES.includes(ECONOMIC);
export const FEATURE_FSR_CW = FEATURES.includes(CASEWARE);
export const FEATURE_CAP = FEATURES.includes('cap');
export const FEATURE_VIRK = FEATURES.includes(VIRK);
