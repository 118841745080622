import axios from 'axios';
import { successFetchData } from '../../screens/Analysis/Simulation/actions';
import { successFetchForecastData } from '../../screens/Analysis/Forecast/actions';
import { setGlobalState } from '../../screens/Input/actions';
import { sanitizeResponseOfGetData } from '../../screens/Input/api/responses';
import { getClientId } from '../../services/navigation/routeMapping';
import {
  preparePayload,
  determineAPIurlForCellSubmitValue,
  determineMethodType,
  isInputScreen
} from './integration';
import {
  SIMULATION_REPORT_ROUTE_NAME,
  FORECAST_ROUTE_NAME
} from '../../services/navigation';

export const CELL_EVENT_END_SUBMIT = 'CELL_EVENT_END_SUBMIT';
export const CELL_EVENT_INIT_SUBMIT = 'CELL_EVENT_INIT_SUBMIT';
export const CELL_EVENT_SIMULATION_SUCCESS_PUT =
  'CELL_EVENT_SIMULATION_SUCCESS_PUT';
export const CELL_EVENT_TOGGLE_EDITING = 'CELL_EVENT_TOGGLE_EDITING';
export const CELL_EVENT_UNTOGGLE_EDITING = 'CELL_EVENT_UNTOGGLE_EDITING';
export const REPORT_SIMULATION_SUCCESS_FETCH_DATA =
  'REPORT_SIMULATION_SUCCESS_FETCH_DATA';
export const CELL_EVENT_NOTHING_TO_UPDATE = 'CELL_EVENT_NOTHING_TO_UPDATE';
export const CELL_EVENT_UPDATE_POSITION = 'CELL_EVENT_UPDATE_POSITION';
export const CELL_EVENT_REMOVE_HIGHTLIGHT = 'CELL_EVENT_REMOVE_HIGHTLIGHT';

/**
 * Init
 */
export function startLoading({ ...cellState }) {
  return {
    type: CELL_EVENT_INIT_SUBMIT,
    payload: {
      ...cellState,
      isLoading: true
    }
  };
}

function nothingToUpdate() {
  return { type: CELL_EVENT_NOTHING_TO_UPDATE };
}

/**
 * Re-uses the actions of each view,
 * in order to trigger a specific reducer update.
 */
export const successResponseHandler = response => (dispatch, getState) => {
  const state = getState();
  const currentRoute = state.router.location.pathname;
  const { data } = response;
  // eslint-disable-next-line
  const { simulation_analysis } = data;

  if (isInputScreen(currentRoute)) {
    return dispatch(setGlobalState(sanitizeResponseOfGetData(data)));
  }

  if (currentRoute === SIMULATION_REPORT_ROUTE_NAME) {
    return dispatch(successFetchData(simulation_analysis));
  }

  if (currentRoute === FORECAST_ROUTE_NAME) {
    return dispatch(successFetchForecastData(response));
  }

  return { type: CELL_EVENT_NOTHING_TO_UPDATE };
};

export function stopLoading() {
  return {
    type: CELL_EVENT_END_SUBMIT,
    payload: { isLoading: false }
  };
}

export function triggerCellSubmit({ ...cellState }, viewName) {
  // id is the position of the cell.
  const { rawValue, value, id, reference, rowName } = cellState;

  return (dispatch, getState) => {
    const { inputData, router } = getState();
    const { years, currentFiscalYear } = inputData;

    const clientId = getClientId(router.location);
    dispatch(startLoading(cellState));

    // Don't do any updates if the values are the same.
    if (Object.is(rawValue, value)) return dispatch(nothingToUpdate());

    // If values are different, prepare payload
    const payload = preparePayload({
      rawValue,
      reference,
      viewName,
      currentFiscalYear,
      clientId,
      rowName
    });

    /**
     * For all other screens with cell updates,
     * this is handled in the "legacy" way (using actions/reducers to store data).
     */
    return axios({
      method: determineMethodType({ reference, viewName }),
      url: determineAPIurlForCellSubmitValue({
        clientId,
        viewName,
        id,
        years,
        reference,
        currentFiscalYear
      }),
      data: payload
    }).then(response => {
      dispatch(successResponseHandler(response));

      return dispatch(stopLoading(cellState));
    });
  };
}

export function updateEditedCellPosition(id, sectionIndex, rowIndex) {
  return dispatch =>
    dispatch({
      type: CELL_EVENT_UPDATE_POSITION,
      payload: {
        cellBeingEdited: id,
        sectionBeingEdited: sectionIndex,
        rowBeingEdited: rowIndex
      }
    });
}
