/**
 * This file is for extraction/mapping of data received.
 * This is temporary until response is cleaned.
 */
export function normalizeGetResponse({ comments }) {
  if (comments && comments.length === 0) {
    return [];
  }

  return comments.map(comment => ({
    comment: comment.content,
    id: comment.id,
    printable: comment.printable,
    createdAt: comment.created_at
  }));
}

export const preparePayload = payload => {
  if (!payload || (payload && !payload.comment)) {
    throw new Error('Cannot POST or PUT an empty comment');
  }

  if (typeof payload.printable !== 'boolean') {
    throw new Error('Printable should be a boolean');
  }

  return {
    comment: {
      content: payload.comment,
      printable: payload.printable
    }
  };
};

export const normalizeUpdatedResponse = response => ({
  comment: response.comment.content,
  id: response.comment.id,
  printable: response.comment.printable,
  createdAt: response.comment.created_at
});

export function generateEmptyComment() {
  return {
    comment: '',
    id: null,
    printable: true,
    edit: true,
    createdAt: new Date(Date.now()).toISOString()
  };
}
