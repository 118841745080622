import {
  SENSITIVITY_ANALYSIS_VALUE_DRIVER_CHANGE,
  SENSITIVITY_ANALYSIS_INIT_FETCH_DATA,
  SENSITIVITY_ANALYSIS_SUCCESS_FETCH_DATA
} from './actions';
import {
  VALUATION_FETCH_DATA_INIT,
  VALUATION_FETCH_DATA_SUCCESS
} from './actionsValuation';

const initialState = {
  valuation: {
    clientID: undefined,
    fiscalYear: undefined,
    isLoading: true,
    sections: []
  },
  sensitivityAnalysis: {
    clientID: undefined,
    fiscalYear: undefined,
    valueDriversOptions: ['turnover_growth', 'wacc'],
    table1: {
      valueDriverX: 'turnover_growth',
      valueDriverY: 'wacc',
      rows: [{}],
      isLoading: true
    },
    table2: {
      valueDriverX: 'turnover_growth',
      valueDriverY: 'gross_margin',
      rows: [{}],
      isLoading: true
    }
  }
};

export default function valuationReducer(state = initialState, action = {}) {
  const { payload } = action;
  switch (action.type) {
    case VALUATION_FETCH_DATA_INIT:
      return { ...state };

    case VALUATION_FETCH_DATA_SUCCESS:
      return {
        ...state,
        valuation: {
          ...state.valuation,
          clientID: payload.clientID,
          fiscalYear: payload.fiscalYear,
          isLoading: false,
          sections: payload.sections
        }
      };

    case SENSITIVITY_ANALYSIS_INIT_FETCH_DATA:
      return {
        ...state,
        sensitivityAnalysis: {
          ...state.sensitivityAnalysis,
          [`${payload.table}`]: {
            ...state.sensitivityAnalysis[`${payload.table}`],
            isLoading: true
          }
        }
      };

    case SENSITIVITY_ANALYSIS_VALUE_DRIVER_CHANGE:
      return {
        ...state,
        sensitivityAnalysis: {
          ...state.sensitivityAnalysis,
          [`${payload.table}`]: {
            ...state.sensitivityAnalysis[`${payload.table}`],
            [`valueDriver${payload.axis}`]: payload.value
          }
        }
      };

    case SENSITIVITY_ANALYSIS_SUCCESS_FETCH_DATA:
      return {
        ...state,
        clientID: payload.clientID,
        fiscalYear: payload.fiscalYear,
        sensitivityAnalysis: {
          ...state.sensitivityAnalysis,
          ...payload.tableData,
          valueDriversOptions: payload.valueDriversOptions
        }
      };
    default:
      return { ...state };
  }
}
