import { get, post } from 'axios';
import { success, error } from 'react-notification-system-redux';
import { push } from 'connected-react-router';
import { getTranslate } from 'react-localize-redux';

import {
  urlForFetchingCVRdata,
  sanitizeResponseFromCVRDataFetch,
  sanitizeErrorResponseForDisplay
} from './integrations';
import {
  ENDPOINT_DASHBOARD_CLIENT,
  INTEGRATION_LIST,
  CREATE_CLIENT_ROUTE_NAME
} from '../../../services/navigation';

import { routeWithClientId } from '../../../helpers/location';
import { trackClientCreated } from '../../../external/segment';
import { NAME, FISCAL_YEAR_START } from './constants';
import * as types from './actionTypes';

/**
 * Hooks the data fetched from CVRapi.dk to the app state 'createClient'.
 */
function successFetchDataFromCVR(sanitizedResponse) {
  return dispatch => {
    dispatch({ type: types.CLEAR_FORM_DATA });
    return dispatch({
      type: types.SUCCESS_FETCH_DATAFROMCVR,
      payload: sanitizedResponse
    });
  };
}

const errorFetchDataFromCVR = errorResponse => (dispatch, getState) => {
  const state = getState();
  const translate = getTranslate(state.locale);
  dispatch(
    error({
      title: translate('searchCvr.noResult.title'),
      message: translate('searchCvr.noResult.message'),
      position: 'tc'
    })
  );
  return dispatch({
    type: types.ERROR_FETCH_DATAFROMCVR,
    payload: errorResponse
  });
};

/**
 * Flags the init of the request to CVRapi.dk.
 */
function initFetchDataFromCVR() {
  return { type: types.INIT_FETCH_DATAFROMCVR };
}

export function fetchDataFromCVR(CVRnumber) {
  return dispatch => {
    dispatch(initFetchDataFromCVR());

    return get(urlForFetchingCVRdata({ cvr: CVRnumber, country: 'dk' }))
      .then(response =>
        dispatch(
          successFetchDataFromCVR(
            sanitizeResponseFromCVRDataFetch({ response })
          )
        )
      )
      .catch(errorResponse => dispatch(errorFetchDataFromCVR(errorResponse)));
  };
}

function errorAfterFormSubmit(errorResponse) {
  return {
    type: types.ERROR_POST_FORM,
    payload: errorResponse
  };
}

function inputValidationError(errors) {
  window.scrollTo(0, 0);
  return {
    type: types.ERROR_INPUT_VALIDATION,
    payload: errors
  };
}

function onSubmitButtonClick() {
  return { type: types.CLICK_SUBMIT_BUTTON };
}

function submitFormPostInit(body) {
  return {
    type: types.INIT_POST_FORM,
    payload: {
      isLoading: true,
      body
    }
  };
}

function successCreateCompany(response) {
  const statusCode = response.status;

  return (dispatch, getState) => {
    const state = getState();
    const translate = getTranslate(state.locale);
    const location = state.router.location.pathname;
    const clientName = state.client.name;
    const clientId = state.client.id;

    if (statusCode === 201 && location === CREATE_CLIENT_ROUTE_NAME) {
      dispatch(
        success({
          title: translate('new.success.title'),
          message: translate('new.success.message', { clientName }),
          position: 'tc'
        })
      );
      trackClientCreated({ clientId });
      dispatch(
        push(
          `${routeWithClientId(
            INTEGRATION_LIST,
            clientId
          )}&from=${CREATE_CLIENT_ROUTE_NAME}`
        )
      );
      return dispatch({ type: types.SUCCESS_POST_CREATE_CLIENT });
    }

    return dispatch({ type: types.ERROR_POST_CREATE_CLIENT });
  };
}

/**
 * Beginning of the actual POST call to submit create client form.
 */
function postCallToSubmit() {
  return (dispatch, getState) => {
    const state = getState();
    const endpoint = ENDPOINT_DASHBOARD_CLIENT;
    const url = `${API_URL}${endpoint}`;
    const { client } = state.createClient;
    // Appending a year to the fiscal year date.
    const fiscalDateWithAddedYear = `${client[FISCAL_YEAR_START]}/2016`;
    const payload = {
      ...client,
      // Updating the value in the payload.
      [FISCAL_YEAR_START]: fiscalDateWithAddedYear
    };

    dispatch(submitFormPostInit(payload));

    return post(`${url}`, { ...payload })
      .then(response => {
        // error handling on 200 status code.
        if (response.data.errors) {
          dispatch(errorAfterFormSubmit(response.data.errors));
        }
        return dispatch(successCreateCompany(response));
      })
      .catch(errorResponse => {
        if (errorResponse.status === 422) {
          const errors = sanitizeErrorResponseForDisplay(errorResponse);
          dispatch(inputValidationError(errors));
        } else {
          throw new Error(errorResponse);
        }
      });
  };
}

function validateOnSubmitCreateClient() {
  return (dispatch, getState) => {
    const state = getState();

    const { client } = state.createClient;
    const isNameEmpty = client[NAME].length === 0;

    if (isNameEmpty) {
      const errors = {
        [NAME]: { isInvalid: isNameEmpty }
      };
      return dispatch(inputValidationError(errors));
    }
    return dispatch(postCallToSubmit());
  };
}

export function onSubmitCreateClient() {
  return dispatch => {
    dispatch(onSubmitButtonClick());
    return dispatch(validateOnSubmitCreateClient());
  };
}

/**
 * Updates the store on keydown but before it validates.
 * It also updates the state of the form, allowing for validation.
 * @param {String} inputName
 * @param {String} inputValue
 * @return {Object}
 */
export function updateClientDataOnInput(inputName, inputValue) {
  return {
    type: types.INPUT_UPDATE_CLIENTDATA,
    payload: {
      client: { [inputName]: inputValue },
      form: {
        [inputName]: {
          isTouched: true,
          isInvalid: false
        }
      }
    }
  };
}
