import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { LoadingSpinner } from '../../../../../../components';
import { SelectorContext } from '../../../context';
import { ParentPanel, ChildPanel } from '../Panels';
import { selectorType } from '../../types';

import { SelectorHeader } from './Header';
import './index.css';

export default class Selector extends Component {
  static propTypes = selectorType;

  state = {
    visiblePanel: 'parent', // can be 'parent' or 'child'
    isLoading: false
  };

  // Enables the child items panel
  onClickParentItem = ({ text }) =>
    this.setState({ visiblePanel: 'child', parentName: text });

  // Returns from child to parent panel
  onClickBackButton = () => this.setState({ visiblePanel: 'parent' });

  onClickCloseModal = () => {
    const { closeModal } = this.props;
    this.setState({ visiblePanel: 'parent' });

    closeModal();
  };

  renderPanel = () => {
    const { data, rowData, selectedOption } = this.props;
    const { visiblePanel, parentName } = this.state;

    if (visiblePanel === 'parent') {
      return (
        <ParentPanel
          closeModal={this.onClickCloseModal}
          data={data}
          rowData={rowData}
          selectedOption={selectedOption}
          toggleLoading={this.toggleLoading}
          visiblePanel={visiblePanel}
        />
      );
    }
    return (
      <ChildPanel
        data={data}
        parentName={parentName}
        selectedOption={selectedOption}
        visiblePanel={visiblePanel}
        onClickBackButton={this.onClickBackButton}
      />
    );
  };

  toggleLoading = () =>
    this.setState(({ isLoading }) => ({
      isLoading: !isLoading
    }));

  render() {
    const { closeModal, data, isModalOpen, rowData } = this.props;

    const { visiblePanel, isLoading } = this.state;
    return (
      <Modal
        centered={false}
        className="Selector-container"
        closeIcon={false}
        open={isModalOpen}
        size="mini"
        onClose={this.onClickCloseModal}
      >
        <SelectorContext.Provider
          value={{
            visiblePanel,
            rowData,
            toggleLoading: this.toggleLoading,
            onClickParentItem: this.onClickParentItem,
            onClickBackButton: this.onClickBackButton,
            data,
            closeModal: this.onClickCloseModal
          }}
        >
          {isLoading && <LoadingSpinner centered />}
          <div className={`Selector-wrapper ${isLoading ? 'hidden' : ''}`}>
            <SelectorHeader closeModal={closeModal} />
            <section className="Selector-content">{this.renderPanel()}</section>
          </div>
        </SelectorContext.Provider>
      </Modal>
    );
  }
}
