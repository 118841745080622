import React from 'react';
import CellDispatcher from '../../Cell/Dispatcher';
import { TableRowCellsPropTypes } from '../types';

export const TableRowCells = ({ row, rowIndex }) =>
  row.cells.map((cell, cellIndex) => (
    <CellDispatcher
      key={
        // eslint-disable-next-line
        `cell-dispatcher-${cellIndex}`
      }
      cell={cell}
      cellIndex={cellIndex}
      row={row}
      rowIndex={rowIndex}
    />
  ));

TableRowCells.propTypes = TableRowCellsPropTypes;
