import React, { Fragment } from 'react';
import { string, arrayOf, func } from 'prop-types';

const AccessSection = ({
  accessFooter,
  accessTitle,
  accessText,
  accessFeatureTitle,
  modules,
  translate
}) => (
  <Fragment>
    <hr className="info-card-detailed__separator" />
    <div className="info-card-detailed__access">
      <h3 className="info-card-detailed__access-title">
        {translate(accessTitle)}
      </h3>
      {accessText && (
        <p className="info-card-detailed__access-text">
          {translate(accessText)}
        </p>
      )}
      {accessFeatureTitle && (
        <h4 className="info-card-detailed__access-feature-title">
          {translate(accessFeatureTitle)}
        </h4>
      )}
      <ul className="info-card-detailed__feature-list">
        {modules.map(feature => (
          <li key={feature} className="info-card-detailed__feature">
            {translate(feature)}
          </li>
        ))}
      </ul>
      {accessFooter && (
        <p className="info-card-detailed__access-text">
          {translate(accessFooter)}
        </p>
      )}
    </div>
  </Fragment>
);

AccessSection.propTypes = {
  accessFooter: string,
  accessTitle: string.isRequired,
  accessText: string.isRequired,
  accessFeatureTitle: string.isRequired,
  modules: arrayOf(string),
  translate: func.isRequired
};

AccessSection.defaultProps = {
  modules: [],
  accessFooter: ''
};

export { AccessSection };
