import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../../services/navigation';
import { Navigation } from './Navigation';
import { MenuTypes } from '../types';

const Menu = ({ translate, currentLocation }) => (
  <Fragment>
    <Navigation
      currentLocation={currentLocation}
      routes={ROUTES}
      translate={translate}
    />

    <div className="BurgerMenu-section last-menu-items">
      <Link to={ROUTES.LIST_CLIENT_ROUTE_NAME}>
        <div className="BurgerMenu-section-title last-menu-items">
          {translate('menu.back')}
        </div>
      </Link>
    </div>
  </Fragment>
);

Menu.propTypes = MenuTypes;

export { Menu };
