export function determineIsBudgetScreen(view) {
  if (view && view.includes('/') === false) {
    throw new Error(
      'The `view` argument in determineIsBudgetScreen needs to be preceded by a front slash'
    );
  }

  switch (view) {
    case '/input_data':
      return false;

    case '/input_budget':
      return true;

    default:
      return false;
  }
}

export const convertViewNameToKind = ({ viewName }) =>
  viewName === '/input_budget' ? 'budget' : 'actual';
