import React from 'react';
import { Button } from '@karnov/kui-deprecated';

export const ActionButtons = ({
  error,
  translate,
  onClickSubmitButton,
  onClickMapButton
}) => {
  const buttons = [
    <Button
      key="submit"
      kind={error ? '' : 'primary'}
      text={translate('ButtonsArea.submit')}
      type="button"
      onClick={onClickSubmitButton}
    />,
    <Button
      key="map"
      kind={error ? 'primary' : ''}
      text={translate('ButtonsArea.map')}
      type="button"
      onClick={onClickMapButton}
    />
  ];

  return error ? buttons.reverse() : buttons;
};
