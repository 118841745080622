import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnalysisModuleDispatcher from '..';
import TitleArea from '../../../components/TitleArea';
import { InfoBox, Table } from '../../../components';
import Sticky from '../../../components/Sticky';

class ForecastMain extends Component {
  setBottomElement = element => {
    this.bottomElement = element;
  };

  render() {
    const { fiscalYear, sections, translate } = this.props;

    return (
      <div className="Forecast-main-container main-container">
        <InfoBox
          text="forecastAnalysis.requirements"
          title="forecastAnalysis.requirementsTitle"
        />
        <TitleArea
          showCurrentFiscalYear
          selectedYear={fiscalYear}
          title="forecastAnalysis.title"
          translate={translate}
        />
        {sections.map(section => (
          <div key={`section-${section.name}`} className="Section-container section-separator">
            {section.sticky ? (
              <Sticky bottomElement={this.bottomElement} offset={69}>
                <Table name={section.name} section={section} />
              </Sticky>
            ) : (
              <Table name={section.name} section={section} />
            )}
          </div>
        ))}
        <span ref={this.setBottomElement} />
      </div>
    );
  }
}

ForecastMain.propTypes = {
  sections: PropTypes.array,
  fiscalYear: PropTypes.number,
  translate: PropTypes.func.isRequired
};

ForecastMain.defaultProps = {
  sections: [],
  fiscalYear: undefined
};

export default AnalysisModuleDispatcher(ForecastMain);
