/* eslint-disable react/no-typos */
import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';

import { LoadingSpinner } from '..';
import triangle from './triangle.svg';
import close from './close.svg';
import './index.css';

export class ModalConfirmation extends Component {
  state = {
    isLoading: false
  };

  onClickSubmitButton = async () => {
    const { showLoading, onClickSubmitButton } = this.props;
    if (showLoading) {
      this.toggleLoading();
    }

    await onClickSubmitButton();
  };

  toggleLoading = () =>
    this.setState(({ isLoading }) => ({ isLoading: !isLoading }));

  render() {
    const {
      contentTitle,
      contentDescription,
      accountName,
      onModalClose,
      isModalOpen,
      onClickCancelButton,
      buttonCancelText,
      buttonSubmitText,
      isWarning,
      showLoading
    } = this.props;

    const { isLoading } = this.state;

    return (
      <Modal
        className="ModalConfirmation"
        open={isModalOpen}
        size="tiny"
        onClose={onModalClose}
      >
        {isLoading && showLoading && <LoadingSpinner centered />}
        <div
          className={`ModalConfirmation__content ${
            isLoading && showLoading ? 'ModalConfirmation__content--hidden' : ''
          }`}
        >
          <button
            aria-label="Close"
            className="ModalConfirmation__closeButton"
            type="button"
            onClick={onModalClose}
          >
            <img alt="close" aria-hidden="true" src={close} />
          </button>
          <div className="ModalConfirmation-container">
            {isWarning === true ? null : (
              <div className="ModalConfirmation-container__icon">
                <img alt="alert" src={triangle} />
              </div>
            )}

            <div className="ModalConfirmation-content">
              <h1 className="ModalConfirmation-content__title">
                <Translate id={contentTitle} />
              </h1>

              <p className="ModalConfirmation-content__description">
                <Translate data={{ accountName }} id={contentDescription} />
              </p>

              <div className="ModalConfirmation-buttons">
                <button
                  className={`ModalConfirmation-buttons__confirm ${
                    isWarning === true
                      ? 'ModalConfirmation-buttons__confirm--isWarning'
                      : null
                  }`}
                  type="button"
                  onClick={this.onClickSubmitButton}
                >
                  <Translate id={buttonSubmitText} />
                </button>
                <button
                  className="ModalConfirmation-buttons__cancel"
                  type="button"
                  onClick={onClickCancelButton || onModalClose}
                >
                  <Translate id={buttonCancelText} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
ModalConfirmation.defaultProps = {
  isWarning: false,
  onClickCancelButton: undefined,
  accountName: '',
  showLoading: false
};

ModalConfirmation.propTypes = {
  buttonCancelText: string.isRequired,
  buttonSubmitText: string.isRequired,
  contentDescription: string.isRequired,
  contentTitle: string.isRequired,
  isModalOpen: bool.isRequired,
  isWarning: bool,
  onClickCancelButton: func,
  onClickSubmitButton: func.isRequired,
  onModalClose: func.isRequired,
  accountName: string,
  showLoading: bool
};

export default ModalConfirmation;
