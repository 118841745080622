import React, { PureComponent } from 'react';
import { object, func } from 'prop-types';

import { Section } from './Section';
import { Subsection } from './Subsection';
import navigationOptions from '../../navigationOptions';

export class Navigation extends PureComponent {
  renderSubsection = subsection => {
    const { translate, currentLocation } = this.props;
    return (
      <Subsection
        key={`${subsection.name}`}
        {...{ translate, subsection, currentLocation }}
      />
    );
  };

  renderSection = (section, index) => {
    const { currentLocation, translate } = this.props;
    return (
      <Section
        key={`${section.name}`}
        {...{
          currentLocation,
          section,
          index,
          translate,
          renderSubsection: this.renderSubsection
        }}
      />
    );
  };

  render() {
    const { currentLocation, routes } = this.props;
    return (
      <div className="Navigation-container">
        {navigationOptions(currentLocation, routes).map(this.renderSection)}
      </div>
    );
  }
}

Navigation.propTypes = {
  // Essential for the 'push-to-the-side' effect of the menu.
  routes: object.isRequired,
  // Allows determining which links are active
  currentLocation: object.isRequired,
  translate: func.isRequired
};
