// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
// global
import IconPerformance from '../../../components/IconPerformance';
import { rawToPretty } from '../../../components/Cell/conversions';
// local
import './KPIs.css';

export const KPIs = ({ data, translate, reportName, numberFormat }) => (
  <div className="KPIs-container">
    <div className="KPIs-title">
      {translate(`kpis_${reportName}_title`)}
      <div className="TableHeader-subtitle">
        {translate(`kpis_${reportName}_subtitle`)}
      </div>
    </div>
    <div className="KPIs-main">
      {data.entries.map(kpi => (
        <div key={`${kpi.name}`} className="KPI">
          <div className="KPI-icon-area">
            <IconPerformance icon={kpi.icon} />
          </div>
          <div className="KPI-text-area">
            <div className="KPI-text-area-name">{translate(kpi.name)}</div>
            <div className="KPI-text-area-values">
              <div className="KPI-text-area-values-value">
                <span>
                  {
                    rawToPretty({
                      value: kpi.value,
                      style: kpi.display,
                      numberFormat
                    }).displayValue
                  }
                </span>
                {data.subtitle}
              </div>
              <div className="KPI-text-area-values-difference">
                Δ
                <span>
                  {
                    rawToPretty({
                      value: kpi.difference,
                      style: kpi.display,
                      numberFormat
                    }).displayValue
                  }
                </span>
                {data.subtitle}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

KPIs.propTypes = {
  data: PropTypes.object.isRequired,
  reportName: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  numberFormat: PropTypes.string
};

KPIs.defaultProps = {
  numberFormat: undefined
};

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  numberFormat: state.client.number_format
});

export default connect(mapStateToProps)(KPIs);
