/**
 * The plugins styling configuration uses JSS.
 */
const styleJS = {
  Containers: {
    DefaultStyle: {
      position: 'fixed', // top and right position should NOT be overwritten.
      width: 600, // this should be a number so the margin left is adjusted correctly.
      height: 'auto',
      minHeight: '76px',
      marginRight: 'auto',
      marginLeft: 'auto'
    }
  },
  NotificationItem: {
    DefaultStyle: {
      height: 'auto',
      minHeight: '76px',
      margin: '16px 5px 2px 1px',
      padding: '26px',
      borderTop: 'none',
      borderRadius: '2px',
      backgroundColor: '#ffffff',
      color: '#2F2F2B',
      fontFamily: 'Roboto',
      fontSize: '16px',
      boxShadow: '0 1px 1px 0 rgba(0,0,0,0.08), 0 1px 10px 0 rgba(0,0,0,0.12)'
    },
    success: { fontWeight: '500' },
    info: { fontWeight: '500' }
  },
  Title: {
    DefaultStyle: {
      display: 'none',
      color: '#2F2F2B',
      fontFamily: 'Roboto',
      fontSize: '16px'
    },
    error: { display: 'block' }
  },
  MessageWrapper: {
    DefaultStyle: {
      display: 'inline-block',
      maxWidth: '80%',
      margin: '0',
      padding: '0'
    },
    error: { paddingLeft: '26px' }
  },
  ActionWrapper: {
    DefaultStyle: {
      position: 'absolute',
      top: '26px',
      right: '0',
      paddingRight: '26px'
    },
    error: {
      position: 'inherit',
      top: '0',
      padding: '26px 26px 0'
    }
  },
  Action: {
    DefaultStyle: {
      margin: '0',
      padding: '0',
      backgroundColor: 'unset',
      color: '#767676',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 'normal',
      textDecoration: 'underline'
    },
    error: {
      padding: '13px 84px',
      border: '1px solid #d5d5d5',
      borderRadius: '0',
      color: '#2F2F2B',
      fontWeight: 'bold',
      textDecoration: 'none'
    }
  },
  Dismiss: { DefaultStyle: { display: 'none' } }
};

export default styleJS;
