import axios from 'axios';
import store from '../../../store';
import errorCodeResponseHandler from './errorCodeDispatcher';
import { handleAuthToken } from '../../auth/actions';
import decodeToken, { setWalkmeVariables } from '../../auth/helpers';

import { updateClientData } from '../../../components/App/actions';

/**
 * Setting up the interceptor for all success responses.
 * Only triggered once, on app init.
 */
export default function responseHandler(history) {
  return axios.interceptors.response.use(
    response => {
      // Refresh the token
      const tokenInResponseHeader = response.headers.authorization;
      const usedToken = handleAuthToken(tokenInResponseHeader);

      setWalkmeVariables({
        token: usedToken,
        isWalkmeEnabled: window.isWalkmeEnabled,
        decoder: decodeToken
      });

      if (response.data && response.data.client) {
        store.dispatch(updateClientData(response.data.client));
      }

      // Return response normally.
      return response;
    },
    ({ response }) => {
      if (response.data && response.data.client) {
        store.dispatch(updateClientData(response.data.client));
      }

      store.dispatch(errorCodeResponseHandler(response, history));
      // Do something with response error
      return Promise.reject(response);
    }
  );
}
