import queryString from 'query-string';

const determineLanguageForTheCallBackURL = language => {
  if (language === 'en') return 'en-US';
  return 'da-DK';
};

const economicURLParams = clientId => `/import/e-conomic/select${clientId}`;
const virkURLParams = clientId => `/import/virk/select${clientId}`;

const economicAppToken = () => {
  if (ENV !== 'production')
    return 'EXAzu3AUnuoeBMcbnEZDQIlU1VeCf0FnTNYBCiEyxn01';
  return 'suH4wABvsHuYLtmv0rRbTtW23YEx9vqj76MmEY7lf5g1';
};

export const economicURL = ({ language }) => {
  const baseURL = 'https://secure.e-conomic.com/secure/api1/requestaccess.aspx';
  const locale = determineLanguageForTheCallBackURL(language);
  const clientId = `?clientId=${
    queryString.parse(window.location.search).clientId
  }`;
  const callbackURL = `${window.encodeURIComponent(
    `${window.location.origin}${window.location.pathname}${economicURLParams(
      clientId
    )}`
  )}`;
  const token = economicAppToken();

  return `${baseURL}?appPublicToken=${token}&redirectURL=${callbackURL}&locale=${locale}`;
};

export const virkURL = () => {
  const clientId = `?clientId=${
    queryString.parse(window.location.search).clientId
  }`;
  const callbackURL = `integrations${virkURLParams(clientId)}`;
  return `${callbackURL}`;
};

export default economicURL;
