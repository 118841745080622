import React, { Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { INTEGRATION_CASEWARE_GENERATE_TOKEN } from '../../services/navigation';
import { clearLocalStorage } from '../../services/localStorage';
import { routeWithClientId } from '../../helpers/location';
import { FEATURE_FSR_CW } from '../../helpers/constants/features';

import { userOptionsType } from './types';

export const logout = () => {
  clearLocalStorage();

  window.location = `${API_URL}/sign_out`;
};
export const renderUserName = ({ sub }) =>
  sub && sub.name.split(' ')[0].toUpperCase();

const UserOptions = ({ translate, auth, loading }) => (
  <div className="Header-name-container">
    <Dropdown
      className="Header-name"
      disabled={loading}
      loading={loading}
      text={renderUserName(auth)}
    >
      <Dropdown.Menu className="Header-dropdown">
        <Dropdown.Item>
          <span className="username">{`${auth.sub.name}`}</span>
          <span className="companyname">{`${auth.sub.company_name}`}</span>
        </Dropdown.Item>

        {FEATURE_FSR_CW && (
          <Fragment>
            <Dropdown.Divider />
            <Dropdown.Item
              as={() => (
                <Link
                  className="item"
                  to={routeWithClientId(INTEGRATION_CASEWARE_GENERATE_TOKEN)}
                >
                  {translate('header.token')}
                </Link>
              )}
            />
          </Fragment>
        )}

        <Dropdown.Divider />
        {!auth.sub.trial_access_token && (
          <Dropdown.Item
            as="a"
            href={auth.sub.profile_url}
            rel="noopener"
            target="_blank"
            text={translate('header.myProfile')}
          />
        )}

        <Dropdown.Item
          as="a"
          href="https://www.karnovgroup.dk/kbo/funktioner"
          rel="noopener"
          target="_blank"
          text={translate('header.about')}
        />
        <Dropdown.Item
          as="a"
          href={KG_PRO_URL}
          rel="noopener"
          target="_blank"
          text={translate('header.pro')}
        />
        <Dropdown.Divider />
        <Dropdown.Item text={translate('header.logout')} onClick={logout} />
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

UserOptions.propTypes = userOptionsType;

export default UserOptions;
