import React from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { Divider } from 'semantic-ui-react';
import isEqual from 'react-fast-compare';
import TitleArea from '../../../components/TitleArea';
import Settings from '../../../components/Settings';
import ButtonSubmit from '../../../components/ButtonSubmit';
import * as actions from '../Edit/actions';
import './Main.css';

export class Main extends React.Component {
  static propTypes = {
    clientData: object.isRequired,
    submitDataChanges: func.isRequired,
    fetchClientData: func.isRequired,
    translate: func.isRequired
  };

  constructor(props) {
    const { clientData } = props;
    super(props);

    this.state = { ...clientData };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { clientData } = prevState;
    // Only monitoring changes on the clientData, the data that populates the form.
    const havePropsChanged =
      isEqual(nextProps.clientData, clientData) === false;
    if (havePropsChanged) return { ...nextProps.clientData };

    return {};
  }

  componentDidMount() {
    const { fetchClientData } = this.props;

    fetchClientData();
  }

  handleSubmit = e => {
    const { submitDataChanges } = this.props;
    e.preventDefault();

    submitDataChanges(this.state);
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="ClientDataCompany-Main">
        <TitleArea
          title="settings.sectionTitle"
          translate={translate}
        />
        <form onSubmit={this.handleSubmit}>
          <Settings />
          <Divider section className="ClientDataCompany-Main-divider-spacer" />
          <div className="ClientDataCompany-Main-buttons align-end">
            <ButtonSubmit text="save" />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientData: state.clientData,
  client: state.client
});

export default connect(
  mapStateToProps,
  actions
)(Main);
