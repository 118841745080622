import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { Title } from './Title';
import { ButtonImportData } from './ButtonImportData';
import CurrentFiscalYear from './CurrentFiscalYear';
import { SelectYear } from './SelectYear';

import { TitleAreaPropTypes } from './types';
import './index.css';

export const TitleArea = ({
  allYears,
  dispatch,
  importButtonText,
  location,
  selectedYear,
  showCurrentFiscalYear,
  showDropDown,
  showImportButton,
  title,
  translate,
  viewName,
  showDescription,
  descriptionText
}) => (
  <div className="TitleArea-container">
    <Title
      descriptionText={descriptionText}
      showDescription={showDescription}
      title={title}
      translate={translate}
    />
    <div className="TitleArea-buttons">
      {showImportButton && <ButtonImportData text={importButtonText} />}
      {showDropDown && (
        <SelectYear
          allYears={allYears}
          dispatch={dispatch}
          location={location}
          selectedYear={selectedYear}
          viewName={viewName}
        />
      )}
      {showCurrentFiscalYear && (
        <CurrentFiscalYear selectedYear={selectedYear} />
      )}
    </div>
  </div>
);

TitleArea.propTypes = TitleAreaPropTypes;
TitleArea.defaultProps = {
  showImportButton: false,
  showCurrentFiscalYear: false,
  selectedYear: 2019,
  showDropDown: false,
  allYears: [],
  dispatch: null,
  location: undefined,
  viewName: undefined,
  importButtonText: '',
  showDescription: false,
  descriptionText: ''
};

const mapStateToProps = state => ({
  translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(TitleArea);
