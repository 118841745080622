import React from 'react';
import { determineActiveSection } from '../helpers';
import { SectionTypes } from '../../../types';

const Section = ({
  currentLocation,
  section,
  index,
  translate,
  renderSubsection
}) => (
  <div
    className={`BurgerMenu-section ${
      determineActiveSection(currentLocation, section) ? 'active' : ''
    }`}
    id={`section-${index}-${section.name}`}
  >
    <div className="BurgerMenu-section-title">
      {translate(`menu.${section.name}`)}
    </div>
    <div className="BurgerMenu-section-content">
      {section.subsections.map(renderSubsection)}
    </div>
  </div>
);

Section.propTypes = SectionTypes;

export { Section };
