export const convertCategoryName = ({ source, accountPlan }) => {
  const capitalizedIntegration =
    source.charAt(0).toUpperCase() + source.slice(1);
  const sanitizedAccountPlanName = accountPlan.split('_')[0].toUpperCase();

  return `${capitalizedIntegration} ${sanitizedAccountPlanName}`;
};
export const eventUserId = ({ pspId, perId }) => {
  // the country is a placeholder, should be replaced with BA data
  const country = 'DK';
  const userId = `${country}-${pspId}-${perId}`;
  return userId;
};
