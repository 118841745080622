/**
 * Keyboard events.
 */

/**
 * [moveToCellBelow description]
 * @param  {[type]} id [description]
 * @return {[type]}    [description]
 */
export function moveToCellBelow(currentPosition) {
  const coordXX = currentPosition.split(',')[0];
  const coordYY = currentPosition.split(',')[1];
  const positionBelow = `${coordXX},${(parseInt(coordYY, 10) + 1).toString()}`;

  return positionBelow;
}

/**
 * [moveToCellAbove description]
 * @param  {[type]} currentPosition [description]
 * @return {[type]}                 [description]
 */
export function moveToCellAbove(currentPosition) {
  const coordXX = currentPosition.split(',')[0];
  const coordYY = currentPosition.split(',')[1];
  const positionAbove = `${coordXX},${(parseInt(coordYY, 10) - 1).toString()}`;

  return positionAbove;
}

/**
 * [moveToCellOnTheRight description]
 * @param  {[type]} currentPosition [description]
 * @return {[type]}                 [description]
 */
export function moveToCellOnTheRight(currentPosition) {
  const coordXX = currentPosition.split(',')[0];
  const coordYY = currentPosition.split(',')[1];
  const positionOnTheRight = `${(
    parseInt(coordXX, 10) + 1
  ).toString()},${coordYY}`;

  return positionOnTheRight;
}

/**
 *
 * @param {String} currentPosition
 * @returns positionOnTheLeft
 */
export function moveToCellOnTheLeft(currentPosition) {
  const coordXX = currentPosition.split(',')[0];
  const coordYY = currentPosition.split(',')[1];
  const positionOnTheLeft = `${(
    parseInt(coordXX, 10) - 1
  ).toString()},${coordYY}`;

  return positionOnTheLeft;
}

/**
 * Mostly useful for the 'Tab' key.
 * When the end of the row is reached,
 * the focused cell should be the first of the next
 * @return {[type]} [description]
 */
export function moveToRowBelowWhenEndWasReached(currentPosition) {
  const coordXX = '0';
  const coordYY = currentPosition.split(',')[1];

  const cellOnFirstPositionOfRowBelow = `${coordXX},${(
    parseInt(coordYY, 10) + 1
  ).toString()}`;
  return cellOnFirstPositionOfRowBelow;
}

export function handleTabKeyPress(e, currentPosition) {
  e.preventDefault();
  const newPosition = moveToCellOnTheRight(currentPosition);
  const cellToTheRight = document.getElementById(newPosition);

  // If focus reached end of row
  if (!cellToTheRight) {
    // try to jump to first cell of row underneath
    try {
      const cellOnFirstPositionOfRowBelow = document.getElementById(
        moveToRowBelowWhenEndWasReached(currentPosition)
      );

      return cellOnFirstPositionOfRowBelow.focus();
    } catch (error) {
      // otherwise, remove focus.
      return e.target.blur();
    }
  }

  return document.getElementById(newPosition).focus();
}

export function handleShiftTabKeyPress(e, currentPosition) {
  const newPosition = moveToCellOnTheLeft(currentPosition);
  return document.getElementById(newPosition).focus();
}

/**
 *
 * @param  {[type]} e  [description]
 * @param  {[type]} currentPosition [description]
 * @return {[type]}    [description]
 */
export function moveCell(e, currentPosition) {
  e.preventDefault();
  let newPosition;
  // Shift+tab key combination.
  if (e.shiftKey && e.keyCode === 9)
    return handleShiftTabKeyPress(e, currentPosition);
  // Move the cell focus one row down
  if (e.key === 'Enter' || e.key === 'ArrowDown')
    newPosition = moveToCellBelow(currentPosition);
  if (e.key === 'ArrowUp') newPosition = moveToCellAbove(currentPosition);
  // On Tab key press, move to the right or jump row
  if (e.keyCode === 9) return handleTabKeyPress(e, currentPosition);

  try {
    // change focus
    document.getElementById(newPosition).focus();
  } catch (error) {
    // if next element doesn't exist, just lose cell focus
    e.target.blur();
  }

  return newPosition;
}
