import * as types from './actionTypes';

export const SORT_KEY = 'name';
export const SORT_ORDER_ASC = 'ascending';
export const SORT_ORDER_DESC = 'descending';

const initialState = {
  isLoading: false,
  clients: [
    {
      id: 1,
      name: '',
      vatin: '',
      customer_id: '',
      address: '',
      city: '',
      zip_code: '',
      country: '',
      fiscal_year_start: '',
      number_format: '',
      currency: '',
      updated_at: '',
      visible: true
    }
  ],
  sortKey: SORT_KEY,
  sortOrder: SORT_ORDER_ASC,
  selected: {}
};

export default function listClientsState(state = initialState, action = {}) {
  const { payload } = action;
  switch (action.type) {
    case types.INIT_FETCH_CLIENTS_LIST:
      return {
        ...state,
        payload
      };

    case types.SUCCESS_FETCH_CLIENTS_LIST:
      return {
        ...state,
        isLoading: false,
        clients: payload.clients
      };

    case types.ERROR_FETCH_CLIENTS_LIST:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case types.LOOKUP_DASHBOARD_LIST:
    case types.SORTING_DASHBOARD_LIST:
      return {
        ...state,
        ...payload
      };
    default:
      return { ...state };
  }
}
