import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { string, shape } from 'prop-types';

import * as routes from '../index';

// Non-lazy loaded modules
import CreateClient from '../../../screens/Client/Create';
import Forecast from '../../../screens/Analysis/Forecast';
import ReportBudget from '../../../screens/Analysis/Budget';
import ReportManagement from '../../../screens/Analysis/Management';
import ReportSimulation from '../../../screens/Analysis/Simulation';
import ValuationReport from '../../../screens/Analysis/Valuation';
import Root from '../../../components/Root';
import { LoadingSpinner } from '../../../components';
import { TrialExpired } from '../../../screens/Static/TrialExpired';
import {
  Overview,
  Success,
  SelectYears,
  Map,
  List,
  SelectPlan,
  GenerateToken
} from '../../../screens/Integrations';

import ClientDataSettings from '../../../screens/Client/Settings';
import ClientEdit from '../../../screens/Client/Edit';
import ListClients from '../../../screens/Client/List'
import DashboardInit from '../../../screens/Client/Init';
import Input from '../../../screens/Input';
import Unauthenticated from '../../../screens/Static/Unauthenticated';
import Unauthorized from '../../../screens/Static/Unauthorized'
import PageNotFound from '../../../screens/Static/404';
import ImportDataError from '../../../screens/Static/424'
import ServerErrorCard from '../../../screens/Static/Error'


/**
 * Redirection to 404 page.
 */
const GoTo404 = ({ location }) => (
  <Redirect
    to={{
      // Should be 403 when implemented
      // and use Unauthorized card
      pathname: routes.CONTAINER_STATIC_404,
      state: { from: location.pathname }
    }}
  />
);

GoTo404.propTypes = {
  location: shape({
    pathname: string.isRequired
  }).isRequired
};

/**
 * The actual routes.
 */
export const Routes = () => (
  <Suspense fallback={<LoadingSpinner />}>
    <Switch>
      <Route exact component={Root} path={routes.INIT_ROUTE_NAME} />
      <Route
        exact
        component={TrialExpired}
        path={routes.TRIAL_EXPIRED_ROUTE_NAME}
      />
      <Route component={Root} path={routes.TRIAL_ROUTE_NAME} />
      <Route
        component={DashboardInit}
        path={routes.DASHBOARD_INIT_ROUTE_NAME}
      />
      <Route component={CreateClient} path={routes.CREATE_CLIENT_ROUTE_NAME} />
      <Route component={ListClients} path={routes.LIST_CLIENT_ROUTE_NAME} />
      <Route component={Input} path={routes.INPUT_DATA_ROUTE_NAME} />
      <Route component={Input} path={routes.INPUT_BUDGET_ROUTE_NAME} />
      <Route
        component={ReportManagement}
        path={routes.MANAGEMENT_REPORT_ROUTE_NAME}
      />
      <Route component={ReportBudget} path={routes.BUDGET_REPORT_ROUTE_NAME} />
      <Route
        component={ReportSimulation}
        path={routes.SIMULATION_REPORT_ROUTE_NAME}
      />
      <Route
        component={ClientEdit}
        path={routes.CLIENT_DATA_COMPANY_ROUTE_NAME}
      />
      <Route
        component={ClientDataSettings}
        path={routes.CLIENT_DATA_SETTINGS_ROUTE_NAME}
      />
      <Route component={Forecast} path={routes.FORECAST_ROUTE_NAME} />
      <Route
        component={ValuationReport}
        path={routes.VALUATION_REPORT_ROUTE_NAME}
      />
      <Route exact component={List} path={routes.INTEGRATION_LIST} />
      <Route component={Success} path={routes.INTEGRATION_IMPORT_SUCCESS} />
      <Route component={SelectYears} path={routes.INTEGRATION_SELECT_YEARS} />
      <Route
        component={GenerateToken}
        path={routes.INTEGRATION_CASEWARE_GENERATE_TOKEN}
      />

      <Route component={Map} path={routes.CAP_INTEGRATION_MAP} />
      <Route component={Overview} path={routes.CAP_INTEGRATION_OVERVIEW} />
      <Route
        component={SelectPlan}
        path={routes.CAP_INTEGRATION_SELECT_ACCOUNT_PLAN}
      />
      <Route
        // 'id' contains information about the error
        //  and is only used for frontend runtime boot errors.
        component={ServerErrorCard}
        path={`${routes.ERROR_SERVER_DOWN}/:id?`}
      />
      <Route
        component={Unauthorized}
        path={routes.UNAUTHORIZED_CARD_ROUTE_NAME}
      />
      <Route
        component={Unauthenticated}
        path={routes.UNAUTHENTICATED_CARD_ROUTE_NAME}
      />
      <Route component={PageNotFound} path={routes.CONTAINER_STATIC_404} />
      <Route
        path={routes.STATIC_ERROR_424}
        render={props => <ImportDataError {...props} />}
      />
      <Route component={routeProps => <GoTo404 {...routeProps} />} />
    </Switch>
  </Suspense>
);

export default Routes;
