import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { CLIENT_DATA_SETTINGS_ROUTE_NAME } from '../../services/navigation';
import * as actionsCreateClient from '../../screens/Client/Create/actions';
import * as actionsClientDataCompany from '../../screens/Client/Edit/actions';
import './Dropdown.css';
import { COUNTRY } from './constants';

export class DropdownInput extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    handleDropDownValueChange: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired
  };

  state = {
    options: this.props.options,
    value: this.props.client[this.props.name]
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update localstate when props have changed
    if (this.state.value !== nextProps.client.currency) {
      this.setState({ value: nextProps.client.currency });
    }
  }

  handleChange = (event, { value }) => {
    // Updating the whole object with the input values.
    const updatedData = Object.assign(this.props.client, {
      [this.props.name]: value
    });
    if (this.props.location === CLIENT_DATA_SETTINGS_ROUTE_NAME) {
      this.setState({ value });
      this.props.handleDropDownValueChange(value);
    } else {
      this.props.updateClientDataOnInput(updatedData);
    }
  };

  render() {
    const { translate, name, subLabelText, labelText } = this.props;
    const { options, value } = this.state;
    return (
      <div>
        <div className="DropdownInput-container">
          <div className="DropdownInput-field">
            <label htmlFor="Dropdown-wrapper">{translate(labelText)}</label>
            <Dropdown
              search
              selection
              id="Dropdown-wrapper"
              options={name !== [COUNTRY] ? options : [{}]}
              value={value}
              onChange={this.handleChange}
            />
            <div className="DropdownInput-subLabel">
              {translate(subLabelText)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DropdownInput.propTypes = {
  // Text above the dropdown.
  labelText: PropTypes.string.isRequired,
  // Options presented in the dropdown.
  options: PropTypes.array.isRequired,
  // Text underneath the dropdown
  subLabelText: PropTypes.string,
  // Name of the dropdown (eg: 'country' or 'numberFormat')
  name: PropTypes.string.isRequired,
  // The actual object with the clients' info.
  client: PropTypes.object.isRequired,
  // action dispatched to update props upon dropdown selection.
  updateClientDataOnInput: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

DropdownInput.defaultProps = {
  subLabelText: ''
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionsCreateClient,
      ...actionsClientDataCompany
    },
    dispatch
  );

const mapStateToProps = state => ({
  location: state.router.location.pathname,
  client: state.createClient.client,
  clientData: state.clientData,
  translate: getTranslate(state.locale)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownInput);
