import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Button } from 'semantic-ui-react';

import print from './integration';
import './index.css';

export class PrintButton extends Component {
  static propTypes = {
    translate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    // eslint-disable-next-line
    currentFiscalYear: PropTypes.number
  };

  static defaultProps = {
    isLoading: false,
    currentFiscalYear: null
  };

  state = { isLoading: false };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ isLoading: nextProps.isLoading });
  }

  onClickPrintButton = async () => {
    this.setState({ isLoading: true });
    await print();
    this.setState({ isLoading: false });
  };

  render() {
    const { translate } = this.props;
    const { isLoading } = this.state;

    return (
      <Button
        basic
        className="NavigationArea-print-button"
        disabled={isLoading}
        loading={isLoading}
        onClick={this.onClickPrintButton}
      >
        {translate('download')}
      </Button>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  isLoading: state.app.isLoading
});

export default connect(mapStateToProps)(PrintButton);
