import { rawToPretty } from '../Cell/conversions';

export function formatLabel({ item, numberFormat, currency }) {
  const { yLabel } = item;
  return `${
    rawToPretty({ value: yLabel, numberFormat, style: 'decimal' }).displayValue
  } ${currency}`;
}

export function footerOfTooltip({ item, data, isBarChart }) {
  if (isBarChart) {
    return item[0].xLabel;
  }
  // for line charts the item at position 0 exists and is the only value.
  const indexOfLabel = item[0].datasetIndex;
  const { label } = data.datasets[indexOfLabel];

  return label;
}
