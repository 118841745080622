import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Button, Icon } from 'semantic-ui-react';
import { RowContext } from '../../context';

import Selector from './Selector';
import { dropdownData } from './static';
import { DropdownTypes } from '../types';

import './index.css';

class Dropdown extends Component {
  static propTypes = DropdownTypes;

  state = {
    isModalOpen: false
  };

  /**
   * Should only update when new data has been received,
   * and there is a new account name.
   * Or when the visibility of the modal has been toggled.
   */
  shouldComponentUpdate = (nextProps, nextState) => {
    const {
      account: { childName }
    } = this.props;
    const { isModalOpen } = this.state;
    const hasAccountNameChanged = childName !== nextProps.account.childName;
    const hasModalOpenStatusChange = nextState.isModalOpen !== isModalOpen;

    return hasAccountNameChanged || hasModalOpenStatusChange;
  };

  openModal = () => this.setState({ isModalOpen: true });

  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { account } = this.props;
    const { childName } = account;
    const { isModalOpen } = this.state;

    return (
      <div className="Row-Dropdown-container">
        <Button className="Row-Dropdown-button" onClick={this.openModal}>
          <p className="text">
            <Translate id={`map.selector.${childName}`} />
          </p>
          <Icon name="chevron down" />
        </Button>
        <RowContext.Consumer>
          {({ rowData }) => (
            <Selector
              closeModal={this.closeModal}
              data={dropdownData}
              isModalOpen={isModalOpen}
              rowData={rowData}
              selectedOption={account}
            />
          )}
        </RowContext.Consumer>
      </div>
    );
  }
}

export { Dropdown };
