import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

import { tracker } from '../services/middlewares';
import { reducers } from './reducers';
import { getMiddlewares } from './middlewares';

export const history = createBrowserHistory();

// instantiating middlewares with the same history
const middlewares = getMiddlewares(history);
// Tracking with segment
if (ENV === 'integration' || ENV === 'production') {
  middlewares.push(tracker());
}

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  connectRouter(history)(combineReducers(reducers)),
  composeEnhancers(applyMiddleware(...middlewares))
);
