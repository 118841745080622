import { string, func, array, shape, bool, object, number } from 'prop-types';

// global shared
const selectedOption = shape({
  parentName: string,
  childName: string
}).isRequired;

const data = array.isRequired;

const rowData = shape({
  changed: bool.isRequired,
  children: data.isRequired,
  kbo_account: shape({
    child_name: string,
    parent_name: string
  }).isRequired,
  value: shape({
    display: string.isRequired,
    error: object,
    exception: string,
    grid_position: string,
    icon: string,
    id: number,
    row_name: string,
    style_properties: object,
    type: string.isRequired,
    value: number
  })
});

// Dropdown index
export const DropdownTypes = {
  account: shape({
    childName: string,
    parentName: string
  }).isRequired,
  rowData
};

// Panels
const sharedPanelType = {
  data,
  selectedOption
};

export const childPanelType = {
  ...sharedPanelType,
  onClickBackButton: func.isRequired,
  parentName: string.isRequired
};

export const parentPanelType = {
  ...sharedPanelType
};

// Selector
const sharedSelectorType = {
  closeModal: func.isRequired
};

export const selectorType = {
  closeModal: func.isRequired,
  isModalOpen: bool.isRequired,
  selectedOption,
  data
};

export const selectorHeaderType = sharedSelectorType;

// SelectorItem
export const selectorItemType = {
  text: string.isRequired,
  active: bool.isRequired
};

// Right side of the Row
export const RightComponentTypes = {
  value: object.isRequired,
  name: string.isRequired,
  account: object.isRequired
};
