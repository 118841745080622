import { paramFromLocation } from '../../helpers/location';

export const ROUTE_MAPPINGS = {
  mgt_report: 'management_report',
  budget_report: 'budget_report',
  simulation: 'simulation_analysis',
  input_data: 'accounting',
  input_budget: 'budget'
};

function keyFromState(state) {
  return state.router.location.pathname.match(/^\/([a-z_]+)/)[1];
}

export function mappedRoute(state) {
  return ROUTE_MAPPINGS[keyFromState(state)];
}

export function getClientId(location) {
  return paramFromLocation('clientId', location);
}
