import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import CommonWhiteButton from '../../../../../components/ButtonAction';
import { INTEGRATION_CASEWARE_GENERATE_TOKEN } from '../../../../../services/navigation';

import casewareLogo from '../../../../../shared/images/casewareLogo.png';

const Caseware = ({ translate, dispatch }) => (
  <div className="Integration-container">
    <div className="Integration-container-logo">
      <img alt="caseware" className="caseware-logo" src={casewareLogo} />
    </div>
    <div className="Integration-container-text-area">
      <div className="Integration-container-text-title">
        {translate('list.caseware.title')}
      </div>
      <div className="Integration-container-text">
        {translate('list.caseware.loginHelpText')}
      </div>
      <CommonWhiteButton
        isEnabled
        handleClick={() => dispatch(push(INTEGRATION_CASEWARE_GENERATE_TOKEN))}
        id="caseware"
        text="list.caseware.loginButton"
      />
    </div>
  </div>
);

Caseware.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default Caseware;
