import { initialize, setActiveLanguage } from 'react-localize-redux';
import numeral from 'numeral';
import { registerLocale } from 'react-datepicker';
import { da, enUS } from 'date-fns/locale';
import { languageFormatFromBackendToFrontend } from '../../helpers/conversions/language';
import { handleAuthToken } from '../auth/actions';
import store, { history } from '../../store';
import requestHandler from '../interceptors/request';
import responseHandler from '../interceptors/response';

/**
 * DANISH:
 * Configuration for the locale of the 'numeral' library.
 */
export const numberLocaleConfigDK = () => ({
  delimiters: {
    thousands: '.',
    decimal: ','
  },

  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },

  currency: { symbol: 'DKK' }
});

/**
 * ENGLISH:
 * Configuration for the locale of the 'numeral' library.
 */
export const numberLocaleConfigEN = () => ({
  delimiters: {
    thousands: ',',
    decimal: '.'
  },

  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },

  currency: { symbol: 'EUR' }
});

const initDateFormatLocale = () => {
  registerLocale('da', da);
  registerLocale('en', enUS);
};

const storeToken = () => {
  const token = document.getElementById('auth-token').innerText;
  handleAuthToken(token);
};

const initHttpInterceptors = () => {
  requestHandler();
  responseHandler(history);
};

export const initNumberFormatLocale = () => {
  numeral.register('locale', 'da-DK', numberLocaleConfigDK());
  numeral.register('locale', 'en-GB', numberLocaleConfigEN());
};

export const initUserLanguage = () => {
  const languages = ['da', 'en'];
  const { getState, dispatch } = store;
  const { auth } = getState();

  const currentLanguage = languageFormatFromBackendToFrontend(
    auth.sub.language
  );

  dispatch(initialize(languages), { defaultLanguage: currentLanguage });
  dispatch(setActiveLanguage(currentLanguage));

  return currentLanguage;
};

export const initializers = () => {
  storeToken();
  initHttpInterceptors();
  initUserLanguage();
  initNumberFormatLocale();
  initDateFormatLocale();
};
