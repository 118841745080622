/**
 * The years array that comes from the backend needs
 * to be converted to a format that can be used for the <Checkbox />
 * components (which can be toggled/untoggled).
 */
export const convertYearsFormat = years =>
  years.map(({ value, contains_data: containsData, disabled, selected }) => ({
    value,
    checked: !disabled && selected,
    hasData: containsData,
    disabled
  }));

export const convertYearsFormatForDropdown = years =>
  years.map(year => {
    // eslint-disable-next-line
    const { contains_data, selected, ...parsedYear } = year;
    return parsedYear;
  });

export const filterSelectedYears = years => years.filter(year => year.selected);

/**
 * Returns an array of numbers, with all the years that the user has selected.
 * @param {array} years
 */
export const convertYearsToBackendFormat = years =>
  years
    // We just want the checked years of the checkboxes
    .filter(year => year.checked === true)
    // and just the years value
    .reduce((acc, year) => {
      acc.push(year.value);
      return acc;
    }, []);

export const prepareImportDataForBackend = ({ years, accountPlan } = {}) => {
  const data = { data_import_session: {} };

  if (accountPlan) {
    data.data_import_session.account_plan = accountPlan;
  }

  if (years) {
    data.data_import_session.years = convertYearsToBackendFormat(years);
  }

  return data;
};

export const sanitizedIntegrationName = integration =>
  integration === 'e-conomic' ? 'economic' : integration;

export const sanitizeDataImportSession = ({
  data_import_session: dataImportSession
}) => {
  const { account_plans: accountPlans, ...parsedData } = dataImportSession;
  parsedData.accountPlans = [...accountPlans];

  return parsedData;
};

export const translateTotals = ({
  totals,
  translate,
  namespace,
  integration
}) =>
  totals.map(total => ({
    ...total,
    name:
      total.name === 'empty_overview'
        ? translate(`${integration}.${namespace}.${total.name}.title`)
        : translate(`${namespace}.${total.name}.title`)
  }));

export const isBalanceZero = (totals = []) => {
  const balanceTotal = totals.find(total => total && total.name === 'balance');

  if (!balanceTotal) {
    return false;
  }

  return (
    (balanceTotal.cells.find(cell => cell.row_name === 'balance') || {})
      .value === 0
  );
};
