import PropTypes from 'prop-types';

/** Shared props * */
const translate = PropTypes.func.isRequired;
const auth = PropTypes.shape({
  sub: PropTypes.shape({
    name: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired
  })
}).isRequired;

/** Specific props * */
// notifications, translate, currentLocale, auth
export const headerType = {
  translate,
  auth,
  notifications: PropTypes.array,
  dispatch: PropTypes.func.isRequired
};

// loading, currentLocale, translate, setLocale
export const LanguageSelectorType = {
  translate,
  setLocale: PropTypes.func.isRequired,
  currentLocale: PropTypes.string.isRequired
};

// translate, auth, loading
export const userOptionsType = {
  auth,
  translate
};
