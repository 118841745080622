import React from 'react';
import { shape, object, func, string, bool } from 'prop-types';

import LeftSide from './LeftSide';
import RightSide from './RightSide';
import { BurgerButton } from '../../BurgerMenu/BurgerButton';
import './index.css';

const NavigationBar = ({
  translate,
  currentLocation,
  client,
  showDownloadButton,
  showRightSideNavigation
}) => (
  <div className="NavigationArea">
    <div className="NavigationArea__burger-button-container">
      <BurgerButton />
    </div>
    <div className="NavigationArea-container">
      <LeftSide
        clientName={client.name}
        clientVatin={client.vatin}
        currentLocation={currentLocation}
        translate={translate}
      />

      {showRightSideNavigation && (
        <RightSide
          currentLocation={currentLocation}
          showDownloadButton={showDownloadButton}
          translate={translate}
        />
      )}
    </div>
  </div>
);

NavigationBar.propTypes = {
  translate: func.isRequired,
  currentLocation: object.isRequired,
  client: shape({
    vatin: string,
    name: string
  }),
  showRightSideNavigation: bool,
  showDownloadButton: bool
};

NavigationBar.defaultProps = {
  client: {
    vatin: undefined,
    name: undefined
  },
  showDownloadButton: false,
  showRightSideNavigation: false
};

export default NavigationBar;
