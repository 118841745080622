import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { LanguageSelectorType } from './types';

const LanguageSelector = ({ loading, currentLocale, translate, setLocale }) => (
  <div className="Header-language-container">
    <Dropdown
      className="Header-language"
      disabled={loading}
      loading={loading}
      text={translate(`locale.${currentLocale}`)}
    >
      <Dropdown.Menu className="Header-dropdown">
        <Dropdown.Item
          flag="dk"
          text={translate('locale.da')}
          onClick={() => setLocale('da')}
        />
        <Dropdown.Item
          flag="gb"
          text={translate('locale.en')}
          onClick={() => setLocale('en')}
        />
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

LanguageSelector.propTypes = LanguageSelectorType;

export default LanguageSelector;
