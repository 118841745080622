import React, { Fragment } from 'react';
import { LoadingSpinner, Tooltip } from '../../../../components';

import { Group } from '../Group';
import { MapScreenContext } from '../context';
import { MainTypes } from '../types';

import { Tabs, UNMAPPED } from './Tabs';
import { Title } from './Title';
import './index.css';

export const MainInner = ({
  integration,
  data,
  selectedTab,
  isTabAreaLoading,
  translate
}) => (
  <section className="Main">
    <Tabs />
    {isTabAreaLoading ? (
      <LoadingSpinner />
    ) : (
      <Fragment>
        {selectedTab === UNMAPPED && (
          <Title hasNoDiscrepancy={data.length === 0} translate={translate} />
        )}

        {data.length > 0 && (
          <section className="MapArea">
            <div className="MapAreaHeader">
              <div className="MapAreaHeader__label1">
                <p>{translate('map.mapArea.columnTitle1', { integration })}</p>

                <Tooltip
                  message={`${integration}.tooltips.map.mapArea.columnTitle1.message`}
                  type="info"
                />
              </div>
              <div className="MapAreaHeader__label2">
                <p>{translate('map.mapArea.columnTitle2')}</p>
                <Tooltip
                  message={`${integration}.tooltips.map.mapArea.columnTitle2.message`}
                  type="info"
                />
              </div>
            </div>
            <Group isRoot data={data} />
          </section>
        )}
      </Fragment>
    )}
  </section>
);

MainInner.propTypes = MainTypes;

export const Main = () => (
  <MapScreenContext.Consumer>{MainInner}</MapScreenContext.Consumer>
);
