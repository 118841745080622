import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import IconPerformance from '../IconPerformance';
import { rawToPretty } from './conversions';
import { shouldCellBeStyled } from './styling';
import './ReadOnlyCell.css';
import Tooltip from '../Tooltip';

export class ReadOnlyCell extends PureComponent {
  static propTypes = {
    error: PropTypes.object,
    display: PropTypes.string,
    icon: PropTypes.string,
    styleProperties: PropTypes.object,
    numberFormat: PropTypes.string
  };

  static defaultProps = {
    display: 'decimal',
    error: null,
    icon: null,
    styleProperties: null,
    numberFormat: 'da-DK'
  };

  render() {
    const { styleProperties, error, display, icon, numberFormat } = this.props;

    return (
      <Table.Cell
        className={`TableCell ReadOnlyCell ${
          error ? error.type : null
        } ${shouldCellBeStyled(styleProperties)}`}
      >
        <div className="TableCell-innerContainer">
          <div className="errorIcon">
            {error ? (
              <Tooltip
                message={error.message}
                title={error.title}
                type={error.type}
              />
            ) : null}
          </div>
          <div className="number-display">
            {
              rawToPretty({
                ...this.props,
                style: display,
                numberFormat
              }).displayValue
            }
            <IconPerformance icon={icon} />
          </div>
        </div>
      </Table.Cell>
    );
  }
}

const mapStateToProps = state => ({ numberFormat: state.client.number_format });
export default connect(mapStateToProps)(ReadOnlyCell);
