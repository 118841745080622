import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Input } from 'semantic-ui-react';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import ReactDatePicker from 'react-datepicker';
import format from 'date-fns/format';

import TitleArea from '../../../components/TitleArea';
import ButtonSubmit from '../../../components/ButtonSubmit';
import LoadingSpinner from '../../../components/LoadingSpinner';

import TextInput from './TextInput';
import Confirmation from '../../../components/Confirmation';
import * as actions from './actions';

import './Main.css';

export class Main extends Component {
  state = {
    companyName: this.props.clientData.companyName,
    cvrNumber: this.props.clientData.cvrNumber,
    customerId: this.props.clientData.customerId,
    address: this.props.clientData.address,
    city: this.props.clientData.city,
    postNumber: this.props.clientData.postNumber,
    fiscalYearStart: this.props.clientData.fiscalYearStart,
    isModalOpen: this.props.clientData.isModalOpen,
    isLoading: true
  };

  async componentDidMount() {
    const { fetchClientData } = this.props;

    await fetchClientData();
    this.setState({ isLoading: false });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clientData } = this.props;
    // Only monitoring changes on the clientData, the data that populates the form.
    const havePropsChanged = !isEqual(nextProps.clientData, clientData);
    if (havePropsChanged) {
      return this.setState({ ...nextProps.clientData });
    }

    return null;
  }

  onChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  onChangeDatePicker = date => {
    this.setState({ fiscalYearStart: format(date, 'dd/MM') });
  };

  handleSubmit = e => {
    const { submitDataChanges } = this.props;
    e.preventDefault();
    submitDataChanges(this.state);
  };

  deleteCompany = () => {
    const { deleteCompany } = this.props;
    this.setState({ isModalOpen: false });

    deleteCompany();
  };

  render() {
    const { translate } = this.props;
    const {
      companyName,
      cvrNumber,
      customerId,
      address,
      postNumber,
      city,
      fiscalYearStart,
      isModalOpen,
      isLoading
    } = this.state;

    if (isLoading) {
      return (
        <div className="ClientDataCompany-Main main-container">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <div className="ClientDataCompany-Main main-container">
        <TitleArea title="edit.sectionTitle" translate={translate} />
        <form onSubmit={this.handleSubmit}>
          <TextInput
            label="stamdataClient.companyName"
            name="companyName"
            type="text"
            value={companyName}
            onChange={this.onChange}
          />
          <TextInput
            label="stamdataClient.cvrNumber"
            name="cvrNumber"
            type="number"
            value={cvrNumber}
            onChange={this.onChange}
          />
          <TextInput
            showTooltip
            label="stamdataClient.customerId"
            name="customerId"
            type="text"
            value={customerId}
            onChange={this.onChange}
          />
          <TextInput
            label="stamdataClient.address"
            name="address"
            type="text"
            value={address}
            onChange={this.onChange}
          />
          <TextInput
            label="stamdataClient.postNumber"
            name="postNumber"
            type="text"
            value={postNumber}
            onChange={this.onChange}
          />
          <TextInput
            label="stamdataClient.city"
            name="city"
            type="text"
            value={city}
            onChange={this.onChange}
          />
          <div className="DatePicker-container">
            <div className="DatePicker-field">
              <label htmlFor="DatePicker-wrapper">
                {translate('stamdataClient.fiscalYearStart')}
              </label>
              <Input>
                <ReactDatePicker
                  dateFormatCalendar="MMMM"
                  id="DatePicker-wrapper"
                  locale="da"
                  value={fiscalYearStart}
                  onChange={this.onChangeDatePicker}
                />
              </Input>
              <div className="DatePicker-subLabel">
                {translate('stamdataClient.fiscalYearStartSubLabel')}
              </div>
            </div>
          </div>
          <Divider section className="ClientDataCompany-Main-divider-spacer" />
          <div className="ClientDataCompany-Main-SubmitArea">
            <Confirmation
              cancelButtonText={translate('no')}
              handleCancelButton={() => this.setState({ isModalOpen: false })}
              handleOkButton={this.deleteCompany}
              isModalOpen={isModalOpen}
              message={translate('delete.message')}
              okButtonText={translate('yes')}
              title={translate('delete.title')}
              trigger={
                <button
                  className="ClientDataCompany-Main-delete"
                  id="trigger-delete-company-modal"
                  type="button"
                  onClick={() => this.setState({ isModalOpen: true })}
                >
                  {translate('delete.title')}
                </button>
              }
            />
            <div className="ClientDataCompany-Main-buttons">
              <ButtonSubmit text="save" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Main.propTypes = {
  // Data displayed in the form
  clientData: PropTypes.object.isRequired,
  // GET call
  fetchClientData: PropTypes.func.isRequired,
  // Persist changes
  submitDataChanges: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired
};

const mapStateToProps = ({ client, clientData, locale }) => ({
  client,
  clientData,
  translate: getTranslate(locale)
});

export default connect(
  mapStateToProps,
  actions
)(Main);
