import React from 'react';
import { bool, func } from 'prop-types';
import circleExclamation from '../../../../../shared/images/circleExclamation.svg';

import './index.css';

export const Title = ({ hasNoDiscrepancy, translate }) => {
  if (hasNoDiscrepancy) {
    return (
      <div className="Title Title--no-discrepancy">
        <div className="Title__text">
          <h2>{translate('map.main.noDiscrepancy.title')}</h2>
          <p>{translate('map.main.noDiscrepancy.subtitle')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="Title">
      <div className="Title__icon">
        <img alt="Warning: missing allocation" src={circleExclamation} />
      </div>
      <div className="Title__text">
        <h2>{translate('map.main.title')}</h2>
        <p>{translate('map.main.subtitle')}</p>
      </div>
    </div>
  );
};

Title.propTypes = {
  hasNoDiscrepancy: bool,
  translate: func.isRequired
};

Title.defaultProps = {
  hasNoDiscrepancy: false
};
