const extractedChartIDFromStorageKey = ({ prefix, key, environment }) => (
    key
      // remove unnecessary elements from the key
      .slice(prefix.length + 1, key.length - environment.length - 1)
      .toLowerCase()
  );

export const getChartLocationSearchParams = ({
  localStorage,
  prefix,
  environment
}) => {
  let chartLocationSearchParams = '';

  // read from storage
  if (localStorage && Object.keys(localStorage).length > 0) {
    Object.keys(localStorage).forEach(key => {
      if (key.toLowerCase().includes('chart')) {
        chartLocationSearchParams += `&${extractedChartIDFromStorageKey({
          prefix,
          key,
          environment
        })}=${localStorage.getItem(key)}`;
      }
    });
  }

  return chartLocationSearchParams;
};
