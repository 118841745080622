// vendor
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// local
import BoxContent from './BoxContent';
import './BoxWrapper.css';
import {
  shouldTopConnectorBeHidden,
  shouldBottomConnectorBeHidden
} from './styling';

/**
 * Out box. Includes right and leftside connectors.
 */
export default class BoxWrapper extends PureComponent {
  showLeftSideConnector() {
    return this.props.position !== 'init' ? (
      <div className="Pyramid-box-right-center" />
    ) : null;
  }

  showRightSideConnector() {
    return this.props.type !== 'flat' ? (
      <div className="Pyramid-box-right-center" />
    ) : null;
  }

  render() {
    const { data, position, numberFormat } = this.props;
    return (
      <div className="Pyramid-subbranch">
        <div
          className={`connector connector-top ${shouldTopConnectorBeHidden(
            position
          )}`}
        />
        <div className="Pyramid-box-wrapper">
          <div className="Pyramid-box-left-wrapper">
            <div
              className={`connector inner-connector-top ${shouldTopConnectorBeHidden(
                position
              )}`}
            />
            {this.showLeftSideConnector()}
            <div
              className={`connector inner-connector-bottom ${shouldBottomConnectorBeHidden(
                position
              )}`}
            />
          </div>
          <BoxContent {...data} numberFormat={numberFormat} />
          <div className="Pyramid-box-right-wrapper">
            {this.showRightSideConnector()}
          </div>
        </div>
        <div
          className={`connector connector-bottom ${shouldBottomConnectorBeHidden(
            position
          )}`}
        />
      </div>
    );
  }
}

BoxWrapper.propTypes = {
  type: PropTypes.string,
  position: PropTypes.string,
  data: PropTypes.object,
  numberFormat: PropTypes.string
};

BoxWrapper.defaultProps = {
  type: undefined,
  position: undefined,
  data: {},
  numberFormat: undefined
};
