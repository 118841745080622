import React from 'react';
import PropTypes from 'prop-types';
import ButtonSubmit from '../../../components/ButtonSubmit';

import './index.css';

const BottomButton = ({ isLoading }) => (
  <div className="Buttons-area-container">
    <ButtonSubmit isLoading={isLoading} text="save" />
  </div>
);

BottomButton.propTypes = { isLoading: PropTypes.bool };
BottomButton.defaultProps = { isLoading: false };

export default BottomButton;
