/* eslint-disable react/jsx-sort-props */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { INPUT_DATA_ROUTE_NAME } from '../../../services/navigation';

import { confirmDataImportSession } from '../backend/requests';
import {
  trackDataImportCancelled,
  trackDataImportSuccess
} from '../../../external/segment';

import { getSelectedAccountPlan } from '../helpers/extractions';
import { filterSelectedYears, translateTotals } from '../helpers/conversions';
import { IntegrationsContainer } from '../container';
import { LoadingSpinner } from '../../../components';
import {
  removeFromSessionStorage,
  DATA_SESSION_IMPORT_ID
} from '../../../services/sessionStorage';

import { MAPPED, UNMAPPED } from './Main/Tabs';
import {
  fetchData,
  requestUpdateData,
  sanitizeMappableDataResponse
} from './backend';
import { MapScreenContent } from './Content';
import { MapScreenContext } from './context';
import { MapScreenTypes } from './types';
import './index.css';

export class MapComponent extends Component {
  static propTypes = MapScreenTypes;

  state = {
    // one of: 'mapped', 'unmapped', 'templates'.
    selectedTab: MAPPED,
    // Full content loading.
    isLoading: true,
    // Only for displaying LoadingSpinner in tabbed area.
    isTabAreaLoading: false,
    data: undefined,
    // Simple notification with a single button.
    isNotificationOpen: false,
    // Dialog to confirm changes, with Submit & Cancel buttons.
    isConfimationModalOpen: false,
    unmappedCount: undefined,
    overview: undefined,
    currentlySelectedAccountItem: undefined
  };

  /**
   * For the component to fetch data,
   * it needs to have an id in memory already.
   * In normal conditions, an id is passed
   * when routing from one screen to another.
   *
   * But if the page is refreshed, that id is lost.
   * For that case, the componentDidUpdate method will
   * _wait_ for the call to get the sessionID to be resolved,
   * and when those props are updated, then the GET data for this
   * component is triggered.
   */
  componentDidMount = async () => {
    if (this.props.id) await this.getData();
  };

  /**
   * When the language is updated,
   * and because part of the content of this screen
   * has localization done in the backend,
   * there should be a new data fetch to retrieve
   * the newly localized data.
   *
   * Also, to ensure that the data for this screen is
   * fetched only after the dataSessionId has been fetched
   * (which happens in the container), we're ensure that the sessionId
   * exists and upon change, will request the data for this screen.
   */
  componentDidUpdate = async ({ languages, id }) => {
    const hasLanguageChanged = languages !== this.props.languages;
    const hasSessionIdChanged = id !== this.props.id;

    if (hasLanguageChanged || hasSessionIdChanged) {
      await this.getData(null, this.state.selectedTab);
    }
  };

  shouldCloseModals = ({
    toggleInnerLoading,
    closeDropdown,
    selectedTab,
    account
  }) => {
    if (selectedTab === UNMAPPED && account) {
      return null;
    }

    toggleInnerLoading();
    return closeDropdown();
  };

  onSelectNewKboAccountItem = async ({
    sourceMappingData,
    closeDropdown,
    account,
    hasChildren,
    toggleInnerLoading
  }) => {
    const { selectedTab } = this.state;
    const shouldShowConfirmationModal = hasChildren;
    /**
     * The UX requirements for this is that the dropdown modal closes
     * and the confirmation dialog opens.
     */
    if (shouldShowConfirmationModal) {
      this.shouldCloseModals({
        toggleInnerLoading,
        closeDropdown,
        selectedTab,
        account
      });
      this.onSelectParentItem({
        sourceMappingData,
        account
      });
      return this.toggleConfirmationModal();
    }

    /**
     * If the item being changed has no children,
     * then the dropdown modal should only be closed when the request
     * has been resolved.
     */
    await this.updateData({
      sourceMappingData,
      account
    });
    return this.shouldCloseModals({
      toggleInnerLoading,
      closeDropdown,
      selectedTab,
      account
    });
  };

  onClickSubmitButton = async ({ e, goTo }) => {
    e.preventDefault();
    const { id, selectedAccountPlan, vatin, match } = this.props;
    const { integration } = match.params;

    this.toggleLoadingState();

    await trackDataImportSuccess({
      source: integration,
      accountPlan: selectedAccountPlan,
      cvr: vatin
    });

    this.toggleLoadingState();
    await confirmDataImportSession(id);
    // removal of session data because the import is complete
    removeFromSessionStorage(DATA_SESSION_IMPORT_ID);

    return this.goToInput(goTo);
  };

  onSelectParentItem = ({ sourceMappingData, account }) =>
    this.setState({
      currentlySelectedAccountItem: {
        sourceMappingData,
        account
      }
    });

  onConfirmParentItemChange = async () => {
    const {
      currentlySelectedAccountItem: { sourceMappingData, account }
    } = this.state;
    await this.updateData({ sourceMappingData, account });
    this.toggleConfirmationModal();
  };

  onCancelParentItemChange = () => {
    this.toggleConfirmationModal();
    this.setState({
      currentlySelectedAccountItem: undefined
    });
  };

  /**
   * 'year' is optional.
   * When the component is loaded, no 'year' is provided.
   * When the dropdown value of the overview table changes,
   * a new request is made using that value as the 'year'.
   */
  getData = async (year, panel) => {
    const { id: dataSessionId } = this.props;
    const { selectedTab: selectedTabFromState } = this.state;
    const selectedTab = panel || selectedTabFromState;
    const response = await fetchData({
      dataSessionId,
      year,
      selectedTab
    });

    this.updateStateAfterDataFetch(sanitizeMappableDataResponse(response));
  };

  updateData = async ({ sourceMappingData, account }) => {
    const { id: dataSessionId } = this.props;
    const {
      selectedTab,
      overview: { year }
    } = this.state;
    const { source_mapping_key: sourceMapingKey } = sourceMappingData;
    const responseFromDataUpdate = await requestUpdateData({
      sourceMappingData,
      account,
      dataSessionId,
      selectedTab,
      year
    });
    const sanitizedResponse = sanitizeMappableDataResponse(
      responseFromDataUpdate,
      sourceMapingKey,
      selectedTab
    );
    const { mappingCompleted } = sanitizedResponse;

    this.updateStateAfterDataFetch(sanitizedResponse, () =>
      this.checkBalance({
        mappingCompleted,
        toggleNotification: this.toggleNotification
      })
    );
  };

  goToInput = goTo => {
    const url = INPUT_DATA_ROUTE_NAME;
    goTo(url);
    return url;
  };

  handleCancel = goTo => {
    const { integration, props } = this;
    const { vatin, selectedAccountPlan } = props;

    trackDataImportCancelled({
      source: integration,
      cvr: vatin,
      accountPlan: selectedAccountPlan
    });
    return this.goToInput(goTo);
  };

  checkBalance = ({ mappingCompleted, toggleNotification }) => {
    if (mappingCompleted) {
      toggleNotification();
    }
  };

  updateStateAfterDataFetch = (sanitizedResponse, checkBalance) => {
    this.setState(
      {
        ...sanitizedResponse,
        isLoading: false
      },
      () => {
        if (checkBalance) checkBalance();
      }
    );
  };

  /**
   * When changing tabs, a new call is made, depending on the tab.
   * If the unmapped tab is selected, then hit the unmapped api endpoint.
   * The same for mapped.
   */
  toggleSelectedTab = async panel => {
    const {
      overview: { year }
    } = this.state;
    this.setState({ isTabAreaLoading: true });
    await this.getData(year, panel);
    this.setState({
      selectedTab: panel,
      isTabAreaLoading: false
    });
  };

  toggleLoadingState = () => {
    this.setState(prevState => ({
      isLoading: !prevState.isLoading
    }));
  };

  /**
   * Shown when the balance table is matching.
   */
  toggleNotification = () =>
    this.setState(prevState => ({
      isNotificationOpen: !prevState.isNotificationOpen
    }));

  /**
   * Shown when the user changes one of the parents dropdowns.
   */
  toggleConfirmationModal = () =>
    this.setState(prevState => ({
      isConfimationModalOpen: !prevState.isConfimationModalOpen
    }));

  render() {
    const { importSessionSelectedYears, match } = this.props;
    const { integration } = match.params;
    const {
      selectedTab,
      data,
      isNotificationOpen,
      isConfimationModalOpen,
      isLoading,
      unmappedCount,
      overview,
      isTabAreaLoading,
      currentlySelectedAccountItem
    } = this.state;
    const shouldShowContent =
      data && !isLoading && importSessionSelectedYears.length > 0;

    return (
      <IntegrationsContainer
        bgColor="light"
        render={({ goTo, translate }) => (
          <MapScreenContext.Provider
            value={{
              data,
              goTo,
              integration,
              selectedTab,
              toggleNotification: this.toggleNotification,
              toggleSelectedTab: this.toggleSelectedTab,
              unmappedCount,
              updateData: this.updateData,
              isTabAreaLoading,
              onSelectNewKboAccountItem: this.onSelectNewKboAccountItem,
              translate
            }}
          >
            {shouldShowContent ? (
              <MapScreenContent
                getData={this.getData}
                currentlySelectedAccountItem={currentlySelectedAccountItem}
                handleCancel={() => this.handleCancel(goTo)}
                integration={integration}
                isConfimationModalOpen={isConfimationModalOpen}
                isNotificationOpen={isNotificationOpen}
                onClickSubmitButton={e => this.onClickSubmitButton({ e, goTo })}
                onConfirmParentItemChange={this.onConfirmParentItemChange}
                onCancelParentItemChange={this.onCancelParentItemChange}
                overviewYear={overview.year}
                selectedTab={selectedTab}
                toggleConfirmationModal={this.toggleConfirmationModal}
                translate={translate}
                overviewRows={translateTotals({
                  totals: overview.totals,
                  namespace: 'map.overview.table',
                  translate,
                  integration
                })}
                importSessionSelectedYears={filterSelectedYears(
                  importSessionSelectedYears
                )}
              />
            ) : (
              <LoadingSpinner />
            )}
          </MapScreenContext.Provider>
        )}
        type="large"
      />
    );
  }
}

const mapStateToProps = state => ({
  importSessionSelectedYears: state.dataImportSession.years,
  languages: state.locale.languages,
  id: state.dataImportSession.id,
  selectedAccountPlan: getSelectedAccountPlan(state.dataImportSession),
  vatin: state.client.vatin
});
export const Map = connect(mapStateToProps)(MapComponent);
