import FileSaver from 'file-saver';
import { get } from 'axios';
import format from 'date-fns/format';
import { KBO_STORAGE_KEY_PREFIX } from '../../services/localStorage';
import { getChartLocationSearchParams } from './helpers';

import {
  INPUT_BUDGET_ROUTE_NAME,
  INPUT_DATA_ROUTE_NAME
} from '../../services/navigation';
import { getClientId } from '../../services/navigation/routeMapping';
import { getNavigationOptionsByLocation } from '../../services/navigation/options';
import { extractYearFromURL } from '../../helpers/location';
import { INPUT_DATA_START_YEAR } from '../../helpers/constants';
import { trackReportDownloaded } from '../../external/segment';

export const paramsFromLocation = location => ({
  clientId: getClientId(location),
  year: extractYearFromURL({ location }),
  id: (getNavigationOptionsByLocation(location) || {}).id
});

const trackDownload = ({ location }) => {
  const { clientId, year, id } = paramsFromLocation(location);
  if (id) {
    trackReportDownloaded({ clientId, year, moduleId: id });
  }
};

export const determineApiUrlToGenerateReport = ({ location, localStorage }) => {
  const { pathname } = location;
  const { clientId, year, id } = paramsFromLocation(location);

  switch (pathname) {
    case INPUT_BUDGET_ROUTE_NAME:
    case INPUT_DATA_ROUTE_NAME:
      const inputYear = year || INPUT_DATA_START_YEAR;
      return `/clients/${clientId}/accounting_years/${id}/${inputYear}.pdf`;
    default:
      return `/clients/${clientId}/reports/${id}.pdf?year=${year}${getChartLocationSearchParams(
        { localStorage, prefix: KBO_STORAGE_KEY_PREFIX, environment: ENV }
      )}`;
  }
};

export const print = () => {
  const url = determineApiUrlToGenerateReport(window);
  const { id } = paramsFromLocation(window.location);
  const currentTime = format(new Date(), 'yyyyMMddHHmmSS');

  return get(url, {
    responseType: 'arraybuffer',
    headers: { Accept: 'application/pdf' }
  }).then(async getResponse => {
    const blob = new Blob([getResponse.data], {
      type: 'application/pdf'
    });

    await FileSaver.saveAs(blob, `${currentTime}-${id}.pdf`);

    trackDownload(window);
  });
};

export default print;
