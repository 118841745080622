export const responseSanitizer = response => {
  const dataImportSession = response.data.data_import_session;
  const { taxonomy } = dataImportSession;

  return {
    source: dataImportSession.source,
    errorMessage: dataImportSession.errors[0].message,
    errorTitle: dataImportSession.errors[0].title,
    taxonomy
  };
};
