import APP_AUTH_TOKEN_STORED from './actionTypes';
import decodeToken from './helpers';

const initialState = {
  token: undefined,
  sub: {}
};

export default function authReducer(state = initialState, action = {}) {
  const { payload } = action;
  switch (action.type) {
    case APP_AUTH_TOKEN_STORED:
      return {
        ...state,
        token: payload,
        ...decodeToken(payload)
      };

    default:
      return { ...state };
  }
}
