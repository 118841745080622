import React, { Fragment, Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { Button } from '@karnov/kui-deprecated';

import { trackDataImportCancelled } from '../../../../external/segment';
import { INTEGRATION_LIST } from '../../../../services/navigation';

import {
  selectAllYears,
  areAllYearsSelected,
  isIndeterminate,
  areSomeYearsSelected
} from './helpers';
import CheckboxYear from './CheckboxYear';
import { YearsComponentPropTypes } from './types';
import './index.css';

export default class Years extends Component {
  static propTypes = YearsComponentPropTypes;

  state = {
    /**
     * This might be considered an anti-pattern if you miss the key prop
     * on the parent of this component.
     *
     * Based on: https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
     */
    years: this.props.years
  };

  handleCancel = () => {
    const { integration, goTo } = this.props;

    trackDataImportCancelled({ source: integration });
    return goTo(INTEGRATION_LIST);
  };

  clickAllYearsCheckBox = () => {
    this.setState(prevState => ({
      years: selectAllYears(prevState.years)
    }));
  };

  clickYearCheckBox = index =>
    this.setState(prevState => ({
      ...prevState,
      years: prevState.years.map((year, i) => {
        if (index !== i) {
          return year;
        }
        return { ...year, checked: !year.checked };
      })
    }));

  render() {
    const { translate, submitYears } = this.props;
    const { years } = this.state;
    const { integration } = this.props;

    return (
      <Fragment>
        <p>{translate(`${integration}.selectYears.innerText`)}</p>
        <div className="SelectYears-ImportData-innerContainer">
          <div className="SelectYears-ImportData-years-container">
            <div className="SelectYears-ImportData-years">
              <Checkbox
                checked={areAllYearsSelected(years)}
                className="checkbox-all"
                indeterminate={isIndeterminate(years)}
                label={translate(`${integration}.selectYears.all`)}
                name="allYears-checkbox"
                tabIndex={0}
                onClick={this.clickAllYearsCheckBox}
              />

              {years.map((year, index) => (
                <CheckboxYear
                  key={year.value}
                  clickYearCheckBox={this.clickYearCheckBox}
                  index={index}
                  integration={integration}
                  translate={translate}
                  year={year}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="SelectYears-buttons-area">
          <Button
            disabled={!areSomeYearsSelected(years)}
            kind="primary"
            text={translate(`${integration}.selectYears.button.import`)}
            type="button"
            onClick={() => submitYears({ years })}
          />
          <button className="cancel" type="button" onClick={this.handleCancel}>
            {translate(`${integration}.selectYears.button.cancel`)}
          </button>
        </div>
      </Fragment>
    );
  }
}
