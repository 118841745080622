import React from 'react';
import { bool } from 'prop-types';

import ButtonSubmit from '../../../../components/ButtonSubmit';

import './index.css';

const Buttons = ({ isLoading }) => (
  <div className="Buttons-container">
    <ButtonSubmit isLoading={isLoading} text="create" />
  </div>
);

Buttons.propTypes = {
  isLoading: bool
};

Buttons.defaultProps = { isLoading: false };

export default Buttons;
