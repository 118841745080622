import React from 'react';
import { Button } from '@karnov/kui-deprecated';
import { InfoBanner } from '../../../components';
import { determineImageProperties } from '../../../helpers';
import { INTEGRATION_LIST } from '../../../services/navigation';

import { MainTypes } from './types';
import './Main.css';

export const Main = ({ source, errorMessage, errorTitle, goTo, translate }) => (
  <section className="Main-container">
    <div className="logowrapper">
      <img
        alt={`logo for ${source}`}
        src={determineImageProperties(source).src}
        width={determineImageProperties(source).width}
      />
    </div>

    <h1>{translate('selectYears.title')}</h1>
    <div className="warning">
      <InfoBanner
        integration={source}
        source="virk"
        subtitle={`424.${errorMessage}`}
        title={`424.${errorTitle}`}
      />
    </div>

    <div className="button">
      <Button
        kind="default"
        text={translate('424.BackButton')}
        onClick={() => goTo(INTEGRATION_LIST)}
      />
    </div>
  </section>
);

Main.propTypes = MainTypes;
