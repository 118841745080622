export function apiURL(clientID, fiscalYear) {
  return `/clients/${clientID}/reports/valuation_report/section/sensitivity_analysis?fiscal_year=${fiscalYear}`;
}

/**
 * Configuration for the initial request of data in the forecast view.
 * @param {?String/Number} clientID
 */
export function configForRequestFetchValuationData(clientID) {
  const url = `/clients/${clientID}/reports/valuation_report`;
  return {
    method: 'GET',
    url,
    baseURL: API_URL
  };
}

/**
 * Response sanitization of data.
 * @param {Object} response
 */
export function cleanUpResponse(response) {
  return {
    clientID: response.data.valuation_report.client_id,
    fiscalYear: response.data.valuation_report.fiscal_year,
    sections: response.data.valuation_report.sections
  };
}
