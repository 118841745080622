import { determineIsBudgetScreen } from '../helpers';
import { INPUT_DATA_START_YEAR, extractYearFromURL } from '../../../helpers';
import { getClientId } from '../../../services/navigation/routeMapping';

export function getDataApiUrl(location, isPUT, yearBeingEdited) {
  const { pathname } = location;

  if (!location || !location.pathname || !location.search)
    throw new Error(
      'Missing either location, pathname and/or clientId when generating the api data url'
    );

  const kind = determineIsBudgetScreen(pathname) ? 'budget' : 'actual';
  const year = isPUT
    ? yearBeingEdited
    : extractYearFromURL({ location }) || INPUT_DATA_START_YEAR;
  const clientId = getClientId(location);

  return `${API_URL}/clients/${clientId}/accounting_years/${kind}/${year}`;
}
