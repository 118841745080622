import { func, string, shape, number, bool, arrayOf } from 'prop-types';

export const BurgerMenuTypes = {
  translate: func.isRequired,
  currentLocation: shape({
    pathname: string,
    search: string
  }).isRequired
};

export const ContainerTypes = {
  ...BurgerMenuTypes,
  isOpen: bool.isRequired,
  isClean: bool.isRequired,
  openMenu: func.isRequired,
  closeMenu: func.isRequired,
  toggleMenu: func.isRequired
};

export const MenuTypes = {
  ...BurgerMenuTypes
};

export const BurgerButtonTypes = {
  toggleMenu: func.isRequired
};

export const SectionTypes = {
  ...BurgerMenuTypes,
  section: shape({
    name: string,
    subsections: arrayOf(
      shape({
        name: string,
        route: string
      })
    ).isRequired
  }),
  index: number.isRequired,
  renderSubsection: func.isRequired
};

export const SubsectionTypes = {
  ...BurgerMenuTypes,
  subsection: shape({
    name: string,
    route: string
  }).isRequired
};
