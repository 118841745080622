import React, { Component } from 'react';
import { Button as SemanticUIButton } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';
import { undoButtonTypes } from '../../types';

import { MapScreenContext, RowContext } from '../../context';
import './UndoButton.css';

export class Button extends Component {
  state = {
    isLoading: false
  };

  render() {
    const { rowData, updateData } = this.props;
    const { isLoading } = this.state;

    return (
      <SemanticUIButton
        className="Map__undo"
        loading={isLoading}
        onClick={async () => {
          this.setState({ isLoading: true });
          await updateData({
            sourceMappingData: rowData,
            account: 'restore_to_default'
          });
        }}
      >
        <Translate id="map.row.undo" />
      </SemanticUIButton>
    );
  }
}

Button.propTypes = undoButtonTypes;

export const UndoButton = () => (
  <MapScreenContext.Consumer>
    {({ updateData }) => (
      <RowContext.Consumer>
        {({ rowData }) => <Button {...{ updateData, rowData }} />}
      </RowContext.Consumer>
    )}
  </MapScreenContext.Consumer>
);
