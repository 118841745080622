import { get } from 'axios';

import {
  mappedRoute,
  getClientId
} from '../../../services/navigation/routeMapping';
import { appHasErrorAfterFetch } from '../../../components/App/actions';
import {
  APP_INIT_FETCHING_INITIAL_DATA,
  APP_STOP_FETCHING_INITIAL_DATA
} from '../../../components/App/actionTypes';

import sanitizePyramidAndKPIResponse from './integration';
import { getCommentsData } from '../../../components/Comments/actions';

export const CONVERT_RAW_DATA_INTO_GRAPH_DATA =
  'REPORT_CONVERT_RAW_DATA_INTO_GRAPH_DATA';
export const ERROR_FETCH_DATA = 'REPORT_ERROR_FETCH_DATA';
export const INIT_FETCH_DATA = 'REPORT_INIT_FETCH_DATA';
export const SUCCESS_FETCH_DATA = 'REPORT_SUCCESS_FETCH_DATA';
export const SUCCESS_FETCH_PYRAMID_DATA = 'REPORT_SUCCESS_FETCH_PYRAMID_DATA';

function initFetchData(selectedYear) {
  return {
    type: INIT_FETCH_DATA,
    payload: {
      isLoading: true,
      year: selectedYear
    }
  };
}

function successFetchPyramidData(sanitizedResponse) {
  return {
    type: SUCCESS_FETCH_PYRAMID_DATA,
    payload: sanitizedResponse
  };
}

/**
 * Handling the success callback after the fetch.
 * Changing the viewName, will change the destructuring of response.
 * @param  {Object} data     [Response from server]
 * @param  {String} viewName [The viewName is linked to the response object structure]
 * @return {Object}          [Payload for the reducer]
 */
function successFetchData(data, viewName) {
  return {
    type: SUCCESS_FETCH_DATA,
    payload: {
      isLoading: false,
      sections: data[viewName].sections,
      fiscal_year: data[viewName].fiscal_year,
      years: data[viewName].years,
      kpi: data[viewName].kpi
    }
  };
}

/**
 * Handling the error callback after the fetch.
 * @param  {Object} error [description]
 * @return {Object}       [description]
 */
export const errorDispatch = error => dispatch => {
  // global error actions
  dispatch(appHasErrorAfterFetch(error));
  return {
    type: ERROR_FETCH_DATA,
    payload: { ...error }
  };
};

/**
 * First call being made to populate the dupont pyramid.
 * @param {*} viewName
 * @param {*} clientId
 * @param {*} selectedYear
 */
export function fetchPyramidData(viewName, clientId, selectedYear) {
  return dispatch =>
    get(
      `${API_URL}/clients/${clientId}/reports/${viewName}/section/dupont_pyramid?fiscal_year=${selectedYear}`
    )
      .then(response => {
        const sanitizedResponse = sanitizePyramidAndKPIResponse(response);
        dispatch({ type: APP_STOP_FETCHING_INITIAL_DATA });

        dispatch(successFetchPyramidData(sanitizedResponse, viewName));
        return response.data;
      })
      .catch(error => dispatch(errorDispatch(error)));
}

/**
 * Second call to populate the remaining tables.
 * @param {*} viewName
 * @param {*} clientId
 * @param {*} selectedYear
 */
export function fetchTablesData(viewName, clientId, selectedYear) {
  return dispatch =>
    get(
      `${API_URL}/clients/${clientId}/reports/${viewName}?fiscal_year=${selectedYear}`
    )
      .then(response => {
        dispatch({ type: APP_STOP_FETCHING_INITIAL_DATA });
        dispatch(successFetchData(response.data, viewName));
        return response.data;
      })
      .catch(error => dispatch(errorDispatch(error)));
}

/**
 * There are two asynchronous calls in the mgt/budget report view:
 * One for the pyramid and another for the rest of the tables.
 */
export function fetchReportsData(year) {
  return (dispatch, getState) => {
    const state = getState();
    const { location } = state.router;
    const viewName = mappedRoute(state);
    const selectedYear = year || null;
    const clientId = getClientId(location);

    dispatch({ type: APP_INIT_FETCHING_INITIAL_DATA });
    dispatch(initFetchData(selectedYear));
    // The pyramid call is the first to be made.
    return Promise.all([
      dispatch(fetchPyramidData(viewName, clientId, selectedYear)),
      dispatch(fetchTablesData(viewName, clientId, selectedYear)),
      dispatch(getCommentsData())
    ]);
  };
}
