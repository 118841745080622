import { fromLocalizedStringToRawNumber } from '../../../helpers/conversions/formatNumber';

export function preparePayload(sectionName, rowName, value) {
  return {
    simulation_analysis: {
      [sectionName]: {
        [rowName]: fromLocalizedStringToRawNumber(value)
      }
    }
  };
}
