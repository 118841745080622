export const BASE_OVERLAY_CLASS = 'BurgerMenu__overlay';
export const HIDDEN_OVERLAY_CLASS = `${BASE_OVERLAY_CLASS}--hidden`;
export const CLEAN_OVERLAY_CLASS = `${BASE_OVERLAY_CLASS}--clean`;

export const generateClassNameForOverlay = ({
  isOpen,
  isClean,
  isWideEnough
}) => {
  /* In order to avoid a blink from the overlay animations
  ** when the component mounts, we hide it when the user hasn't touched the menu (mounted).
  ** After the user opens the menu, the animations are the ones that hide/show the side menu and overlay.
  */

  if (isClean || isWideEnough) {
    return `${BASE_OVERLAY_CLASS} ${CLEAN_OVERLAY_CLASS}`;
  }

  if (!isOpen) {
    return `${BASE_OVERLAY_CLASS} ${HIDDEN_OVERLAY_CLASS}`;
  }

  return BASE_OVERLAY_CLASS;
};
