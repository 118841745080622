import { currencyOptions } from '../../components/Settings/constants';

/**
 * Translates a label if the label is present.
 *
 * @param  {func} translate [getTranslate function]
 * @param  {label} string [Key of the translation]
 * @return {String}  [Returns the translated string if label is present, otherwise null]
 */
export function optionalTranslation(translate, label) {
  return label ? translate(label) : null;
}

/**
 * This includes sanitization of data due to inconsistent types
 * @param  {[type]} translate [description]
 * @param  {[type]} value     [description]
 * @param  {[type]} scope     [description]
 * @return {[type]}           [description]
 */
export function typeSanitizer(translate, value, scope = null) {
  switch (true) {
    // Nothing to be translated
    case !value:
      return null;
    // Don't translate currencies
    case currencyOptions.indexOf(value) >= 0:
      return value;
    // Don't translate numbers
    case typeof value === 'number':
      return value;
    // Don't translate if value matches things like '(value1 = value2)'.
    case String(value).match(/\([^=)]+=[^)]+\)/) !== null:
      return value;

    // Beginning of dynamic translations. Ex: Budget 2016. 2016 is dynamic.
    case value.toLowerCase().startsWith('budget'):
      return translate('budgetHeader', { year: value.split(' ')[1] });

    case value.startsWith('estimate'):
      return translate('forecast_assumptions.estimate', {
        year: value.split(' ')[1]
      });
    case value.startsWith('terminal'):
      return translate('forecast_assumptions.terminal', {
        year: value.split(' ')[1]
      });
    // End of dynamic translations.
    default:
      return !scope ? translate(value) : translate(`${scope}.${value}`);
  }
}

export function tryTranslate(translate, value, scope = null) {
  // Don't translate if value matches things like '(value1 = value2)'.
  if (String(value).match(/\([^=)]+=[^)]+\)/)) {
    return value;
  }
  const translatedValue = !scope
    ? translate(value)
    : translate(`${scope}.${value}`);
  return translatedValue.startsWith('Missing localized key')
    ? null
    : translatedValue;
}
