import React from 'react';
import { func } from 'prop-types';

import './index.css';

const TrialPromotion = ({ translate }) => (
  <div className="trial-promotion">
    <div className="trial-promotion__content">
      <h3 className="trial-promotion__title">
        {translate('trial-promotion.image.title')}
      </h3>
      <p className="trial-promotion__text">
        {translate('trial-promotion.image.text')}
      </p>
      <a
        className="trial-promotion__button info-card-detailed__link info-card-detailed__link--primary"
        href={translate('trial-promotion.image.url')}
        rel="noreferrer noopener"
        target="_blank"
      >
        {translate('trial-promotion.image.button')}
      </a>
      <p className="trial-promotion__footer">
        {translate('trial-promotion.image.footer')}
      </p>
    </div>
  </div>
);

TrialPromotion.propTypes = {
  translate: func.isRequired
};

export { TrialPromotion };
