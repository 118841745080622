import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Menu } from './Menu';
import { Container } from './Container';
import { BurgerMenuTypes } from './types';
import './index.css';

export const BurgerMenuComponent = ({ currentLocation, translate }) => (
  <Container>
    {({ closeMenu }) => <Menu {...{ currentLocation, translate, closeMenu }} />}
  </Container>
);

BurgerMenuComponent.propTypes = BurgerMenuTypes;

const mapStateToProps = ({ locale, router: { location } }) => ({
  translate: getTranslate(locale),
  currentLocation: location
});

const BurgerMenu = connect(mapStateToProps)(BurgerMenuComponent);

export default BurgerMenu;
