import { redirectToErrorView } from '../../../external/rollbar';
import { submitIdentityToSegment, trackPage } from '../../../external/segment';
import { getModuleId } from '../../../helpers/location';
import { determineOptions } from './options';

/**
 * The middleware is for catching runtime errors after the app
 * was loaded.
 *
 * The middleware gives access to the store, action and error.
 * There are useful because they allow to not report errors on all action types
 * or locations.
 */
export const tracker = () => store => next => action => {
  try {
    if (action.type === '@@router/LOCATION_CHANGE') {
      const state = store.getState();
      const { location } = action.payload;
      const moduleId = getModuleId({ location });
      // if it is a view that has information per year, we track it.
      // if changes, the year is sent in each fetch data action.
      const options = determineOptions({ location: window.location, moduleId });

      trackPage({ location, options });
      submitIdentityToSegment({ state });
    }

    return next(action);
  } catch (err) {
    const location = store.getState().router.location.pathname;
    return redirectToErrorView({ message: err, location });
  }
};
