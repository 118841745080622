// Vendors
import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import Screen from '../../../components/Screen';
import { SECONDARY_BACKGROUND } from '../../../components/Screen/constants';

import Search from './Search';
import Form from './Form';

import ClientDataLocale from '../locale.json';

import './index.css';

const CreateClient = props => {
  const { translate } = props;
  return (
    <Screen
      hideNavigation
      background={SECONDARY_BACKGROUND}
      locale={ClientDataLocale}
    >
      <div className="CreateClient-content">
        <div className="Createclient-content-header">
          <h1>{translate('new.title')}</h1>
        </div>
        <Search />
        <Form {...props} />
      </div>
    </Screen>
  );
};

CreateClient.propTypes = {
  translate: func.isRequired
};

const mapStateToProps = state => ({ translate: getTranslate(state.locale) });

export default connect(mapStateToProps)(CreateClient);
