import React from 'react';
import PropTypes from 'prop-types';

import AnalysisModuleDispatcher from '..';
import TitleArea from '../../../components/TitleArea';
import { InfoBox } from '../../../components';

import { Sections } from './Sections';
import './index.css';

export class Main extends React.PureComponent {
  static propTypes = {
    currentLocation: PropTypes.object.isRequired,
    sections: PropTypes.array.isRequired,
    fiscal_year: PropTypes.number,
    title: PropTypes.string.isRequired
  };

  static defaultProps = {
    fiscal_year: null
  };

  render() {
    const {
      // eslint-disable-next-line
      fiscal_year,
      currentLocation,
      title,
      sections: data
    } = this.props;

    return (
      <div className="ReportSimulation-container main-container">
        <div className="analysis-section-container">
          <InfoBox
            text="simulation.requirements"
            title="simulation.requirementsTitle"
          />
          <TitleArea
            currentLocation={currentLocation}
            importButton={false}
            // eslint-disable-next-line
            selectedYear={fiscal_year}
            showCurrentFiscalYear
            showDropDown={false}
            title={`${title}.title`}
          />

          <Sections data={data} moduleBottom={this.moduleBottom} />
          <span
            ref={span => {
              this.moduleBottom = span;
            }}
          />
        </div>
      </div>
    );
  }
}

export default AnalysisModuleDispatcher(Main);
