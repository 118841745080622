import APP_AUTH_TOKEN_STORED from './actionTypes';
import decodeToken from './helpers';
import store from '../../store';
import {
  AUTH_ID,
  getFromLocalStorage,
  setInLocalStorage
} from '../localStorage/index';

export const actionStoreToken = ({ token }) => ({
  type: APP_AUTH_TOKEN_STORED,
  payload: token
});

export function handleAuthToken(newToken) {
  const existingToken = getFromLocalStorage(AUTH_ID);

  if (!newToken) {
    store.dispatch(actionStoreToken({ token: existingToken }));
    return existingToken;
  }

  if (!existingToken) {
    setInLocalStorage(AUTH_ID, newToken);
    store.dispatch(actionStoreToken({ token: newToken }));
    return newToken;
  }

  const decodedExistingToken = decodeToken(existingToken);
  const decodedNewToken = decodeToken(newToken);

  if (decodedExistingToken.iat < decodedNewToken.iat) {
    setInLocalStorage(AUTH_ID, newToken);
    store.dispatch(actionStoreToken({ token: newToken }));
    return newToken;
  }

  return existingToken;
}
