import React, { useState, useEffect } from 'react';
import { SidePanelTitle } from './SidePanelTitle';
import { SidePanelModules } from './SidePanelModules';
import { SidePanelContentTypes } from './types';

export const SidePanelContent = ({ definitions }) => {
  const [toggleClass, setToggleClass] = useState(false);

  useEffect(() => {
    setToggleClass(!toggleClass);
  }, [definitions]);

  const getCurrentClassName = () => {
    const currentClassName = 'sidebar__definition--current';
    if (toggleClass) {
      return `${currentClassName}-0`;
    }

    return currentClassName;
  };

  const getOldClassName = () => {
    const oldClassName = 'sidebar__definition--old';
    if (toggleClass) {
      return `${oldClassName}-0`;
    }

    return oldClassName;
  };

  return (
    <div className="sidebar__content">
      {definitions.length > 0 &&
        definitions.map(definition => (
          <div
            key={definition.title}
            // this is used so the keyframe animation
            // is re-triggered  when a new definition
            // is clicked
            className={`sidebar__definition ${
              definition.current ? getCurrentClassName() : getOldClassName()
            }`}
          >
            <SidePanelTitle
              description={definition.description}
              title={definition.title}
            />
            {definition.modules.length > 0 && (
              <SidePanelModules modules={definition.modules} />
            )}
          </div>
        ))}
    </div>
  );
};

SidePanelContent.propTypes = SidePanelContentTypes;
