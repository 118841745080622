import React, { Component } from 'react';
import { shape, oneOf, arrayOf, string, bool, number, func } from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Button } from '@karnov/kui-deprecated';
import { determineImageProperties } from '../../../helpers';
import { INTEGRATION_LIST } from '../../../services/navigation';
import { ECONOMIC, VIRK, CASEWARE } from '../../../helpers/constants';
import { updateDataImportSession } from '../backend/requests';
import { IntegrationsContainer } from '../container';
import { SelectPlanDropdown } from './Dropdown';
import { saveDataImportSession as saveSession } from '../actions';

import './index.css';

export class SelectPlanComponent extends Component {
  static propTypes = {
    match: shape({
      params: shape({
        integration: oneOf([ECONOMIC, VIRK, CASEWARE])
      }).isRequired
    }).isRequired,
    accountPlans: arrayOf(
      shape({
        text: string,
        value: string,
        selected: bool
      })
    ).isRequired,
    id: number,
    translate: func.isRequired,
    saveDataImportSession: func.isRequired
  };

  static defaultProps = {
    id: null
  };

  integration = this.props.match.params.integration;

  constructor(props) {
    super(props);
    this.state = {
      selected: this.getSelectedAccountPlan()
    };
  }

  onSelectOption = (event, data) => this.setState({ selected: data.value });

  onClickSelectPlanButton = async ({ e, goTo, integration }) => {
    e.preventDefault();
    const { id, saveDataImportSession } = this.props;
    const { selected } = this.state;
    const { data } = await updateDataImportSession({
      id,
      accountPlan: selected
    });

    saveDataImportSession(data);

    const url = `${INTEGRATION_LIST}/import/${integration}/overview`;
    goTo(url);
    return url;
  };

  getSelectedAccountPlan = () => {
    const selectedPlan =
      this.props.accountPlans.find(plan => plan.selected) || {};

    return selectedPlan.value || 'none';
  };

  localizeAccountPlans = accountPlans =>
    accountPlans.map(accountPlan => ({
      ...accountPlan,
      text: this.props.translate(
        `${this.integration}.selectPlan.accountPlan.${accountPlan.text}`
      )
    }));

  render() {
    const { integration } = this;
    const { translate, accountPlans } = this.props;
    const { selected } = this.state;
    const { src, width } = determineImageProperties(integration);
    const translatedAccountPlans = this.localizeAccountPlans([
      { text: 'none', value: 'none' },
      ...accountPlans
    ]);

    return (
      <IntegrationsContainer
        render={({ goTo }) => (
          <div className="SelectPlan-container">
            <section className="content">
              <div className="logowrapper">
                <img alt="logo" src={src} width={width} />
              </div>
              <p className="__title">
                {translate(`${integration}.selectPlan.title`)}
              </p>
              <p className="__text">
                {translate(`${integration}.selectPlan.text`)}
              </p>

              <SelectPlanDropdown
                options={translatedAccountPlans}
                selected={selected}
                onSelectOption={this.onSelectOption}
              />
            </section>
            <section className="actions">
              <Button
                disabled={selected === 'none'}
                kind="primary"
                text={translate(`${integration}.selectPlan.button`)}
                onClick={e =>
                  this.onClickSelectPlanButton({ e, goTo, integration })
                }
              />
            </section>
          </div>
        )}
        type="medium"
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveDataImportSession: data => dispatch(saveSession(data))
});

const mapStateToProps = ({ locale, dataImportSession }) => ({
  translate: getTranslate(locale),
  accountPlans: dataImportSession.accountPlans,
  id: dataImportSession.id
});

export const SelectPlan = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPlanComponent);
