import React, { Fragment, useState } from 'react';
import { object, func, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Line, defaults } from 'react-chartjs-2';
import queryString from 'query-string';

import { ChartLegends } from './ChartLegends';
import { chartData, generateLegends } from './helpers';
import { chartOptions } from './options';
import { chartDataProps } from '../propTypes';
import './index.css';

defaults.global.defaultFontColor = '#2F2F2B';
defaults.global.defaultFontFamily =
  "'Roboto', 'Helvetica', 'Arial', sans-serif";
defaults.global.defaultFontSize = 14;

function ChartComponent({
  section,
  translate,
  numberFormat,
  currency,
  data,
  units,
  shouldDisplayChartsSideBySide
}) {
  const [chart, setChart] = useState();

  const chartTitle = data.title;
  const chartID = `chart-${data.key}`;
  let enabledLegendsIndexArray;

  const unitsForDisplay = (units === undefined) ? currency : units;

  // fetch data from url on mount
  const urlSearchParams = queryString.parse(window.location.search);
  const convertToInteger = item => parseInt(item, 10);
  const chartMetadataInURL = urlSearchParams[chartID];
  if (chartMetadataInURL) {
    enabledLegendsIndexArray = chartMetadataInURL
      .split('')
      .map(convertToInteger);
  } else {
    enabledLegendsIndexArray = false;
  }

  return (
    <div className="Chart-container__wrapper">
      {shouldDisplayChartsSideBySide && (
        <Fragment>
          <h3>{translate(`charts.${data.title}`)}</h3>
          <ChartLegends
            chart={chart}
            chartID={chartID}
            legends={generateLegends({
              translate,
              data,
              chartID,
              enabledLegendsIndexArray
            })}
          />
        </Fragment>
      )}
      <div
        className={`${
          shouldDisplayChartsSideBySide ? 'LineChart-wrapper' : ''
        }`}
        id={chartID}
      >
        <Line
          ref={ref => {
            if (ref) {
              setChart(ref.chart_instance);
            }
          }}
          data={chartData({
            section,
            translate,
            data,
            chartID,
            enabledLegendsIndexArray
          })}
          height={390}
          options={chartOptions({
            numberFormat,
            shouldDisplayChartsSideBySide,
            units: unitsForDisplay
          })}
        />
      </div>
      {!shouldDisplayChartsSideBySide && (
        <ChartLegends
          chart={chart}
          chartID={chartID}
          legends={generateLegends({
            translate,
            data,
            chartTitle,
            chartID,
            enabledLegendsIndexArray
          })}
        />
      )}
    </div>
  );
}

ChartComponent.propTypes = {
  section: object.isRequired,
  translate: func.isRequired,
  numberFormat: string.isRequired,
  currency: string.isRequired,
  data: chartDataProps.isRequired,
  units: string.isRequired,
  shouldDisplayChartsSideBySide: bool.isRequired
};

const mapStateToProps = state => {
  const { number_format: numberFormat, currency } = state.client;

  return {
    numberFormat,
    currency
  };
};
export default connect(mapStateToProps)(ChartComponent);
