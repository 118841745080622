import * as types from './actionTypes';

const removeStyleToBody = () => {
  if (window.innerWidth < 1300) document.body.style.overflow = '';
};

const addStyleToBody = () => {
  if (window.innerWidth < 1300) document.body.style.overflow = 'hidden';
};

export const toggleMenu = () => (dispatch, getState) => {
  const {
    menu: { isOpen }
  } = getState();

  if (isOpen) {
    removeStyleToBody();
  } else {
    addStyleToBody();
  }

  return dispatch({
    type: types.TOGGLE_MENU
  });
};

export const closeMenu = () => {
  removeStyleToBody();

  return {
    type: types.CLOSE_MENU
  };
};

export const openMenu = () => {
  addStyleToBody();
  return {
    type: types.OPEN_MENU
  };
};
