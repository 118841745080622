import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import { string } from 'prop-types';
import { Translate } from 'react-localize-redux';

import { parentPanelType } from '../../../types';
import { ParentItem } from '../../SelectorItem';
import { MapScreenContext, RowContext } from '../../../../context';

export const ResetOptionContent = ({ parentName }) => (
  <div className="Icon-container">
    {parentName === null && <Icon name="check" />}
  </div>
);

ResetOptionContent.propTypes = {
  parentName: string
};

ResetOptionContent.defaultProps = {
  parentName: null
};

export const ParentPanel = ({
  data,
  selectedOption,
  closeModal,
  toggleLoading
}) => (
  <div className="ParentPanel-container">
    {data.map(item => (
      <ParentItem
        key={item.id}
        active={selectedOption.parentName === item.name}
        text={item.name}
      />
    ))}
    <MapScreenContext.Consumer>
      {({ onSelectNewKboAccountItem }) => (
        <Fragment>
          <RowContext.Consumer>
            {({ rowData }) => (
              <button
                className="SectionItem"
                type="button"
                onClick={async () => {
                  toggleLoading();

                  await onSelectNewKboAccountItem({
                    hasChildren: rowData.children.length > 0,
                    closeDropdown: closeModal,
                    sourceMappingData: rowData,
                    account: null,
                    toggleInnerLoading: toggleLoading
                  });
                }}
              >
                <div className="Left">
                  <ResetOptionContent parentName={selectedOption.parentName} />
                  <p>
                    <Translate id="map.selector.null" />
                  </p>
                </div>
              </button>
            )}
          </RowContext.Consumer>
        </Fragment>
      )}
    </MapScreenContext.Consumer>
  </div>
);

ParentPanel.propTypes = parentPanelType;
