import React from 'react';
import PropTypes from 'prop-types';

import { TranslateContext } from '../../../../components';
import Economic from './Economic';
import Virk from './Virk';
import Caseware from './Caseware';

export const Cards = ({ integrations, language, dispatch }) => (
  <TranslateContext.Consumer>
    {({ translate }) => (
      <div className="Cards-container">
        {integrations.map(item => {
          switch (item.id) {
            case 'e-conomic':
              return (
                <Economic
                  key={item.id}
                  language={language}
                  translate={translate}
                />
              );
            case 'virk':
              return (
                <Virk key={item.id} language={language} translate={translate} />
              );
            case 'fsr-caseware':
              return (
                <Caseware
                  key={item.id}
                  dispatch={dispatch}
                  translate={translate}
                />
              );

            default:
              return null;
          }
        })}
      </div>
    )}
  </TranslateContext.Consumer>
);

Cards.propTypes = {
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  language: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default Cards;
