import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import format from 'date-fns/format';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import * as actions from '../actions';
import './index.css';

export class Datepicker extends React.Component {
  static propTypes = {
    // Text above the input field.
    label: PropTypes.string.isRequired,
    // Text below the input field.
    subLabel: PropTypes.string,
    // Name of the input field. ex: postalCode.
    name: PropTypes.string.isRequired,
    // Data relating to the company's details.
    client: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    // action dispatched to update props upon keydown.
    updateClientDataOnInput: PropTypes.func.isRequired,
    currentLanguage: PropTypes.string.isRequired
  };

  static defaultProps = {
    subLabel: null
  };

  state = { value: this.props.client[this.props.name] };

  getLocale = () => {
    const { currentLanguage } = this.props;
    return currentLanguage;
  };

  handleChange = date => {
    const { name: inputName, updateClientDataOnInput } = this.props;
    // The date format is required to be DD/MM

    const inputValue = format(date, 'dd/MM');
    this.setState({ value: inputValue });
    updateClientDataOnInput(inputName, inputValue);
  };

  render() {
    const { translate, label, subLabel } = this.props;
    const { value } = this.state;

    return (
      <div className="DatePicker-container">
        <div className="DatePicker-field">
          <label htmlFor="DatePicker-wrapper">{translate(label)}</label>
          <Input>
            <ReactDatePicker
              dateFormatCalendar="MMMM"
              id="DatePicker-wrapper"
              locale={this.getLocale()}
              value={value}
              onChange={this.handleChange}
            />
          </Input>
          <div className="DatePicker-subLabel">{translate(subLabel)}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.createClient.client,
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code
});

export default connect(
  mapStateToProps,
  actions
)(Datepicker);
