import { interceptors } from 'axios';
import store from '../../../store';

export default function requestHandler() {
  interceptors.request.use(
    config => {
      // Since the token may get updated
      // we need a new instance of the state
      const newConfig = { ...config };

      newConfig.headers.Authorization = store.getState().auth.token;

      return newConfig;
    },
    error =>
      // Do something with request error
      Promise.reject(error)
  );
}
