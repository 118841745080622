import React, { PureComponent } from 'react';
import { object, array, number, func } from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { SECTION_REPORTS } from '../../../services/navigation';
import Screen from '../../../components/Screen';
import Main from './Main';

import './index.css';

import AnalysisLocale from '../locale.json';
import ForecastLocale from './locale.json';
import CellsLocale from '../../../components/Cell/locale.json';

export class Forecast extends PureComponent {
  static propTypes = {
    currentLocation: object.isRequired,
    sections: array,
    fiscalYear: number,
    translate: func.isRequired
  };

  static defaultProps = {
    sections: [],
    fiscalYear: undefined
  };

  generateLocales = () => ({
    ...CellsLocale,
    ...ForecastLocale,
    ...AnalysisLocale
  });

  render() {
    const { currentLocation, fiscalYear, sections, translate } = this.props;

    return (
      <Screen
        showDownloadButton
        showRightSideNavigation
        currentLocation={currentLocation}
        locale={this.generateLocales()}
        section={SECTION_REPORTS}
      >
        <Main
          currentLocation={currentLocation}
          fiscalYear={fiscalYear}
          sections={sections}
          translate={translate}
        />
      </Screen>
    );
  }
}

const mapStateToProps = ({ forecast, router: { location }, locale }) => ({
  currentLocation: location,
  sections: forecast.sections,
  isLoading: forecast.isLoading,
  fiscalYear: forecast.fiscalYear,
  translate: getTranslate(locale)
});

export default connect(mapStateToProps)(Forecast);
