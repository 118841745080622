import React, { Component, Fragment } from 'react';
import isEqual from 'react-fast-compare';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { saveDataImportSession } from '../actions';
import { LoadingSpinner } from '../../../components';
import { FEATURE_CAP } from '../../../helpers/constants';

import { INTEGRATION_LIST } from '../../../services/navigation';
import { determineImageProperties, routeWithClientId } from '../../../helpers';
import { updateDataImportSession } from '../backend/requests';

import { convertYearsFormat } from '../helpers/conversions';
import { IntegrationsContainer } from '../container';

import { SelectYearsComponentPropTypes } from './types';
import Years from './Years';
import { NoYears } from './NoYears';
import './index.css';

class SelectYearsComponent extends Component {
  static propTypes = SelectYearsComponentPropTypes;

  state = {
    isComponentLoading: false
  };

  integration = this.props.match.params.integration;

  shouldComponentUpdate = (prevProps, prevState) =>
  !(isEqual(prevProps, this.props) && isEqual(prevState, this.state));

  toggleComponentLoading = () =>
    this.setState(prevState => ({
      isComponentLoading: !prevState.isComponentLoading
    }));

  storeSession = data => {
    const {
      saveDataImportSession: saveDataImportSessionFromProps
    } = this.props;
    saveDataImportSessionFromProps(data);
    this.toggleComponentLoading();
  };

  submitYears = async ({ years }) => {
    const { goTo, dataImportSession } = this.props;
    const { id } = dataImportSession;

    this.toggleComponentLoading();

    const { data } = await updateDataImportSession({ id, years });

    this.storeSession(data);
    this.toggleComponentLoading();

    if (FEATURE_CAP) {
      if (this.integration === "virk") {
        return goTo(`${INTEGRATION_LIST}/import/${this.integration}/overview`);
      }
      return goTo(`${INTEGRATION_LIST}/import/${this.integration}/plan`);
    }
    return goTo(`${INTEGRATION_LIST}/import/${this.integration}/success`);
  };

  render() {
    const { isComponentLoading } = this.state;
    const { dataImportSession, name } = this.props;
    const sanitizedYears = convertYearsFormat(dataImportSession.years);
    const { src, width } = determineImageProperties(this.integration);

    return (
      <IntegrationsContainer
        integration={this.integration}
        render={({ goTo, id, translate }) => (
          <div className="SelectYears">
            {/**
             * isComponentLoading is for the PUT performed here.
             * an id is always necessary to show the screen.
             */}
            {isComponentLoading || !id ? (
              <LoadingSpinner
                text={`${this.integration}.selectYears.loadingYears.text`}
                title={`${this.integration}.selectYears.loadingYears.title`}
              />
            ) : (
              <Fragment>
                <div className="logowrapper">
                  <img alt="logo" src={src} width={width} />
                </div>

                <div className="SelectYears-ImportData-years">
                  <h2>{translate('selectYears.title')}</h2>

                  {sanitizedYears.length > 0 ? (
                    <Years
                      key={sanitizedYears}
                      goTo={goTo}
                      integration={this.integration}
                      name={name}
                      submitYears={this.submitYears}
                      translate={translate}
                      years={sanitizedYears}
                    />
                  ) : (
                    <NoYears
                      goTo={goTo}
                      integration={this.integration}
                      name={name}
                      translate={translate}
                    />
                  )}
                </div>
              </Fragment>
            )}
          </div>
        )}
        type="medium"
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  goTo: route => dispatch(push(routeWithClientId(route))),
  saveDataImportSession: data => dispatch(saveDataImportSession(data))
});

const mapStateToProps = ({ client, dataImportSession }) => ({
  name: client.name || '',
  dataImportSession
});

export const SelectYears = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectYearsComponent);
