import React from 'react';

import { FeedbackPanelButtonPropTypes } from './types';

import './FeedbackButton.css';

export const FeedbackButton = ({ showFeedbackPanel, text }) => (
  <button
    className="FeedbackButton"
    type="button"
    onClick={() => showFeedbackPanel(true)}
  >
    {text}
  </button>
);

FeedbackButton.propTypes = FeedbackPanelButtonPropTypes;
