import React from 'react';
import { Table } from 'semantic-ui-react';

import { TableBodyPropTypes } from '../types';

import { TableRowDispatcher } from './TableRowDispatcher';
import './index.css';

export const TableBody = ({
  rows,
  translate,
  numberFormat,
  toggleSidePanel
}) => (
  <Table.Body>
    <TableRowDispatcher
      numberFormat={numberFormat}
      rows={rows}
      toggleSidePanel={toggleSidePanel}
      translate={translate}
    />
  </Table.Body>
);

TableBody.propTypes = TableBodyPropTypes;
