export function sanitizeMappableDataResponse(response) {
  const {
    mappable,
    unmapped_count: unmappedCount,
    overview,
    mapping_completed: mappingCompleted
  } = response.data;

  if (!mappable || unmappedCount === undefined || !overview) {
    const error = `Response data is not being parsed correctly, when hitting the following endpoint: ${
      response.request.responseURL
    }\n\n`;
    throw new Error(error);
  }

  return {
    data: mappable,
    unmappedCount,
    mappingCompleted,
    overview
  };
}

export function sanitizeAccountName(account) {
  return {
    childName: account.child_name,
    parentName: account.parent_name
  };
}
