import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTranslate, addTranslation } from 'react-localize-redux';

import { INPUT_DATA_ROUTE_NAME } from '../../services/navigation';
import icon from './icon-missing_data.svg';

import ErrorCardLocale from './locale.json';
import InfoCard from '../InfoCard';

class ReportUnavailable extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    redirect: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    redirect: null
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.dispatch(addTranslation(ErrorCardLocale));
  }

  render() {
    const { translate, searchQuery, redirect } = this.props;
    return (
      <InfoCard
        buttonText="reportUnavailable.mainButton"
        icon={icon}
        redirect={redirect}
        text="reportUnavailable.body"
        title="reportUnavailable.title"
        translate={translate}
        url={`${INPUT_DATA_ROUTE_NAME}${searchQuery}`}
      />
    );
  }
}

const mapStateToProps = state => ({
  searchQuery: state.router.location.search,
  translate: getTranslate(state.locale),
  location: state.router.location
});

export default connect(mapStateToProps)(ReportUnavailable);
