import React from 'react';
import PropTypes from 'prop-types';

import ButtonSubmit from '../../../components/ButtonSubmit';
import LoadingSpinner from '../../../components/LoadingSpinner';

import DisplayToken from './DisplayToken';

const Main = ({
  status,
  token,
  disabledInput,
  tokenID,
  onClickConfirmDeleteTokenButton
}) => {
  switch (status) {
    case 'hasToken':
    case 'copiedToken':
      return (
        <DisplayToken
          disabledInput={disabledInput}
          status={status}
          token={token}
          tokenID={tokenID}
          onClickConfirmDeleteTokenButton={onClickConfirmDeleteTokenButton}
        />
      );

    case 'noToken':
      return <ButtonSubmit text="caseware.generateToken.buttonGenerate" />;

    default:
      return <LoadingSpinner />;
  }
};

Main.propTypes = {
  status: PropTypes.string,
  token: PropTypes.string,
  disabledInput: PropTypes.bool,
  tokenID: PropTypes.number,
  onClickConfirmDeleteTokenButton: PropTypes.func.isRequired
};

Main.defaultProps = {
  status: undefined,
  token: undefined,
  disabledInput: true,
  tokenID: undefined
};

export default Main;
