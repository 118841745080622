import { getTranslate } from 'react-localize-redux';
import store from '../../../store';

export function prepareURLParameters(valueDriverX, valueDriverY) {
  if (!valueDriverX || !valueDriverY) return '';
  return `?value_driver_x=${valueDriverX}&value_driver_y=${valueDriverY}`;
}

export function prepareURL(clientID, valueDriverX, valueDriverY) {
  const parameters = prepareURLParameters(valueDriverX, valueDriverY);
  return `/clients/${clientID}/reports/valuation_report/section/sensitivity_analysis${parameters}`;
}

export function prepareResponse(response, table) {
  const sensitivityAnalysis = response.data.sensitivity_analysis;
  return {
    clientID: sensitivityAnalysis.client_id,
    fiscalYear: sensitivityAnalysis.fiscal_year,
    valueDriversOptions: sensitivityAnalysis.value_drivers,
    tableData: {
      [`${table}`]: {
        isLoading: false,
        rows: sensitivityAnalysis.rows,
        valueDriverX: sensitivityAnalysis.value_driver_x,
        valueDriverY: sensitivityAnalysis.value_driver_y
      }
    }
  };
}

function isValueDriverRepeated(driverX, driverY, value, axis) {
  if (axis === 'Y') return value === driverX;
  if (axis === 'X') return value === driverY;
  return false;
}

/**
 * ValueDrivers have a format when sent from the backend
 * that needs to be converted to the dropdown options format,
 * so it can populate the dropdown.
 *
 * Localisation also happens at this level.
 * @param {array} valueDrivers
 * @return {object}
 */
export function convertResponseOptionsToDropdownOptionsFormat(
  valueDrivers,
  table,
  axis
) {
  const state = store.getState();
  const translate = getTranslate(state.locale);
  return valueDrivers.map(value => ({
    key: value,
    text: translate(`sensitivityAnalysis.${value}`),
    value,
    disabled: isValueDriverRepeated(
      state.valuationReport.sensitivityAnalysis[`${table}`].valueDriverX,
      state.valuationReport.sensitivityAnalysis[`${table}`].valueDriverY,
      value,
      axis
    )
  }));
}
