import React from 'react';
import { Row } from '../Row';
import { GroupType } from '../types';
import './index.css';

const Group = ({ isRoot, data }) => (
  <div className="Group-container">
    {data.map((item, index) => (
      <Row
        key={item.source_mapping_key}
        childrenData={item.children}
        index={index}
        isRoot={isRoot}
        rowData={item}
        value={item.value}
        {...item}
      />
    ))}
  </div>
);

Group.propTypes = GroupType;

export { Group };
