import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';

import { updateUserLanguage } from '../App/actions';
import { INIT_ROUTE_NAME } from '../../services/navigation';

import LanguageSelector from './LanguageSelector';
import UserOptions from './UserOptions';
import { headerType } from './types';

import './index.css';
import logo from './logo_k.svg';

export class Header extends PureComponent {
  setLocale = locale => {
    const { dispatch } = this.props;

    dispatch(updateUserLanguage(locale));
  };

  /**
   * When a success/error notification is triggered and displayed,
   * it should change the header top border color,
   * depending on the error.
   *
   * Position 0 refers to the most recently displayed notification.
   */
  showNotificationClass = notifications =>
    notifications.length > 0 ? `Header-${notifications[0].level}` : null;

  render() {
    const { notifications, translate, auth, currentLocale } = this.props;

    return (
      <div
        className={`Header-container ${this.showNotificationClass(
          notifications
        )}`}
      >
        <div className="Header-left-container">
          <div className="Header-logo">
            <Link to={INIT_ROUTE_NAME}>
              <img alt="Karnov Group" src={logo} />
            </Link>
          </div>
        </div>
        <div className="Header-right-container">
          <LanguageSelector
            currentLocale={currentLocale}
            setLocale={this.setLocale}
            translate={translate}
          />

          {auth.sub.name && auth.sub.company_name ? (
            <UserOptions auth={auth} translate={translate} />
          ) : null}
        </div>
      </div>
    );
  }
}

Header.propTypes = headerType;
Header.defaultProps = {
  auth: {},
  notifications: []
};

const mapStateToProps = state => ({
  search: state.router.location.search,
  translate: getTranslate(state.locale),
  currentLocale: getActiveLanguage(state.locale).code,
  auth: state.auth,
  notifications: state.notifications
});

export default connect(mapStateToProps)(Header);
