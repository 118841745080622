import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { localize } from 'react-localize-redux';

import Confirmation from '../Confirmation';

export const ActionButtons = ({ translate, edit, id, createdAt, ...props }) => {
  const createdAtDate = new Date(createdAt);

  return (
    <ul className="Comments-container Comments-list">
      <li>
        <div className="Comments-timestamp">
          {format(createdAtDate, 'dd.MM.yy')}
        </div>
      </li>
      {!edit && (
        <li key={1} className="Comments-hidden-from-print">
          <button
            className="Comments-action Comments-edit-button"
            type="button"
            onClick={props.handleEdit}
          >
            {translate('comments.actions.edit')}
          </button>
        </li>
      )}
      {id && edit && (
        <li
          key={2}
          className="Comments-hidden-from-print Comments-delete-button-wrapper"
        >
          <Confirmation
            cancelButtonText={translate('comments.delete.no')}
            handleCancelButton={props.toggleModal}
            handleOkButton={() => props.handleDelete(id)}
            isModalOpen={props.isModalOpen}
            message={translate('comments.delete.message')}
            okButtonText={translate('comments.delete.yes')}
            title={translate('comments.delete.title')}
            trigger={
              <button
                className="Comments-action Comments-delete-button"
                type="button"
                onClick={props.toggleModal}
              >
                {translate('comments.delete.button')}
              </button>
            }
          />
        </li>
      )}
    </ul>
  );
};

ActionButtons.propTypes = {
  hasComment: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.number,
  edit: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  toggleModal: PropTypes.func,
  isModalOpen: PropTypes.bool
};

ActionButtons.defaultProps = {
  id: null,
  edit: false,
  handleEdit: undefined,
  isModalOpen: false,
  toggleModal: undefined
};

export default localize(ActionButtons, 'locale');
