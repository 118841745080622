import React, { Component } from 'react';
import { shape, string } from 'prop-types';
import { connect } from 'react-redux';

import { trackDataImportFail } from '../../../external/segment';
import { IntegrationsContainer } from '../../Integrations/container';
import { Main } from './Main';
import locale from '../locale.json';

export class Error424 extends Component {
  static propTypes = {
    vatin: string.isRequired,
    location: shape({
      state: shape({
        source: string,
        taxonomy: string
      }).isRequired
    }).isRequired,
    companyName: string.isRequired
  };

  componentDidMount() {
    const { vatin } = this.props;
    const { source, taxonomy } = this.props.location.state;

    trackDataImportFail({
      source,
      cvr: vatin,
      taxonomy
    });
  }

  render() {
    const { companyName } = this.props;
    const { source, errorMessage, errorTitle } = this.props.location.state;
    return (
      <IntegrationsContainer
        secondaryBackground
        locale={locale}
        render={({ goTo, translate }) => (
          <Main
            companyName={companyName}
            errorMessage={errorMessage}
            errorTitle={errorTitle}
            goTo={goTo}
            source={source}
            translate={translate}
          />
        )}
        shouldGenerateDataImportSession={false}
        type="medium"
      />
    );
  }
}

const mapStateToProps = state => ({
  companyName: state.client.name,
  vatin: state.client.vatin
});

export default connect(mapStateToProps)(Error424);
