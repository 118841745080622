import { get, put } from 'axios';
import { MAPPED } from '../Main/Tabs';

export const API_DATA_PATH = 'data_import_sessions';

/**
 * selectedTab will determine which enpoind will be hit.
 */
// eslint-disable-next-line
export function apiMapURL({ path, dataSessionId, year, selectedTab }) {
  let endpoint = 'unmapped';
  if (selectedTab === MAPPED) endpoint = 'map';

  if (!dataSessionId)
    throw new Error('Data session ID is required to generate a Map URL');

  const baseURL = `/${path}/${dataSessionId}/${endpoint}`;

  return year ? `${baseURL}?current_year=${year}` : baseURL;
}
/** *********************** GET ************************* */
/**
 * dataSessionId: used in the API url.
 * year: same
 * selectedTab: depending on the selected Tab,
 * the request is to either hit mapped or unmapped endpoints.
 */
export async function fetchData({ dataSessionId, year, selectedTab }) {
  const url = apiMapURL({
    path: API_DATA_PATH,
    dataSessionId,
    year,
    selectedTab
  });
  const response = await get(url);

  return response;
}

/**
 * End of GET.
 */

/** *********************** PUT ************************* */
function mergeSourceMappingData({ sourceMappingData, account }) {
  const { children, source_mapping_key: sourceMappingKey } = sourceMappingData;
  const result = { [sourceMappingKey]: account };

  return children.reduce(
    (acc, item) =>
      Object.assign(
        result,
        mergeSourceMappingData({ sourceMappingData: item, account })
      ),
    result
  );
}

export function preparePayloadForUpdate({ sourceMappingData, account }) {
  return {
    mapping_updates: {
      ...mergeSourceMappingData({ sourceMappingData, account })
    }
  };
}

export async function requestUpdateData({
  sourceMappingData,
  account,
  dataSessionId,
  selectedTab,
  year
}) {
  const payload = preparePayloadForUpdate({ sourceMappingData, account });
  const url = apiMapURL({
    path: API_DATA_PATH,
    dataSessionId,
    year,
    selectedTab
  });
  const response = await put(url, payload);

  return response;
}
