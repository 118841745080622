const userInputValidationErrorType = 'critical';

/**
 * Error to be displayed when a user precedes the value with a '='.
 * @param  {String} displayValue [value added by the user]
 * @return {Object}              [error message and type]
 */
export function userCantUseEqual(displayValue) {
  return {
    displayValue,
    error: {
      type: userInputValidationErrorType,
      message: 'cellError.notAFormula.message',
      title: 'cellError.notAFormula.title'
    }
  };
}

/**
 * Error to be displayed when a non-allowed character is inserted in the input field.
 * @param  {String} displayValue [value added by the user]
 * @return {Object}              [error message and type]
 */
export function userCantWriteNonAllowedCharacters(displayValue) {
  return {
    displayValue,
    error: {
      type: userInputValidationErrorType,
      message: 'cellError.textNotAllowed.message',
      title: 'cellError.textNotAllowed.title'
    }
  };
}

/**
 * Allowed characters in the input text field.
 * @param  {String}  displayValue [value added by the user]
 * @return {Boolean}              [Matching regex]
 */
export function isAllowedCharacter(displayValue) {
  return /^$|^([+-]?[\d\s.%,]+)$/.test(displayValue);
}
