import {
  object,
  shape,
  oneOf,
  string,
  arrayOf,
  integer,
  float,
  bool,
  number,
  func
} from 'prop-types';
import { ECONOMIC, VIRK, CASEWARE } from '../../../../helpers/constants';

const integrationType = {
  integration: oneOf([ECONOMIC, VIRK, CASEWARE])
};

const rowsType = {
  rows: arrayOf(object).isRequired
};

const yearsType = {
  years: arrayOf(
    shape({
      text: integer,
      value: integer,
      selected: bool,
      contains_data: bool
    })
  ).isRequired
};

export const BannerTypes = integrationType;
export const OverviewTypes = {
  match: shape({
    params: shape({
      ...integrationType
    }).isRequired
  }).isRequired,
  ...yearsType,
  overview: shape({
    year: number,
    totals: arrayOf(
      shape({
        name: string,
        type: string,
        style: string,
        cells: arrayOf(
          shape({
            display: string,
            error: shape({
              type: string,
              title: string,
              message: string
            }),
            id: integer,
            precision: integer,
            type: string,
            value: float
          })
        )
      })
    )
  })
};

export const TableTypes = {
  ...integrationType,
  ...rowsType,
  ...yearsType,
  selectedYear: number.isRequired,
  onSelectYear: func.isRequired,
  loading: bool
};
