export const REPORT_COMMENTS_GET_INIT = 'REPORT_COMMENTS_GET_INIT';
export const REPORT_COMMENTS_GET_SUCCESS = 'REPORT_COMMENTS_GET_SUCCESS';
export const REPORT_COMMENTS_GET_ERROR = 'REPORT_COMMENTS_GET_ERROR';

export const REPORT_COMMENT_POST_INIT = 'REPORT_COMMENT_POST_INIT';
export const REPORT_COMMENT_POST_SUCCESS = 'REPORT_COMMENT_POST_SUCCESS';
export const REPORT_COMMENT_POST_ERROR = 'REPORT_COMMENT_POST_ERROR';

export const REPORT_COMMENT_DELETE_INIT = 'REPORT_COMMENT_DELETE_INIT';
export const REPORT_COMMENT_DELETE_SUCCESS = 'REPORT_COMMENT_DELETE_SUCCESS';
export const REPORT_COMMENT_DELETE_ERROR = 'REPORT_COMMENT_DELETE_ERROR';

export const REPORT_COMMENT_PUT_INIT = 'REPORT_COMMENTS_PUT_INIT';
export const REPORT_COMMENT_PUT_SUCCESS = 'REPORT_COMMENT_PUT_SUCCESS';
export const REPORT_COMMENT_PUT_ERROR = 'REPORT_COMMENTS_PUT_ERROR';
