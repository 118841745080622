import React, { Fragment } from 'react';
import { string } from 'prop-types';

import { TranslateContext } from '..';

export const CardTitle = ({ integration, title, subtitle }) => (
  <TranslateContext.Consumer>
    {({ translate }) => (
      <Fragment>
        <h1>{translate(title, { integration })}</h1>
        <p>{translate(subtitle, { integration })}</p>
      </Fragment>
    )}
  </TranslateContext.Consumer>
);

CardTitle.propTypes = {
  // eslint-disable-next-line
  integration: string.isRequired,
  // eslint-disable-next-line
  title: string.isRequired,
  // eslint-disable-next-line
  subtitle: string.isRequired
};
