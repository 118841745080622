import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { getTranslate } from 'react-localize-redux';
import * as actions from '../../screens/Client/Edit/actions';
import { numberFormatOptions, NUMBER_FORMAT } from './constants';

export class SelectNumberFormat extends React.Component {
  static propTypes = {
    createClient: PropTypes.object,
    clientData: PropTypes.object,
    handleDropDownNumberFormatChange: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
  };

  static defaultProps = {
    createClient: {},
    clientData: {}
  };

  state = {
    options: numberFormatOptions,
    value: this.props.createClient.number_format
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.clientData.numberFormat });
  }

  handleChange(e, target) {
    e.preventDefault(e);

    this.setState({ value: target.value });
    this.props.handleDropDownNumberFormatChange(target.value);
  }

  render() {
    return (
      <div>
        <div className="DropdownInput-container">
          <div className="DropdownInput-field Settings-number-format">
            <label htmlFor="Dropdown-select-number">
              {this.props.translate('settings.numberFormat')}
            </label>
            <Dropdown
              search
              selection
              id="Dropdown-select-number"
              name={NUMBER_FORMAT}
              options={this.state.options}
              value={this.state.value}
              onChange={(e, target) => this.handleChange(e, target)}
            />
            <div className="DropdownInput-subLabel">
              {this.props.translate('settings.numberFormatSubLabelText')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  clientData: state.clientData,
  createClient: state.createClient.client
});

export default connect(
  mapStateToProps,
  actions
)(SelectNumberFormat);
