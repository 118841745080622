import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import { closeDefinitionsPanel } from '../services/middlewares';

export const getMiddlewares = history => [
  routerMiddleware(history),
  thunk,
  closeDefinitionsPanel()
];
