import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import DeleteToken from './DeleteToken';
import './DisplayToken.css';

const determineTextBasedOnTokenStatus = ({ status }) => {
  if (status === 'copiedToken') return 'caseware.generateToken.copied';
  return 'caseware.generateToken.copy';
};

const DisplayToken = ({
  token,
  status,
  disabledInput,
  onClickConfirmDeleteTokenButton,
  tokenID
}) => (
  <div className="DisplayToken-container">
    <div className="DisplayToken-token-input-area">
      <input
        className="DisplayToken-input"
        defaultValue={token}
        disabled={disabledInput}
        id="DisplayToken-input"
        type="text"
      />

      <button
        className={`DisplayToken-copy-button ${
          status === 'copiedToken' ? 'DisplayToken-copy-button-disabled' : null
        }`}
        type="submit"
      >
        <Translate id={determineTextBasedOnTokenStatus({ status })} />
      </button>
    </div>

    <DeleteToken
      tokenID={tokenID}
      onClickConfirmDeleteTokenButton={onClickConfirmDeleteTokenButton}
    />
  </div>
);

DisplayToken.propTypes = {
  token: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onClickConfirmDeleteTokenButton: PropTypes.func.isRequired,
  disabledInput: PropTypes.bool.isRequired,
  tokenID: PropTypes.number.isRequired
};

export default DisplayToken;
