import { NAME, CVR_NUMBER, ADDRESS, ZIP_CODE, CITY } from './constants';

export const urlForFetchingCVRdata = ({ cvr, country }) =>
  `${API_URL}/cvr_lookup/${country}/${cvr}`;

/* eslint-disable */
export const sanitizeResponseFromCVRDataFetch = ({ response }) => ({
  [NAME]:
    response.data.hits.hits[0]._source.Vrvirksomhed.virksomhedMetadata
      .nyesteNavn.navn,
  [CVR_NUMBER]: response.data.hits.hits[0]._source.Vrvirksomhed.cvrNummer,
  [ADDRESS]: `${
    response.data.hits.hits[0]._source.Vrvirksomhed.virksomhedMetadata
      .nyesteBeliggenhedsadresse.vejnavn
  } ${
    response.data.hits.hits[0]._source.Vrvirksomhed.virksomhedMetadata
      .nyesteBeliggenhedsadresse.husnummerFra
  }, ${
    response.data.hits.hits[0]._source.Vrvirksomhed.virksomhedMetadata
      .nyesteBeliggenhedsadresse.etage
  }`,
  [ZIP_CODE]:
    response.data.hits.hits[0]._source.Vrvirksomhed.virksomhedMetadata
      .nyesteBeliggenhedsadresse.postnummer,
  [CITY]:
    response.data.hits.hits[0]._source.Vrvirksomhed.virksomhedMetadata
      .nyesteBeliggenhedsadresse.postdistrikt,
})
/* eslint-enable */

export const sanitizeErrorResponseForDisplay = ({ data }) => {
  const fieldsWithErrors = data.errors.details;
  const reducer = (accumulator, currentValue) =>
    ({[currentValue]: { isInvalid: true }, ...accumulator});

  return Object.keys(fieldsWithErrors).reduce(reducer, {});
};
