import React from 'react';
import { Translate } from 'react-localize-redux';
import { Icon } from 'semantic-ui-react';

import { selectorItemType } from '../../types';
import { SelectorContext, MapScreenContext } from '../../../context';
import './index.css';

export const ChildItem = ({ text, active }) => (
  <MapScreenContext.Consumer>
    {({ onSelectNewKboAccountItem }) => (
      <SelectorContext.Consumer>
        {({ rowData, closeModal, toggleLoading }) => (
          <button
            className="SectionItem"
            type="button"
            onClick={async () => {
              toggleLoading();
              await onSelectNewKboAccountItem({
                hasChildren: rowData.children.length > 0,
                closeDropdown: closeModal,
                sourceMappingData: rowData,
                account: text,
                toggleInnerLoading: toggleLoading
              });
            }}
          >
            <div className="Left">
              <div className="Icon-container">
                {active ? <Icon name="check" /> : null}
              </div>
              <p>
                <Translate id={`map.selector.${text}`} />
              </p>
            </div>
          </button>
        )}
      </SelectorContext.Consumer>
    )}
  </MapScreenContext.Consumer>
);

ChildItem.propTypes = selectorItemType;
