import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
// shared
import Settings from '../../../../components/Settings';
// local
import TextInput from '../TextInput';
import Buttons from '../Buttons';
import DatePicker from '../DatePicker';
import * as actions from '../actions';
// styling
import './index.css';
import {
  NAME,
  CVR_NUMBER,
  CUSTOMER_ID,
  ADDRESS,
  CITY,
  ZIP_CODE,
  COUNTRY,
  FISCAL_YEAR_START,
  countryOptions
} from '../constants';

export class Form extends React.Component {
  static propTypes = {
    // Data relating to the company's details.
    client: PropTypes.object,
    // action dispatched to update props upon keydown.
    updateClientDataOnInput: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    translate: PropTypes.func.isRequired,
    onSubmitCreateClient: PropTypes.func.isRequired
  };

  static defaultProps = {
    client: {},
    isLoading: false
  };

  // The country options state is handled locally.
  state = {
    countryOptions: [
      {
        key: 'dk',
        value: 'dk',
        flag: 'dk',
        text: 'Denmark'
      },
      {
        key: 'se',
        value: 'se',
        flag: 'se',
        text: 'Sweden'
      },
      {
        key: 'no',
        value: 'no',
        flag: 'no',
        text: 'Norway'
      },
      {
        key: 'gb',
        value: 'gb',
        flag: 'gb',
        text: 'England'
      },
      {
        key: 'us',
        value: 'us',
        flag: 'us',
        text: 'United States'
      }
    ],
    searchText: ''
  };

  onSearchChange = (event, value) =>
    this.setState({
      searchText: value.searchQuery
    });

  /**
   * The country list dropdown is long, which causes performance issues.
   * In this method, the countryOptions are updated upon OPEN or CHANGE.
   */
  handleChangeCountry = (event, { value }) => {
    const { client, updateClientDataOnInput } = this.props;
    this.setState({
      countryOptions: [
        {
          key: value,
          value,
          flag: value,
          text: event.target.innerText
        }
      ]
    });

    const updatedData = Object.assign(client, { [COUNTRY]: value });

    updateClientDataOnInput(updatedData);
  };

  searchCountry = list => {
    const { searchText } = this.state;

    return list.filter(value =>
      value.text.toLowerCase().startsWith(searchText.toLocaleLowerCase())
    );
  };

  handleSubmit = async e => {
    const { onSubmitCreateClient } = this.props;
    e.preventDefault();
    await onSubmitCreateClient();
  };

  render() {
    const { translate, isLoading } = this.props;
    const {
      countryOptions: countryOptionsFromState,
      [COUNTRY]: country
    } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="Form-container">
          <div className="Form-content">
            <TextInput
              {...this.props}
              label="stamdataClient.companyName"
              name={NAME}
            />
            <TextInput
              {...this.props}
              label="stamdataClient.cvrNumber"
              name={CVR_NUMBER}
            />
            <TextInput
              {...this.props}
              showTooltip
              label="stamdataClient.customerId"
              name={CUSTOMER_ID}
            />
            <TextInput
              {...this.props}
              label="stamdataClient.address"
              name={ADDRESS}
            />
            <TextInput
              {...this.props}
              label="stamdataClient.postNumber"
              name={ZIP_CODE}
            />
            <TextInput
              {...this.props}
              label="stamdataClient.city"
              name={CITY}
            />
            <div className="DropdownInput-container">
              <div className="DropdownInput-field Dropdown-country">
                <label htmlFor="Dropdown-country-wrapper">
                  {translate('stamdataClient.country')}
                </label>
                <Dropdown
                  selection
                  defaultValue="dk"
                  id="Dropdown-country-wrapper"
                  name={COUNTRY}
                  options={countryOptionsFromState}
                  search={this.searchCountry}
                  value={country}
                  onChange={this.handleChangeCountry}
                  onOpen={() => this.setState({ countryOptions })}
                  onSearchChange={this.onSearchChange}
                />
              </div>
            </div>

            <DatePicker
              label="stamdataClient.fiscalYearStart"
              name={FISCAL_YEAR_START}
              subLabel="stamdataClient.fiscalYearStartSubLabel"
            />
            <div className="Form-settings-content-header">
              <h1>{translate('settings.sectionTitle')}</h1>
            </div>
            <Settings />
            <Buttons isLoading={isLoading} />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  client: state.createClient.client,
  isLoading: state.createClient.isLoading
});

export default connect(
  mapStateToProps,
  actions
)(Form);
