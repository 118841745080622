import React, {Fragment} from 'react';
import { SidePanelModuleItemContent } from './SidePanelModuleItemContent';
import { SidePanelModuleItemTypes } from './types';

export const SidePanelModuleItem = ({ title, content, type, imageUrl }) => (
  <Fragment>
    <h2>{title}</h2>
    {type === 'text' ? (
      <SidePanelModuleItemContent content={content} />
    ) : (
      <img alt="formula" src={imageUrl} />
    )}
  </Fragment>
);

SidePanelModuleItem.propTypes = SidePanelModuleItemTypes;
