import jwtDecode from 'jwt-decode';

export default function decodeToken(token) {
  try {
    return jwtDecode(token);
  } catch (err) {
    return null;
  }
}

const setWalkmeVariables = ({ token, isWalkmeEnabled, decoder }) => {
  if (!isWalkmeEnabled) {
    window.walkme_segments = {};
    return;
  }

  const decodedToken = decoder(token) || { sub: {} };
  const {
    sub: { language, per_id: perId, psp_id: pspId, permissions }
  } = decodedToken;
  const splitLocale = (language && language.split('-')) || [];
  const variables = {
    country: splitLocale[1],
    language: splitLocale[0],
    user_id: perId,
    customer_id: pspId,
    product_keys: permissions
  };

  window.walkme_segments = variables;
};

export { setWalkmeVariables };
