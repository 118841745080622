import React from 'react';
import { default as Report } from '../Management';

export default class ReportBudget extends React.PureComponent {
  render() {
    return (
      <Report title="budgetReport" viewName="budget_report" {...this.props} />
    );
  }
}
