import React from 'react';

import NotificationsComponent from '../Notifications';
import Routes from '../../services/navigation/Routes';

import { FeedbackPanel } from '../FeedbackPanel';
import { SidePanel } from '../SidePanel';

export const App = () => (
  <div className="full-height-container">
    <NotificationsComponent />
    <FeedbackPanel />
    <Routes />
    <SidePanel />
  </div>
);

export default App;
