import axios from 'axios';
import { getClientId } from '../../../services/navigation/routeMapping';
import { appHasErrorAfterFetch } from '../../../components/App/actions';
import {
  APP_INIT_FETCHING_INITIAL_DATA,
  APP_STOP_FETCHING_INITIAL_DATA
} from '../../../components/App/actionTypes';

import {
  configForRequestFetchValuationData,
  cleanUpResponse
} from './integrationValuation';

export const VALUATION_FETCH_DATA_INIT = 'VALUATION_FETCH_DATA_INIT';
export const VALUATION_FETCH_DATA_SUCCESS = 'VALUATION_FETCH_DATA_SUCCESS';
export const VALUATION_FETCH_DATA_ERROR = 'VALUATION_FETCH_DATA_ERROR';

export function errorFetchingData(error) {
  return dispatch => {
    dispatch(appHasErrorAfterFetch(error));
    // local actions
    return { type: VALUATION_FETCH_DATA_ERROR };
  };
}

export function successFetchValuationData(response) {
  return dispatch => {
    const sanitizedResponse = cleanUpResponse(response);
    dispatch({
      type: VALUATION_FETCH_DATA_SUCCESS,
      payload: { ...sanitizedResponse }
    });
    dispatch({ type: APP_STOP_FETCHING_INITIAL_DATA });
  };
}

export function fetchValuationData() {
  return (dispatch, getState) => {
    const clientID = getClientId(getState().router.location);
    dispatch({ type: APP_INIT_FETCHING_INITIAL_DATA });
    dispatch({ type: VALUATION_FETCH_DATA_INIT });
    axios(configForRequestFetchValuationData(clientID))
      .then(response => {
        dispatch(successFetchValuationData(response));
      })
      .catch(error => {
        dispatch(errorFetchingData(error));
      });
  };
}
