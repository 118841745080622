import React from 'react';
import { Table } from 'semantic-ui-react';
import { TableRow } from '../TableRow';
import { TableDivider } from '../TableDivider';

export const TableRowDispatcher = ({
  rows,
  translate,
  numberFormat,
  toggleSidePanel
}) =>
  rows.map((row, rowIndex) => {
    let key = `row-${row.name}-${rowIndex}`;

    switch (row.type) {
      case 'divider':
        key = `row-divider-${row.name}-${rowIndex}`;
        return <TableDivider key={key} row={row} rowIndex={rowIndex} />;

      case 'label':
        return (
          <Table.Row key={key} className="TableRow TableRow-label">
            <Table.Cell>{translate(row.name)}</Table.Cell>
          </Table.Row>
        );

      case 'spacer':
        return <Table.Row key={key} className="TableRow TableRow-spacer" />;

      default:
        return (
          <TableRow
            key={key}
            hasDefinition={row.hasDefinition}
            numberFormat={numberFormat}
            row={row}
            rowIndex={rowIndex}
            sidePanelId={row.sidePanelId}
            style={row.style}
            toggleSidePanel={toggleSidePanel}
            translate={translate}
          />
        );
    }
  });
