import React from 'react';
import PropTypes from 'prop-types';
import { localize } from 'react-localize-redux';

import './index.css';
import logo from './logo_karnov.svg';

export const Footer = ({ translate }) => (
  <footer className="Footer-container">
    <div className="Footer-leftside">
      <div className="Footer-logo">
        <a href={WWW_URL}>
          <img alt="Karnov logo" src={logo} />
        </a>
      </div>
      <div className="Footer-link">
        <a
          className="Footer-link"
          href={`${KG_PRO_URL}/tos_acceptance`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {translate('footer.userConsent')}
        </a>
      </div>
    </div>
    <div className="Footer-rightside">
      <a className="Footer-link" href="tel:+4533740700">
        <i className="mobile icon" />
        33 74 07 00
      </a>
      <a className="Footer-link" href="mailto:kundeservice@karnovgroup.com">
        <i className="mail icon" />
        kundeservice@karnovgroup.com
      </a>
    </div>
  </footer>
);

Footer.propTypes = {
  translate: PropTypes.func.isRequired
};

export default localize(Footer, 'locale');
