import React, { Component } from 'react';
import { shape, oneOf, number } from 'prop-types';
import { connect } from 'react-redux';
import { confirmDataImportSession } from '../backend/requests';
import successImage from './complete.svg';
import { TranslateContext, ButtonRedirect } from '../../../components';
import { trackDataImportSuccess } from '../../../external/segment';
import { ECONOMIC, VIRK, CASEWARE } from '../../../helpers/constants';
import { IntegrationsContainer } from '../container';
import {
  removeFromSessionStorage,
  DATA_SESSION_IMPORT_ID
} from '../../../services/sessionStorage';

import './index.css';

export class SuccessComponent extends Component {
  static propTypes = {
    match: shape({
      params: shape({
        integration: oneOf([ECONOMIC, VIRK, CASEWARE])
      })
    }).isRequired,
    id: number.isRequired
  };

  componentDidMount = async () => {
    await confirmDataImportSession(this.props.id);
    // removal of session data because the import is complete
    removeFromSessionStorage(DATA_SESSION_IMPORT_ID);
    trackDataImportSuccess({ source: this.props.match.params.integration });
  };

  render() {
    return (
      <IntegrationsContainer
        render={() => (
          <TranslateContext>
            {({ translate }) => (
              <div className="Success-container">
                <div className="Transition-container">
                  <img alt="Success importing data" src={successImage} />
                  <h2>{translate('e-conomic.success.title')}</h2>
                  <p className="Success__subtitle">
                    {translate('e-conomic.success.subTitle')}
                  </p>
                </div>
                <ButtonRedirect redirect={['actual']} translate={translate} />
              </div>
            )}
          </TranslateContext>
        )}
        shouldGenerateDataImportSession={false}
      />
    );
  }
}

const mapStateToProps = ({ dataImportSession }) => ({
  id: dataImportSession.id
});

export const Success = connect(
  mapStateToProps,
  null
)(SuccessComponent);
