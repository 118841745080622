import React from 'react';
import { Table, Sticky } from '../../../../components';

import BarChart from '../../../../components/Charts/BarChart';

export const Sections = ({ moduleBottom, data }) =>
  data.map((section, sectionIndex) => (
    <div key={`section-${section.name}`} className="Section-container">
      <div className="Whatif-section-container section-separator">
        <Table
          key={
            // eslint-disable-next-line
            `section-${section.name}-table-${sectionIndex}`
          }
          name={section.name}
          section={section}
          sectionIndex={sectionIndex}
        />

        {section.hasChart ? (
          <Sticky bottomElement={moduleBottom}>
            <BarChart
              key={`section-${section.name}-chart`}
              bottomElement={moduleBottom}
              section={section}
            />
          </Sticky>
        ) : null}
      </div>
    </div>
  ));
