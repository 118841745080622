import React from 'react';
import PropTypes from 'prop-types';

import ReportUnavailable from '../../components/Error/ReportUnavailable';
import UnauthorizedCard from '../../components/UnauthorizedCard';

const ScreenDispatcher = props => {
  const { dispatch, error, Component } = props;
  if (error) {
    const { redirect, trial_user: isTrial } = error.details;

    switch (error.status) {
      case 403:
        return (
          <UnauthorizedCard dispatch={dispatch} {...props} isTrial={isTrial} />
        );
      case 412:
        return <ReportUnavailable redirect={redirect} />;
      default:
        return null;
    }
  } else {
    return <Component {...props} />;
  }
};

ScreenDispatcher.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.shape({
    details: PropTypes.shape({
      redirect: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired
  }),
  Component: PropTypes.func
};

ScreenDispatcher.defaultProps = {
  error: null,
  Component: null
};

export default ScreenDispatcher;
