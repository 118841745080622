/* eslint-disable react/no-typos */
import React from 'react';
import { Button } from '@karnov/kui-deprecated';
import { string, func } from 'prop-types';

import { INTEGRATION_LIST } from '../../../../services/navigation';
import { InfoBanner } from '../../../../components';

import './index.css';

export const NoYears = ({ integration, goTo, translate }) => (
  <section className="NoYears">
    <div className="NoYears__banner">
      <InfoBanner
        integration={integration}
        subtitle="selectYears.noYears.banner.subtitle"
        title="selectYears.noYears.banner.title"
      />
    </div>

    <div className="NoYears__button">
      <Button
        text={translate('selectYears.noYears.button')}
        type="Primary"
        onClick={() => goTo(INTEGRATION_LIST)}
      />
    </div>
  </section>
);

NoYears.propTypes = {
  integration: string.isRequired,
  goTo: func.isRequired,
  translate: func.isRequired
};
