export const RETURN_URL_PATH = 'return_url_pathname';
export const RETURN_URL_SEARCH = 'return_url_search';
export const DATA_SESSION_IMPORT_ID = 'data_import_session_id';

export const getSessionStorageKey = key => {
  const normalizedKey = key && key.toUpperCase();
  return `KBO_SESSION_STORAGE_${normalizedKey}-${ENV}`;
};

export const setInSessionStorage = (key, value) => {
  const kboKey = getSessionStorageKey(key);

  window.sessionStorage.setItem(kboKey, value);

  return { [kboKey]: value };
};

export const getFromSessionStorage = key => {
  const kboKey = getSessionStorageKey(key);
  return window.sessionStorage.getItem(kboKey);
};

export const removeFromSessionStorage = key => {
  const kboKey = getSessionStorageKey(key);

  window.sessionStorage.removeItem(kboKey);
};
