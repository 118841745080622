import axios, { get, post, delete as axiosDelete } from 'axios';
import {
  prepareImportDataForBackend,
  sanitizedIntegrationName
} from '../helpers/conversions';

/**
 * Listing of integrations
 */
export const getIntegrationsListURL = `${API_URL}/integration_modules`;

/**
 * CREATE
 */

export const createDataImportSessionURL = clientId =>
  `/clients/${clientId}/data_import_sessions`;

export const generatePostPayload = (integration, token) => {
  const data = {
    integration_module_id: sanitizedIntegrationName(integration),
    data_import_session: { configuration: {} }
  };

  if (token) {
    data.data_import_session.configuration.agreement_token = token;
  }

  return data;
};

export const createDataImportSession = ({ integration, clientId, token }) => {
  const data = generatePostPayload(integration, token);

  return axios.post(createDataImportSessionURL(clientId), data, {
    baseURL: API_URL
  });
};

/**
 * GET
 */
export const getDataImportSession = (id, year) =>
  axios.get(
    `/data_import_sessions/${id}${year ? `?current_year=${year}` : ''}`
  );

/**
 * UPDATE
 */
export const updateDataImportSessionURL = id => `/data_import_sessions/${id}`;

export const updateDataImportSessionRequestBody = ({ data, id }) => ({
  method: 'PUT',
  url: updateDataImportSessionURL(id),
  baseURL: API_URL,
  data
});

export const updateDataImportSession = ({ id, years, accountPlan }) => {
  const data = prepareImportDataForBackend({ years, accountPlan });
  return axios(updateDataImportSessionRequestBody({ data, id }));
};

/**
 * CONFIRM
 */

export const confirmDataImportSession = id =>
  axios.post(`/data_import_sessions/${id}/confirm`);

/**
 * Caseware.
 */
const agreementTokenURL = `${API_URL}/agreement_tokens`;
export const fetchCasewareExistingTokens = () => get(agreementTokenURL);
export const generateNewToken = () =>
  post(agreementTokenURL, {
    source: 'fsr-caseware'
  });

const deleteTokenURL = id => `${API_URL}/agreement_tokens/${id}`;
export const deleteToken = id => axiosDelete(deleteTokenURL(id));
