import React, { PureComponent } from 'react';
import { func, bool } from 'prop-types';
import { addTranslation } from 'react-localize-redux';

import { InfoCardDetailed } from '../InfoCardDetailed';
import { TrialPromotion } from '../TrialPromotion';
import './index.css';

import ErrorCardLocale from '../Error/locale.json';
import PromotionLocale from '../TrialPromotion/locale.json';

export default class UnauthorizedCard extends PureComponent {
  static propTypes = {
    dispatch: func.isRequired,
    isTrial: bool
  };

  static defaultProps = {
    isTrial: false
  };

  componentDidMount() {
    const { dispatch, isTrial } = this.props;

    dispatch(addTranslation(ErrorCardLocale));

    if (isTrial) {
      dispatch(addTranslation(PromotionLocale));
    }
  }

  render() {
    const { isTrial } = this.props;

    return (
      <div className="Unauthorized-container full-height-container">
        {isTrial ? (
          <InfoCardDetailed
            accessFeatureTitle=""
            accessFooter="trial-promotion.access.footer"
            accessText=""
            accessTitle="trial-promotion.access.title"
            footer="trial-promotion.footer"
            modules={[
              'detailed-card.unauthorized.access.module1',
              'detailed-card.unauthorized.access.module2',
              'detailed-card.unauthorized.access.module3'
            ]}
            text="trial-promotion.text"
            title="trial-promotion.title"
          >
            {props => <TrialPromotion {...props} />}
          </InfoCardDetailed>
        ) : (
          <InfoCardDetailed
            buttons={[
              {
                text: 'detailed-card.unauthorized.info.button.text',
                url: 'detailed-card.unauthorized.info.button.url',
                primary: true
              }
            ]}
            modules={[
              'detailed-card.unauthorized.access.module1',
              'detailed-card.unauthorized.access.module2',
              'detailed-card.unauthorized.access.module3'
            ]}
          />
        )}
      </div>
    );
  }
}
