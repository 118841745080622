import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';

import { childPanelType } from '../../../types';
import { ChildItem } from '../../SelectorItem';

export const ChildPanel = ({
  onClickBackButton,
  data,
  selectedOption,
  parentName
}) => (
  <div className="ChildPanel-container">
    <button className="header" type="button" onClick={onClickBackButton}>
      <Icon name="chevron left" />
      <span>
        <Translate id={`map.selector.${parentName}`} />
      </span>
    </button>

    {data.find(item => item.name === parentName).children.map(item => (
      <ChildItem
        key={item.id}
        active={selectedOption.childName === item.name}
        text={item.name}
      />
    ))}
  </div>
);

ChildPanel.propTypes = childPanelType;
