/* eslint-disable react/no-typos */
import React from 'react';
import { func } from 'prop-types';
import { Button } from '@karnov/kui-deprecated';

import './index.css';

const ButtonsArea = ({ translate, onClickSubmitButton, handleCancel }) => (
  <div className="Map__action-buttons">
    <Button
      key="submit"
      text={translate('ButtonsArea.submit')}
      type="button"
      onClick={onClickSubmitButton}
    />
    <div className="Map__cancel">
      <Button text={translate('ButtonsArea.cancel')} onClick={handleCancel} />
    </div>
  </div>
);

ButtonsArea.propTypes = {
  translate: func.isRequired,
  onClickSubmitButton: func.isRequired,
  handleCancel: func.isRequired
};

export { ButtonsArea };
