import React, { Fragment } from 'react';

import { SidePanelTitleTypes } from './types';

export const SidePanelTitle = ({ title, description }) => (
  <Fragment>
    <h1>{title}</h1>
    <div dangerouslySetInnerHTML={{__html: description}} />
  </Fragment>
);

SidePanelTitle.propTypes = SidePanelTitleTypes;
