import axios from 'axios';
import { success } from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';

import {
  mappedRoute,
  getClientId
} from '../../services/navigation/routeMapping';
import {
  normalizeGetResponse,
  normalizeUpdatedResponse,
  preparePayload
} from './integration';
import { trackCommentAdded } from '../../external/segment';
import { getModuleId } from '../../helpers/location';
import { SECTION_REPORTS } from '../../services/navigation';

import * as types from './actionTypes';

export const showSuccessNotification = (dispatch, locale, message) => {
  const translate = getTranslate(locale);

  dispatch(
    success({
      title: translate('comments.success.title'),
      message: translate(message),
      position: 'tc'
    })
  );
};

const commentsApiUrl = (apiUrl, state, id) => {
  const viewName = mappedRoute(state);
  const clientId = getClientId(state.router.location);
  const url = `${apiUrl}/clients/${clientId}/reports/${viewName}/comments`;
  // when the comments have an id, append that to the url.
  return id ? `${url}/${id}` : url;
};

const initRequest = type => ({ type });

export const getCommentsData = () => (dispatch, getState) => {
  dispatch(initRequest(types.REPORT_COMMENTS_GET_INIT));
  const url = commentsApiUrl(API_URL, getState());

  return axios
    .get(url)
    .then(response =>
      dispatch({
        type: types.REPORT_COMMENTS_GET_SUCCESS,
        payload: normalizeGetResponse(response.data)
      })
    )
    .catch(e =>
      dispatch({
        type: types.REPORT_COMMENTS_GET_ERROR,
        payload: e.message
      })
    );
};

const successUpdateComment = (response, type) => (dispatch, getState) => {
  const { locale } = getState();

  dispatch({
    type,
    payload: normalizeUpdatedResponse(response.data)
  });

  showSuccessNotification(dispatch, locale, 'comments.success.message');

  return normalizeUpdatedResponse(response.data);
};

export const postComment = comment => (dispatch, getState) => {
  dispatch(initRequest(types.REPORT_COMMENT_POST_INIT));
  const state = getState();
  const clientId = state.client.id;
  const { location } = state.router;
  const moduleId = getModuleId({ section: SECTION_REPORTS, location });
  const url = commentsApiUrl(API_URL, state);

  return axios
    .post(url, preparePayload(comment))
    .then(response => {
      trackCommentAdded({ clientId, moduleId });
      return dispatch(
        successUpdateComment(response, types.REPORT_COMMENT_POST_SUCCESS)
      );
    })
    .catch(error =>
      dispatch({
        type: types.REPORT_COMMENT_POST_ERROR,
        payload: error.message
      })
    );
};

export const updateComment = comment => (dispatch, getState) => {
  dispatch(initRequest(types.REPORT_COMMENT_PUT_INIT));
  const url = commentsApiUrl(API_URL, getState(), comment.id);

  return axios
    .put(url, preparePayload(comment))
    .then(response =>
      dispatch(successUpdateComment(response, types.REPORT_COMMENT_PUT_SUCCESS))
    )
    .catch(error =>
      dispatch({
        type: types.REPORT_COMMENT_PUT_ERROR,
        payload: error.message
      })
    );
};

export const deleteComment = id => (dispatch, getState) => {
  dispatch(initRequest(types.REPORT_COMMENT_DELETE_INIT));
  const url = commentsApiUrl(API_URL, getState(), id);
  return axios
    .delete(url)
    .then(() => {
      const { locale } = getState();

      dispatch({
        type: types.REPORT_COMMENT_DELETE_SUCCESS,
        payload: id
      });

      showSuccessNotification(dispatch, locale, 'comments.delete.success');
    })
    .catch(error =>
      dispatch({
        type: types.REPORT_COMMENT_DELETE_ERROR,
        payload: error.message
      })
    );
};
