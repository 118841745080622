import React from 'react';
import { Table } from 'semantic-ui-react';

import { TableRowNamePropTypes } from '../types';

import { TableRowNameSubLabel } from './TableRowNameSubLabel';

export const TableRowName = ({
  row,
  rowIndex,
  hasDefinition,
  numberFormat,
  sidePanelId,
  translate,
  toggleSidePanel
}) => (
  <Table.Cell
    key={`cell-header${row.name}-${rowIndex}`}
    className={`TableCell-header ${
      hasDefinition ? 'TableCell__header--has-definition' : ''
    }`}
    data-key={sidePanelId}
    onClick={() => toggleSidePanel({ sidePanelId })}
  >
    {translate(row.name)}
    {row.label && (
      <TableRowNameSubLabel
        numberFormat={numberFormat}
        row={row}
        rowIndex={rowIndex}
        translate={translate}
      />
    )}
  </Table.Cell>
);

TableRowName.propTypes = TableRowNamePropTypes;
