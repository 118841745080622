import axios from 'axios';
import { success } from 'react-notification-system-redux';
import { push } from 'connected-react-router';
import { getTranslate } from 'react-localize-redux';
import { INIT_ROUTE_NAME } from '../../../services/navigation';

import { url, sanitizeResponseData, preparePayload } from './Integrations';
import { trackClientDeleted } from '../../../external/segment';

export const CLIENT_DATA_NUMBERFORMAT_UPDATE =
  'CLIENT_DATA_NUMBERFORMAT_UPDATE';
export const CLIENT_DATA_TOGGLE_MODAL_CONFIRMATION =
  'CLIENT_DATA_TOGGLE_MODAL_CONFIRMATION';
export const DELETE_SUCCESS_CLIENT_DATA = 'DELETE_SUCCESS_CLIENT_DATA';
export const DROPDOWN_VALUE_UPDATED_CLIENT_DATA =
  'DROPDOWN_VALUE_UPDATED_CLIENT_DATA';
export const INIT_FETCH_CLIENT_DATA = 'INIT_FETCH_CLIENT_DATA';
export const INIT_SUBMIT_CLIENT_DATA = 'INIT_SUBMIT_CLIENT_DATA';
export const RESET_FORM_CLIENT_DATA = 'RESET_FORM_CLIENT_DATA';
export const SUCCESS_FETCH_CLIENT_DATA = 'SUCCESS_FETCH_CLIENT_DATA';
export const SUCCESS_POST_CLIENT_DATA = 'SUCCESS_POST_CLIENT_DATA';

/**
 * Open/close confirmation modal.
 * @param {Boolean} value
 */
export function toggleConfirmationModal(value) {
  return {
    type: CLIENT_DATA_TOGGLE_MODAL_CONFIRMATION,
    payload: { isModalOpen: value }
  };
}

function initFetchData() {
  return {
    type: INIT_FETCH_CLIENT_DATA,
    payload: { isLoading: true }
  };
}

/**
 * GET call for client data.
 */
export function fetchClientData() {
  return (dispatch, getState) => {
    dispatch(initFetchData());
    const state = getState();
    const { location } = state.router;
    const urlOfGetCall = url(state, location);
    axios.get(urlOfGetCall).then(response => {
      // Mapping backend/frontend data.
      const data = sanitizeResponseData(response.data.client);
      return dispatch({
        type: SUCCESS_FETCH_CLIENT_DATA,
        payload: data
      });
    });
  };
}

function initSubmitData() {
  return {
    type: INIT_SUBMIT_CLIENT_DATA,
    payload: { isLoading: true }
  };
}

/**
 * Persisting changes to the company
 * @param {Object} localState
 */
export function submitDataChanges(localState) {
  return (dispatch, getState) => {
    dispatch(initSubmitData());
    const state = getState();
    const translate = getTranslate(state.locale);
    const urlOfPostCall = url(state);
    const payload = preparePayload(localState);

    axios({
      method: 'put',
      url: urlOfPostCall,
      data: payload
    }).then(response => {
      const data = sanitizeResponseData(response.data.client);

      dispatch(
        success({
          title: translate('edit.success.title'),
          message: translate('edit.success.message'),
          position: 'tc'
        })
      );
      return dispatch({
        type: SUCCESS_POST_CLIENT_DATA,
        payload: data
      });
    });
  };
}

/**
 * Clearing the form.
 */
export function resetForm() {
  return {
    type: RESET_FORM_CLIENT_DATA,
    payload: {
      companyName: '',
      cvrNumber: '',
      address: '',
      postNumber: '',
      fiscalYearStart: ''
    }
  };
}

/**
 * Deleting a company.
 */
export function deleteCompany() {
  return (dispatch, getState) => {
    const state = getState();
    const translate = getTranslate(state.locale);
    const clientId = state.client.id;
    const urlOfDeleteCall = url(state);

    return axios({
      method: 'delete',
      url: urlOfDeleteCall
    }).then(() => {
      dispatch({
        type: DELETE_SUCCESS_CLIENT_DATA,
        payload: resetForm()
      });
      trackClientDeleted({ clientId });
      dispatch(push(INIT_ROUTE_NAME));
      return dispatch(
        success({
          title: translate('delete.success.title'),
          message: translate('delete.success.message'),
          position: 'tc'
        })
      );
    });
  };
}

export function handleDropDownValueChange(value) {
  return {
    type: DROPDOWN_VALUE_UPDATED_CLIENT_DATA,
    payload: value
  };
}

export function handleDropDownNumberFormatChange(value) {
  return {
    type: CLIENT_DATA_NUMBERFORMAT_UPDATE,
    payload: value
  };
}
