import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTranslate } from 'react-localize-redux';
import ButtonRedirect from '../ButtonRedirect';

import defaultIcon from './icon-newcompany.svg';
import './index.css';

const MainButton = ({ url, buttonText, translate }) => (
  <div className="InfoCard-start-area-button">
    {url.startsWith('/') ? (
      <Link to={url}>
        <div className="InfoCard-start-area-button-text">
          {translate(buttonText)}
        </div>
      </Link>
    ) : (
      <a href={url}>
        <div className="InfoCard-start-area-button-text">
          {translate(buttonText)}
        </div>
      </a>
    )}
  </div>
);

MainButton.propTypes = {
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

export class InfoCard extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
    buttonText: PropTypes.string,
    icon: PropTypes.string,
    translate: PropTypes.func.isRequired,
    redirect: PropTypes.array
  };

  static defaultProps = {
    url: '',
    buttonText: '',
    icon: defaultIcon,
    showErrorMessages: false,
    redirect: null
  };

  render() {
    const {
      url,
      buttonText,
      icon,
      translate,
      text,
      title,
      redirect
    } = this.props;

    return (
      <div className="InfoCard-container main-container">
        <div className="InfoCard-start-area">
          <div className="InfoCard-icon">
            <img alt="icon-newcompany" src={icon} />
          </div>
          <div className="InfoCard-start-area-title">{translate(title)}</div>
          <div className="InfoCard-start-area-subtitle">{translate(text)}</div>
          {redirect ? (
            <ButtonRedirect redirect={redirect} translate={translate} />
          ) : (
            <MainButton
              buttonText={buttonText}
              translate={translate}
              url={url}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => ({ translate: getTranslate(state.locale) }))(
  InfoCard
);
