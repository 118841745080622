import { arrayOf, object, string, number, func, bool, shape } from 'prop-types';

const integration = string.isRequired;
const data = arrayOf(object).isRequired;

export const GroupType = {
  isRoot: bool
};

export const LeftTextType = {
  name: string.isRequired,
  label: string.isRequired,
  integration: string.isRequired
};

export const LeftType = {
  name: string.isRequired,
  label: string.isRequired,
  length: number.isRequired,
  toggleChildren: func.isRequired,
  isExpanded: bool,
  hasOriginalValueChanged: bool
};

export const ExpandButtonType = {
  length: number.isRequired,
  toggleChildren: func.isRequired,
  integration: string.isRequired,
  isExpanded: bool
};

export const RowType = {
  source_mapping_key: string.isRequired,
  source_mapping_label: string.isRequired,
  index: number.isRequired
};

export const MapScreenTypes = {
  match: shape({
    params: shape({
      integration: string.isRequired
    }).isRequired
  }).isRequired
};

export const MapScreenContentTypes = {
  integration,
  onClickSubmitButton: func.isRequired,
  handleCancel: func.isRequired,
  translate: func.isRequired,
  selectedTab: string.isRequired,
  isNotificationOpen: bool,
  getData: func.isRequired,
  isConfimationModalOpen: bool.isRequired,
  overviewYear: number,
  toggleConfirmationModal: func.isRequired,
  importSessionSelectedYears: arrayOf(
    shape({
      text: number.isRequired,
      value: number.isRequired,
      selected: bool.isRequired,
      contains_data: bool.isRequired
    }).isRequired
  ).isRequired
};

export const MainTypes = {
  integration,
  data,
  translate: func.isRequired
};

export const undoButtonTypes = {
  rowData: shape({
    source_mapping_key: string,
    children: arrayOf(object)
  }).isRequired,
  updateData: func.isRequired
};

export const DiscrepancyNotificationTypes = {
  onClickSubmitButton: func.isRequired
};
