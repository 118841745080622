import {
  ERROR_FETCH_DATA,
  INIT_FETCH_DATA,
  SUCCESS_FETCH_DATA,
  SUCCESS_FETCH_PYRAMID_DATA
} from './actions';

const initialState = {
  isLoading: false,
  isFetchingInitialData: false,
  error: null,
  allYears: [2013, 2014, 2015, 2016, 2017],
  sections: [],
  kpi: {
    name: 'kpi',
    subtitle: 'dkkm',
    hasChart: false,
    entries: []
  },
  pyramid: null
};

export default function reportManagementState(
  state = initialState,
  action = {}
) {
  const { payload } = action;

  switch (action.type) {
    case INIT_FETCH_DATA:
      return {
        ...state,
        ...payload
      };

    case SUCCESS_FETCH_PYRAMID_DATA:
      return {
        ...state,
        kpi: action.payload.kpiData,
        pyramid: action.payload.pyramidData
      };

    case SUCCESS_FETCH_DATA:
      return {
        ...state,
        allYears: payload.years,
        error: null,
        fiscal_year: payload.fiscal_year,
        isFetchingInitialData: false,
        sections: payload.sections,
        isLoading: false
      };

    case ERROR_FETCH_DATA:
      return {
        ...state,
        error: {
          status: payload.status,
          statusText: payload.statusText
        },
        isLoading: false,
        isFetchingInitialData: payload.isFetchingInitialData
      };

    default:
      return { ...state };
  }
}
