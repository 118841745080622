import * as types from './actionTypes';

const initialState = { comments: [] };

export default function commentsReducer(state = initialState, action = {}) {
  const { payload } = action;

  switch (action.type) {
    case types.REPORT_COMMENTS_GET_SUCCESS:
      return {
        ...state,
        comments: payload
      };
    case types.REPORT_COMMENT_POST_SUCCESS:
      return {
        ...state,
        comments: [...state.comments, action.payload]
      };
    case types.REPORT_COMMENT_PUT_SUCCESS:
      return {
        ...state,
        comments: state.comments.map(
          comment => (comment.id === payload.id ? payload : comment)
        )
      };
    case types.REPORT_COMMENT_DELETE_SUCCESS:
      return {
        ...state,
        comments: state.comments.filter(
          comment => comment.id !== action.payload
        )
      };
    default:
      return { ...state };
  }
}
