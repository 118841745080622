import React from 'react';
import PropTypes from 'prop-types';
import PrintButton from '../../../PrintButton';

const RightSide = ({ showDownloadButton, translate }) => (
  <div className="NavigationArea-right-side">
    {showDownloadButton ? <PrintButton translate={translate} /> : null}
  </div>
);

RightSide.propTypes = {
  showDownloadButton: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

RightSide.defaultProps = {
  showDownloadButton: false
};

export default RightSide;
