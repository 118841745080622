export const locale = {
  buttonOpen: {
    en: 'Feedback',
    da: 'Feedback'
  },
  panelMainTitle: {
    en: 'Help us improve our product',
    da: 'Hjælp os med at blive bedre'
  },
  panelMainDescription: {
    en:
      'We would like to know what you think about our services. If you wish to be contacted, make sure to type your phone number in the text.',
    da:
      'Vi vil meget gerne vide, hvad du synes om vores tjenester. Hvis du ønsker at blive kontaktet, så angiv gerne telefonnummer i teksten.'
  },
  panelSubmitButton: {
    en: 'Send',
    da: 'Send'
  },
  panelCloseButton: {
    en: 'Close',
    da: 'Luk'
  },
  panelTextArea: {
    en: 'Write you comment or question here',
    da: 'Skriv din kommentar eller dit spørgsmål her'
  }
};
