import React from 'react';

import { VIRK } from '../../../../../helpers/constants';
import { MapScreenContext } from '../../context';
import { LeftType, LeftTextType, ExpandButtonType } from '../../types';
import { UndoButton } from './UndoButton';

import plusIcon from './plus.svg';
import minusIcon from './minus.svg';
import './index.css';

const isVirk = integration => integration === VIRK;

/**
 * Because virk replaces 'label' with 'name'.
 */
const LeftText = ({ integration, name, label }) => {
  const label1 = isVirk(integration) ? label : name;
  const label2 = isVirk(integration) ? null : label;

  return (
    <div className="Row__text">
      <div className="Row__title">{label1}</div>
      <div className="Row__description">{label2}</div>
    </div>
  );
};

LeftText.propTypes = LeftTextType;

const ExpandButton = ({ length, integration, toggleChildren, isExpanded }) => {
  if (length <= 0) return null;

  return (
    <button
      className={`${isVirk(integration) ? '__expand--centered' : '__expand'}`}
      type="button"
      onClick={toggleChildren}
    >
      <img
        alt={isExpanded ? 'Collapse' : 'Expand'}
        className="plusIcon"
        src={isExpanded ? minusIcon : plusIcon}
      />
    </button>
  );
};

ExpandButton.propTypes = ExpandButtonType;

const Left = ({
  name,
  label,
  length,
  toggleChildren,
  isExpanded,
  hasOriginalValueChanged
}) => (
  <MapScreenContext.Consumer>
    {({ integration }) => (
      <div className="Row-leftarea">
        <div className="Row__text-wrapper">
          <ExpandButton
            integration={integration}
            isExpanded={isExpanded}
            length={length}
            toggleChildren={toggleChildren}
          />

          <LeftText integration={integration} label={label} name={name} />
        </div>
        {hasOriginalValueChanged && <UndoButton />}
      </div>
    )}
  </MapScreenContext.Consumer>
);

Left.propTypes = LeftType;

export { Left, LeftText, ExpandButton };
