import React from 'react';
import { Link } from 'react-router-dom';

import { flatNavigationOptions } from '../../services/navigation/options';

const ButtonRedirect = ({ redirect, translate }) =>
  redirect.map(value => {
    const { link, name } = flatNavigationOptions(value);

    return (
      <div
        key={`redirect-${value}`}
        className="InfoCard-start-area-button"
        id="InfoCard-redirect-button"
      >
        <Link to={link}>
          <div className="InfoCard-start-area-button-text">
            {translate(name)}
          </div>
        </Link>
      </div>
    );
  });

export default ButtonRedirect;
