import React from 'react';

import { SidePanelModuleItem } from './SidePanelModuleItem';
import { SidePanelModulesTypes } from './types';

export const SidePanelModules = ({ modules }) =>
  modules.map(item => (
    <SidePanelModuleItem
      key={item.title}
      content={item.content}
      imageUrl={item.imageUrl}
      title={item.title}
      type={item.type}
    />
  ));

SidePanelModules.propTypes = SidePanelModulesTypes;
