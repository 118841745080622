/* eslint-disable react/no-typos */

import React from 'react';
import { Button } from '@karnov/kui-deprecated';

import { func } from 'prop-types';

import { INPUT_DATA_ROUTE_NAME } from '../../../../services/navigation';

import './index.css';

export const SkipImport = ({ goTo, translate }) => (
  <div className="SkipImport">
    <Button
      text={translate('list.buttons.jumpTo')}
      onClick={() => goTo(INPUT_DATA_ROUTE_NAME)}
    />
    <p className="__text">{translate('list.buttons.helpText')}</p>
  </div>
);

SkipImport.propTypes = {
  goTo: func.isRequired,
  translate: func.isRequired
};
