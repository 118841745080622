import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addTranslation } from 'react-localize-redux';

import InfoCard from '../../../components/InfoCard';
import TopRow from '../../../components/TopRow';
import Footer from '../../../components/Footer';
import localeTopRow from '../../../components/TopRow/locale.json';
import localeFooter from '../../../components/Footer/locale.json';

import locale from '../locale.json';
import './index.css';

export class PageNotFound extends PureComponent {
  static propTypes = { dispatch: PropTypes.func.isRequired };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.dispatch(addTranslation(locale));
    this.props.dispatch(addTranslation(localeTopRow));
    this.props.dispatch(addTranslation(localeFooter));
  }

  render() {
    return (
      <div className="PageNotFound-container wrapper-container">
        <TopRow />
        <InfoCard
          buttonText="404.buttonText"
          text="404.textPageNotFound"
          title="404.titlePageNotFound"
          url="/"
        />
        <Footer />
      </div>
    );
  }
}

export default connect()(PageNotFound);
