import { put } from 'axios';
import { setActiveLanguage } from 'react-localize-redux';

import * as types from './actionTypes';

export const updateUserLanguage = locale => (dispatch, getState) => {
  const { auth } = getState();
  const id = auth.sub && auth.sub.id;

  // this should be moved to an external file and consumed whenever needed
  const localeWithRegion = {
    en: 'en-GB',
    da: 'da-DK'
  };

  dispatch({ type: types.APP_UPDATE_LANGUAGE_INIT });
  dispatch(setActiveLanguage(locale));

  return put(`${API_URL}/users/${id}`, {
    user: { language: localeWithRegion[locale] }
  })
    .then(() =>
      dispatch({
        type: types.APP_UPDATE_LANGUAGE_SUCCESS,
        payload: locale
      })
    )
    .catch(err =>
      dispatch({
        type: types.APP_UPDATE_LANGUAGE_ERROR,
        payload: err.message
      })
    );
};
/**
 * Fetching the user clients' data,
 * needed on every view of the app.
 */
export const updateClientData = client => ({
  type: types.APP_CLIENT_DATA_STATE_UPDATE,
  payload: client
});

/**
 * For views that depend on the data being validated before rendering any component.
 * For example, 412 on reports and simulation views.
 */
export const initFetchData = () => dispatch =>
  dispatch({ type: types.APP_INIT_FETCHING_INITIAL_DATA });

export const appHasErrorAfterFetch = response => {
  const { data } = response;
  const { errors } = data;

  return {
    type: types.APP_HAS_ERROR,
    payload: {
      error: { ...errors, status: response.status }
    }
  };
};

export const toggleDefinitionsPanel = ({ sidePanelId }) => ({
  type: types.APP_TOGGLE_DEFINITIONS_PANEL,
  payload: {
    sidePanelId
  }
});
