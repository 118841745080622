/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { get } from 'axios';
import { connect } from 'react-redux';
import { success } from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';

import TitleArea from '../../components/TitleArea';
import { Screen, LoadingSpinner, InfoBox } from '../../components';
import { getModuleId } from '../../helpers/location';
import { trackSaveButtonClick } from '../../external/segment';
import { getDataApiUrl } from './api/requests';
import { sanitizeResponseOfGetData } from './api/responses';
import { setGlobalState, wipeGlobalState } from './actions';
import InputDataLocale from './locale.json';
import {
  SECTION_INPUTS,
  INPUT_DATA_ROUTE_NAME
} from '../../services/navigation';

import { Sections } from './Sections';
import BottomButton from './BottomButton';
import { InputPropTypes } from './types';
import './index.css';

export class Input extends Component {
  static propTypes = InputPropTypes;

  state = {
    isLoading: false
  };

  componentDidMount() {
    const { location } = this.props;
    const apiURL = getDataApiUrl(location);

    this.getData(apiURL);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isLoading } = this.state;
    const {
      location: { search, pathname },
      sections
    } = this.props;

    // Enabling FETCH of data when switching Accounting Data to Budget Data tabs.
    if (
      search !== nextProps.location.search ||
      pathname !== nextProps.location.pathname
    ) {
      const apiURL = getDataApiUrl(location);
      this.getData(apiURL);
    }

    return sections !== nextProps.sections || isLoading !== nextState.isLoading;
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(wipeGlobalState);
  }

  updateStateAfterDataReceived = response => {
    const { dispatch } = this.props;

    dispatch(setGlobalState(sanitizeResponseOfGetData(response.data)));
    this.setState({ isLoading: false });
  };

  getData = async apiURL => {
    this.setState({ isLoading: true });
    const response = await get(apiURL);

    this.updateStateAfterDataReceived(response);
  };

  onSubmit = e => {
    e.preventDefault();
    const { location, dispatch, translate } = this.props;
    const inputData = getModuleId({ section: SECTION_INPUTS, location });
    this.setState({ isLoading: true });
    trackSaveButtonClick({ inputData });
    setTimeout(() => {
      this.setState({ isLoading: false });
      dispatch(
        success({
          title: translate('update.success.title'),
          message: translate('update.success.message'),
          position: 'tc'
        })
      );
    }, 1000);
  };

  render() {
    const {
      location: { pathname },
      dispatch,
      sections,
      years,
      currentFiscalYear,
      translate
    } = this.props;
    const { isLoading } = this.state;

    return (
      <Screen
        showDownloadButton
        showRightSideNavigation
        currentLocation={window.location}
        locale={InputDataLocale}
        section={SECTION_INPUTS}
      >
        {sections ? (
          <form className="InputData-main-container" onSubmit={this.onSubmit}>
            <InfoBox
              text={`${pathname}.requirements`}
              title={`${pathname}.requirementsTitle`}
            />

            <TitleArea
              showDropDown
              allYears={years}
              dispatch={dispatch}
              importButtonText={translate(
                '/input_data.importData.importDataButton'
              )}
              isLoading={isLoading}
              location={location}
              selectedYear={currentFiscalYear}
              showImportButton={pathname === INPUT_DATA_ROUTE_NAME}
              title={`${pathname}.title`}
            />
            <Sections sections={sections} />

            <BottomButton isLoading={isLoading} text="save" />
          </form>
        ) : (
          <LoadingSpinner />
        )}
      </Screen>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  sections: state.inputData.sections,
  years: state.inputData.years,
  currentFiscalYear: state.inputData.currentFiscalYear
});

export default connect(mapStateToProps)(Input);
