import React from 'react';
import PropTypes from 'prop-types';
import { localize } from 'react-localize-redux';
import { Table } from '../../../components';
import {
  getClassForSideBySideChart,
  ChartWrapper
} from '../../../components/Charts/LineChartWrapper';
import './Section.css';

export function Section({ section, translate }) {
  const chartPositionClassName = getClassForSideBySideChart(section);
  return (
    <div className="Section-container section-separator">
      <Table name={section.name} section={section} />
      {/*
       * Section-container-chart-placeholder fixes Chrome's weird behaviour on PDF.
       * DO NOT REMOVE.
       */}
      <div className="Section-container-chart-placeholder">
        <div className={`Chart-container ${chartPositionClassName}`}>
          {section.hasChart &&
            section.chartData.map(item =>
              ChartWrapper({ section, translate, item })
            )}
        </div>
      </div>
    </div>
  );
}

Section.propTypes = {
  section: PropTypes.object,
  translate: PropTypes.func.isRequired
};

Section.defaultProps = {
  section: []
};

ChartWrapper.propTypes = {
  ...Section.propTypes
};

export default localize(Section, 'locale');
