import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { routeWithClientId } from '../../helpers/location';
import { INTEGRATION_LIST } from '../../services/navigation';

export const ButtonImportData = ({ text }) => (
  <div className="TitleArea-button-import-data">
    <Link to={routeWithClientId(INTEGRATION_LIST)}>
      <Button>{text}</Button>
    </Link>
  </div>
);

ButtonImportData.propTypes = { text: PropTypes.string.isRequired };
