import React from 'react';
import PropTypes from 'prop-types';

import CommonWhiteButton from '../../../../../components/ButtonAction';
import { virkURL } from '../../../helpers/integration';
import virkLogo from '../../../../../shared/images/virkLogo.svg';

const Virk = ({ translate, language }) => (
  <div className="Integration-container">
    <div className="Integration-container-logo">
      <img alt="virk" className="virk-logo" src={virkLogo} />
    </div>
    <div className="Integration-container-text-area">
      <div className="Integration-container-text-title">
        {translate('list.virk.title')}
      </div>
      <div className="Integration-container-text">
        {translate('list.virk.importHelpText')}
      </div>
      <CommonWhiteButton
        // user is thrown to outside of KBO.
        handleClick={() => window.location.assign(virkURL(language))}
        id="virk"
        text="list.virk.importButton"
      />
    </div>
  </div>
);

Virk.propTypes = {
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
};

export default Virk;
