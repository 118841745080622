/**
 * Generic types validation.
 * @param {*} value
 * @param {*} numberFormat
 * @param {*} style
 */
export function hasValidTypes(value, numberFormat, style) {
  const isValidNumberFormat = value !== null && value !== undefined;
  const isValidStyle = style === 'decimal' || style === 'percent';
  const isValidValue = value !== null && value !== undefined;
  return (
    isValidNumberFormat &&
    isValidStyle &&
    isValidValue &&
    typeof numberFormat === 'string'
  );
}

/**
 * Specific string values validation.
 * Plus generic.
 *
 * Makes sure the values coming in are strings (typeof string)
 * and that all the arguments are present at all times.
 */
export function hasValidTypesForStringValues(value, numberFormat, style) {
  return typeof value === 'string' && hasValidTypes(value, numberFormat, style);
}

/**
 * Specific number values validation.
 * Plus generic.
 *
 * Makes sure the values coming in are numbers (typeof number)
 * and that all the arguments are present at all times.
 */
export function hasValidTypesForNumberValues(value, numberFormat, style) {
  return typeof value === 'number' && hasValidTypes(value, numberFormat, style);
}

export function errorHandlerForStringValues(
  value,
  numberFormat,
  style,
  errorIdentifier
) {
  switch (true) {
    case !value: {
      return {
        error: {
          message: `Error(${errorIdentifier}): value is missing`,
          type: 'debug'
        }
      };
    }

    case typeof value !== 'string': {
      return {
        error: {
          message: `Error(${errorIdentifier}): value is not string`,
          type: 'debug'
        }
      };
    }

    case !numberFormat: {
      return {
        error: {
          message: `Error(${errorIdentifier}): numberFormat is missing`,
          type: 'debug'
        }
      };
    }

    case !style: {
      return {
        error: {
          message: `Error(${errorIdentifier}): style is missing`,
          type: 'debug'
        }
      };
    }

    case style !== 'percent':
    case style !== 'decimal': {
      return {
        error: {
          message: `Error(${errorIdentifier}): style has wrong name`,
          type: 'debug'
        }
      };
    }

    default:
      return {
        error: {
          message: `Error(${errorIdentifier}): generic error`,
          type: 'debug'
        }
      };
  }
}

export function errorHandlerForNumberValues(
  value,
  numberFormat,
  style,
  errorIdentifier
) {
  switch (true) {
    case !value: {
      return {
        error: {
          message: `Error(${errorIdentifier}): value is missing`,
          type: 'debug'
        }
      };
    }

    case typeof value !== 'number': {
      return {
        error: {
          message: `Error(${errorIdentifier}): value is NaN`,
          type: 'debug'
        }
      };
    }

    case !numberFormat: {
      return {
        error: {
          message: `Error(${errorIdentifier}): numberFormat is missing`,
          type: 'debug'
        }
      };
    }

    case !style: {
      return {
        error: {
          message: `Error(${errorIdentifier}): style is missing`,
          type: 'debug'
        }
      };
    }

    case style !== 'percent':
    case style !== 'decimal': {
      return {
        error: {
          message: `Error(${errorIdentifier}): style has wrong name`,
          type: 'debug'
        }
      };
    }

    default:
      return {
        error: {
          message: `Error(${errorIdentifier}): generic error`,
          type: 'debug'
        }
      };
  }
}
