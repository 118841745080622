import React, { Fragment } from 'react';

import { VIRK } from '../../../../../../helpers/constants';
import { TranslateContext } from '../../../../../../components/Screen';
import { selectorHeaderType } from '../../types';
import { RowContext, MapScreenContext } from '../../../context';

import closeIcon from './closeIcon.svg';

const isVirk = integration => integration === VIRK;

export const SelectorHeader = ({ closeModal }) => (
  <section className="Selector-header">
    <button className="close-button" type="button" onClick={closeModal}>
      <img alt="close" src={closeIcon} />
    </button>
    <div className="header-text">
      <TranslateContext.Consumer>
        {({ translate }) => (
          <MapScreenContext.Consumer>
            {({ integration }) => (
              <RowContext.Consumer>
                {({ name, label }) => (
                  <Fragment>
                    <p className="title">
                      {translate('map.row.selector.title')}
                    </p>
                    <p className="subtitle">
                      {isVirk(integration) ? label : name}
                    </p>
                  </Fragment>
                )}
              </RowContext.Consumer>
            )}
          </MapScreenContext.Consumer>
        )}
      </TranslateContext.Consumer>
    </div>
  </section>
);

SelectorHeader.propTypes = selectorHeaderType;
