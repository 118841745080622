import React from 'react';

import './index.css';
import DropdownInput from './Dropdown';
import SelectNumberFormat from './SelectNumberFormat';
import { currencyFormatOptions, CURRENCY } from './constants';

export const Settings = () => (
  <div className="Settings-container">
    <div className="Settings-field Settings-currency">
      <DropdownInput
        defaultValue="da-DK"
        labelText="settings.currency"
        name={CURRENCY}
        options={currencyFormatOptions}
        subLabelText="settings.currencySubLabel"
      />
      <SelectNumberFormat />
    </div>
  </div>
);

export default Settings;
