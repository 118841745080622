import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import {
  SECTION_REPORTS,
  MANAGEMENT_REPORT_ROUTE_NAME
} from '../../../services/navigation';
import Screen from '../../../components/Screen';

import Main from './Main';
import './index.css';

import ReportManagementLocale from './locale.json';

const ReportManagement = props => {
  const { currentLocation } = props;

  return (
    <Screen
      showComments
      showDownloadButton
      showRightSideNavigation
      currentLocation={currentLocation}
      locale={ReportManagementLocale}
      section={SECTION_REPORTS}
    >
      <Main
        section={SECTION_REPORTS}
        title="managementReport"
        viewName="management_report"
        {...props}
      />
    </Screen>
  );
};

const mapStateToProps = state => ({
  currentLocation: state.router.location,
  kpi: state.reportManagement.kpi,
  sections: state.reportManagement.sections,
  year: state.reportManagement.fiscal_year,
  allYears: state.reportManagement.allYears,
  error: state.reportManagement.error,
  isFetchingInitialData: state.reportManagement.isFetchingInitialData,
  searchQuery: state.router.location.search,
  translate: getTranslate(state.locale)
});

ReportManagement.propTypes = {
  currentLocation: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  year: PropTypes.number
};

ReportManagement.defaultProps = {
  currentLocation: MANAGEMENT_REPORT_ROUTE_NAME,
  year: null
};

export default connect(mapStateToProps)(ReportManagement);
