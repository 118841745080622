/**
 * Placeholder for preparing payloads, preparing responses, and any other data sanitization
 * when communication backend <-> frontend.
 */
import {
  INPUT_BUDGET_ROUTE_NAME,
  INPUT_DATA_ROUTE_NAME,
  SIMULATION_REPORT_ROUTE_NAME,
  FORECAST_ROUTE_NAME
} from '../../services/navigation';
import { convertViewNameToKind } from '../../screens/Input/helpers';
import { getDataApiUrl } from '../../screens/Input/api/requests';

export function isInputScreen(viewName) {
  return (
    viewName === INPUT_BUDGET_ROUTE_NAME || viewName === INPUT_DATA_ROUTE_NAME
  );
}

/**
 * Ensures the backend receives value, id and all needed.
 *
 * Note: when the value is -0, a JSON.stringify() will 'convert' it to 0.
 * Because JSON.stringify is needed on the XHR calls, then the workaround is to flag
 * the payload with a boolean, to cover for the -0 case.
 *
 * @param  {Number} value
 * @param  {String} id [cell position]
 * @return {Object}
 */
export function preparePayload({
  rawValue,
  reference,
  viewName,
  currentFiscalYear,
  clientId,
  rowName
}) {
  let payload = {
    id: reference,
    client_id: Number.parseInt(clientId, 10),
    cell_value: {
      value: rawValue,
      negative_zero: Object.is(rawValue, -0),
      row_name: rowName
    }
  };

  if (isInputScreen(viewName)) {
    payload = {
      ...payload,
      current_fiscal_year: currentFiscalYear,
      year: currentFiscalYear,
      kind: convertViewNameToKind({ viewName })
    };
  }

  if (reference) {
    payload = {
      ...payload,
      id: reference
    };
  }

  return payload;
}

export function getCoordX(id) {
  return parseInt(id.split(',')[0], 10);
}

/**
 * The API call method depends on the fact if the id of the cell exists.
 * @param  {String} id [cell identifier (includes coordinates)]
 * @return {String}    [method]
 */
export function determineMethodType({ reference, viewName }) {
  return viewName === FORECAST_ROUTE_NAME ||
    viewName === SIMULATION_REPORT_ROUTE_NAME ||
    reference
    ? 'put'
    : 'post';
}

/**
 * The year being edited refers to the year correspondent
 * of the cell whose value is going to be sent to the backend.
 *
 * Because we only know the full array of years (available years),
 * and the current fiscal year, we need to use the position of the cell
 * to determine which year is being edited.
 *
 */
export const determineYearBeingEdited = ({
  currentFiscalYear,
  positionOfYearBeingEdited,
  availableYears,
  viewName
}) => {
  /**
   * The /input_budget only display one, which then we'll need only
   * the current fiscal year.
   */
  if (viewName === INPUT_BUDGET_ROUTE_NAME) return currentFiscalYear;

  /**
   * The /input_screen displays 5 years each time. The last year
   * being displayed is the current fiscal year. So the logic is to
   * slice the available years in an array of 5 years,
   * and use the position to determine which year in the array of 5 years
   * it corresponds to.
   */
  return (
    availableYears
      /**
       *  sorting will avoid any mistakes in case the avaiable as another
       * order than expected.
       */
      .sort()
      .slice(
        Math.max(availableYears.indexOf(currentFiscalYear - 4), 0),
        availableYears.indexOf(currentFiscalYear) + 1
      )[positionOfYearBeingEdited]
  );
};

const determineInputScreenAPIurl = ({
  viewName,
  reference,
  id,
  years,
  currentFiscalYear
}) => {
  const yearBeingEdited = determineYearBeingEdited({
    viewName,
    currentFiscalYear,
    availableYears: years,
    positionOfYearBeingEdited: getCoordX(id)
  });

  const apiPath = reference
    ? `accounting_values/${reference}`
    : 'accounting_values';

  return `${getDataApiUrl(window.location, true, yearBeingEdited)}/${apiPath}`;
};

/**
 * The API URL changes depending on the view.
 */
export function determineAPIurlForCellSubmitValue({
  clientId,
  currentFiscalYear,
  id,
  reference,
  viewName,
  years
}) {
  if (viewName === SIMULATION_REPORT_ROUTE_NAME) {
    return `${API_URL}/clients/${clientId}/reports/simulation_analysis`;
  }

  if (isInputScreen(viewName)) {
    return determineInputScreenAPIurl({
      clientId,
      viewName,
      reference,
      years,
      id,
      currentFiscalYear
    });
  }

  if (viewName === FORECAST_ROUTE_NAME) {
    return `${API_URL}/clients/${clientId}/reports/forecast_analysis`;
  }

  return null;
}
