import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addTranslation } from 'react-localize-redux';

import TopRow from '../../../components/TopRow';
import InfoCard from '../../../components/InfoCard';
import Footer from '../../../components/Footer';

import './index.css';
import icon from './icon-locked.svg';

import TopRowLocale from '../../../components/TopRow/locale.json';
import FooterLocale from '../../../components/Footer/locale.json';
import locale from '../locale.json';

class Unauthenticated extends PureComponent {
  static propTypes = { dispatch: PropTypes.func.isRequired };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(addTranslation(TopRowLocale));
    dispatch(addTranslation(locale));
    dispatch(addTranslation(FooterLocale));
  }

  render() {
    return (
      <div className="Unauthenticated-container full-height-container">
        <TopRow />
        <InfoCard
          buttonText="unauthenticated.mainButton"
          icon={icon}
          text="unauthenticated.body"
          title="unauthenticated.title"
          url="/"
        />
        <Footer />
      </div>
    );
  }
}

export default connect()(Unauthenticated);
