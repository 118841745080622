import React from 'react';
import PropTypes from 'prop-types';
import { localize } from 'react-localize-redux';

import './index.css';

const InfoBoxComponent = ({ translate, title, text }) => (
  <div className="InfoBox-container">
    <div className="InfoBox-area-title">{translate(title)}</div>
    <div className="InfoBox-area-text">{translate(text)}</div>
  </div>
);

InfoBoxComponent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

export const InfoBox = localize(InfoBoxComponent, 'locale');
