import axios from 'axios';

import { getClientId } from '../../../services/navigation/routeMapping';
import { prepareURL, prepareResponse } from './integration';
// action types
export const SENSITIVITY_ANALYSIS_INIT_FETCH_DATA =
  'SENSITIVITY_ANALYSIS_INIT_FETCH_DATA';
export const SENSITIVITY_ANALYSIS_SUCCESS_FETCH_DATA =
  'SENSITIVITY_ANALYSIS_SUCCESS_FETCH_DATA';
export const SENSITIVITY_ANALYSIS_VALUE_DRIVER_CHANGE =
  'SENSITIVITY_ANALISYS_VALUE_DRIVER_CHANGE';

/**
 * Upon fetch data, sanitize responses
 * and trigger a reducer update.
 * @param {object} response
 * @returns {object}
 */
function successFetchData(response, table) {
  const sanitizedResponse = prepareResponse(response, table);

  return {
    type: SENSITIVITY_ANALYSIS_SUCCESS_FETCH_DATA,
    payload: { ...sanitizedResponse }
  };
}

/**
 * Value Driver X and Y need to go in the url params.
 */
export function initFetchSensitivityAnalysisData(table) {
  return (dispatch, getState) => {
    const state = getState();
    const clientID = getClientId(state.router.location);
    const {
      valueDriverX,
      valueDriverY
    } = state.valuationReport.sensitivityAnalysis[`${table}`];
    const url = prepareURL(clientID, valueDriverX, valueDriverY);

    axios({
      method: 'GET',
      url,
      baseURL: API_URL
    }).then(response => dispatch(successFetchData(response, table)));
  };
}

/**
 * Init function.
 */
export function fetchData(table) {
  return dispatch => {
    dispatch({
      type: SENSITIVITY_ANALYSIS_INIT_FETCH_DATA,
      payload: { table }
    });
    dispatch(initFetchSensitivityAnalysisData(table));
  };
}

/**
 * Changes state value of the dropdowns (value drivers)
 * and after triggers an API call.
 * @param {*} event
 * @param {*} data
 * @param {*} axis
 */
export function onDropdownChange(event, data, axis, table) {
  return dispatch => {
    dispatch({
      type: SENSITIVITY_ANALYSIS_VALUE_DRIVER_CHANGE,
      payload: {
        table,
        value: data.value,
        axis
      }
    });
    dispatch(fetchData(table));
  };
}
